import React, { useEffect, useState } from "react";
import Layout from "../../components/global/Layout";
import LayoutToolsDistricts from "../../components/districts_components/LayoutToolsDistricts";
import CustomTable from "../../components/tables/CustomTable";
import { districtTables } from "../../utils/Tables";
import ItemDistrictTable from "../../components/districts_components/ItemDistrictTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearDistrictList,
  loadDistrictsRequest,
} from "../../stores/ducks/district/actions";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import { Center, Text } from "@chakra-ui/react";
import { District } from "../../@types/interfaces/api/district.interface";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import PaginationBase from "../../@types/interfaces/api/pagination-base.interface";
import { useSorting } from "../../hooks/useSorting";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";

const Districts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<District>();
  const { districtsList, isLoadingDistricts } = useAppSelector(
    (s) => s.district
  );
  const filterProps = useAppSelector((i) => i.filters);

  const pageCount = Number(districtsList?.pages);

  const { sorting, listSorted, setListSorted } = useSorting<District>();

  const onChangePage = (page: string) => {
    dispatch(
      loadDistrictsRequest({
        page,
        search: filterProps.search,
        is_active: filterProps.isActiveFilter,
      })
    );
    dispatch(setFilters({ ...filterProps, page }));
  };

  useEffect(() => {
    if (districtsList?.results) {
      setListSorted(districtsList?.results);
    }
  }, [districtsList]);

  useEffect(() => {
    return () => {
      dispatch(clearDistrictList());
      dispatch(clearFilter());
    };
  }, []);

  return (
    <Layout text={"Districts"}>
      <LayoutToolsDistricts
        selectedItems={items}
        onClickActions={clearSelections}
      />
      {listSorted && !isLoadingDistricts ? (
        <CustomTable
          isFullSelected={() => isFullSelected(listSorted)}
          headers={districtTables}
          onClickCheck={() => handleAll(listSorted)}
          onClickHeader={(col) => sorting(col)}
        >
          {listSorted &&
            listSorted.map((item) => (
              <ItemDistrictTable
                key={item.id}
                district={item}
                onClickCheckbox={() => handleItem(item)}
                isSelected={items.some((s) => s.id === item.id)}
              />
            ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}
      {listSorted && listSorted.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}

      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
    </Layout>
  );
};

export default Districts;
