import { Flex, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Plus, Search } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { loadClassroomRequest } from "../../stores/ducks/classroom/actions";
import { setFilters } from "../../stores/ducks/filters/actions";
import RegularInput from "../form/RegularInput";
import RegularButton from "../global/RegularButton";

const LayoutTollsClassroom: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setData } = useTypeDelay(
    (s) => {
      dispatch(setFilters({ search: s }));

      dispatch(loadClassroomRequest({ search: s }));
    },
    () => {
      dispatch(setFilters({ search: "" }));

      dispatch(loadClassroomRequest({}));
    }
  );
  const navigate = useNavigate();
  return (
    <Flex w="full" justify="space-between">
      <InputGroup as={Flex} maxW="40% ">
        <InputLeftElement
          mt="4px"
          ml="12px"
          children={<Search size="24" color="#A0AEC0" />}
        />
        <RegularInput
          placeholder="Search Classroom or Teacher "
          pl="52px"
          onChange={(e: any) => setData(e.target.value)}
        />
      </InputGroup>
      <Flex gap="16px" align="center">
        <RegularButton
          leftIcon={<Plus size="18px" />}
          onClick={() => navigate("/classroom/create-classroom")}
        >
          Create New Classroom
        </RegularButton>
        {/* <Menu>
          <MenuButton
            as={IconButton}
            borderRadius="50%"
            w="48px"
            h="48px"
            fontSize="24px"
            variant="outline"
            icon={<MoreVertical fontSize="24px" />}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <Text pl="24px" py="8px">
              Actions
            </Text>

            <MenuListItem borderTop="1px solid #E2E5EE" color="red">
              Delete Classroom
            </MenuListItem>
          </MenuList>
        </Menu> */}
      </Flex>
    </Flex>
  );
};

export default LayoutTollsClassroom;
