import { District } from "../../../@types/interfaces/api/district.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum DistrictActionTypes {
  LOAD_DISTRICTS_REQUEST = "@district/LOAD_DISTRICTS_REQUEST",
  LOAD_DISTRICTS_SUCCESS = "@district/LOAD_DISTRICTS_SUCCESS",
  LOAD_DISTRICTS_ERROR = "@district/LOAD_DISTRICTS_ERROR",

  LOAD_DISTRICT_BY_ID_REQUEST = "@district/LOAD_DISTRICT_BY_ID_REQUEST",
  LOAD_DISTRICT_BY_ID_SUCCESS = "@district/LOAD_DISTRICT_BY_ID_SUCCESS",
  LOAD_DISTRICT_BY_ID_ERROR = "@district/LOAD_DISTRICT_BY_ID_ERROR",

  DELETE_DISTRICTS_REQUEST = "@district/DELETE_DISTRICTS_REQUEST",
  DELETE_DISTRICTS_SUCCESS = "@district/DELETE_DISTRICTS_SUCCESS",
  DELETE_DISTRICTS_ERROR = "@district/DELETE_DISTRICTS_ERROR",

  UPDATE_DISTRICT_SUCCESS = "@district/UPDATE_DISTRICT_SUCCESS",
  UPDATE_DISTRICT_REQUEST = "@district/UPDATE_DISTRICT_REQUEST",
  UPDATE_DISTRICT_ERROR = "@district/UPDATE_DISTRICT_ERROR",

  DISTRICT_REGISTER_SUCCESS = "@district/DISTRICT_REGISTER_SUCCESS",
  DISTRICT_REGISTER_REQUEST = "@district/DISTRICT_REGISTER_REQUEST",
  DISTRICT_REGISTER_ERROR = "@district/DISTRICT_REGISTER_ERROR",

  BULK_UPDATE_DISTRICT_REQUEST = "@district/BULK_UPDATE_DISTRICT_REQUEST",
  BULK_UPDATE_DISTRICT_SUCCESS = "@district/BULK_UPDATE_DISTRICT_SUCCESS",
  BULK_UPDATE_DISTRICT_ERROR = "@district/BULK_UPDATE_DISTRICT_ERROR",

  CLEAR_DISTRICT_LIST = "@district/CLEAR_DISTRICT_LIST",
}

export interface DistrictState extends DistictStateRequestsStatus {
  district: District | undefined;
  districtsList: PaginationBase<District[]> | undefined;
  districtById: District | undefined;
}

interface DistictStateRequestsStatus {
  isLoadingDistricts: boolean;
  isLoadingDistrictById: boolean;
  isLoadingUpdate: boolean;
}
