import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";
import React from "react";

const SkeletonLibraryButton: React.FC = () => {
  return (
    <Flex gap="16px" mt="12px">
      <Skeleton w="286px" h="80px" borderRadius="16px" />
      <Skeleton w="286px" h="80px" borderRadius="16px" />
      <Skeleton w="286px" h="80px" borderRadius="16px" />
      <Skeleton w="286px" h="80px" borderRadius="16px" />
    </Flex>
  );
};

export default SkeletonLibraryButton;
