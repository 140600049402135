import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Edit, Plus, Trash, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import InputLabel from "../components/form/InputLabel";
import RegularInput from "../components/form/RegularInput";
import RegularMenu from "../components/form/RegularMenu";
import Layout from "../components/global/Layout";
import OutlineButton from "../components/global/OutlineButton";
import RegularButton from "../components/global/RegularButton";
import { Form } from "../styles/global";
import { useForm } from "react-hook-form";
import {
  UpdateProfile,
  UpdateUser,
} from "../@types/interfaces/api/user.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfileFormSchema } from "../utils/yup-schemas";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  loadTokenRequest,
  updateUserRequest,
} from "../stores/ducks/auth/actions";
import { updateProfilePhotoRequest } from "../stores/ducks/profile/actions";

const Profile: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [filePreview, setfilePreview] = useState("");
  const { user } = useAppSelector((i) => i.auth);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateUser>({
    defaultValues: {
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      email: user ? user.email : "",
      username: user ? user.username : "",
    },
    resolver: yupResolver(updateProfileFormSchema),
  });

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || file.size > 1048576) {
      window.alert("The file size exceeds 1MB ");
      return;
    }
    setSelectedFile(file);
  };
  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      if (reader.result) {
        setfilePreview(reader.result as string);
      }
    };
  }, [selectedFile]);

  const submitForm = (values: UpdateUser) => {
    if (user?.id) {
      dispatch(updateUserRequest(user?.id, values));
    }
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      dispatch(updateProfilePhotoRequest(formData));
      let reader = new FileReader();
      reader.readAsText(selectedFile);
    }
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <Layout text="Profile">
      <Form onSubmit={handleSubmit(submitForm)}>
        <Flex
          w="full"
          p="24px"
          bg="white"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          {selectedFile ? (
            <Flex
              flexDir="column"
              mr="40px"
              align="center"
              gap="16px"
              w="170px"
            >
              <Avatar
                boxSize="100px"
                src={
                  filePreview !== ""
                    ? filePreview
                    : user?.profile_photo ?? undefined
                }
              />
              <Flex w="150px" overflow="hidden">
                <Text fontSize="10px">{selectedFile.name}</Text>
              </Flex>

              <OutlineButton
                leftIcon={<Edit size="14px" />}
                fontSize="14px"
                h="32px"
                p="12px"
              >
                <Text as="label" htmlFor={"#fileInput"} cursor="pointer">
                  Change Photo
                  <Input
                    hidden
                    type="file"
                    id={"#fileInput"}
                    name="file"
                    accept={"image/png, image/jpeg"}
                    onChange={handleImageSelect}
                  />
                </Text>
              </OutlineButton>
              <Button
                variant="ghost"
                leftIcon={<Trash2 size="14px" />}
                fontSize="14px"
                h="32px"
                p="12px"
                borderRadius="16px"
                onClick={() => setSelectedFile(undefined)}
              >
                Remove
              </Button>
            </Flex>
          ) : (
            <Flex
              flexDir="column"
              mr="40px"
              align="center"
              gap="16px"
              w="170px"
            >
              <Avatar
                boxSize="100px"
                src={user?.profile_photo ? user.profile_photo : undefined}
              />

              <OutlineButton
                leftIcon={<Plus size="14px" />}
                fontSize="14px"
                h="32px"
                p="12px"
              >
                <Text as="label" htmlFor={"#fileInput"} cursor="pointer">
                  Add Photo
                  <Input
                    hidden
                    type="file"
                    id={"#fileInput"}
                    name="file"
                    accept={"image/png, image/jpeg"}
                    onChange={(e) => {
                      const file = e.target.files ? e.target.files[0] : null;
                      if (file && file?.size < 1048576) {
                        setSelectedFile(file);
                      } else {
                        window.alert("The file size exceeds 1MB ");
                      }
                    }}
                  />
                </Text>
              </OutlineButton>
            </Flex>
          )}

          <Grid gap="24px" w="full" templateColumns="repeat(2, 1fr)">
            <GridItem>
              <FormControl isInvalid={!!errors.first_name}>
                <InputLabel>
                  First Name*
                  <RegularInput mt="4px" {...register("first_name")} />
                  {
                    <FormErrorMessage>
                      {errors.first_name?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={!!errors.last_name}>
                <InputLabel>
                  Last Name*
                  <RegularInput mt="4px" {...register("last_name")} />
                  {
                    <FormErrorMessage>
                      {errors.last_name?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={!!errors.email}>
                <InputLabel>
                  Email Address*
                  <RegularInput mt="4px" type="email" {...register("email")} />
                  {<FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
                </InputLabel>
              </FormControl>
            </GridItem>
          </Grid>
        </Flex>
        <Flex
          mt="24px"
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          <Heading fontWeight="600" fontSize="18px">
            Account Access
          </Heading>
          <Text fontWeight="500" fontSize="16px" mt="16px" color="gray.500">
            Change your access information
          </Text>
          <Flex flexDir="column" mt="24px" gap="24px" w="full">
            <Flex w="full" gap="24px">
              <InputLabel>
                Username*
                <RegularInput mt="4px" {...register("username")} />
                <Text fontWeight="400" fontSize="14px" color="gray.500">
                  Space and capital letters aren't allowed
                </Text>
              </InputLabel>
              <InputLabel>
                Password*
                <RegularInput mt="4px" {...register("password")} />
              </InputLabel>
              <InputLabel>
                Repeat Password*
                <RegularInput mt="4px" {...register("repeat_password")} />
              </InputLabel>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mt="24px"
          mb="100px"
          gap="12px"
          w="full"
          justifyContent="flex-end"
        >
          <RegularButton type="submit">Save</RegularButton>
        </Flex>
      </Form>
    </Layout>
  );
};

export default Profile;
