import { Reducer } from "redux";
import { AuthActionTypes, AuthState } from "./types";
import { ProfileActionTypes } from "../profile/types";

const INITIAL_STATE: AuthState = {
  authenticated: false,
  isSignInLoading: false,
  isTokenLoading: true,
  user: undefined,
  usersList: undefined,
  isLoadingUsers: false,
  isLoadingUserById: false,
  isLoadingUpdate: false,
  userById: undefined,
  recovered: false,
  recovering: false,
  reseting: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        isSignInLoading: true,
      };
    case AuthActionTypes.SIMULATE_USER_REQUEST:
      return {
        ...state,
        isSignInLoading: true,
      };
    case AuthActionTypes.RECOVER_PASSWORD_REQUEST:
      return {
        ...state,
        recovering: true,
      };
    case AuthActionTypes.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recovering: false,
        recovered: true,
      };
    case AuthActionTypes.RECOVER_USERNAME_REQUEST:
      return {
        ...state,
        recovering: true,
      };
    case AuthActionTypes.RECOVER_USERNAME_SUCCESS:
      return {
        ...state,
        recovering: false,
        recovered: true,
      };
    case ProfileActionTypes.UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        user: { ...state.user!, profile_photo: action.payload },
      };
    case AuthActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        reseting: true,
      };
    case AuthActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reseting: false,
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isSignInLoading: false,
      };
    case AuthActionTypes.SIGN_IN_ERROR:
      return {
        ...INITIAL_STATE,
        isSignInLoading: false,
        isTokenLoading: false,
      };

    case AuthActionTypes.LOG_OUT:
      return {
        ...INITIAL_STATE,
        isTokenLoading: false,
      };
    case AuthActionTypes.LOAD_TOKEN_REQUEST:
      return {
        ...state,
        isTokenLoading: true,
      };
    case AuthActionTypes.LOAD_TOKEN_SUCCESS:
      return {
        ...state,
        isTokenLoading: false,
        authenticated: true,
        user: action.payload,
      };
    case AuthActionTypes.LOAD_TOKEN_ERROR:
      return {
        ...state,
        isTokenLoading: false,
        authenticated: false,
      };
    case AuthActionTypes.LOAD_USERS_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case AuthActionTypes.LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        isLoadingUsers: false,
      };
    case AuthActionTypes.LOAD_USERS_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingUsers: false,
      };
    case AuthActionTypes.LOAD_USER_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingUserById: true,
      };
    case AuthActionTypes.LOAD_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userById: action.payload,
        isLoadingUserById: false,
      };
    case AuthActionTypes.LOAD_USER_BY_ID_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingUserById: false,
      };

    case AuthActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoadingUpdate: true,
      };
    case AuthActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
      };
    case AuthActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isLoadingUpdate: false,
      };

    case AuthActionTypes.ADMIN_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case AuthActionTypes.ADMIN_REGISTER_SUCCESS:
      return {
        ...state,
        isLoaisLoadingUsersding: false,
      };
    case AuthActionTypes.ADMIN_REGISTER_ERROR:
      return {
        ...state,
        isLoadingUsers: false,
      };
    case AuthActionTypes.PRINCIPAL_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case AuthActionTypes.PRINCIPAL_REGISTER_SUCCESS:
      return {
        ...state,
        isLoaisLoadingUsersding: false,
      };
    case AuthActionTypes.PRINCIPAL_REGISTER_ERROR:
      return {
        ...state,
        isLoadingUsers: false,
      };
    case AuthActionTypes.TEACHER_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case AuthActionTypes.TEACHER_REGISTER_SUCCESS:
      return {
        ...state,
        isLoaisLoadingUsersding: false,
      };
    case AuthActionTypes.TEACHER_REGISTER_ERROR:
      return {
        ...state,
        isLoadingUsers: false,
      };
    case AuthActionTypes.CLEAR_USER_LIST:
      return { ...state, usersList: undefined };

    case AuthActionTypes.CLEAR_USER:
      return {
        ...state,
        userById: undefined,
        usersList: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
