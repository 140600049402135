import {
  Button,
  Divider,
  Flex,
  Image,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React, { useState } from "react";
import MenuLink from "./MenuLink";
import logo from "../../assets/image/logo.png";
import {
  Contact,
  UserCog,
  GraduationCap,
  FileSpreadsheet,
  FolderOpen,
  BookOpen,
  Album,
  HelpCircle,
  MessageSquare,
  LogOut,
  Activity,
  Diamond,
  MapPin,
  Building,
  Users,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import ModalHelpFaq from "../modals/ModalHelpFaq";
import Students from "../../pages/students/Students";

const Sidebar: React.FC = () => {
  const [modalOpen, setModalOpen] = useState({ isOpen: false, title: "" });
  const { user } = useAppSelector((i) => i.auth);

  const adminPrincipal = ["admin", "principal"].includes(
    user?.user_type as string
  );

  return (
    <Flex
      display={["none", "flex"]}
      pos="sticky"
      top="0"
      left="0"
      flexDir="column"
      align="flex-start"
      h="100vh"
      maxW="264px"
      minW="264px"
      bgColor="white"
      pl="32px"
      borderRight={"1px solid #E2E8F0"}
    >
      <Link to="/dashboard">
        <Image src={logo} maxW="135px" mt="18px" ml="16px" />
      </Link>

      <Flex
        w="100%"
        flexDir="column"
        mt="24px"
        mb="24px"
        gap="8px"
        overflowX="auto"
        pr="32px"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#CBD5E0",
            borderRadius: "100px",
          },
        }}
      >
        <MenuLink to="/dashboard" leftIcon={<Activity size="16" />} mt="24px">
          Dashboard
        </MenuLink>

        <Text fontWeight="600" pl="16px" mt="24px">
          Management
        </Text>
        <Divider my="16px" />
        {user?.user_type === "admin" && (
          <Flex w="100%" flexDir="column" gap="8px">
            <MenuLink to="/districts" leftIcon={<MapPin size="16" />}>
              Districts
            </MenuLink>
            <MenuLink to="/schools" leftIcon={<Building size="16" />}>
              Schools
            </MenuLink>
          </Flex>
        )}

        <MenuLink to="/classroom" leftIcon={<Users size="16" />}>
          Classrooms
        </MenuLink>

        <Text fontWeight="600" pl="16px" mt="24px">
          Users
        </Text>
        <Divider my="16px" />
        {user?.user_type === "admin" && (
          <MenuLink to="/admin" leftIcon={<UserCog size="16" />}>
            Administrators
          </MenuLink>
        )}

        {user?.user_type === "admin" && (
          <MenuLink to="/principals" leftIcon={<Diamond size="16" />}>
            Principals
          </MenuLink>
        )}

        {adminPrincipal && (
          <MenuLink to="/teachers" leftIcon={<Contact size="16" />}>
            Teachers
          </MenuLink>
        )}

        {user?.user_type !== "student" && (
          <MenuLink to="/students" leftIcon={<GraduationCap size="16" />}>
            Students
          </MenuLink>
        )}

        <Divider my="16px" />
        <MenuLink to="/build-report" leftIcon={<FileSpreadsheet size="16" />}>
          Build Report
        </MenuLink>

        <MenuLink to="/library" leftIcon={<BookOpen size="16" />}>
          Library
        </MenuLink>

        <MenuLink to="/tutorials" leftIcon={<FolderOpen size="16" />}>
          Tutorials
        </MenuLink>
        <MenuLink to="/certificates" leftIcon={<Album size="16" />}>
          Certificates
        </MenuLink>

        <ChakraLink
          href="https://help.failurefreereading.net/help-center/"
          isExternal
        >
          <Button
            w="full"
            borderRadius="100px"
            justifyContent="flex-start"
            fontWeight="500"
            fontSize="14px"
            color="gray.500"
            iconSpacing="12px"
            _selected={{ bg: "gray.600", color: "white" }}
            variant="ghost"
            p="12px 14px"
            leftIcon={<HelpCircle size="16" />}
          >
            Help
          </Button>
        </ChakraLink>
        <ChakraLink href="https://help.failurefreereading.net/faq/" isExternal>
          <Button
            w="full"
            borderRadius="100px"
            justifyContent="flex-start"
            fontWeight="500"
            fontSize="14px"
            color="gray.500"
            iconSpacing="12px"
            _selected={{ bg: "gray.600", color: "white" }}
            variant="ghost"
            p="12px 14px"
            leftIcon={<MessageSquare size="16" />}
          >
            Faq
          </Button>
        </ChakraLink>
      </Flex>
      <ModalHelpFaq
        isOpen={modalOpen}
        onClose={() => setModalOpen({ ...modalOpen, isOpen: false })}
      />
    </Flex>
  );
};

export default Sidebar;
