import { Button, Flex, Progress, Tag, Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { StudentHistory } from "../../@types/interfaces/api/student.interface";
import { StoriesReport } from "../../@types/interfaces/api/buildReport.interface";

interface ItemViewStudentProps {
  data: StoriesReport;
}

const ItemProgressStories: React.FC<ItemViewStudentProps> = ({ data }) => {
  return (
    <Tr>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text>{data.story_name}</Text>
      </Td>
      <Td p="18px 16px">
        <Text>{data.total_lessons}</Text>
      </Td>

      <Td p="18px 16px" maxW="300px" whiteSpace="pre-wrap">
        <Text>{data.lessons_completed}</Text>
      </Td>
      <Td p="18px 16px" w="300px">
        <Flex w="full" flexDir="row" align="center" gap="16px">
          <Progress
            w="90%"
            colorScheme="blueScheme"
            borderRadius="full"
            size="md"
            value={data.pre_test}
          />
          <Text color="primary" fontWeight="700">
            {data.pre_test}%
          </Text>
        </Flex>
      </Td>
      <Td p="18px 16px" w="300px">
        <Flex w="full" flexDir="row" align="center" gap="16px">
          <Progress
            w="90%"
            colorScheme="blueScheme"
            borderRadius="full"
            size="md"
            value={data.post_test}
          />
          <Text color="primary" fontWeight="700">
            {data.post_test}%
          </Text>
        </Flex>
      </Td>
      <Td p="18px 16px" maxW="200px">
        <Flex maxW="200px" flexDir="row" align="center" gap="16px">
          <Text color={data.growth >= 0 ? "#41B081" : "red"} fontWeight="700">
            {data.growth}%
          </Text>
        </Flex>
      </Td>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text>{data.total_time}</Text>
      </Td>
    </Tr>
  );
};

export default ItemProgressStories;
