import React from "react";
import { Badge, Text } from "@chakra-ui/react";

interface BadgeStatusProps {
  status: "active" | "inactive";
}

const BadgeStatus: React.FC<BadgeStatusProps> = ({ status }) => {
  const badgeText = {
    active: {
      text: "Active",
      bgColor: "#41B081",
      textColor: "#ffff",
    },
    inactive: {
      text: "Inactive",
      bgColor: "#E53E3E",
      textColor: "#ffff",
    },
  };

  return (
    <Badge
      variant="subtle"
      bgColor={badgeText[status].bgColor}
      color={badgeText[status].textColor}
      fontSize="12px"
      lineHeight="20px"
      px="12px"
      borderRadius="100px"
      textTransform="unset"
      fontWeight="400"
    >
      {badgeText[status].text}
    </Badge>
  );
};

export default BadgeStatus;
