import { NoteActionTypes, NoteState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: NoteState = {
  note: undefined,
  noteList: [],
  isLoadingNote: false,
};

const reducer: Reducer<NoteState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NoteActionTypes.CREATE_NOTE_REQUEST:
      return {
        ...state,
        isLoadingNote: true,
      };
    case NoteActionTypes.CREATE_NOTE_SUCCESS:
      return {
        ...state,
        isLoadingNote: false,
      };
    case NoteActionTypes.CREATE_NOTE_ERROR:
      return { ...INITIAL_STATE, isLoadingNote: false };

    case NoteActionTypes.LOAD_NOTE_REQUEST:
      return {
        ...state,
        isLoadingNote: true,
      };
    case NoteActionTypes.LOAD_NOTE_SUCCESS:
      return {
        ...state,
        isLoadingNote: false,
        noteList: action.payload,
      };
    case NoteActionTypes.LOAD_NOTE_ERROR:
      return { ...INITIAL_STATE, isLoadingNote: false };

    case NoteActionTypes.DELETE_NOTE_REQUEST:
      return {
        ...state,
        isLoadingNote: true,
      };
    case NoteActionTypes.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        isLoadingNote: false,
      };
    case NoteActionTypes.DELETE_NOTE_ERROR:
      return { ...INITIAL_STATE, isLoadingNote: false };

    default:
      return state;
  }
};

export default reducer;
