import {
  Flex,
  IconButton,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { MoreVertical, Plus, Search } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Student } from "../../@types/interfaces/api/student.interface";
import UsersBulkUpdateInterface from "../../@types/interfaces/api/users-bulk-update.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { bulkUpdateUsersRequest } from "../../stores/ducks/auth/actions";
import { setFilters } from "../../stores/ducks/filters/actions";
import { loadSchoolsReportRequest } from "../../stores/ducks/school/actions";
import { loadStudentRequest } from "../../stores/ducks/student/action";
import RegularInput from "../form/RegularInput";
import MenuFilterActiveStudent from "../global/MenuFilterActiveStudent";
import MenuListItem from "../global/MenuListItem";
import RegularButton from "../global/RegularButton";

interface LayoutToolsStudentsProps {
  onOpen: () => void;
  selectedItems: Student[];
  onClickActions?: () => void;
}

const LayoutToolsStudents: React.FC<LayoutToolsStudentsProps> = ({
  onOpen,
  selectedItems,
  onClickActions = () => null,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const selectedIds = selectedItems.map((d) => d.user);
  const filterProps = useAppSelector((i) => i.filters);
  const { schoolsListReport } = useAppSelector((s) => s.school);
  const { user } = useAppSelector((i) => i.auth);
  const isAdmin = user?.user_type === "admin";

  const changeStatus = (data: UsersBulkUpdateInterface) => {
    dispatch(bulkUpdateUsersRequest(selectedIds, data, "student"));
    dispatch(loadStudentRequest({}));
    onClickActions();
    window.location.reload();
  };

  console.log(filterProps.school);

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(
        setFilters({
          ...filterProps,
          search: s,
          isActiveFilter: filterProps.isActiveFilter,
        })
      );
      if (id) {
        dispatch(
          loadStudentRequest({
            search: s,
            classroom__id__exact: Number(id),
          })
        );
      } else {
        dispatch(
          loadStudentRequest({
            search: s,
            user__is_active__exact: filterProps.isActiveFilter,
            red_flag: filterProps.redFlag,
            user__profile__school__id__exact: filterProps.school,
          })
        );
      }
    },
    () => {
      dispatch(
        setFilters({
          ...filterProps,
          search: "",
          isActiveFilter: filterProps.isActiveFilter,
        })
      );
      if (id) {
        dispatch(loadStudentRequest({ classroom__id__exact: Number(id) }));
      } else {
        dispatch(
          loadStudentRequest({
            user__is_active__exact: filterProps.isActiveFilter,
            red_flag: filterProps.redFlag,
            user__profile__school__id__exact: filterProps.school,
          })
        );
      }
    }
  );

  const filterActive = (e: any) => {
    if (e.target.value === "active") {
      dispatch(
        setFilters({
          ...filterProps,
          search: data,
          isActiveFilter: true,
          filterLabel: "Active",
        })
      );
      dispatch(
        loadStudentRequest({
          user__is_active__exact: true,
          search: data,
          user__profile__school__id__exact: filterProps.school,
        })
      );
    } else if (e.target.value === "inactive") {
      dispatch(
        setFilters({
          ...filterProps,
          search: data,
          isActiveFilter: false,
          filterLabel: "Inactive",
        })
      );
      dispatch(
        loadStudentRequest({
          user__is_active__exact: false,
          search: data,
          user__profile__school__id__exact: filterProps.school,
        })
      );
    } else if (e.target.value === "is_redflag") {
      dispatch(
        setFilters({
          ...filterProps,
          search: data,
          redFlag: true,
          filterLabel: "Red Flagged",
        })
      );
      dispatch(
        loadStudentRequest({
          red_flag: true,
          search: data,
          user__profile__school__id__exact: filterProps.school,
        })
      );
    } else if (e.target.value === "non_redflag") {
      dispatch(
        setFilters({
          ...filterProps,
          search: data,
          redFlag: false,
          filterLabel: "Nom Red Flagged",
        })
      );
      dispatch(
        loadStudentRequest({
          red_flag: false,
          search: data,
          user__profile__school__id__exact: filterProps.school,
        })
      );
    } else {
      dispatch(
        setFilters({
          ...filterProps,
          search: data,
          isActiveFilter: null,
          redFlag: null,
          filterLabel: "View All",
        })
      );
      dispatch(
        loadStudentRequest({
          user__is_active__exact: null,
          search: data,
          user__profile__school__id__exact: filterProps.school,
        })
      );
    }
  };

  const { setData: setSchool } = useTypeDelay(
    (s) => {
      if (isAdmin) {
        dispatch(
          loadSchoolsReportRequest({
            search: s,
            is_active__exact: true,
            pagination: "true",
          })
        );
      }
    },
    () => {
      if (isAdmin) {
        dispatch(loadSchoolsReportRequest({ pagination: "true" }));
      }
    }
  );

  return (
    <Flex w="full" justify="space-between">
      <Flex w="full" gap="16px">
        <InputGroup as={Flex} maxW="30% ">
          <InputLeftElement
            mt="4px"
            ml="12px"
            children={<Search size="24" color="#A0AEC0" />}
          />
          <RegularInput
            placeholder="Search Students"
            pl="52px"
            onChange={(e: any) => setData(e.target.value)}
          />
        </InputGroup>
        {isAdmin && !id && (
          <Select
            placeholder="Search School(s):"
            onChange={(value) => {
              dispatch(
                setFilters({
                  ...filterProps,
                  school: Number(value?.value),
                  schoolName: value?.label,
                })
              );
              dispatch(
                loadStudentRequest({
                  red_flag: filterProps.redFlag,
                  user__is_active__exact: filterProps.isActiveFilter,
                  user__profile__school__id__exact: Number(value?.value),
                  page: filterProps.page,
                })
              );
            }}
            onInputChange={(e) => {
              setSchool(e);
            }}
            value={
              filterProps.school
                ? {
                    value: filterProps.school,
                    label: filterProps.schoolName,
                  }
                : null
            }
            options={
              schoolsListReport &&
              schoolsListReport?.results.map((data) => ({
                label: data.name,
                value: data.id,
              }))
            }
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                minWidth: "200px",
                maxWidth: "300px",
                borderRadius: "96px",
                height: "48px",
                background: "transparent",
                borderColor: "gray.500",
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: "200px",
                maxWidth: "300px",
              }),
            }}
          />
        )}
        {!id && (
          <MenuFilterActiveStudent
            handleClick={filterActive}
            namePage="Student"
            filter={filterProps.filterLabel ?? "View All"}
          />
        )}
      </Flex>
      {!id && (
        <Flex gap="16px" align="center">
          {/* <Button
            variant="ghost"
            leftIcon={<Import size="18px" />}
            onClick={onOpen}
          >
            Import Students
          </Button> */}

          <RegularButton
            leftIcon={<Plus size="18px" />}
            onClick={() => navigate("/students/enroll-students")}
          >
            Enroll Students
          </RegularButton>

          <Menu>
            <MenuButton
              as={IconButton}
              borderRadius="50%"
              w="48px"
              h="48px"
              fontSize="24px"
              variant="outline"
              icon={<MoreVertical fontSize="24px" />}
            />
            <MenuList
              borderRadius="16px"
              boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
              border="1px solid #E2E5EE"
            >
              <MenuListItem
                fontSize="16px"
                _hover={{ color: "primary" }}
                color="green"
                onClick={() => changeStatus({ is_active: true })}
              >
                Activate Student
              </MenuListItem>
              <MenuListItem
                fontSize="16px"
                _hover={{ color: "primary" }}
                borderTop="1px solid #E2E5EE"
                color="red"
                onClick={() => changeStatus({ is_active: false })}
              >
                Inactivate Student
              </MenuListItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
    </Flex>
  );
};

export default LayoutToolsStudents;
