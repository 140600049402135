import { Profile } from "../../../@types/interfaces/api/profile.interface";

export enum ProfileActionTypes {
  UPDATE_PROFILE_REQUEST = "@profile/UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS = "@profile/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR = "@profile/UPDATE_PROFILE_ERROR",

  UPDATE_PROFILE_PHOTO_REQUEST = "@profile/UPDATE_PROFILE_PHOTO_REQUEST",
  UPDATE_PROFILE_PHOTO_SUCCESS = "@profile/UPDATE_PROFILE_PHOTO_SUCCESS",
  UPDATE_PROFILE_PHOTO_ERROR = "@profile/UPDATE_PROFILE_PHOTO_ERROR",
}

export interface ProfileState extends ProfileStateRequestsStatus {
  profile: Profile | undefined;
}
export interface ProfileStateRequestsStatus {
  isLoadingProfile: boolean;
}
