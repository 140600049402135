import { DashboardActionTypes, DashboardState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: DashboardState = {
  isLoading: false,
  adminDashboard: undefined,
  principalDashboard: undefined,
  teacherDashboard: undefined,
  dashboardAdminDataChartDonuts: {
    labels: [],
    series: [],
    totalUsers: 0,
  },
  dashboardAdminDataChartPie: {
    labels: [],
    series: [],
    totalDiagnostic: 0,
  },
  dashboardAdminDataChartBarJr: undefined,
  dashboardAdminDataChartBarLS: undefined,
  dashboardAdminDataChartBarVm: undefined,

  dashboardPrincipalDataChartDonuts: {
    labels: [],
    series: [],
    totalUsers: 0,
  },
  dashboardPrincpalDataChartPie: {
    labels: [],
    series: [],
    totalDiagnostic: 0,
  },
  dashboardPrincipalDataChartBarJr: undefined,
  dashboardPrincipalDataChartBarLS: undefined,
  dashboardPrincipalDataChartBarVm: undefined,

  dashboaTeacherlDataChartPie: {
    labels: [],
    series: [],
    totalDiagnostic: 0,
  },
  dashboardTeacherDataChartDonuts: {
    labels: [],
    series: [],
    totalUsers: 0,
  },
  dashboardTeacherDataChartBarJr: undefined,
  dashboardTeacherDataChartBarLS: undefined,
  dashboardTeacherDataChartBarVm: undefined,
};

const reducer: Reducer<DashboardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.LOAD_DASHBOARD_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminDashboard: action.payload.data,
        dashboardAdminDataChartDonuts: action.payload.dataChartsDonuts,
        dashboardAdminDataChartPie: action.payload.dataChartsPie,
        dashboardAdminDataChartBarJr: action.payload.dataChartsBarJr,
        dashboardAdminDataChartBarLS: action.payload.dataChartsBarLs,
        dashboardAdminDataChartBarVm: action.payload.dataChartsBarVm,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        principalDashboard: action.payload.data,
        dashboardPrincipalDataChartDonuts: action.payload.userStatusData,
        dashboardPrincpalDataChartPie: action.payload.dataChartsPiDp,
        dashboardPrincipalDataChartBarJr: action.payload.dataChartsBarJr,
        dashboardPrincipalDataChartBarLS: action.payload.dataChartsBarLs,
        dashboardPrincipalDataChartBarVm: action.payload.dataChartsBarVm,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DashboardActionTypes.LOAD_DASHBOARD_TEACHER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teacherDashboard: action.payload.data,
        dashboardTeacherDataChartDonuts: action.payload.userStatusData,
        dashboaTeacherlDataChartPie: action.payload.dataChartsPiDp,
        dashboardTeacherDataChartBarJr: action.payload.dataChartsBarJr,
        dashboardTeacherDataChartBarLS: action.payload.dataChartsBarLs,
        dashboardTeacherDataChartBarVm: action.payload.dataChartsBarVm,
      };
    case DashboardActionTypes.LOAD_DASHBOARD_TEACHER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DashboardActionTypes.CLEAR_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
