import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import RegularButton from "../components/global/RegularButton";

interface ErrorFallbackProps {
  error: string;
  reset: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, reset }) => {
  return (
    <Flex
      w="full"
      h="100vh"
      justify="center "
      align="center"
      flexDir="column"
      py="150px"
    >
      <Text fontSize="32px" fontWeight="500">
        Something went wrong.
      </Text>
      <Text fontSize="20px" mt="34px">
        We apologize, but an unexpected error occurred.
      </Text>
      <Text fontSize="20px">
        Please try refreshing the page or contact our support team for
        assistance.
      </Text>
      <Flex
        fontSize="20px"
        mt="34px"
        flex={1}
        bg="#ff4343"
        color="#fff"
        p="10px"
      >
        Error: <Text>{error}</Text>
      </Flex>
      <RegularButton onClick={reset} mt="34px">
        Return to dashboard
      </RegularButton>
    </Flex>
  );
};

export default ErrorFallback;
