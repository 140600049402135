import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import { generateKey } from "crypto";
import React, { LegacyRef, forwardRef } from "react";

const InputLogin = (
  { type = "text", ...rest }: InputProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <Input
      ref={ref}
      h="48px"
      borderRadius="100px"
      _placeholder={{ color: "gray.400" }}
      bg="white"
      p="12px 44px"
      type={type}
      {...rest}
    />
  );
};

export default forwardRef<HTMLInputElement, InputProps>(InputLogin);
