import { Flex, Text, TextProps } from "@chakra-ui/react";
import React, { Dispatch } from "react";
import { UseFormSetValue } from "react-hook-form";
import { AnyAction } from "redux";
import PaginationBase from "../../@types/interfaces/api/pagination-base.interface";

interface SearchSelectProps<T> extends TextProps {
  list: PaginationBase<T[]> | undefined;
  onMouseClick: (short_name: string) => void;
}

const SearchSelect: React.FC<SearchSelectProps<any>> = ({
  list,
  onMouseClick,
  ...rest
}) => {
  return (
    <Flex
      borderRadius="8px"
      flexDir="column"
      boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
      border="1px solid #E2E5EE"
      position="absolute"
      bg="white"
      top="55px"
      left="10px"
      zIndex="99"
      maxH="250px"
      overflow="scroll"
    >
      {list &&
        list.results.map((item) => (
          <Text
            color="gray.500"
            fontWeight="400"
            py="8px"
            cursor="pointer"
            _hover={{
              bgColor: "#ebebeb",
            }}
            px="16px"
            key={item.id}
            {...rest}
            onMouseDown={() => onMouseClick(item.short_name)}
          >
            {item.long_name}
          </Text>
        ))}
    </Flex>
  );
};

export default SearchSelect;
