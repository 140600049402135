import { Flex, FlexProps, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";
import { areaChartConfig } from "../../utils/charts/areaChartConfig";
import { barChartConfig } from "../../utils/charts/barChartConfig";

interface ContainerChartsAreaProps extends ComponentProps, FlexProps {
  title: string;
  series: { name: string; data: number[] }[] | undefined;
  categories: string[] | undefined;
  type: "area" | "bar";
  colors?: string[];
  total?: number;
}

const ContainerChartsArea: React.FC<ContainerChartsAreaProps> = ({
  title,
  categories,
  series,
  type,
  colors,
  total,
  ...rest
}) => {
  const [width, setWidth] = useState<number>(0);
  const chartRef = useRef(null);

  const parent = useRef<HTMLDivElement>();
  //set chart width based on parent
  const loadBounding = (node: HTMLDivElement) => {
    if (node !== null) {
      parent.current = node;
      setWidth(node.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    const listener = () => {
      if (!parent.current) return;
      setWidth(parent.current.getBoundingClientRect().width);
    };

    //ensure chart resize based on window resize
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const chartOptions = {
    series,
    options:
      type === "area"
        ? areaChartConfig({
            chartId: `${type}-chart`,
            categories,
          })
        : colors &&
          barChartConfig({
            chartId: `${type}-chart`,
            categories,
            colors,
          }),
  };

  return (
    <Flex
      w="full"
      h="100%"
      bg="white"
      flexDir="column"
      p="24px"
      boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      borderRadius="16px"
      {...rest}
    >
      <Flex flexDir="column">
        <Text fontWeight="600">{title}</Text>
        {title !== "Red Flags" && (
          <Text fontSize="14px" color="gray.500">
            Total {total}
          </Text>
        )}

        <Flex
          height="100%"
          flex={1}
          ref={loadBounding}
          css={`
            .apexcharts-legend {
              padding: 0 !important;
            }
          `}
        >
          <Chart
            ref={chartRef}
            width={width}
            height="220px"
            type={type}
            series={chartOptions.series}
            options={{ ...chartOptions.options }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContainerChartsArea;
