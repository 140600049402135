import { Center, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { User } from "../../@types/interfaces/api/user.interface";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import CustomTable from "../../components/tables/CustomTable";
import ItemTeachersTable from "../../components/teachers_components/ItemTeachersTable";
import LayoutTollsTeachers from "../../components/teachers_components/LayoutToolsTeachers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import { useSorting } from "../../hooks/useSorting";
import {
  clearUsersList,
  loadUsersRequest,
} from "../../stores/ducks/auth/actions";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";
import { teachersTables } from "../../utils/Tables";

const Teachers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { usersList, isLoadingUsers } = useAppSelector((s) => s.auth);
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<User>();
  const { sorting, listSorted, setListSorted } = useSorting<User>();
  const filterProps = useAppSelector((i) => i.filters);

  const pageCount = Number(usersList?.pages);

  const onChangePage = (page: string) => {
    dispatch(
      loadUsersRequest({
        user_type__in: "teacher",
        page: page,
        search: filterProps.search,
        is_active__exact: filterProps.isActiveFilter,
      })
    );
    dispatch(setFilters({ ...filterProps, page }));
  };
  useEffect(() => {
    if (usersList?.results) {
      setListSorted(usersList?.results);
    }
  }, [usersList]);

  useEffect(() => {
    return () => {
      dispatch(clearUsersList());
      setListSorted(undefined);
      dispatch(clearFilter());
    };
  }, []);

  return (
    <Layout text="Teachers">
      <LayoutTollsTeachers
        selectedItems={items}
        onClickActions={clearSelections}
      />
      {listSorted && !isLoadingUsers ? (
        <CustomTable
          headers={teachersTables}
          isFullSelected={() => isFullSelected(listSorted)}
          onClickCheck={() => handleAll(listSorted)}
          onClickHeader={(v) => sorting(v)}
        >
          {listSorted.map((item) => (
            <ItemTeachersTable
              key={item.id}
              teacher={item}
              onClickCheckbox={() => handleItem(item)}
              isSelected={items.some((s) => s.id === item.id)}
            />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}

      {listSorted && listSorted.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
    </Layout>
  );
};

export default Teachers;
