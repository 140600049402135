import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  Student,
  StudentHistory,
  StudentInfo,
  StudentListImport,
} from "../../../@types/interfaces/api/student.interface";

export enum StudentActionTypes {
  LOAD_STUDENT_REQUEST = "@student/LOAD_STUDENT_REQUEST",
  LOAD_STUDENT_SUCCESS = "@student/LOAD_STUDENT_SUCCESS",
  LOAD_STUDENT_REPORT_SUCCESS = "@student/LOAD_STUDENT_REPORT_SUCCESS",
  LOAD_STUDENT_REPORT_REQUEST = "@student/LOAD_STUDENT_REPORT_REQUEST",
  LOAD_STUDENT_ERROR = "@student/LOAD_STUDENT_ERROR",

  LOAD_STUDENT_BY_ID_REQUEST = "@student/LOAD_STUDENT_BY_ID_REQUEST",
  LOAD_STUDENT_BY_ID_SUCCESS = "@student/LOAD_STUDENT_BY_ID_SUCCESS",
  LOAD_STUDENT_BY_ID_ERROR = "@student/LOAD_STUDENT_BY_ID_ERROR",

  LOAD_STUDENT_IMPORT_REQUEST = "@student/LOAD_STUDENT_IMPORT_REQUEST",
  LOAD_STUDENT_IMPORT_SUCCESS = "@student/LOAD_STUDENT_IMPORT_SUCCESS",
  LOAD_STUDENT_IMPORT_ERROR = "@student/LOAD_STUDENT_IMPORT_ERROR",

  LOAD_STUDENT_INFO_REQUEST = "@student/LOAD_STUDENT_INFO_REQUEST",
  LOAD_STUDENT_INFO_SUCCESS = "@student/LOAD_STUDENT_INFO_SUCCESS",
  LOAD_STUDENT_INFO_ERROR = "@student/LOAD_STUDENT_INFO_ERROR",

  LOAD_STUDENT_HISTORY_JR_REQUEST = "@student/LOAD_STUDENT_HISTORY_JR_REQUEST",
  LOAD_STUDENT_HISTORY_JR_SUCCESS = "@student/LOAD_STUDENT_HISTORY_JR_SUCCESS",
  LOAD_STUDENT_HISTORY_JR_ERROR = "@student/LOAD_STUDENT_HISTORY_JR_ERROR",

  LOAD_STUDENT_HISTORY_VM_REQUEST = "@student/LOAD_STUDENT_HISTORY_VM_REQUEST",
  LOAD_STUDENT_HISTORY_VM_SUCCESS = "@student/LOAD_STUDENT_HISTORY_VM_SUCCESS",
  LOAD_STUDENT_HISTORY_VM_ERROR = "@student/LOAD_STUDENT_HISTORY_VM_ERROR",

  LOAD_STUDENT_HISTORY_LS_REQUEST = "@student/LOAD_STUDENT_HISTORY_LS_REQUEST",
  LOAD_STUDENT_HISTORY_LS_SUCCESS = "@student/LOAD_STUDENT_HISTORY_LS_SUCCESS",
  LOAD_STUDENT_HISTORY_LS_ERROR = "@student/LOAD_STUDENT_HISTORY_LS_ERROR",

  BULK_UPDATE_STUDENTS_REQUEST = "@student/BULK_UPDATE_STUDENTS_REQUEST",
  BULK_UPDATE_STUDENTS_SUCCESS = "@student/BULK_UPDATE_STUDENTS_SUCCESS",
  BULK_UPDATE_STUDENTS_ERROR = "@student/BULK_UPDATE_STUDENTS_ERROR",

  UPDATE_STUDENT_SUCCESS = "@student/UPDATE_STUDENT_SUCCESS",
  UPDATE_STUDENT_REQUEST = "@student/UPDATE_STUDENT_REQUEST",
  UPDATE_STUDENT_ERROR = "@student/UPDATE_STUDENT_ERROR",

  STUDENT_REGISTER_SUCCESS = "@student/STUDENT_REGISTER_SUCCESS",
  STUDENT_REGISTER_REQUEST = "@student/STUDENT_REGISTER_REQUEST",
  STUDENT_REGISTER_ERROR = "@student/STUDENT_REGISTER_ERROR",

  CLEAR_STUDENT_LIST = "@student/CLEAR_STUDENT_LIST",

  REMOVE_REDFLAG_SUCCESS = "@student/REMOVE_REDFLAG_SUCCESS",
  REMOVE_REDFLAG_REQUEST = "@student/REMOVE_REDFLAG_REQUEST",
  REMOVE_REDFLAG_ERROR = "@student/REMOVE_REDFLAG_ERROR",
}

export interface StudentState extends StudentStateRequestsStatus {
  student: Student | undefined;
  studentList: PaginationBase<Student[]> | undefined;
  studentListReport: PaginationBase<Student[]> | undefined;
  StudentById: Student | undefined;
  isActive: boolean;
  studentListImport: StudentListImport[];
  studentInfo: StudentInfo | undefined;
  studentHistoryJr: StudentHistory[] | undefined;
  studentHistoryVm: StudentHistory[] | undefined;
  studentHistoryLs: StudentHistory[] | undefined;
}

interface StudentStateRequestsStatus {
  isStudentLoading: boolean;
  redFlagLoading: boolean;
}
