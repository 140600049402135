import { Flex } from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";
import { useAppSelector } from "../../hooks/redux";
import MessageAlert from "./MessageAlert";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

interface LayoutProps extends ComponentProps {
  text: string;
}

const Layout: React.FC<LayoutProps> = ({ text, children }) => {
  const { checkLicense } = useAppSelector((i) => i.license);
  const { user } = useAppSelector((i) => i.auth);

  return (
    <Flex minH="100%" w="100vw" pos="relative">
      <Sidebar />
      <Flex flexDir="column" flex={1} maxW={`calc(100vw - 264px)`}>
        {checkLicense &&
          !checkLicense?.is_valid &&
          user?.user_type !== "admin" && <MessageAlert />}

        <Topbar text={text} />
        <Flex
          w="full"
          maxW="100%"
          h="100%"
          flexDir="column"
          justify="flex-start"
          p="24px 24px"
          pos="relative"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Layout;
