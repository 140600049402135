import {
  Center,
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MoreVertical } from "lucide-react";
import React, { useState } from "react";
import { Library } from "../../@types/interfaces/api/library.interface";
import MenuListItem from "../global/MenuListItem";
import { useAppSelector } from "../../hooks/redux";
import pdfImg from "../../assets/image/PDF.png";
import Download from "../../assets/image/dowload.png";

interface CardProps {
  data: Library;
  openModal: () => void;
  levelList: string;
}

const CardIntroLibrary: React.FC<CardProps> = ({
  data,
  openModal,
  levelList,
}) => {
  const { user } = useAppSelector((i) => i.auth);

  return (
    <Flex w="168px" mt="16px" flexDir="column" gap="16px" position="relative">
      <Link href={String(data.file)} isExternal>
        <Center bg="gray.700" h="218px" borderRadius="8px" position="relative">
          <Image src={Download} position="absolute" />
          <Image src={pdfImg} />
        </Center>
      </Link>

      <Text fontWeight="500">{data.name}</Text>
      {user?.user_type === "admin" && (
        <Menu>
          <MenuButton
            position="absolute"
            right={2}
            top={2}
            as={IconButton}
            borderRadius="50%"
            boxSize="38px"
            variant="outline"
            bg="rgba(119, 119, 119, 0.593)"
            flexShrink={0}
            icon={<MoreVertical size="28px" color="white" />}
            _hover={{}}
            _active={{}}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <MenuListItem color="red" onClick={openModal}>
              Delete Lesson
            </MenuListItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default CardIntroLibrary;
