import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Eye, EyeOff } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CreateStudent } from "../../@types/interfaces/api/student.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { loadClassroomRequest } from "../../stores/ducks/classroom/actions";
import { loadSchoolsRequest } from "../../stores/ducks/school/actions";
import { registerStudentRequest } from "../../stores/ducks/student/action";
import { Form } from "../../styles/global";
import { generatePassword, generateUsername } from "../../utils/generates";
import { StudentFormSchema } from "../../utils/yup-schemas";
import InputLabel from "../form/InputLabel";
import InputPassword from "../form/InputPassword";
import RegularInput from "../form/RegularInput";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import ModalPrintAccess from "../modals/ModalPrintAccess";

const CreateStudentForm: React.FC = () => {
  const [activeFieldLicense, setActiveFieldLicense] = useState(false);
  const [modalOpen, setModalOpen] = useState({ isOpen: false });
  const [showPassword, setShowPassword] = useState(false);
  const [hasSchool, setHasSchool] = useState("");
  const toast = useToast();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { schoolsList } = useAppSelector((i) => i.school);
  const { classroomList } = useAppSelector((i) => i.classroom);
  const userMe = useAppSelector((i) => i.auth.user);
  const generatedPassword = generatePassword("weak");

  const { setData } = useTypeDelay(
    (v) => {
      dispatch(
        loadSchoolsRequest({
          is_active__exact: true,
          search: v,
        })
      );
    },
    () => {
      dispatch(
        loadSchoolsRequest({
          is_active__exact: true,
        })
      );
    }
  );

  useEffect(() => {
    if (userMe?.user_type !== "admin") {
      dispatch(
        loadClassroomRequest({
          school__id__exact: userMe?.school,
        })
      );
    }
    dispatch(loadSchoolsRequest({ is_active__exact: true }));
    if (userMe?.user_type !== "admin") {
      setValue("school", userMe?.school);
    }
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateStudent>({
    defaultValues: {
      password: generatedPassword,
      repeat_password: generatedPassword,
    },
    resolver: yupResolver(StudentFormSchema),
  });

  useEffect(() => {
    if (activeFieldLicense) {
      setValue("school", undefined);
      setValue("classroom", undefined);
    }
  }, [activeFieldLicense]);

  const watchFirstName = watch("first_name", "");
  const watchLastName = watch("last_name", "");

  useEffect(() => {
    if (
      watchFirstName !== undefined &&
      watchFirstName !== null &&
      watchLastName !== undefined &&
      watchLastName !== null
    ) {
      setValue("username", generateUsername(watchFirstName, watchLastName));
    }
  }, [watchFirstName, watchLastName]);

  const submitForm = (values: CreateStudent) => {
    const isAfterBirthday = moment(values.birthday).isAfter(new Date());
    const tomorrow = moment().add(1, "day");

    const isAfterOrEqualLicenseStart = moment(
      values.license?.license_starting_date
    ).isSameOrAfter(moment(), "day");

    const isAfterLicenseExpiration = moment(
      values.license?.license_expiration_date
    ).isSameOrAfter(tomorrow, "day");

    const isValidLicense =
      isAfterOrEqualLicenseStart && isAfterLicenseExpiration;

    const date = moment(values.birthday, "YYYY-MM-DD").format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const date1 = moment(
      values.license?.license_starting_date,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const date2 = moment(
      values.license?.license_expiration_date,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    const dateFormatted: CreateStudent = {
      ...values,
      birthday: date,
      license: values.license?.license_starting_date
        ? {
            ...values.license,
            license_starting_date: date1,
            license_expiration_date: date2,
          }
        : undefined,
    };

    if (isAfterBirthday) {
      toast({
        title: "invalid date of birth",
        description: "The date must be less than the current one",
        status: "error",
      });
    } else if (!isValidLicense && activeFieldLicense) {
      toast({
        title: "invalid date license",
        description:
          "The start date needs to be from today onwards, and the end date needs to be at least the day after.",
        status: "error",
      });
    } else {
      dispatch(registerStudentRequest(dateFormatted, navigate));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(submitForm)}>
        <Flex
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          <Heading fontWeight="600" fontSize="18px">
            Enroll Student
          </Heading>

          <Grid
            mt="40px"
            gridTemplateAreas={`"first-name last-name gender"
                            "divider divider divider"
                               "grade date language"
                               "divider2 divider2 divider2"
                               "classroom classroom1 classroom2"
                               "divider3 divider3 divider3"
                                        "note note note"`}
            gap="24px"
          >
            <GridItem area={"first-name"}>
              <FormControl isInvalid={!!errors.first_name}></FormControl>
              <InputLabel>
                First Name*
                <RegularInput mt="4px" {...register("first_name")} />
                {
                  <FormErrorMessage>
                    {errors.first_name?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </GridItem>
            <GridItem area={"last-name"}>
              <FormControl isInvalid={!!errors.last_name}>
                <InputLabel>
                  Last Name*
                  <RegularInput mt="4px" {...register("last_name")} />
                  {
                    <FormErrorMessage>
                      {errors.last_name?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>
            <GridItem area={"gender"}>
              <FormControl isInvalid={!!errors.gender}>
                <InputLabel>
                  Gender*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("gender")}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="none">None</option>
                  </RegularMenu>
                  {
                    <FormErrorMessage>
                      {errors.gender?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>
            <GridItem area={"divider"}>
              <Divider my="12px" />
            </GridItem>
            <GridItem area={"grade"}>
              <FormControl isInvalid={!!errors.grade}>
                <InputLabel>
                  Grade*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("grade")}
                  >
                    <option value="k">K</option>
                    <option value="1"> 1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="adult">adult</option>
                  </RegularMenu>
                  {<FormErrorMessage>{errors.grade?.message}</FormErrorMessage>}
                </InputLabel>
              </FormControl>
            </GridItem>
            <GridItem area={"date"}>
              <FormControl isInvalid={!!errors.birthday}>
                <InputLabel>
                  Date of Birth*
                  <RegularInput
                    mt="4px"
                    type="date"
                    color="gray.500"
                    {...register("birthday")}
                  />
                  {
                    <FormErrorMessage>
                      {errors.birthday?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>
            <GridItem area={"language"}>
              <FormControl isInvalid={!!errors.language}>
                <InputLabel>
                  Instructional Language*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("language")}
                  >
                    <option value="ENG">English</option>
                    <option value="SP">Spanish</option>
                  </RegularMenu>
                  {
                    <FormErrorMessage>
                      {errors.language?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>
            <GridItem area={"divider2"}>
              <Divider my="12px" />
            </GridItem>
            <GridItem area={"classroom"}>
              {userMe?.user_type === "admin" && (
                <FormControl isInvalid={!!errors.school}>
                  <InputLabel>
                    School
                    <Select
                      onChange={(value) => {
                        setValue("school", value!.value);
                        dispatch(
                          loadClassroomRequest({
                            school__id__exact: value?.value,
                          })
                        );
                      }}
                      placeholder="Search School(s):"
                      onInputChange={(e) => {
                        setData(e);
                      }}
                      options={schoolsList?.results.map((data) => ({
                        label: data.name,
                        value: data.id,
                      }))}
                      isSearchable
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          marginTop: "4px",
                          borderRadius: "100px",
                          height: "48px",
                          border: "1px solid #e6e8f0",
                          paddingInline: "12px",
                          color: "gray.500",
                          fontSize: "16px",
                          ":hover": {
                            outline: "none",
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                        }),
                      }}
                    />
                    {
                      <FormErrorMessage>
                        {errors.school?.message}
                      </FormErrorMessage>
                    }
                  </InputLabel>
                </FormControl>
              )}
            </GridItem>

            <GridItem
              area={userMe?.user_type === "admin" ? "classroom1" : "classroom"}
            >
              <FormControl isInvalid={!!errors.classroom}>
                <InputLabel>
                  Classroom
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    isDisabled={activeFieldLicense}
                    {...register("classroom")}
                  >
                    {classroomList?.results.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </RegularMenu>
                  {
                    <FormErrorMessage>
                      {errors.classroom?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>

            <GridItem area={"classroom2"}></GridItem>
            <GridItem area={"divider3"}>
              <Divider my="12px" />
            </GridItem>
            <GridItem area={"note"}>
              <FormControl isInvalid={!!errors.note}>
                <InputLabel>
                  Notes
                  <Textarea
                    mt="4px"
                    placeholder="Type here..."
                    _placeholder={{ color: "gray.400" }}
                    {...register("note")}
                  />
                </InputLabel>
                {<FormErrorMessage>{errors.note?.message}</FormErrorMessage>}
              </FormControl>
            </GridItem>
          </Grid>
        </Flex>
        {userMe?.user_type === "admin" && (
          <Flex
            mt="24px"
            w="full"
            p="24px"
            bg="white"
            flexDir="column"
            borderRadius="16px"
            boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
          >
            <Flex gap="24px" mb="16px">
              <Switch
                isChecked={activeFieldLicense}
                onChange={(e) => setActiveFieldLicense(e.target.checked)}
              />
              <Text fontWeight="500">Personal License</Text>
            </Flex>
            <Flex flexDir="column" mt="24px" gap="24px" w="full">
              <Flex w="full" gap="24px">
                <FormControl isInvalid={!!errors.license?.licenses_number}>
                  <InputLabel>
                    Number of Licenses
                    <RegularInput
                      mt="4px"
                      isDisabled={!activeFieldLicense}
                      {...register("license.licenses_number")}
                      isRequired
                    />
                    {
                      <FormErrorMessage>
                        {errors.license?.licenses_number?.message}
                      </FormErrorMessage>
                    }
                  </InputLabel>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.license?.license_starting_date}
                >
                  <InputLabel>
                    License Starting Date
                    <RegularInput
                      mt="4px"
                      type="date"
                      isDisabled={!activeFieldLicense}
                      {...register("license.license_starting_date")}
                      isRequired
                    />
                    {
                      <FormErrorMessage>
                        {errors.license?.license_starting_date?.message}
                      </FormErrorMessage>
                    }
                  </InputLabel>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.license?.license_expiration_date}
                >
                  <InputLabel>
                    License Expiration Date
                    <RegularInput
                      mt="4px"
                      type="date"
                      isDisabled={!activeFieldLicense}
                      {...register("license.license_expiration_date")}
                      isRequired
                    />
                    {
                      <FormErrorMessage>
                        {errors.license?.license_expiration_date?.message}
                      </FormErrorMessage>
                    }
                  </InputLabel>
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex
          mt="24px"
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          <Heading fontWeight="600" fontSize="18px">
            Independent Student Access
          </Heading>
          <Text fontWeight="500" fontSize="16px" mt="16px" color="gray.500">
            With Independent Student Access, students can access lessons on
            their own, using an alphanumeric PIN.
          </Text>
          <Flex w="full" gap="24px" mt="24px">
            <FormControl isInvalid={!!errors.username}>
              <InputLabel>
                Username*
                <RegularInput
                  mt="4px"
                  {...register("username")}
                  maxLength={Number("30")}
                />
                {
                  <FormErrorMessage>
                    {errors.username?.message}
                  </FormErrorMessage>
                }
                <Text fontWeight="400" fontSize="14px" color="gray.500">
                  Space and capital letters aren't allowed
                </Text>
              </InputLabel>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputLabel>
                PIN code*
                <InputPassword
                  mt="8px"
                  icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                  clickShow={() => setShowPassword(!showPassword)}
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  maxLength={Number("30")}
                />
                {
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
            <FormControl isInvalid={!!errors.repeat_password}>
              <InputLabel>
                Repeat PIN code*
                <InputPassword
                  mt="8px"
                  icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                  clickShow={() => setShowPassword(!showPassword)}
                  type={showPassword ? "text" : "password"}
                  {...register("repeat_password")}
                  maxLength={Number("30")}
                />
                {
                  <FormErrorMessage>
                    {errors.repeat_password?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </Flex>
        </Flex>

        <Flex
          mt="24px"
          mb="100px"
          gap="12px"
          w="full"
          justifyContent="flex-end"
        >
          <OutlineButton onClick={() => reset()}>Reset</OutlineButton>{" "}
          <RegularButton
            // onClick={() => setModalOpen({ isOpen: true })}
            type="submit"
          >
            Save
          </RegularButton>
        </Flex>
      </Form>
      <ModalPrintAccess
        isOpen={modalOpen.isOpen}
        onClose={() => setModalOpen({ isOpen: false })}
      />
    </>
  );
};

export default CreateStudentForm;
