import React from "react";
import { Route, Routes } from "react-router-dom";
import EditStudents from "../pages/students/EditStudents";
import EnrollStudents from "../pages/students/EnrollStudents";
import Students from "../pages/students/Students";
import ViewStudents from "../pages/students/ViewStudents";

const StudentsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Students />} />
      <Route path="/enroll-students" element={<EnrollStudents />} />

      <Route path="/edit-students" element={<EditStudents />}>
        <Route path=":id" element={<EditStudents />} />
        <Route path="" element={<EditStudents />} />
      </Route>
      <Route path="/view-students" element={<ViewStudents />}>
        <Route path=":id" element={<ViewStudents />} />
        <Route path="" element={<ViewStudents />} />
      </Route>
    </Routes>
  );
};

export default StudentsRoutes;
