import { Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import { Printer } from "lucide-react";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { dataYear } from "../../utils/data-year";

interface TopbarProps {
  text: string;
  date: string;
}

const TopbarBuildReport: React.FC<TopbarProps> = ({ text, date }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const yearUrl = searchParams.get("year");

  const updateYearInUrl = (newYear: string) => {
    searchParams.set("year", newYear);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <Flex
      w="full"
      h="80px"
      p="16px 24px"
      borderBottom="1px solid #E2E8F0"
      bg="white"
      pos="sticky"
      align="center"
      top="0"
      left="0"
      zIndex="100"
    >
      <Flex w="full" justify="space-between" align="center">
        <Flex align="center">
          <Heading fontWeight="600" fontSize="20px" color="blue.600">
            {text}
          </Heading>
        </Flex>
        <Flex align="center" gap="16px">
          <Text fontWeight="600">Select Year</Text>
          <Select
            placeholder="Select Year"
            onChange={(value) => {
              updateYearInUrl(value?.value!);
            }}
            options={dataYear().map((data) => ({
              label: data.label,
              value: data.value,
            }))}
            value={dataYear().find((data) => data.value === yearUrl)}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100%",
                borderRadius: "16px",
              }),
              menu: (provided) => ({
                ...provided,
                width: "100%",
              }),
            }}
            autoFocus
            isSearchable
          />
        </Flex>
        <Flex align="center " gap="16px">
          <Text fontWeight="600">Report Date</Text>
          <Text>{moment(date).format("MM-DD-YYYY HH:mm") ?? "No Data"}</Text>
          <IconButton
            icon={<Printer size="24px" />}
            aria-label="print"
            width="38px"
            height="38px"
            variant="ghost"
            color="gray.500"
            border="2px solid #A0AEC0"
            borderRadius="50%"
            _hover={{}}
            _active={{}}
            onClick={() => window.print()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopbarBuildReport;
