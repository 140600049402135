import { ApexOptions } from "apexcharts";

interface barChart {
  chartId: string;
  categories: string[] | undefined;
  colors: string[];
}

export const barChartConfig = ({
  chartId,
  categories,
  colors,
}: barChart): ApexOptions => {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      id: chartId,
      stacked: true,
      animations: { enabled: false, dynamicAnimation: { enabled: false } },
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 3,
        horizontal: false,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val: string | number | number[]) {
        if (Array.isArray(val)) {
          return val.join(", ");
        }
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories,
      labels: {
        show: false,
        style: {
          colors: "#9AA0B1",
          fontSize: "14px",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#7EC8E3",
          fontSize: "10px",
        },
        show: false,
        formatter: (val) =>
          val.toLocaleString("en-US", { notation: "standard" }),
      },
    },
    grid: {
      borderColor: "#EDF2F7",
      yaxis: {
        lines: { show: true },
      },
    },
    fill: {
      gradient: { type: "linear" },
    },
    markers: {
      size: 0,
    },
    tooltip: {
      y: {
        formatter: function (val: string | number | number[]) {
          if (Array.isArray(val)) {
            return val.join(", "); // Exemplo: "1, 2, 3"
          }
          return String(val);
        },
      },
      style: {
        fontSize: "16px",
      },
    },
    colors,
  };
};
