import { Flex, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Plus, Search } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SchoolBulkUpdateInterface from "../../@types/interfaces/api/school-bulk-update.interface";
import { School } from "../../@types/interfaces/api/school.interface";
import { useAppDispatch } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { setFilters } from "../../stores/ducks/filters/actions";
import {
  bulkUpdateSchoolRequest,
  loadSchoolsRequest,
} from "../../stores/ducks/school/actions";
import RegularInput from "../form/RegularInput";
import RegularButton from "../global/RegularButton";

interface LayoutToolsSchoolsProps {
  selectedItems: School[];
  onClickActions?: () => void;
}

const LayoutToolsSchools: React.FC<LayoutToolsSchoolsProps> = ({
  selectedItems,
  onClickActions = () => null,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<boolean | null>(null);

  const selectedIds = selectedItems.map((d) => d.id);
  const onClickAction = (data: SchoolBulkUpdateInterface) => {
    dispatch(bulkUpdateSchoolRequest(selectedIds, data));
    onClickActions();
  };

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(setFilters({ search: s, isActiveFilter: active }));

      dispatch(
        dispatch(loadSchoolsRequest({ search: s, is_active__exact: active }))
      );
    },
    () => {
      dispatch(setFilters({ search: "", isActiveFilter: active }));

      dispatch(loadSchoolsRequest({}));
    }
  );

  const filterActive = (e: any) => {
    if (e.target.value === "active") {
      dispatch(setFilters({ search: data, isActiveFilter: true }));

      setActive(true);
      dispatch(loadSchoolsRequest({ is_active__exact: true, search: data }));
    } else if (e.target.value === "inactive") {
      dispatch(setFilters({ search: data, isActiveFilter: false }));

      setActive(false);
      dispatch(loadSchoolsRequest({ is_active__exact: false, search: data }));
    } else {
      dispatch(setFilters({ search: data, isActiveFilter: null }));

      setActive(null);
      dispatch(loadSchoolsRequest({ is_active__exact: null, search: data }));
    }
  };
  return (
    <Flex w="full" justify="space-between">
      <Flex w="full" gap="16px">
        <InputGroup as={Flex} maxW="40% ">
          <InputLeftElement
            mt="4px"
            ml="12px"
            children={<Search size="24" color="#A0AEC0" />}
          />
          <RegularInput
            placeholder="Search Schools or Districts"
            pl="52px"
            onChange={(e: any) => setData(e.target.value)}
          />
        </InputGroup>
        {/* <MenuFilterActive
          handleClick={filterActive}
          active={active}
          namePage="School"
        /> */}
      </Flex>
      <Flex gap="16px" align="center">
        <RegularButton
          leftIcon={<Plus size="18px" />}
          onClick={() => navigate("/schools/create-schools")}
        >
          Create New School
        </RegularButton>
        {/* <Menu>
          <MenuButton
            as={IconButton}
            borderRadius="50%"
            w="48px"
            h="48px"
            fontSize="24px"
            variant="outline"
            icon={<MoreVertical fontSize="24px" />}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <MenuListItem
              color="alert.green"
              onClick={() => onClickAction({ is_active: true })}
            >
              Activate School
            </MenuListItem>

            <MenuListItem
              color="alert.red"
              borderTop="1px solid #E2E5EE"
              onClick={() => onClickAction({ is_active: false })}
            >
              Inactivate School
            </MenuListItem>
          </MenuList>
        </Menu> */}
      </Flex>
    </Flex>
  );
};

export default LayoutToolsSchools;
