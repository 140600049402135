import React, { useEffect, useState } from "react";
import Layout from "../../components/global/Layout";

import { Link, useParams } from "react-router-dom";
import EditStudentForm from "../../components/forms/EditStudentForm";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadStudentByIdRequest,
  loadStudentByIdSuccess,
} from "../../stores/ducks/student/action";

const EditStudents: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { StudentById, isStudentLoading } = useAppSelector((i) => i.student);

  useEffect(() => {
    if (id) {
      dispatch(loadStudentByIdRequest(Number(id)));
    }
    return () => {
      dispatch(loadStudentByIdSuccess(undefined));
    };
  }, [id]);

  return (
    <Layout text={"Students"}>
      {!isStudentLoading && <EditStudentForm studentById={StudentById} />}
    </Layout>
  );
};

export default EditStudents;
