import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import worldImg from "../../assets/image/world.png";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: { isOpen: boolean; title: string };
}

const ModalHelpFaq: React.FC<ModalPrintAccessProps> = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen.isOpen}>
      <ModalOverlay />
      <ModalContent
        p="32px"
        w="403px"
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
        mt="14%"
      >
        <Image boxSize="80px" src={worldImg} />
        <Heading fontSize="24px" fontWeight="500" color="gray.700" mt="16px">
          Would you like to see the {isOpen.title} page on the website?
        </Heading>

        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="15px"
            lineHeight="150%"
            color="gray.500"
          >
            You will be redirect to Failure Free {isOpen.title} page.
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mt="32px" w="full" gap="10px">
          <OutlineButton onClick={onClose} w="169px">
            Close
          </OutlineButton>
          <Link
            href={
              isOpen.title === "FAQ"
                ? "https://www.failurefreeonline.com/educator/faq.php"
                : "https://www.failurefreeonline.com/educator/"
            }
            isExternal
          >
            <RegularButton w="169px" bgColor="primary" _hover={{}}>
              Go to website
            </RegularButton>
          </Link>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ModalHelpFaq;
