import {Flex, LinkBox, LinkOverlay, Text} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";

interface KpiDashboardProps {
    icon: React.ReactNode;
    title: string;
    data: number | undefined;
    linkTo?: string;
}

const KpiDashboard: React.FC<KpiDashboardProps> = ({icon, title, data, linkTo = "#"}) => {
    return (
        <LinkBox
            display="flex"
            flex={1}
            bg="white"
            p="20px"
            boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
            borderRadius="16px"
        >
            <LinkOverlay as={Link} to={linkTo}>
                {icon}
            </LinkOverlay>
            <Flex flexDir="column" ml="24px">
                <Text fontSize="24px" fontWeight="800">
                    {data === undefined ? "-" : data}
                </Text>
                <Text fontSize="14px" color="gray.500">
                    {title}
                </Text>
            </Flex>
        </LinkBox>
    );
};

export default KpiDashboard;
