import { Flex } from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";
import { useAppSelector } from "../../hooks/redux";
import MessageAlert from "../global/MessageAlert";
import TopbarBuildReport from "./TopbarBuildReport";

interface LayoutProps extends ComponentProps {
  text: string;
  date: string;
}

const LayoutBuildReport: React.FC<LayoutProps> = ({ text, date, children }) => {
  const { checkLicense } = useAppSelector((i) => i.license);
  const { user } = useAppSelector((i) => i.auth);

  return (
    <Flex minH="100vh" pos="relative">
      <Flex flexDir="column" flex={1}>
        {!checkLicense?.is_valid && user?.user_type !== "admin" && (
          <MessageAlert />
        )}

        <TopbarBuildReport text={text} date={date} />
        <Flex
          w="full"
          h="100%"
          flexDir="column"
          justify="flex-start"
          p="24px 24px"
          pos="relative"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LayoutBuildReport;
