import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
import FilterInterface from "../../../@types/interfaces/api/filters/filter.interface";
import { License } from "../../../@types/interfaces/api/license.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  CreateStudent,
  HistoryProgram,
  Student,
  StudentListImport,
  UpdateStudent,
} from "../../../@types/interfaces/api/student.interface";
import { UpdateUser } from "../../../@types/interfaces/api/user.interface";
import api from "../../../services/api";
import theme from "../../../styles/theme";
import { updateUserRequest } from "../auth/actions";
import { updateUser } from "../auth/sagas";
import { createLicense } from "../license/sagas";
import { createNoteRequest } from "../note/actions";
import { updateProfileRequest } from "../profile/actions";
import { Profile } from "../../../@types/interfaces/api/profile.interface";

import {
  bulkUpdateStudentsError,
  bulkUpdateStudentsSuccess,
  loadStudentByIdError,
  loadStudentByIdSuccess,
  loadStudentError,
  loadStudentHistoryJrError,
  loadStudentHistoryJrSuccess,
  loadStudentHistoryLsError,
  loadStudentHistoryLsSuccess,
  loadStudentHistoryVmError,
  loadStudentHistoryVmSuccess,
  loadStudentImportError,
  loadStudentImportRequest,
  loadStudentImportSuccess,
  loadStudentInfoError,
  loadStudentInfoSuccess,
  loadStudentReportSuccess,
  loadStudentSuccess,
  registerStudentError,
  registerStudentSuccess,
  removeRedFlagError,
  removeRedFlagSuccess,
  updateStudentError,
  updateStudentSuccess,
} from "./action";
import StudentsBulkUpdateInterface from "../../../@types/interfaces/api/students-bulk-update.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadStudent(action: AnyAction) {
  const filters: FilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<Student[]>> = yield call(
      api.get,
      "/student",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentSuccess(data));
    }
  } catch (error) {
    yield loadStudentError();
  }
}
export function* loadStudentReport(action: AnyAction) {
  const filters: FilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<Student[]>> = yield call(
      api.get,
      "/student",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentReportSuccess(data));
    }
  } catch (error) {
    yield loadStudentError();
  }
}

export function* loadStudentImport(action: AnyAction) {
  const filters: FilterInterface = action.payload;

  try {
    const response: AxiosResponse<StudentListImport[]> = yield call(
      api.get,
      "/student/import",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentImportSuccess(data));
    }
  } catch (error) {
    yield loadStudentImportError();
  }
}

export function* registerStudent(action: AnyAction) {
  const {
    values,
    navigate,
    isActive,
  }: { values: CreateStudent; navigate: NavigateFunction; isActive: boolean } =
    action.payload;
  if (values.school === undefined) {
    delete values.school;
  }
  if (values.classroom === "") {
    delete values.classroom;
  }

  if (!values.license?.license_starting_date) {
    delete values.license;
  }

  if (values.note === "") {
    delete values.note;
  }
  try {
    let resp = "";
    if (values.license?.license_starting_date) {
      const licenseRequest: License = yield call(createLicense, values.license);
      resp = String(licenseRequest.id);
      const response: AxiosResponse = yield call(api.post, `/student`, {
        ...values,
        license: resp,
      });

      const { status, data } = response;
      if (status === 200) {
        if (values.note) {
          yield put(createNoteRequest(String(values.note), data.user));
        }
        yield put(registerStudentSuccess(true));
        navigate("/students");
        toast({
          title: "Success",
          description: "Student successfully registered",
          status: "success",
        });
      }
    } else {
      const notes = values.note;
      delete values.license;
      const response: AxiosResponse = yield call(api.post, `/student`, values);
      const { status, data } = response;

      if (status === 200) {
        if (values.note) {
          yield put(createNoteRequest(String(values.note), data.user));
        }
        if (data.is_active === false) {
          yield put(registerStudentSuccess(false));
        } else {
          yield put(registerStudentSuccess(true));
        }

        navigate("/students");

        toast({
          title: "Success",
          description: "Student successfully registered",
          status: "success",
        });
      }
    }
  } catch (error: any) {
    yield put(registerStudentError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* loadStudentById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.get, `/student/${id}`);
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentByIdSuccess(data));
    }
  } catch (error) {
    yield loadStudentByIdError();
  }
}
export function* loadStudentInfo(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.get, `/student/${id}/info`);
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentInfoSuccess(data));
    }
  } catch (error) {
    yield loadStudentInfoError();
  }
}

export function* loadStudentHistoryJr(action: AnyAction) {
  const id: number = action.payload.id;
  const programId: HistoryProgram = action.payload.HistoryProgram;

  try {
    const response: AxiosResponse = yield call(
      api.get,
      `/student/${id}/history`,
      { params: programId }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentHistoryJrSuccess(data));
    }
  } catch (error) {
    yield loadStudentHistoryJrError();
  }
}
export function* loadStudentHistoryVm(action: AnyAction) {
  const id: number = action.payload.id;
  const programId: HistoryProgram = action.payload.HistoryProgram;

  try {
    const response: AxiosResponse = yield call(
      api.get,
      `/student/${id}/history`,
      { params: programId }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentHistoryVmSuccess(data));
    }
  } catch (error) {
    yield loadStudentHistoryVmError();
  }
}
export function* loadStudentHistoryLs(action: AnyAction) {
  const id: number = action.payload.id;
  const programId: HistoryProgram = action.payload.HistoryProgram;

  try {
    const response: AxiosResponse = yield call(
      api.get,
      `/student/${id}/history`,
      { params: programId }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStudentHistoryLsSuccess(data));
    }
  } catch (error) {
    yield loadStudentHistoryLsError();
  }
}

export function* updateStudent(action: AnyAction) {
  const {
    id,
    values,
    navigate,
  }: { id: number; values: UpdateStudent; navigate: NavigateFunction } =
    action.payload;
  const valuesUser: UpdateUser = {
    username: values.username!,
    first_name: values.first_name,
    last_name: values.last_name,
    password: values.password,
    repeat_password: values.repeat_password,
  };
  if (values.password === "") {
    delete valuesUser.password;
  }
  if (values.repeat_password === "") {
    delete valuesUser.repeat_password;
  }

  const valuesStudent: UpdateStudent = {
    gender: values.gender,
    grade: values.grade,
    birthday: values.birthday,
    language: values.language,
    classroom: values.classroom,
    jr_matching_activities: values.jr_matching_activities,
    jr_scrambled_activities: values.jr_scrambled_activities,
    jr_short_answer_activities: values.jr_short_answer_activities,
    jr_alphabetical_activities: values.jr_alphabetical_activities,
    ls_alphabetical_activities: values.ls_alphabetical_activities,
    ls_matching_activities: values.ls_matching_activities,
    ls_scrambled_activities: values.ls_scrambled_activities,
    ls_short_answer_activities: values.ls_short_answer_activities,
    vm_short_answer_activities: values.vm_short_answer_activities,
    ls_activities: values.ls_activities,
    jr_talking_book: values.jr_talking_book,
    ls_talking_book: values.ls_talking_book,
  };

  const valuesNotes = values.note;
  const valuesSchool: Profile = {
    school: values.school,
  };

  try {
    yield put(updateUserRequest(id, valuesUser, "student"));

    const response: AxiosResponse = yield call(
      api.patch,
      `/student/${id}`,
      valuesStudent
    );
    const { status, data } = response;
    if (status === 200) {
      if (values.note) {
        yield put(createNoteRequest(String(valuesNotes), id));
      }
      yield put(updateProfileRequest(id, valuesSchool));
      yield put(updateStudentSuccess());
      navigate("/students");
    }
  } catch (error: any) {
    yield put(updateStudentError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* bulkUpdateStudents(action: AnyAction) {
  const users: number[] = action.payload.users;
  const dataBulk: StudentsBulkUpdateInterface[] = action.payload.data;

  const requestData = users.map((user) => ({
    user,
    ...dataBulk,
  }));

  try {
    const response: AxiosResponse<Student> = yield call(
      api.patch,
      `/student/bulk`,
      requestData
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(bulkUpdateStudentsSuccess());
      yield put(loadStudentImportRequest({}));
      toast({
        title: "Success",
        description: "Student successfully updated",
        status: "success",
      });
    }
  } catch (errors: any) {
    yield put(bulkUpdateStudentsError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* removeRedFlag(action: AnyAction) {
  const id: string = action.payload;

  try {
    const response: AxiosResponse<Student> = yield call(
      api.delete,
      `/red-flag/${id}`
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(removeRedFlagSuccess());
      toast({
        title: "Success",
        description: "Student successfully updated",
        status: "success",
      });
    }
  } catch (errors: any) {
    yield put(removeRedFlagError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
