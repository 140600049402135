import { Reducer } from "redux";
import { ClassificationType } from "typescript";
import { StudentActionTypes, StudentState } from "./types";

const INITIAL_STATE: StudentState = {
  student: undefined,
  StudentById: undefined,
  studentList: undefined,
  isStudentLoading: false,
  isActive: true,
  studentListImport: [],
  studentInfo: undefined,
  studentHistoryJr: undefined,
  studentHistoryVm: undefined,
  studentHistoryLs: undefined,
  redFlagLoading: false,
  studentListReport: undefined,
};

const reducer: Reducer<StudentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StudentActionTypes.LOAD_STUDENT_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_REPORT_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_REPORT_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentListReport: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentList: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_IMPORT_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_IMPORT_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentListImport: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_IMPORT_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.STUDENT_REGISTER_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.STUDENT_REGISTER_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        isActive: action.payload,
      };
    case StudentActionTypes.STUDENT_REGISTER_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_BY_ID_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_BY_ID_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        StudentById: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_BY_ID_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_INFO_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_INFO_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentInfo: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_INFO_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_HISTORY_JR_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_JR_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentHistoryJr: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_JR_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_HISTORY_VM_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_VM_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentHistoryVm: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_VM_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.LOAD_STUDENT_HISTORY_LS_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_LS_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
        studentHistoryLs: action.payload,
      };
    case StudentActionTypes.LOAD_STUDENT_HISTORY_LS_ERROR:
      return { ...INITIAL_STATE, isStudentLoading: false };

    case StudentActionTypes.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
      };
    case StudentActionTypes.UPDATE_STUDENT_ERROR:
      return {
        ...state,
        isStudentLoading: false,
      };

    case StudentActionTypes.BULK_UPDATE_STUDENTS_REQUEST:
      return {
        ...state,
        isStudentLoading: true,
      };
    case StudentActionTypes.BULK_UPDATE_STUDENTS_SUCCESS:
      return {
        ...state,
        isStudentLoading: false,
      };
    case StudentActionTypes.BULK_UPDATE_STUDENTS_ERROR:
      return {
        ...state,
        isStudentLoading: false,
      };

    case StudentActionTypes.CLEAR_STUDENT_LIST:
      return {
        ...state,
        studentList: undefined,
      };
    case StudentActionTypes.REMOVE_REDFLAG_REQUEST:
      return {
        ...state,
        redFlagLoading: true,
      };
    case StudentActionTypes.REMOVE_REDFLAG_SUCCESS:
      return {
        ...state,
        redFlagLoading: false,
      };
    case StudentActionTypes.REMOVE_REDFLAG_ERROR:
      return {
        ...state,
        redFlagLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
