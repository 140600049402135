import { Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import ReportProgramElement from "../../components/build_report_components/ReportProgramElement";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadProgramReportRequest,
} from "../../stores/ducks/buildReport/actions";

const ProgramBuildReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { programReport, isLoading } = useAppSelector((i) => i.buildReport);
  const { user } = useAppSelector((d) => d.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ids = searchParams.get("students");
  const program = searchParams.get("program");
  const schools = searchParams.get("schools");
  const currentYear = searchParams.get("year");

  useEffect(() => {
    if (
      ids &&
      program &&
      currentYear &&
      schools &&
      user?.user_type === "admin"
    ) {
      dispatch(
        loadProgramReportRequest({
          program_id: program,
          students: ids,
          school_ids: schools,
          year: currentYear,
        })
      );
    }
    if (ids && program && currentYear && user?.user_type != "admin") {
      dispatch(
        loadProgramReportRequest({
          program_id: program,
          students: ids,
          year: currentYear,
        })
      );
    }
    return () => {
      dispatch(clearReportList());
    };
  }, [ids, currentYear]);

  if (isLoading) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  let programName = "";
  switch (program) {
    case "1":
      programName = "Joseph’s Readers";
      break;
    case "2":
      programName = "Life Skills";
      break;
    case "3":
      programName = "Verbal Master";
      break;
    default:
      break;
  }

  return (
    <LayoutBuildReport
      text={`${programName} Student Program Report`}
      date={programReport?.report_date ?? String(new Date())}
    >
      {programReport?.items.length === 0 ? (
        <Text marginX="auto" fontWeight={600} fontSize={20}>
          No data for this report
        </Text>
      ) : (
        programReport?.items.map((item, index) => (
          <ReportProgramElement key={index} data={item} />
        ))
      )}
    </LayoutBuildReport>
  );
};

export default ProgramBuildReport;
