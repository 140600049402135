import {
  Circle,
  Flex,
  Icon,
  Input,
  Text,
  TextProps,
  useToast,
} from "@chakra-ui/react";
import { FileUp, Upload } from "lucide-react";
import React from "react";

interface FileProps {
  text?: string;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>>;

  selectedFile: File | undefined;
  selectType: boolean;
}

const FileTutorial: React.FC<FileProps> = ({
  text,
  setSelectedFile,
  selectedFile,
  selectType,
  ...rest
}) => {
  const toast = useToast();
  return (
    <Text
      cursor="pointer"
      flex="1"
      bgColor="blue.50"
      border="1px dashed #3989F2"
      borderRadius="16px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      as="label"
      htmlFor={"#fileInput"}
      p="0px"
      h="192px"
      {...rest}
    >
      <Flex flexDir="column" align="center" gap="8px">
        <Circle size="56px" bg="blue.100">
          <FileUp size="32px" color="#3989F2" />
        </Circle>
        <Text color="primary" fontWeight="600">
          {selectedFile ? selectedFile.name : " Click to upload a new file"}
        </Text>
        <Text fontWeight="500" color="gray.500">
          {text}
        </Text>
      </Flex>

      <Input
        hidden
        type="file"
        id={"#fileInput"}
        name="file"
        accept={selectType ? "video/*" : ".pdf"}
        onChange={(e) => {
          const file = e.target.files?.[0];
          const fileType = file?.type;
          const fileSize = file?.size ?? 0;

          const isAceptPdf =
            fileType === "application/pdf" && fileSize <= 100000000;

          const isAceptVideo =
            fileType?.startsWith("video/") && fileSize <= 100000000;

          if (!isAceptPdf && !selectType) {
            toast({
              title: "Invalid file",
              description: "The file is not a PDF or it is larger than 100MB",
              status: "error",
            });
            e.target.value = "";
            setSelectedFile(undefined);
            return;
          }

          if (!isAceptVideo && selectType) {
            toast({
              title: "Invalid file",
              description:
                "The file is not a MP4 / AVI or it is larger than 100MB",
              status: "error",
            });
            e.target.value = "";
            setSelectedFile(undefined);
            return;
          }
          setSelectedFile(file);
        }}
      />
    </Text>
  );
};
export default FileTutorial;
