import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import StudentsRoutes from "./routes/students.routes";
import DistrictsRoutes from "./routes/districts.routes";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import PrincipalsRoutes from "./routes/principals.routes";
import ClassroomRoutes from "./routes/classroom.routes";
import TeachersRoutes from "./routes/teachers.routes";
import SchoolsRoutes from "./routes/schools.routes";
import AdminRoutes from "./routes/admin.routes";
import LibraryRoutes from "./routes/library.routes";
import TutorialsRoutes from "./routes/tutorials.routes";
import CertificatesRoutes from "./routes/certificates.routes";
import Profile from "./pages/Profile";
import Dashboard from "./pages/dashboard/Dashboard";
import { AuthProvider } from "./providers/AuthPovider";
import { Provider } from "react-redux";
import store from "./stores/ducks";
import { useAppSelector } from "./hooks/redux";
import BuildReportRouts from "./routes/buildReport.routes";
import Recover from "./pages/Recover";
import Reset from "./pages/Reset";
import ErrorFallback from "./pages/ErrorFallback";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { UserType } from "./@types/interfaces/api/user.interface";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider>
          <GlobalStyle />
          <BrowserRouter>
            <Routes>
              <Route element={<PublicOutlet />}>
                <Route path="/" element={<Login />} />
                <Route path="/recover" element={<Recover />} />
                <Route path="/reset" element={<Reset />} />
              </Route>
              <Route element={<AuthOutlet />}>
                <Route element={<RoleOutlet allowed_roles={["admin"]} />}>
                  <Route path="/districts/*" element={<DistrictsRoutes />} />
                  <Route path="/principals/*" element={<PrincipalsRoutes />} />
                  <Route path="/schools/*" element={<SchoolsRoutes />} />
                  <Route path="/admin/*" element={<AdminRoutes />} />
                </Route>

                <Route
                  element={
                    <RoleOutlet allowed_roles={["admin", "principal"]} />
                  }
                >
                  <Route path="/teachers/*" element={<TeachersRoutes />} />
                </Route>

                <Route path="/students/*" element={<StudentsRoutes />} />
                <Route path="/classroom/*" element={<ClassroomRoutes />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/library/*" element={<LibraryRoutes />} />
                <Route path="/tutorials/*" element={<TutorialsRoutes />} />
                <Route
                  path="/certificates/*"
                  element={<CertificatesRoutes />}
                />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/build-report/*" element={<BuildReportRouts />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </ChakraProvider>
  );
};

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  const navigate = useNavigate();

  const reset = () => {
    navigate("/dashboard");
    resetErrorBoundary();
  };

  return <ErrorFallback error={error.message} reset={reset} />;
}

const AuthOutlet = () => {
  const authenticated = useAppSelector((s) => s.auth.authenticated);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      {authenticated ? <Outlet /> : <Navigate to="/" />}
    </ErrorBoundary>
  );
};

const RoleOutlet: React.FC<{ allowed_roles: UserType[] }> = ({
  allowed_roles,
}) => {
  const user = useAppSelector((s) => s.auth.user);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      {allowed_roles.find((d) => d === user?.user_type) ? (
        <Outlet />
      ) : (
        <Navigate to="/" />
      )}
    </ErrorBoundary>
  );
};

const PublicOutlet = () => {
  const authenticated = useAppSelector((s) => s.auth.authenticated);
  return authenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default App;
