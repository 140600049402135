import { useState } from "react";

export const useSorting = <T>() => {
  const [order, setOrder] = useState("ASC");
  const [listSorted, setListSorted] = useState<T[] | undefined>();

  const sorting = (col: keyof T) => {
    if (order === "ASC" && listSorted) {
      const sorted = [...listSorted].sort((a, b) =>
        (a[col] as any)?.toLowerCase() > (b[col] as any)?.toLowerCase() ? 1 : -1
      );
      setListSorted(sorted);
      setOrder("DSC");
    }
    if (order === "DSC" && listSorted) {
      const sorted = [...listSorted].sort((a, b) =>
        (a[col] as any)?.toLowerCase() < (b[col] as any)?.toLowerCase() ? 1 : -1
      );
      setListSorted(sorted);
      setOrder("ASC");
    }
  };

  return { sorting, listSorted, setListSorted };
};
