import React from "react";
import { Route, Routes } from "react-router-dom";
import Classroom from "../pages/classroom/Classrooms";
import CreateClassroom from "../pages/classroom/CreateClassroom";
import ViewClassroom from "../pages/classroom/ViewClassroom";

const ClassroomRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Classroom />} />
      <Route path="/create-classroom" element={<CreateClassroom />}>
        <Route path=":id" element={<CreateClassroom />} />
        <Route path="" element={<CreateClassroom />} />
      </Route>
      <Route path="/view-classroom" element={<ViewClassroom />}>
        <Route path=":id" element={<ViewClassroom />} />
        <Route path="" element={<ViewClassroom />} />
      </Route>
    </Routes>
  );
};

export default ClassroomRoutes;
