import { Link } from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";

interface TelToProps extends ComponentProps {
  phone: number | undefined;
}

const TelTo: React.FC<TelToProps> = ({ phone, children, ...props }) => {
  return <Link href={`tel:${phone}`}>{children}</Link>;
};

export default TelTo;
