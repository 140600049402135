import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import TutorialFilterInterface from "../../../@types/interfaces/api/filters/tutorial.filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  TutorialData,
  TutorialDataCreate,
} from "../../../@types/interfaces/api/tutorial-data.interface";
import api from "../../../services/api";
import {
  createTutorialError,
  createTutorialuccess,
  deleteTutorialErrorr,
  deleteTutorialSuccess,
  loadTutorialErrorr,
  loadTutorialRequest,
  loadTutorialSuccess,
} from "./actions";
import theme from "../../../styles/theme";
import { NavigateFunction } from "react-router-dom";

const { toast } = createStandaloneToast({ theme });

export function* loadAllTutorials(action: AnyAction) {
  const filters: TutorialFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<TutorialData[]>> = yield call(
      api.get,
      "/tutorial",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadTutorialSuccess(data.results));
    }
  } catch (error: any) {
    yield put(loadTutorialErrorr());
  }
}

export function* updateFile(file: FormData, id: number) {
  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/tutorial/${id}/file`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error: any) {
    console.log(error);
  }
}

export function* createFile(action: AnyAction) {
  const {
    values,
    navigate,
    file,
  }: {
    values: TutorialDataCreate;
    navigate: NavigateFunction;
    file: FormData;
  } = action.payload;
  try {
    const response: AxiosResponse = yield call(api.post, `/tutorial`, values);
    const { status, data } = response;
    if (status === 201) {
      yield call(updateFile, file, data.id);
      yield put(createTutorialuccess());
      navigate("/tutorials");
      toast({
        title: "Success",
        description: "Tutorial successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(createTutorialError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* deleteTutorials(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.delete, `/tutorial/${id}`);
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteTutorialSuccess());
      yield put(loadTutorialRequest());
    }
  } catch (error: any) {
    yield put(deleteTutorialErrorr());
  }
}
