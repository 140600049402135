import {
  Center,
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import certificateImg from "../../assets/image/certificate.png";
import Download from "../../assets/image/dowload.png";
import { ICertificate } from "../../@types/interfaces/api/certificate.interface";

interface CetificateProps {
  data: ICertificate;
}

const Cardcertificate: React.FC<CetificateProps> = ({ data }) => {
  return (
    <Flex flexDir="column" w="264px" mt="24px" position="relative">
      <Link href={data.file} isExternal>
        <Center position="relative">
          <Image src={Download} position="absolute" />
          <Image src={data.thumbnail} />
        </Center>
      </Link>

      <Text fontWeight="500" color="gray.700" mt="16px">
        {data.name}
      </Text>
      <Text fontWeight="500" color="gray.500">
        {data.level_name}
      </Text>
    </Flex>
  );
};

export default Cardcertificate;
