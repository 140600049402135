import { useState } from "react";

export const useBulkSelect = <T>(defaultDataValue: T[] = []) => {
  const [items, setItems] = useState(defaultDataValue);

  const isFullSelected = (totalItems: T[]) =>
    items.length === totalItems.length;

  const clearSelections = () => setItems([]);

  const handleItem = (item: T) => {
    const currentItems = [...items];

    for (const iterator in items) {
      if (isEqual(currentItems[iterator], item)) {
        currentItems.splice(Number(iterator), 1);
        setItems([...currentItems]);
        return;
      }
    }

    currentItems.push(item);
    setItems([...items, item]);
  };

  const handleAll = (newItems: T[]) =>
    items.length !== 0 ? setItems([]) : setItems([...newItems]);

  return { handleItem, handleAll, items, isFullSelected, clearSelections };
};

function isEqual(obj1: any, obj2: any) {
  var props1 = Object.getOwnPropertyNames(obj1);
  var props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length != props2.length) {
    return false;
  }
  for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
function isObject(object: any) {
  return object != null && typeof object === "object";
}
