import { Button, ButtonProps, Circle, Flex, Text } from "@chakra-ui/react";
import { GraduationCap } from "lucide-react";
import React from "react";
import { Level } from "../../@types/interfaces/api/library.interface";

interface LibraryButtonProps extends ButtonProps {
  level: Level;
  isSelected: boolean;
  handleClick: () => void;
}
// const buttonColors = {
//   red: jrLevels.redProps,
//   green: jrLevels.greenProps,
//   orange: jrLevels.orangeProps,
//   teal: jrLevels.tealProps,
//   vm1: vmLevels.props1,
//   vm2: vmLevels.props2,
//   vm3: vmLevels.props3,
//   vm4: vmLevels.props4,
//   blue: lfLevels.blueProps,
//   purple: lfLevels.purpleProps,
//   maroon: lfLevels.maroonProps,
//   yellow: lfLevels.yellowProps,
// };

const ButtonLibrary: React.FC<LibraryButtonProps> = ({
  level,
  children,
  isSelected,
  handleClick,
  ...rest
}) => {
  // const [jrProps, SetJrProps] = useState(
  //   buttonColors[level.name.toLowerCase() as keyof typeof buttonColors]
  // );

  return (
    <Button
      w="270px"
      h="80px"
      bg="white"
      border={`2px solid #ffff`}
      aria-selected={isSelected}
      borderRadius="16px"
      p="16px"
      _hover={{}}
      _selected={{ border: `2px solid ${level.color} ` }}
      onClick={handleClick}
      {...rest}
    >
      <Circle bg={"#cbd5e078"} p="4px" size="48px">
        <GraduationCap color={level.color} size="24px" />
      </Circle>
      <Flex justify="space-between" align="center" w="full">
        <Flex flexDir="column" ml="8px" align="flex-start" gap="8px">
          <Text> {`${level.name} Tests`} </Text>
        </Flex>
      </Flex>
    </Button>
  );
};

export default ButtonLibrary;
