import { TutorialActionTypes } from "./types";
import {
  TutorialData,
  TutorialDataCreate,
} from "../../../@types/interfaces/api/tutorial-data.interface";
import TutorialFilterInterface from "../../../@types/interfaces/api/filters/tutorial.filter.interface";
import { NavigateFunction } from "react-router-dom";

export const loadTutorialRequest = (filters?: TutorialFilterInterface) => ({
  type: TutorialActionTypes.LOAD_TUTORIAL_REQUEST,
  payload: filters,
});

export const loadTutorialSuccess = (Tutorial: TutorialData[]) => ({
  type: TutorialActionTypes.LOAD_TUTORIAL_SUCCESS,
  payload: Tutorial,
});
export const loadTutorialErrorr = () => ({
  type: TutorialActionTypes.LOAD_TUTORIAL_ERROR,
});

export const createTutorialRequest = (
  values: TutorialDataCreate,
  navigate: NavigateFunction,
  file: FormData
) => {
  return {
    type: TutorialActionTypes.CREATE_TUTORIAL_REQUEST,
    payload: { values, navigate, file },
  };
};

export const createTutorialuccess = () => {
  return {
    type: TutorialActionTypes.CREATE_TUTORIAL_SUCCESS,
  };
};
export const createTutorialError = () => {
  return {
    type: TutorialActionTypes.CREATE_TUTORIAL_ERROR,
  };
};

export const deleteTutorialRequest = (id: number) => ({
  type: TutorialActionTypes.DELETE_TUTORIAL_REQUEST,
  payload: id,
});

export const deleteTutorialSuccess = () => ({
  type: TutorialActionTypes.DELETE_TUTORIAL_SUCCESS,
});
export const deleteTutorialErrorr = () => ({
  type: TutorialActionTypes.DELETE_TUTORIAL_ERROR,
});
