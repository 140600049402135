type levelTypes = {
  [key: string]: string;
};

export const replaceNameJr: levelTypes = {
  level_1: "Red",
  level_2: "Green",
  level_3: "Orange",
  level_4: "Teal",
};

export const replaceNameLs: levelTypes = {
  level_1: "Blue",
  level_2: "Purple",
  level_3: "Maroon",
  level_4: "Yellow",
};

export const replaceNameVm: levelTypes = {
  level_1: "vm1",
  level_2: "vm2",
  level_3: "vm3",
  level_4: "vm4",
};
