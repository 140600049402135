import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ChevronDown } from "lucide-react";
import React from "react";
import MenuListItem from "./MenuListItem";
import OutlineButton from "./OutlineButton";

interface MenuFilterActiveStudentProps {
  handleClick: (e: any) => void;
  namePage: string;
  filter: string;
}

const MenuFilterActiveStudent: React.FC<MenuFilterActiveStudentProps> = ({
  handleClick,
  namePage,
  filter,
}) => {
  return (
    <Menu>
      <MenuButton
        as={OutlineButton}
        w="200px"
        bg="transparent"
        rightIcon={<ChevronDown size="16px" />}
        _active={{ bg: "white" }}
        _expanded={{ borderRadius: "24px 24px 0 0" }}
        textTransform="capitalize"
      >
        {filter}
      </MenuButton>
      <MenuList
        minW="0"
        w="200px"
        maxW="200px"
        borderRadius="0 0 24px 24px"
        border="1.5px solid #E2E5EE"
        borderTop="none"
        p="0"
        marginTop="-8px"
        onClick={handleClick}
      >
        <MenuListItem value="active" color={"alert.green"}>
          Active {namePage}
        </MenuListItem>
        <MenuListItem
          borderTop="1px solid #E2E5EE"
          color={"alert.red"}
          value="inactive"
        >
          Inactive {namePage}
        </MenuListItem>
        {namePage === "Student" && (
          <>
            <MenuListItem borderTop="1px solid #E2E5EE" value="is_redflag">
              Red Flagged {namePage}
            </MenuListItem>
            <MenuListItem borderTop="1px solid #E2E5EE" value="non_redflag">
              Non Red Flagged {namePage}
            </MenuListItem>
          </>
        )}

        <MenuListItem borderTop="1px solid #E2E5EE" value="all">
          View All
        </MenuListItem>
      </MenuList>
    </Menu>
  );
};

export default MenuFilterActiveStudent;
