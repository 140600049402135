import {
  Button,
  Flex,
  Progress,
  Stack,
  Tag,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { StudentHistory } from "../../@types/interfaces/api/student.interface";
import {
  DataRas,
  IBuildReportDiagnostic,
} from "../../@types/interfaces/api/buildReport.interface";

interface ItemViewStudentProps {
  data: DataRas;
}

const ItemRasReport: React.FC<ItemViewStudentProps> = ({ data }) => {
  return (
    <Tr>
      <Td>{data.question}</Td>
      <Td>
        <Text
          bg={data.pre_point > 3 ? "#41B081" : "none"}
          color={data.pre_point > 3 ? "white" : "black"}
          p={data.pre_point > 3 ? "1px 8px" : "0px"}
          display="inline-block"
        >
          {data.pre_point}
        </Text>
        <Text display="inline-block">- {data.pre_answer}</Text>
      </Td>

      <Td>
        <Text
          bg={data.post_point > 3 ? "#41B081" : "none"}
          color={data.post_point > 3 ? "white" : "black"}
          p={data.post_point > 3 ? "1px 8px" : "0px"}
          display="inline-block"
        >
          {data.post_point}
        </Text>
        <Text display="inline-block">- {data.post_answer}</Text>
      </Td>
    </Tr>
  );
};

export default ItemRasReport;
