import React from "react";
import { Route, Routes } from "react-router-dom";
import BuildReport from "../pages/build-report/BuildReport";
import DiagnosticPrecriptiveReport from "../pages/build-report/DiagnosticPrecriptiveReport";
import ReadingAttitudeSurveyReport from "../pages/build-report/ReadingAttitudeSurveyReport";
import StudentReport from "../pages/build-report/StudentReport";
import SchoolReport from "../pages/build-report/SchoolReport";
import LessonReport from "../pages/build-report/LessonReport";
import ProgramBuildReport from "../pages/build-report/ProgramBuildReport";
import DiagnosticPlacement from "../pages/build-report/DiagnosticPlacement";

const BuildReportRouts: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BuildReport />} />
      <Route path="/ras" element={<ReadingAttitudeSurveyReport />} />

      <Route path="/diagnostic" element={<DiagnosticPrecriptiveReport />} />
      <Route path="/diagnostic-placement" element={<DiagnosticPlacement />} />

      <Route path="/student-progress" element={<StudentReport />} />

      <Route path="/school-progress" element={<SchoolReport />}>
        <Route path=":id" element={<SchoolReport />} />
        <Route path="" element={<SchoolReport />} />
      </Route>

      <Route path="/lesson" element={<LessonReport />}>
        <Route path=":id" element={<LessonReport />} />
        <Route path="" element={<LessonReport />} />
      </Route>

      <Route path="/program" element={<ProgramBuildReport />} />
    </Routes>
  );
};

export default BuildReportRouts;
