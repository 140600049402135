import {
  Center,
  Flex,
  Grid,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTab,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Layout from "../../components/global/Layout";
import LayoutToolsTutorials from "../../components/tutorials_components/LayoutToolsTutorials";
import CardPdf from "../../components/tutorials_components/CardPdf";
import CardVideo from "../../components/tutorials_components/CardVideo";
import ModalDelete from "../../components/modals/ModalDelete";
import ModalVideo from "../../components/modals/ModalVideo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  deleteTutorialRequest,
  loadTutorialRequest,
} from "../../stores/ducks/tutorial/actions";
import SkeletonLibraryVideo from "../../components/global/skeleton/SkeletonLibraryVideo";
import SkeletonLibraryLesson from "../../components/global/skeleton/SkeletonLibraryLesson";

const Tutorials: React.FC = () => {
  const [modalDeleteOpen, setModalDeleteOpen] = useState({
    isOpen: false,
    title: "video",
    id: 0,
  });
  const [isOpenVideo, setOpenVideo] = useState({
    isOpen: false,
    urlVideo: "",
    nameVideo: "video",
  });
  const dispatch = useAppDispatch();
  const { tutorial, isLoadingTutorial } = useAppSelector((i) => i.tutorial);

  // useEffect(() => {
  //   dispatch(loadTutorialRequest({}));
  // }, []);

  const handleDelete = () => {
    dispatch(deleteTutorialRequest(modalDeleteOpen.id));
    setModalDeleteOpen({ ...modalDeleteOpen, isOpen: false });
  };

  return (
    <Layout text="Tutorials">
      <LayoutToolsTutorials />
      <Tabs mt="24px" colorScheme="blueScheme">
        <TabList>
          <Tab
            fontWeight="500"
            onClick={() => dispatch(loadTutorialRequest({ type: "video" }))}
          >
            Video
          </Tab>
          <Tab
            fontWeight="500"
            onClick={() => dispatch(loadTutorialRequest({ type: "document" }))}
          >
            Document Guide
          </Tab>
        </TabList>
        <TabPanels mt="16px">
          <TabPanel>
            <Flex flexDir="column">
              <Heading fontWeight="600" fontSize="18px" mt="32px">
                Failure Free Videos
              </Heading>
              {!isLoadingTutorial ? (
                <Flex
                  w="full"
                  gap="24px"
                  flexWrap="wrap"
                  // templateColumns={"repeat( auto-fit, minmax(207px, 1fr) )"}
                >
                  {tutorial?.map(
                    (item) =>
                      item.type === "video" && (
                        <CardVideo
                          key={item.id}
                          video={item}
                          openModal={() =>
                            setModalDeleteOpen({
                              title: item.title,
                              isOpen: true,
                              id: Number(item.id),
                            })
                          }
                          openVideo={() =>
                            setOpenVideo({
                              urlVideo: String(item.file),
                              isOpen: true,
                              nameVideo: item.title,
                            })
                          }
                        />
                      )
                  )}
                </Flex>
              ) : (
                <SkeletonLibraryVideo />
              )}
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex flexDir="column">
              <Heading fontWeight="600" fontSize="18px" mt="32px">
                Failure Free Documents
              </Heading>
              {!isLoadingTutorial ? (
                <Flex
                  w="full"
                  gap="24px"
                  flexWrap="wrap"
                  // templateColumns={"repeat( auto-fit, minmax(168px, 1fr) )"}
                >
                  {tutorial?.map(
                    (item) =>
                      item.type === "document" && (
                        <CardPdf
                          key={item.id}
                          doc={item}
                          openModal={() =>
                            setModalDeleteOpen({
                              title: item.title,
                              isOpen: true,
                              id: Number(item.id),
                            })
                          }
                        />
                      )
                  )}
                </Flex>
              ) : (
                <SkeletonLibraryLesson />
              )}
            </Flex>
          </TabPanel>
          {tutorial && tutorial.length <= 0 && (
            <Center mt="32px">
              <Text fontSize="18px" fontWeight="500">
                No items found
              </Text>
            </Center>
          )}
        </TabPanels>
      </Tabs>
      <ModalDelete
        handleDelete={handleDelete}
        isOpen={modalDeleteOpen}
        onClose={() =>
          setModalDeleteOpen({ ...modalDeleteOpen, isOpen: false })
        }
      />
      <ModalVideo
        isOpen={isOpenVideo}
        onClose={() => setOpenVideo({ ...isOpenVideo, isOpen: false })}
      />
    </Layout>
  );
};

export default Tutorials;
