import { Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ClassroomList } from "../../@types/interfaces/api/classroom.interface";
import EditButton from "../global/circle_button/EditButton";
import ViewButton from "../global/circle_button/ViewButton";

interface ClassroomProps {
  classroom: ClassroomList;
}

const ItemClassroomTable: React.FC<ClassroomProps> = ({ classroom }) => {
  const navigate = useNavigate();
  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        {/* <Checkbox colorScheme="tagScheme" /> */}
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.name}</Text>
      </Td>

      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.teacher}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{classroom.school}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.students}</Text>
      </Td>

      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.used_24h}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.used_7d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.used_30d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.used_90d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{classroom.used_1y}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>
          {classroom.last_student_login
            ? moment(classroom.last_student_login).format("MM/D/YYYY")
            : "No data"}
        </Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>
          {classroom.last_teacher_login
            ? moment(classroom.last_teacher_login).format("MM/D/YYYY")
            : "No data"}
        </Text>
      </Td>

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <ViewButton
          onClick={() => navigate(`/classroom/view-classroom/${classroom.id}`)}
        />
        <EditButton
          ml="4px"
          onClick={() =>
            navigate(`/classroom/create-classroom/${classroom.id}`)
          }
        />
      </Td>
    </Tr>
  );
};

export default ItemClassroomTable;
