import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

const GradeLevels: React.FC = () => {
  return (
    <Flex mt="24px" gap="12px">
      <Flex
        p="12pX"
        bg="#E53E3E"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        justify="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>1.0</Text>
        <Text>Grade Level</Text>
        <Text>(Red)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#3BB800"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>1.5</Text>
        <Text>Grade Level</Text>
        <Text>(Green)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#FF6B00"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>2.0</Text>
        <Text>Grade Level</Text>
        <Text>(Orange A)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#FF6B00"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>3.0</Text>
        <Text>Grade Level</Text>
        <Text>(Orange B)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#08D6BD"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>4.0</Text>
        <Text>Grade Level</Text>
        <Text>(Teal S1)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#08D6BD"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>4.5</Text>
        <Text>Grade Level</Text>
        <Text>(Teal S2)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#08D6BD"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>5.0</Text>
        <Text>Grade Level</Text>
        <Text>(Teal S3)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#CC00FF"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>6.0</Text>
        <Text>Grade Level</Text>
        <Text>(VM1)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#CC00FF"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>8.0</Text>
        <Text>Grade Level</Text>
        <Text>(VM2)</Text>
      </Flex>
      <Flex
        p="12pX"
        bg="#CC00FF"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>10.0</Text>
        <Text>Grade Level</Text>
        <Text>(VM3)</Text>
      </Flex>

      <Flex
        p="12pX"
        bg="#CC00FF"
        borderRadius="12px"
        color="white"
        flexDir="column"
        align="center"
        lineHeight={"16px"}
        textAlign="center"
      >
        <Text>12.0</Text>
        <Text>Grade Level</Text>
        <Text>(VM4)</Text>
      </Flex>
    </Flex>
  );
};

export default GradeLevels;
