import { Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { DataReportSchool } from "../../@types/interfaces/api/buildReport.interface";

interface ItemViewStudentProps {
  data: DataReportSchool;
}

const ItemSchoolReport: React.FC<ItemViewStudentProps> = ({ data }) => {
  return (
    <Tr>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.student_name}</Text>
      </Td>
      <Td p="18px 16px" maxW="200px">
        {data.diagnostic_date !== null ? (
          <Text fontWeight="500">
            {moment(data.diagnostic_date).format("MM-DD-YYYY") ?? "No Data"}
          </Text>
        ) : (
          <Text>No data</Text>
        )}
      </Td>

      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.diagnostic_placement ?? "No Data"}</Text>
      </Td>

      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.current_bookmark ?? "No Data"}</Text>
      </Td>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.stories_completed}</Text>
      </Td>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.average_pre_test}%</Text>
      </Td>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text fontWeight="500">{data.average_post_test}%</Text>
      </Td>
    </Tr>
  );
};

export default ItemSchoolReport;
