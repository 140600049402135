import { Grid, GridItem, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect } from "react";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadReportStudentProgressLsRequest,
  loadReportStudentProgressRequest,
  loadReportStudentProgressVmRequest,
} from "../../stores/ducks/buildReport/actions";

import { useParams } from "react-router-dom";
import SectionTablesProgram from "../../components/build_report_components/SectionTablesProgram";
import {
  loadStudentHistoryJrRequest,
  loadStudentHistoryLsRequest,
  loadStudentHistoryVmRequest,
  loadStudentInfoRequest,
} from "../../stores/ducks/student/action";

const StudentReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    studentProgressDataJr,
    studentProgressDataLs,
    studentProgressDataVm,
    isLoading,
  } = useAppSelector((d) => d.buildReport);
  const {
    StudentById,
    studentInfo,
    studentHistoryJr,
    studentHistoryVm,
    studentHistoryLs,
  } = useAppSelector((i) => i.student);

  useEffect(() => {
    dispatch(loadStudentInfoRequest(Number(id)));

    dispatch(
      loadReportStudentProgressRequest({
        student_id: Number(id),
        program_id: 1,
      })
    );
    dispatch(
      loadReportStudentProgressLsRequest({
        student_id: Number(id),
        program_id: 2,
      })
    );
    dispatch(
      loadReportStudentProgressVmRequest({
        student_id: Number(id),
        program_id: 3,
      })
    );

    dispatch(
      loadStudentHistoryJrRequest(Number(id), { program__id: 1, limit: 3 })
    );
    dispatch(
      loadStudentHistoryVmRequest(Number(id), { program__id: 3, limit: 3 })
    );
    dispatch(
      loadStudentHistoryLsRequest(Number(id), { program__id: 2, limit: 3 })
    );
    return () => {
      dispatch(clearReportList());
    };
  }, [id]);

  return (
    <LayoutBuildReport
      text="Student Progress Report (Class Year: 2022-2023)"
      date={String(new Date())}
    >
      <SectionWhite>
        <Text fontSize="18px" fontWeight="600">
          Report Details
        </Text>
        <Grid mt="16px" gap="24px" templateColumns="repeat(6, 1fr)">
          <GridItem>
            <Text fontWeight="600">Student Name</Text>
          </GridItem>{" "}
          <GridItem>
            <Text fontWeight="600">Teacher Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Classroom</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">School</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">District</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Report Date</Text>
          </GridItem>
          <GridItem>
            <Text>
              {studentProgressDataJr?.report_details.student ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {studentProgressDataJr?.report_details.teacher ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {studentProgressDataJr?.report_details.classroom ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {studentProgressDataJr?.report_details.school ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {studentProgressDataJr?.report_details.district ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {moment(studentProgressDataJr?.report_details.report_date).format(
                "MM-DD-YYYY HH:mm"
              ) ?? "No Data"}
            </Text>
          </GridItem>
        </Grid>
      </SectionWhite>
      <SectionWhite mt="24px">
        <Text fontSize="18px" fontWeight="600">
          Current Bookmark
        </Text>
        <Grid mt="16px" gap="24px" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <Text fontWeight="600">Diagnosed Level</Text>
          </GridItem>{" "}
          <GridItem>
            <Text fontWeight="600">Current Bookmark</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600"> Total Time on Current Bookmark</Text>
          </GridItem>
          <GridItem>
            {studentInfo?.diagnostic_placement != null ? (
              <Text>{studentInfo?.diagnostic_placement.name}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
          <GridItem>
            {studentInfo?.current_lesson != null ? (
              <Text>{studentInfo?.current_lesson}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
          <GridItem>
            {studentInfo?.total_time_in_program != null ? (
              <Text>{studentInfo?.total_time_in_program}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
        </Grid>
      </SectionWhite>
      {studentHistoryJr && studentProgressDataJr && (
        <SectionTablesProgram
          handleclick={() =>
            dispatch(
              loadStudentHistoryJrRequest(Number(id), {
                program__id: 1,
                limit: 100,
              })
            )
          }
          studentHistoryData={studentHistoryJr}
          studentProgressData={studentProgressDataJr}
          title="Joseph Readers"
        />
      )}
      {studentHistoryLs && studentProgressDataLs && (
        <SectionTablesProgram
          handleclick={() =>
            dispatch(
              loadStudentHistoryLsRequest(Number(id), {
                program__id: 2,
                limit: 100,
              })
            )
          }
          studentHistoryData={studentHistoryLs}
          studentProgressData={studentProgressDataLs}
          title="Life Skills"
        />
      )}
      {studentHistoryVm && studentProgressDataVm && (
        <SectionTablesProgram
          handleclick={() =>
            dispatch(
              loadStudentHistoryVmRequest(Number(id), {
                program__id: 3,
                limit: 100,
              })
            )
          }
          studentHistoryData={studentHistoryVm}
          studentProgressData={studentProgressDataVm}
          title="Verbal Master"
        />
      )}
    </LayoutBuildReport>
  );
};

export default StudentReport;
