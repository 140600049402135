import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "../pages/administrator/Admin";
import CreateAdmin from "../pages/administrator/CreateAdmin";
import ViewAdmin from "../pages/administrator/ViewAdmin";

const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Admin />} />
      <Route path="/create-admin" element={<CreateAdmin />}>
        <Route path=":id" element={<CreateAdmin />} />
        <Route path="" element={<CreateAdmin />} />
      </Route>
      <Route path="/view-admin">
        <Route path=":id" element={<ViewAdmin />}></Route>
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
