import { Flex, Text } from "@chakra-ui/react";
import React from "react";

interface CardDailyLessonProps {
  score: number;
  index: number;
}

const CardDailyLesson: React.FC<CardDailyLessonProps> = ({ score, index }) => {
  return (
    <Flex
      justify="center"
      align="center"
      flexDir="column"
      px="16px"
      py="4px"
      bg={score < 70 && score > 0 ? "#ff7777" : "#E5F0FD"}
      borderRadius="4px"
    >
      <Text fontWeight="500">Lesson {index + 1}</Text>
      <Text fontSize="18px" fontWeight="600">
        {score < 0 ? "-" : score + "%"}
      </Text>
    </Flex>
  );
};

export default CardDailyLesson;
