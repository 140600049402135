import React, { useState } from "react";
import Layout from "../../components/global/Layout";
import CreateStudentForm from "../../components/forms/CreateStudentForm";

const EnrollStudents: React.FC = () => {
  return (
    <Layout text={"Students"}>
      <CreateStudentForm />
    </Layout>
  );
};

export default EnrollStudents;
