import { createStandaloneToast } from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import store from "..";
import UsersFilterInterface from "../../../@types/interfaces/api/filters/users.filter.interface";
import LoginCredentials from "../../../@types/interfaces/api/login-credencials.interface";
import LoginResponse from "../../../@types/interfaces/api/login.response.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  CreateUser,
  UpdateUser,
  User,
  UserType,
} from "../../../@types/interfaces/api/user.interface";
import UsersBulkUpdateInterface from "../../../@types/interfaces/api/users-bulk-update.interface";
import api from "../../../services/api";
import theme from "../../../styles/theme";
import { checkLicenseIdRequest } from "../license/actions";
import { loadStudentRequest } from "../student/action";
import {
  loadTokenError,
  loadTokenRequest,
  loadTokenSuccess,
  loadUserByIdError,
  loadUserByIdSuccess,
  loadUsersErrorr,
  loadUsersRequest,
  loadUsersSuccess,
  recoverPasswordSuccess,
  recoverUsernameSuccess,
  registerAdminError,
  registerAdminSuccess,
  registerPrincipalError,
  registerPrincipalSuccess,
  registerTeacherError,
  registerTeacherSuccess,
  resetPasswordSuccess,
  signInError,
  signInSuccess,
  updateUserError,
  updateUserSuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* handleSignIn(action: AnyAction) {
  const {
    credentials,
    navigate,
  }: { credentials: LoginCredentials; navigate: NavigateFunction } =
    action.payload;

  try {
    const response: AxiosResponse<LoginResponse> = yield call(
      axios.post,
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      credentials
    );
    if (response.status === 200) {
      yield put(signInSuccess(response.data));
      yield call(loadAuthUser);
      navigate("/dashboard", { replace: true });
    }
  } catch (e: any) {
    if (e.response.status === 401) {
      toast({
        title: "Erro",
        description:
          "The provided username or password is incorrect, or the user account is not active. Please check your credentials and ensure that your account is active to access this resource.",
        status: "error",
      });
    } else if (e.response.status === 403) {
      toast({
        title: "Erro",
        description: "unauthorized user",
        status: "error",
      });
    } else {
      toast({
        title: "Erro",
        description: e.message,
        status: "error",
      });
    }
    yield put(signInError());
  }
}

export function* loadAuthUser() {
  try {
    const response: AxiosResponse<User> = yield call(api.get, "/auth/me");
    yield put(loadTokenSuccess(response.data));
    if (response.data.user_type === "student") {
      toast({
        title: "Erro",
        description: "unauthorized user",
        status: "error",
      });
    }
    yield put(checkLicenseIdRequest());
  } catch (e: any) {
    yield put(loadTokenError());
  }
}

export function* loadAllUsers(action: AnyAction) {
  const filters: UsersFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<User[]>> = yield call(
      api.get,
      "/auth/user",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadUsersSuccess(data));
    }
  } catch (error: any) {
    yield put(loadUsersErrorr());
  }
}

export function* loadUserById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse<User> = yield call(
      api.get,
      `/auth/user/${id}`
    );
    const { data, status } = response;
    if (status === 200) {
      yield put(loadUserByIdSuccess(data));
    }
  } catch (e: any) {
    yield put(loadUserByIdError());
  }
}

export function* bulkUpdateUsers(action: AnyAction) {
  const ids: string[] = action.payload.ids;
  const data: UsersBulkUpdateInterface[] = action.payload.data;
  const userType: UserType = action.payload.userType;

  const requestData = ids.map((id) => ({
    id,
    ...data,
  }));

  try {
    const response: AxiosResponse<User> = yield call(
      api.patch,
      `/auth/bulk`,
      requestData
    );
    const { status } = response;
    if (status === 200) {
      yield put(loadUsersRequest({ user_type__in: userType }));
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* updateUser(action: AnyAction) {
  const {
    id,
    values,
    userType,
  }: {
    id: number;
    values: UpdateUser;
    userType: UserType;
  } = action.payload;

  const { isActiveFilter, page, search } = store.getState().filters;

  if (values.password === "") {
    delete values.password;
  }
  if (values.repeat_password === "") {
    delete values.repeat_password;
  }
  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `${process.env.REACT_APP_BASE_URL}/auth/user/${id}`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateUserSuccess());
      yield put(
        loadUsersRequest({
          user_type__in: userType,
          page,
          search,
          is_active__exact: isActiveFilter,
        })
      );
      if (values.is_active !== data.is_active) {
        yield put(loadTokenRequest());
      }
      if (userType === "student") {
        yield put(
          loadStudentRequest({
            search,
            page,
            user__is_active__exact: isActiveFilter,
          })
        );
      }

      toast({
        title: "Success",
        description: "User successfully updated",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(updateUserError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* registerAdmin(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: CreateUser; navigate: NavigateFunction } = action.payload;
  try {
    const response: AxiosResponse = yield call(api.post, `/auth/admin`, values);
    const { status, data } = response;
    if (status === 200) {
      yield put(registerAdminSuccess());
      navigate("/admin");
      toast({
        title: "Success",
        description: "Admin successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerAdminError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* registerPrincipal(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: CreateUser; navigate: NavigateFunction } = action.payload;
  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/auth/principal`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(registerPrincipalSuccess());
      navigate("/principals");
      toast({
        title: "Success",
        description: "Principal successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerPrincipalError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* registerTeacher(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: CreateUser; navigate: NavigateFunction } = action.payload;
  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/auth/teacher`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(registerTeacherSuccess());
      navigate("/teachers");
      toast({
        title: "Success",
        description: "Teacher successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerTeacherError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* recoverPassword(action: AnyAction) {
  const data = action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/auth/password/recovery`,
      data
    );
    const { status } = response;
    if (status === 200) {
      yield put(recoverPasswordSuccess());
      toast({
        title: "Success",
        description:
          "We sent an email to reset your password, check your email!",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(recoverPasswordSuccess());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
export function* recoverUsername(action: AnyAction) {
  const data = action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/auth/username/recovery`,
      data
    );
    const { status } = response;
    if (status === 200) {
      yield put(recoverUsernameSuccess());
      toast({
        title: "Success",
        description:
          "We sent an email to reset your username, check your email!",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(recoverUsernameSuccess());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* resetPassword(action: AnyAction) {
  const { data, token, navigate } = action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/auth/password/reset`,
      {
        ...data,
        token,
      }
    );
    const { status } = response;
    if (status === 200) {
      yield put(resetPasswordSuccess());
      toast({
        title: "Success",
        description: "Your password has been updated!",
        status: "success",
      });
      navigate("/");
    }
  } catch (error: any) {
    yield put(resetPasswordSuccess());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* simulateUser(action: AnyAction) {
  try {
    const response: AxiosResponse<LoginResponse> = yield call(
      api.post,
      `/auth/simulate/${action.payload}`
    );
    yield put(signInSuccess(response.data));
    window.location.reload();
  } catch (e: any) {
    yield put(signInError());
  }
}
