import {
  Checkbox,
  Flex,
  IconButton,
  Switch,
  Tag,
  TagLabel,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Eye, Pencil } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../@types/interfaces/api/user.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateUserRequest } from "../../stores/ducks/auth/actions";
import BadgeStatus from "../global/BadgeStatus";
import EditButton from "../global/circle_button/EditButton";
import EmailButton from "../global/circle_button/EmailButton";
import TrashButton from "../global/circle_button/TrashButton";
import ViewButton from "../global/circle_button/ViewButton";
import MailTo from "../global/MailTo";

interface ItemPrincipalsProps {
  principal: User;
  isSelected: boolean;
  onClickCheckbox: () => void;
}

const ItemPrincipalsTable: React.FC<ItemPrincipalsProps> = ({
  principal,
  isSelected,
  onClickCheckbox,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        <Checkbox
          colorScheme="tagScheme"
          isChecked={isSelected}
          onChange={onClickCheckbox}
        />
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{principal.first_name}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <MailTo email={principal?.email}>
          <Text color="primary">{principal?.email}</Text>
        </MailTo>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{principal.school}</Text>
      </Td>
      <Td
        py="16px"
        pl="0"
        pr="50px"
        fontWeight="400"
        color="gray.700"
        w="120px"
      >
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={principal.is_active ? "active" : "inactive"} />
          <Switch
            size="sm"
            colorScheme="tagScheme"
            ml="6px"
            isChecked={principal.is_active}
            onChange={(e: any) =>
              dispatch(
                updateUserRequest(
                  principal.id,
                  {
                    ...principal,
                    user_type: "principal",
                    is_active: e.target.checked,
                  },
                  "principal"
                )
              )
            }
          />
        </Flex>
      </Td>

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <ViewButton
          onClick={() => navigate(`/principals/view-principal/${principal.id}`)}
        />
        <EditButton
          ml="4px"
          onClick={() =>
            navigate(`/principals/create-principal/${principal.id}`)
          }
        />
        {/* <EmailButton ml="4px" /> */}
      </Td>
    </Tr>
  );
};

export default ItemPrincipalsTable;
