import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const StudentsIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#E5F0FD" />
      <path
        d="M41.3332 25.3333V33.3333M14.6665 25.3333L27.9998 18.6667L41.3332 25.3333L27.9998 32L14.6665 25.3333Z"
        stroke="#0C4DA2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 28V34.6667C24 38.6667 32 38.6667 36 34.6667V28"
        stroke="#0C4DA2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default StudentsIcon;
