import { CertificateActionTypes, CertificateState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: CertificateState = {
  isLoadingCertificate: false,
  certificate: undefined,
};

const reducer: Reducer<CertificateState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CertificateActionTypes.LOAD_CERTIFICATE_REQUEST:
      return {
        ...state,
        isLoadingCertificate: true,
        certificate: undefined,
      };
    case CertificateActionTypes.LOAD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoadingCertificate: false,
        certificate: action.payload,
      };
    case CertificateActionTypes.LOAD_CERTIFICATE_ERROR:
      return { ...INITIAL_STATE, isLoadingCertificate: false };

    default:
      return state;
  }
};

export default reducer;
