import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import SectionWhite from "../../components/build_report_components/SectionWhite";

const LabelComponent: React.FC = () => {
  return (
    <SectionWhite my="16px">
      <Flex flexDir="column">
        <Text fontSize="18px" fontWeight="600" color="gray.600">
          Total key:
        </Text>
        <UnorderedList color="gray.600">
          <ListItem fontSize="18px" fontWeight="600">
            (15 - 26) very negative attitude
          </ListItem>
          <ListItem fontSize="18px" fontWeight="600">
            (27 - 38) negative attitude
          </ListItem>
          <ListItem fontSize="18px" fontWeight="600">
            (39 - 50) good attitude
          </ListItem>
          <ListItem fontSize="18px" fontWeight="600">
            (51 - 62) positive attitude
          </ListItem>
          <ListItem fontSize="18px" fontWeight="600">
            (63 - 75) very positive attitude
          </ListItem>
        </UnorderedList>
      </Flex>
    </SectionWhite>
  );
};

export default LabelComponent;
