import { Checkbox, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import {
  Student,
  StudentListImport,
} from "../../@types/interfaces/api/student.interface";

interface ItemStudent {
  student: StudentListImport;
  isSelected: boolean;
  onClickCheckbox: () => void;
}

const ItemStudentTableModal: React.FC<ItemStudent> = ({
  student,
  isSelected,
  onClickCheckbox,
}) => {
  return (
    <Tr>
      <Td py="16px" px="16px">
        <Checkbox
          colorScheme="tagScheme"
          isChecked={isSelected}
          onChange={onClickCheckbox}
        />
      </Td>
      <Td fontWeight="400" color="gray.700" py="16px" px="0" fontSize="14px">
        <Text>{student.first_name}</Text>
      </Td>
      <Td fontWeight="400" color="gray.700" py="16px" px="0" fontSize="14px">
        <Text>{moment(student.birthday).format("MM-DD-yyyy")}</Text>
      </Td>
      <Td fontWeight="400" color="gray.700" py="16px" px="0" fontSize="14px">
        <Text>{student.classroom}</Text>
      </Td>
      <Td
        py="16px"
        px="20px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        {student.current_lesson ? (
          <Tooltip
            borderRadius="8px"
            bg="gray.600"
            label={student.current_lesson}
          >
            <Text maxW="180px">
              {student.current_lesson.substring(0, 20)}
              {student.current_lesson.length > 20 ? "..." : ""}
            </Text>
          </Tooltip>
        ) : (
          <Text maxW="180px">No Data</Text>
        )}
      </Td>
    </Tr>
  );
};

export default ItemStudentTableModal;
