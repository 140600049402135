import { ProfileActionTypes, ProfileState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: ProfileState = {
  isLoadingProfile: false,
  profile: undefined,
};

const reducer: Reducer<ProfileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoadingProfile: true,
      };
    case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingProfile: false,
      };
    case ProfileActionTypes.UPDATE_PROFILE_ERROR:
      return { ...INITIAL_STATE, isLoadingProfile: false };

    case ProfileActionTypes.UPDATE_PROFILE_PHOTO_REQUEST:
      return {
        ...state,
        isLoadingProfile: true,
      };
    case ProfileActionTypes.UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        isLoadingProfile: false,
      };
    case ProfileActionTypes.UPDATE_PROFILE_PHOTO_ERROR:
      return { ...INITIAL_STATE, isLoadingProfile: false };

    default:
      return state;
  }
};

export default reducer;
