import { CoreActionTypes, CoreState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: CoreState = {
  countries: undefined,
  states: undefined,
  isLoading: false,
};

const reducer: Reducer<CoreState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CoreActionTypes.LOAD_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CoreActionTypes.LOAD_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload,
      };
    case CoreActionTypes.LOAD_COUNTRY_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case CoreActionTypes.LOAD_STATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CoreActionTypes.LOAD_STATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload,
      };
    case CoreActionTypes.LOAD_STATE_ERROR:
      return { ...INITIAL_STATE, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
