import { Center, Flex, FlexProps, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import InputLabel from "../form/InputLabel";
import RegularMenu from "../form/RegularMenu";
import SectionWhite from "./SectionWhite";
import TableProgressStories from "./TableProgressStories";
import TableProgressWords from "./TableProgressWords";
import TableViewStudent from "../students_components/TableViewStudent";
import ItemViewStudent from "../students_components/ItemViewStudent";
import ItemProgressStories from "./ItemProgressStories";
import ItemProgressWords from "./ItemProgressWords";
import { IStudentProgress } from "../../@types/interfaces/api/buildReport.interface";
import { StudentHistory } from "../../@types/interfaces/api/student.interface";

interface SectionDropdownProps extends FlexProps {
  handleclick: () => void;
  studentProgressData: IStudentProgress;
  studentHistoryData: StudentHistory[];
  title: string;
}

const SectionTablesProgram: React.FC<SectionDropdownProps> = ({
  title,
  studentHistoryData,
  studentProgressData,
  handleclick,
  children,
  ...rest
}) => {
  return (
    <SectionWhite mt="24px">
      <Text
        fontSize="18px"
        fontWeight="600"
        color="primary"
        border="2px solid #0C4DA2"
        borderRadius="100px"
        w="-webkit-fit-content"
        p="8px 12px"
      >
        {title}
      </Text>
      <Text fontWeight="600" mt="16px">
        Stories
      </Text>
      <TableProgressStories>
        {studentProgressData?.program_report.stories.map((story, index) => (
          <ItemProgressStories key={index} data={story} />
        ))}
      </TableProgressStories>

      {studentProgressData &&
        studentProgressData?.program_report.stories.length === 0 && (
          <Center mt="32px" w="full">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}
      <Text fontWeight="600" mt="16px">
        Words
      </Text>
      <TableProgressWords>
        {studentProgressData &&
          studentProgressData?.program_report.words.length > 0 &&
          studentProgressData?.program_report.words.map((word, index) => (
            <ItemProgressWords key={index} word={word} />
          ))}
      </TableProgressWords>
      {studentProgressData &&
        studentProgressData?.program_report.words.length === 0 && (
          <Center mt="32px" w="full">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}

      <Text fontWeight="600" mt="16px">
        Scores
      </Text>
      <TableViewStudent>
        {studentHistoryData &&
          studentHistoryData?.length > 0 &&
          studentHistoryData.map((s, index) => (
            <ItemViewStudent key={index} student={s} />
          ))}
      </TableViewStudent>
      {studentHistoryData && studentHistoryData?.length === 0 && (
        <Center mt="32px" w="full">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}
      {studentHistoryData && studentHistoryData.length >= 3 && (
        <Link color="primary" mt="16px" fontWeight="600" onClick={handleclick}>
          <Text>Show All History</Text>
        </Link>
      )}
    </SectionWhite>
  );
};

export default SectionTablesProgram;
