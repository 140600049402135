import { Flex, Grid, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ClassroomIcon from "../../assets/icons/dashboard/ClassroomIcon";
import DistrictIcon from "../../assets/icons/dashboard/DistrictIcon";
import ProgramIcon from "../../assets/icons/dashboard/Program";
import ReportsIcon from "../../assets/icons/dashboard/ReportsIcon";
import SchoolsIcon from "../../assets/icons/dashboard/SchoolsIcon";
import SeatsIcon from "../../assets/icons/dashboard/SeatsIcon";
import StudentsIcon from "../../assets/icons/dashboard/StudentsIcon";
import TeacherIcon from "../../assets/icons/dashboard/TeacherIcon";
import KpiDashboard from "../../components/dashboard_components/KpiDashboard";
import Layout from "../../components/global/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import {
  clearListDashboard,
  loadDashboarAdminRequest,
  loadDashboardPrincipalRequest,
  loadDashboardTeacherRequest,
} from "../../stores/ducks/dashboard/action";
import { setFilters } from "../../stores/ducks/filters/actions";
import { loadSchoolsReportRequest } from "../../stores/ducks/school/actions";
import { captalize } from "../../utils/string-utils";
import ContainerCharts from "./ContainerCharts";
import ContainerChartsArea from "./ContainerChartsArea";

export interface IDataArea {
  series: { name: string; data: number[] }[] | undefined;
  categories: string[] | undefined;
}

export type SeriesTypes = {
  name: string;
  data: number[] | undefined;
};

const Dashboard: React.FC = () => {
  const { user } = useAppSelector((i) => i.auth);
  const navigate = useNavigate();
  const filterProps = useAppSelector((i) => i.filters);
  const {
    adminDashboard,
    dashboardAdminDataChartDonuts,
    principalDashboard,
    dashboardPrincipalDataChartDonuts,
    teacherDashboard,
    dashboardAdminDataChartPie,
    dashboardAdminDataChartBarJr,
    dashboardAdminDataChartBarLS,
    dashboardAdminDataChartBarVm,
    dashboardPrincpalDataChartPie,
    dashboardPrincipalDataChartBarJr,
    dashboardPrincipalDataChartBarLS,
    dashboardPrincipalDataChartBarVm,
    dashboaTeacherlDataChartPie,
    dashboardTeacherDataChartBarJr,
    dashboardTeacherDataChartBarLS,
    dashboardTeacherDataChartBarVm,
    dashboardTeacherDataChartDonuts,
  } = useAppSelector((i) => i.dashboard);
  const dispatch = useAppDispatch();
  const { schoolsListReport } = useAppSelector((s) => s.school);
  const filters = useAppSelector((s) => s.filters);
  const isAdmin = user?.user_type === "admin";
  const { setData } = useTypeDelay(
    (s) => {
      if (isAdmin) {
        dispatch(
          loadSchoolsReportRequest({
            search: s,
            is_active__exact: true,
            pagination: "true",
          })
        );
      }
    },
    () => {
      if (isAdmin) {
        dispatch(loadSchoolsReportRequest({ pagination: "true" }));
      }
    }
  );

  useEffect(() => {
    if (user?.user_type === "admin") {
      dispatch(loadDashboarAdminRequest());
    }
    if (user?.user_type === "principal") {
      dispatch(loadDashboardPrincipalRequest());
    }
    if (user?.user_type === "teacher") {
      dispatch(loadDashboardTeacherRequest());
    }

    return () => {
      dispatch(clearListDashboard());
    };
  }, []);

  const verifystudentActive = () => {
    if (user?.user_type === "admin") {
      return dashboardAdminDataChartDonuts;
    }
    if (user?.user_type === "principal") {
      return dashboardPrincipalDataChartDonuts;
    }
    if (user?.user_type === "teacher") {
      return dashboardTeacherDataChartDonuts;
    }
  };

  const verifyDiagnosticData = () => {
    if (user?.user_type === "admin") {
      return dashboardAdminDataChartPie;
    }
    if (user?.user_type === "principal") {
      return dashboardPrincpalDataChartPie;
    }
    if (user?.user_type === "teacher") {
      return dashboaTeacherlDataChartPie;
    }
  };

  const verifyJrData = () => {
    if (user?.user_type === "admin") {
      return dashboardAdminDataChartBarJr;
    }
    if (user?.user_type === "principal") {
      return dashboardPrincipalDataChartBarJr;
    }
    if (user?.user_type === "teacher") {
      return dashboardTeacherDataChartBarJr;
    }
  };
  const verifyLsData = () => {
    if (user?.user_type === "admin") {
      return dashboardAdminDataChartBarLS;
    }
    if (user?.user_type === "principal") {
      return dashboardPrincipalDataChartBarLS;
    }
    if (user?.user_type === "teacher") {
      return dashboardTeacherDataChartBarLS;
    }
  };
  const verifyVmData = () => {
    if (user?.user_type === "admin") {
      return dashboardAdminDataChartBarVm;
    }
    if (user?.user_type === "principal") {
      return dashboardPrincipalDataChartBarVm;
    }
    if (user?.user_type === "teacher") {
      return dashboardTeacherDataChartBarVm;
    }
  };

  const verifyDataAreaRedFlag = () => {
    if (user?.user_type === "admin") {
      return dataAreaAdmin;
    }
    if (user?.user_type === "principal") {
      return dataAreaPrincipal;
    }
    if (user?.user_type === "teacher") {
      return dataAreaTeacher;
    }
  };

  const dataAreaAdmin: IDataArea = {
    series: [
      {
        name: "Red Flags",
        data: adminDashboard?.red_flags.data
          ? adminDashboard?.red_flags.data
          : [],
      },
    ],
    categories: adminDashboard?.red_flags.categories,
  };
  const dataAreaPrincipal: IDataArea = {
    series: [
      {
        name: "Red Flags",
        data: principalDashboard?.red_flags.data
          ? principalDashboard?.red_flags.data
          : [],
      },
    ],
    categories: principalDashboard?.red_flags.categories,
  };
  const dataAreaTeacher: IDataArea = {
    series: [
      {
        name: "Red Flags",
        data: teacherDashboard?.red_flags.data
          ? teacherDashboard?.red_flags.data
          : [],
      },
    ],
    categories: teacherDashboard?.red_flags.categories,
  };

  const levels: Record<string, string> = {
    Red: "1",
    Green: "2",
    Orange: "3",
    Teal: "4",
    Vm1: "9",
    Vm2: "10",
    Vm3: "11",
    Vm4: "12",
  };

  function clickInDiagnosticChart(label: string) {
    const currentYear = new Date().getFullYear();
    if (user?.user_type === "admin") {
      window.open(
        `/build-report/diagnostic-placement?school=all&level=${levels[label]}&year=${currentYear}`
      );
    } else {
      window.open(
        `/build-report/diagnostic-placement?school=&level=${levels[label]}&year=${currentYear}`
      );
    }
  }

  return (
    <Layout text={"Dashboard"}>
      <Flex alignItems="center" gap="24px" justify="space-between">
        <Heading fontSize="24px">Welcome, {user?.first_name}</Heading>
        {isAdmin && (
          <Select
            placeholder="Search School(s):"
            onChange={(value) => {
              dispatch(
                loadDashboarAdminRequest({ school_id: String(value?.value) })
              );
              dispatch(
                setFilters({ schoolName: value?.label, school: value?.value })
              );
            }}
            onInputChange={(e) => {
              setData(e);
            }}
            options={
              schoolsListReport &&
              schoolsListReport?.results.map((data) => ({
                label: data.name,
                value: data.id,
              }))
            }
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                minWidth: "300px",
                borderRadius: "16px",
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: "300px",
              }),
            }}
          />
        )}
      </Flex>

      <Flex flexWrap="wrap" mt="28px" gap="24px">
        {user?.user_type === "admin" && (
          <KpiDashboard
            linkTo="/districts"
            data={adminDashboard?.districts}
            icon={<DistrictIcon />}
            title="Districts"
          />
        )}
        {user?.user_type === "admin" && (
          <KpiDashboard
            linkTo="/schools"
            data={adminDashboard?.schools}
            icon={<SchoolsIcon />}
            title="Schools"
          />
        )}

        {user?.user_type === "admin" && (
          <KpiDashboard
            linkTo="/classroom"
            data={adminDashboard?.classrooms}
            icon={<ClassroomIcon />}
            title="Classrooms"
          />
        )}
        {user?.user_type === "principal" && (
          <KpiDashboard
            linkTo="/teachers"
            data={principalDashboard?.teachers}
            icon={<TeacherIcon />}
            title="Teacher"
          />
        )}
        {user?.user_type === "principal" && (
          <KpiDashboard
            linkTo="/classroom"
            data={principalDashboard?.teachers}
            icon={<StudentsIcon />}
            title="Classrooms"
          />
        )}
        {user?.user_type === "principal" && (
          <KpiDashboard
            data={principalDashboard?.seats_available}
            icon={<SeatsIcon />}
            title="Seats Available"
          />
        )}
        {user?.user_type === "teacher" && (
          <KpiDashboard
            linkTo="/classroom"
            data={teacherDashboard?.classrooms}
            icon={<ClassroomIcon />}
            title="Classrooms"
          />
        )}
        {user?.user_type === "teacher" && (
          <KpiDashboard
            linkTo="/students"
            data={teacherDashboard?.total_students}
            icon={<StudentsIcon />}
            title="Total students"
          />
        )}
        {user?.user_type === "teacher" && (
          <KpiDashboard
            data={teacherDashboard?.seats_available}
            icon={<SeatsIcon />}
            title="Seats Available"
          />
        )}
        {user?.user_type !== "admin" && (
          <KpiDashboard
            data={
              user?.user_type === "principal"
                ? principalDashboard?.total_reports
                : teacherDashboard?.total_reports
            }
            icon={<ReportsIcon />}
            title="Total Reports"
          />
        )}
        {user?.user_type === "admin" && (
          <KpiDashboard
            data={adminDashboard?.programs}
            icon={<ProgramIcon />}
            title="Programs"
          />
        )}
      </Flex>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
        gap="24px"
        mt="28px"
      >
        <Flex>
          {verifystudentActive() && (
            <ContainerCharts
              title="User Status"
              total={verifystudentActive()?.totalUsers}
              series={verifystudentActive()?.series}
              labels={verifystudentActive()?.labels}
              type="donut"
            />
          )}
        </Flex>
        <Flex>
          {verifyDiagnosticData() && (
            <ContainerCharts
              title="Diagnostic Prescriptive"
              type="pie"
              series={verifyDiagnosticData()?.series}
              labels={verifyDiagnosticData()?.labels.map((v) => captalize(v))}
              total={verifyDiagnosticData()?.totalDiagnostic}
              cursor="pointer"
              getLabel={(label) => clickInDiagnosticChart(label)}
            />
          )}
        </Flex>
        <Flex flex={1}>
          <ContainerChartsArea
            title="Red Flags"
            series={verifyDataAreaRedFlag()?.series}
            categories={verifyDataAreaRedFlag()?.categories}
            type="area"
            cursor="pointer"
            onClick={() => {
              dispatch(setFilters({ ...filters, redFlag: true }));
              navigate("/students");
            }}
          />
        </Flex>
        <Flex>
          {verifyJrData() && (
            <ContainerChartsArea
              title="Joseph's Readers"
              series={verifyJrData()?.series}
              categories={verifyJrData()?.categories}
              colors={verifyJrData()?.colors}
              total={verifyJrData()?.totalData}
              type="bar"
            />
          )}
        </Flex>

        <Flex>
          {verifyLsData() && (
            <ContainerChartsArea
              title="Life Skills"
              series={verifyLsData()?.series}
              categories={verifyLsData()?.categories}
              colors={verifyLsData()?.colors}
              total={verifyLsData()?.totalData}
              type="bar"
            />
          )}
        </Flex>
        <Flex>
          {verifyVmData() && (
            <ContainerChartsArea
              title="Verbal Master"
              series={verifyVmData()?.series}
              categories={verifyVmData()?.categories.map((v) => captalize(v))}
              colors={verifyVmData()?.colors}
              total={verifyVmData()?.totalData}
              type="bar"
            />
          )}
        </Flex>
      </Grid>

      {/* {user?.user_type === "admin" && (
        <Flex w="full" h="400px" mt="24px">
          <ContainerChartsBarAdmin
            title="Licenses per School"
            series={dataChart.dataBarAdmin.series}
            categories={dataChart.dataBarAdmin.categories}
            colors={dataChart.dataBarAdmin.colors}
            type="bar"
          />
        </Flex>
      )} */}
    </Layout>
  );
};

export default Dashboard;
