import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";
import React from "react";

const SkeletonLibraryVideo: React.FC = () => {
  return (
    <Flex gap="16px" mt="12px">
      <Skeleton w="217px" h="168px" borderRadius="16px" />
      <Skeleton w="217px" h="168px" borderRadius="16px" />
      <Skeleton w="217px" h="168px" borderRadius="16px" />
      <Skeleton w="217px" h="168px" borderRadius="16px" />
    </Flex>
  );
};

export default SkeletonLibraryVideo;
