import {
  DashboardAdmin,
  DashboardAdminDataChartBar,
  DashboardAdminDataChartPie,
  DashboardAdminFilter,
  DashboardPrincipal,
  DashboardTeacher,
} from "../../../@types/interfaces/api/dashboard.inteface";
import {
  replaceNameJr,
  replaceNameLs,
  replaceNameVm,
} from "../../../utils/replaceNameCharts";
import { DashboardActionTypes } from "./types";

export const loadDashboarAdminRequest = (filter?: DashboardAdminFilter) => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_ADMIN_REQUEST,
    payload: filter,
  };
};
export const loadDashboarAdminSuccess = (data: DashboardAdmin) => {
  const dataChartsDonuts = {
    labels: ["Active", "Inactive"],
    series: [data.active_users, data.inactive_users],
    totalUsers: data.total_users,
  };
  const dataChartsPie: DashboardAdminDataChartPie = {
    labels: Object.keys(data.diagnostic),
    series: Object.values(data.diagnostic),
    totalDiagnostic: Object.values(data.diagnostic).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  const dataChartsBarJr: DashboardAdminDataChartBar = {
    categories: Object.keys(data.jr_levels).map((i) => replaceNameJr[i]),
    colors: ["#FF1313", "#3BB800", "#FF6B00", "#08D6BD"],
    series: [
      {
        name: "Joseph's Readers",
        data: Object.values(data.jr_levels),
      },
    ],
    totalData: Object.values(data.jr_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };
  const dataChartsBarLs: DashboardAdminDataChartBar = {
    categories: Object.keys(data.ls_levels).map((i) => replaceNameLs[i]),
    colors: ["#3989F2", "#9C39FF", "#9A4A00", "#FFE500"],
    series: [
      {
        name: "Life Skills",
        data: Object.values(data.ls_levels),
      },
    ],
    totalData: Object.values(data.ls_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  const dataChartsBarVm: DashboardAdminDataChartBar = {
    categories: Object.keys(data.vm_levels).map((i) => replaceNameVm[i]),
    colors: ["#7EC8E3", "#68B0AB", "#0047AB", "#003366"],
    series: [
      {
        name: "Verbal Masters",
        data: Object.values(data.vm_levels),
      },
    ],
    totalData: Object.values(data.vm_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_ADMIN_SUCCESS,
    payload: {
      data,
      dataChartsDonuts,
      dataChartsPie,
      dataChartsBarJr,
      dataChartsBarLs,
      dataChartsBarVm,
    },
  };
};
export const loadDashboarAdminError = () => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_ADMIN_ERROR,
  };
};

export const loadDashboardPrincipalRequest = () => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_REQUEST,
  };
};
export const loadDashboardPrincipalSuccess = (data: DashboardPrincipal) => {
  const userStatusData = {
    labels: ["Active", "Inactive"],
    series: [data.active_students, data.inactive_students],
    totalUsers: data.total_students,
  };

  const dataChartsPiDp: DashboardAdminDataChartPie = {
    labels: Object.keys(data.diagnostic),
    series: Object.values(data.diagnostic),
    totalDiagnostic: Object.values(data.diagnostic).reduce(
      (acc, value) => acc + value,
      0
    ),
  };
  const dataChartsBarJr: DashboardAdminDataChartBar = {
    categories: Object.keys(data.jr_levels),
    colors: ["#FF1313", "#3BB800", "#FF6B00", "#08D6BD"],
    series: [
      {
        name: "Joseph's Readers",
        data: Object.values(data.jr_levels),
      },
    ],
    totalData: Object.values(data.jr_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };
  const dataChartsBarLs: DashboardAdminDataChartBar = {
    categories: Object.keys(data.ls_levels),
    colors: ["#3989F2", "#9C39FF", "#9A4A00", "#FFE500"],
    series: [
      {
        name: "Life Skills",
        data: Object.values(data.ls_levels),
      },
    ],
    totalData: Object.values(data.ls_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  const dataChartsBarVm: DashboardAdminDataChartBar = {
    categories: Object.keys(data.vm_levels),
    colors: ["#7EC8E3", "#68B0AB", "#0047AB", "#003366"],
    series: [
      {
        name: "Verbal Masters",
        data: Object.values(data.vm_levels),
      },
    ],
    totalData: Object.values(data.vm_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_SUCCESS,
    payload: {
      data,
      userStatusData,
      dataChartsPiDp,
      dataChartsBarJr,
      dataChartsBarLs,
      dataChartsBarVm,
    },
  };
};
export const loadDashboardPrincipalError = () => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_ERROR,
  };
};

export const loadDashboardTeacherRequest = () => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_TEACHER_REQUEST,
  };
};
export const loadDashboardTeacherSuccess = (data: DashboardTeacher) => {
  const userStatusData = {
    labels: ["Active", "Inactive"],
    series: [data.active_students, data.inactive_students],
    totalUsers: data.total_students,
  };

  const dataChartsPiDp: DashboardAdminDataChartPie = {
    labels: Object.keys(data.diagnostic),
    series: Object.values(data.diagnostic),
    totalDiagnostic: Object.values(data.diagnostic).reduce(
      (acc, value) => acc + value,
      0
    ),
  };
  const dataChartsBarJr: DashboardAdminDataChartBar = {
    categories: Object.keys(data.jr_levels),
    colors: ["#FF1313", "#3BB800", "#FF6B00", "#08D6BD"],
    series: [
      {
        name: "Joseph's Readers",
        data: Object.values(data.jr_levels),
      },
    ],
    totalData: Object.values(data.jr_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };
  const dataChartsBarLs: DashboardAdminDataChartBar = {
    categories: Object.keys(data.ls_levels),
    colors: ["#3989F2", "#9C39FF", "#9A4A00", "#FFE500"],
    series: [
      {
        name: "Life Skills",
        data: Object.values(data.ls_levels),
      },
    ],
    totalData: Object.values(data.ls_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  const dataChartsBarVm: DashboardAdminDataChartBar = {
    categories: Object.keys(data.vm_levels),
    colors: ["#7EC8E3", "#68B0AB", "#0047AB", "#003366"],
    series: [
      {
        name: "Verbal Masters",
        data: Object.values(data.vm_levels),
      },
    ],
    totalData: Object.values(data.vm_levels).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_TEACHER_SUCCESS,
    payload: {
      data,
      userStatusData,
      dataChartsPiDp,
      dataChartsBarJr,
      dataChartsBarLs,
      dataChartsBarVm,
    },
  };
};
export const loadDashboardTeacherError = () => {
  return {
    type: DashboardActionTypes.LOAD_DASHBOARD_TEACHER_ERROR,
  };
};

export const clearListDashboard = () => {
  return {
    type: DashboardActionTypes.CLEAR_LIST,
  };
};
