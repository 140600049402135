import { Center, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Student } from "../../@types/interfaces/api/student.interface";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import ModalAlertLicense from "../../components/modals/ModalAlertLicense";
import ModalImportStudents from "../../components/modals/ModalImportStudents";
import ItemStudentTable from "../../components/students_components/ItemStudentTable";
import LayoutToolsStudents from "../../components/students_components/LayoutToolsStudents";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import { useSorting } from "../../hooks/useSorting";
import { clearUsersList } from "../../stores/ducks/auth/actions";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";
import {
  clearStudentList,
  loadStudentRequest,
} from "../../stores/ducks/student/action";
import { studantTables } from "../../utils/Tables";

const Students: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState({ isOpen: false });
  const { studentList, isStudentLoading, isActive } = useAppSelector(
    (i) => i.student
  );
  const filterProps = useAppSelector((i) => i.filters);
  const [modalAlertOpen, setModalAlertOpen] = useState({ isOpen: !isActive });
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<Student>();
  const { sorting, listSorted, setListSorted } = useSorting<Student>();

  const pageCount = Number(studentList?.pages);

  const onChangePage = (page: string) => {
    dispatch(
      loadStudentRequest({
        page,
        search: filterProps.search,
        user__is_active__exact: filterProps.isActiveFilter,
        red_flag: filterProps.redFlag,
        user__profile__school__id__exact: filterProps.school,
      })
    );
    dispatch(setFilters({ ...filterProps, page }));
  };
  useEffect(() => {
    if (studentList?.results) {
      setListSorted(studentList?.results);
    }
  }, [studentList]);

  useEffect(() => {
    return () => {
      dispatch(clearUsersList());
      dispatch(clearStudentList());
      dispatch(clearFilter());
      setListSorted(undefined);
    };
  }, []);

  return (
    <Layout text={"Students"}>
      <LayoutToolsStudents
        onOpen={() => setModalOpen({ isOpen: true })}
        selectedItems={items}
        onClickActions={clearSelections}
      />
      {listSorted && !isStudentLoading ? (
        <CustomTable
          headers={studantTables}
          isFullSelected={() => isFullSelected(listSorted)}
          onClickCheck={() => handleAll(listSorted)}
          onClickHeader={(v) => sorting(v)}
        >
          {listSorted?.map((item) => (
            <ItemStudentTable
              key={item.user}
              student={item}
              onClickCheckbox={() => handleItem(item)}
              isSelected={items.some((s) => s.user === item.user)}
            />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}

      {listSorted && listSorted.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}

      <ModalImportStudents
        isOpen={modalOpen.isOpen}
        onClose={() => setModalOpen({ isOpen: false })}
      />
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
      <ModalAlertLicense
        isOpen={modalAlertOpen.isOpen}
        onClose={() => setModalAlertOpen({ isOpen: !modalAlertOpen.isOpen })}
      />
    </Layout>
  );
};

export default Students;
