import { LicenseActionTypes, LicenseState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: LicenseState = {
  isLoadingLicense: false,
  licenses: [],
  licenseById: undefined,
  checkLicense: undefined,
};

const reducer: Reducer<LicenseState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LicenseActionTypes.LOAD_LICENSE_REQUEST:
      return {
        ...state,
        isLoadingLicense: true,
      };
    case LicenseActionTypes.LOAD_LICENSE_SUCCESS:
      return {
        ...state,
        isLoadingLicense: false,
        license: action.payload,
      };
    case LicenseActionTypes.LOAD_LICENSE_ERROR:
      return { ...INITIAL_STATE, isLoadingLicense: false };

    case LicenseActionTypes.LOAD_LICENSE_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingLicense: true,
      };
    case LicenseActionTypes.LOAD_LICENSE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingLicense: false,
        licenseById: action.payload,
      };
    case LicenseActionTypes.LOAD_LICENSE_BY_ID_ERROR:
      return { ...INITIAL_STATE, isLoadingLicense: false };

    case LicenseActionTypes.REGISTER_LICENSE_REQUEST:
      return {
        ...state,
        isLoadingLicense: true,
      };
    case LicenseActionTypes.REGISTER_LICENSE_SUCCESS:
      return {
        ...state,
        isLoadingLicense: false,
      };
    case LicenseActionTypes.REGISTER_LICENSE_ERROR:
      return { ...INITIAL_STATE, isLoadingLicense: false };

    case LicenseActionTypes.CHECK_LICENSE_REQUEST:
      return {
        ...state,
        isLoadingLicense: true,
      };
    case LicenseActionTypes.CHECK_LICENSE_SUCCESS:
      return {
        ...state,
        isLoadingLicense: false,
        checkLicense: action.payload,
      };
    case LicenseActionTypes.CHECK_LICENSE_ERROR:
      return { ...INITIAL_STATE, isLoadingLicense: false };

    default:
      return state;
  }
};

export default reducer;
