export const dataYear = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2023;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
  }

  return years;
};
