import {
  Store,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import createSagaMiddleware from "redux-saga";
import { AuthState } from "./auth/types";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { composeWithDevTools } from "redux-devtools-extension";
import { DistrictState } from "./district/types";
import { SchoolState } from "./school/types";
import { LicenseState } from "./license/types";
import { ClassroomState } from "./classroom/types";
import { ProfileState } from "./profile/types";
import { TutorialState } from "./tutorial/types";
import { LibraryState } from "./library/types";
import { StudentState } from "./student/types";
import { NoteState } from "./note/types";
import { CoreState } from "./core/types";
import { DashboardState } from "./dashboard/types";
import { BookMarkState } from "./bookmark/types";
import { BuildReportState } from "./buildReport/types";
import { CertificateState } from "./certificate/types";
import { FilterState } from "./filters/types";

export interface ApplicationState {
  auth: AuthState;
  district: DistrictState;
  school: SchoolState;
  license: LicenseState;
  classroom: ClassroomState;
  profile: ProfileState;
  tutorial: TutorialState;
  library: LibraryState;
  student: StudentState;
  note: NoteState;
  core: CoreState;
  dashboard: DashboardState;
  bookMark: BookMarkState;
  buildReport: BuildReportState;
  certificate: CertificateState;
  filters: FilterState;
}

const sagaMiddeware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddeware))
);

sagaMiddeware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export const getGlobalState = (state: ApplicationState) => state;

export default store;
