import { Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { ItemsBrDiagnostic } from "../../@types/interfaces/api/buildReport.interface";

interface ItemViewStudentProps {
  data: ItemsBrDiagnostic;
}

const ItemDiagnosticReport: React.FC<ItemViewStudentProps> = ({ data }) => {
  return (
    <Tr>
      <Td whiteSpace="pre-wrap" maxW="150px">
        {moment(data.date).format("MM-DD-YYYY HH:mm")}
      </Td>
      <Td>
        <Text>{data.grade}</Text>
      </Td>

      <Td whiteSpace="pre-wrap" maxW="150px">
        <Text>{data.level}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_1_0 !== null ? data.p_1_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_1_5 !== null ? data.p_1_5 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_2_0 !== null ? data.p_2_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_3_0 !== null ? data.p_3_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_4_0 !== null ? data.p_4_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_4_5 !== null ? data.p_4_5 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_5_0 !== null ? data.p_5_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_6_0 !== null ? data.p_6_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_8_0 !== null ? data.p_8_0 + "%" : "-"}</Text>
      </Td>

      <Td p="10px">
        <Text>{data.p_10_0 !== null ? data.p_10_0 + "%" : "-"}</Text>
      </Td>
      <Td p="10px">
        <Text>{data.p_12_0 !== null ? data.p_12_0 + "%" : "-"}</Text>
      </Td>
    </Tr>
  );
};

export default ItemDiagnosticReport;
