import {
  Center,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Library as TipeLibrary } from "../../@types/interfaces/api/library.interface";
import Layout from "../../components/global/Layout";
import SkeletonLibraryButton from "../../components/global/skeleton/SkeletonLibraryButton";
import SkeletonLibraryLesson from "../../components/global/skeleton/SkeletonLibraryLesson";
import ButtonLibrary from "../../components/library_components/ButtonLibrary";
import CardIntroLibrary from "../../components/library_components/CardIntroLibrary";
import LayoutToolsLibrary from "../../components/library_components/LayoutToolsLibrary";
import ModalDeleteLibrary from "../../components/modals/ModalDeleteLibrary";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  deleteFullFileRequest,
  deleteIntroRequest,
  deleteLibraryRequest,
  loadLevelRequest,
  loadLibraryRequest,
  loadProgramRequest,
} from "../../stores/ducks/library/actions";

const dataCardRas: TipeLibrary[] = [
  {
    id: 198,
    story: 0,
    file: "https://d2af8e2dnzvzx8.cloudfront.net/app/assets/library/ras/RAS_teacher.pdf",
    name: "Teacher Reading Attitude Survey",
    story_name: "RAS Documents",
    created_at: "0",
    updated_at: "0",
  },
  {
    id: 199,
    story: 0,
    file: "https://d2af8e2dnzvzx8.cloudfront.net/app/assets/library/ras/RAS_student.pdf",
    name: "Student Reading Attitude Survey",
    story_name: "RAS Documents",
    created_at: "0",
    updated_at: "0",
  },
];

const Library: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    programList,
    levelList,
    libraryList,
    isLoadingLevel,
    isLoadingLibrary,
    fullFileList,
    introList,
  } = useAppSelector((i) => i.library);
  const [selectedProgram, setSelectedProgram] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [stories, setStories] = useState<string[]>([]);
  const [modalDeleteOpen, setModalDeleteOpen] = useState({
    isOpen: false,
    title: "",
    id: 0,
    type: "",
  });

  useEffect(() => {
    dispatch(loadProgramRequest());
    dispatch(loadLevelRequest({ program__id: 1 }));
  }, []);

  const handleTabs = (id: number) => {
    dispatch(loadLevelRequest({ program__id: id }));
    setSelectedLevel(0);
  };

  const lessonHandleClick = (index: number, id: number) => {
    setSelectedLevel(index);
    setStories([]);
    dispatch(loadLibraryRequest({ story__level__id: id }));
  };
  const handleDelete = () => {
    if (modalDeleteOpen.type === "library") {
      dispatch(
        deleteLibraryRequest(modalDeleteOpen.id, {
          story__level__id: levelList[selectedLevel].id,
        })
      );
    } else if (modalDeleteOpen.type === "full_file") {
      dispatch(
        deleteFullFileRequest(modalDeleteOpen.id, {
          story__level__id: levelList[selectedLevel].id,
        })
      );
    } else {
      dispatch(
        deleteIntroRequest(modalDeleteOpen.id, {
          story__level__id: levelList[selectedLevel].id,
        })
      );
    }
    setModalDeleteOpen({ ...modalDeleteOpen, isOpen: false });
  };

  useEffect(() => {
    if (levelList.length === 0) return;

    dispatch(
      loadLibraryRequest({ story__level__id: levelList[selectedLevel].id })
    );
  }, [levelList]);

  useEffect(() => {
    if (libraryList.length === 0) return;
    const stories = libraryList.map((d) => d.story_name);
    // new Set delete duplicate itens-------------------
    setStories([...new Set(stories)]);
  }, [libraryList]);

  return (
    <Layout text="Library">
      <LayoutToolsLibrary selectedLevel={selectedLevel} />
      <Tabs
        mt="24px"
        colorScheme="blueScheme"
        onChange={(index) => setSelectedProgram(index)}
      >
        <TabList fontWeight="500">
          {programList?.map((item) => (
            <Tab
              fontWeight="500"
              key={item.id}
              onClick={() => handleTabs(item.id)}
            >
              {item.name}
            </Tab>
          ))}
          <Tab fontWeight="500" onClick={() => {}}>
            Reading Attitude Survey
          </Tab>
        </TabList>
        <TabPanels mt="24px">
          {programList.map((item) => (
            <TabPanel key={item.id}>
              {!isLoadingLevel ? (
                <Flex gap="16px">
                  {levelList?.map((item, index) => (
                    <ButtonLibrary
                      level={item}
                      key={item.id}
                      isSelected={selectedLevel === index}
                      handleClick={() => lessonHandleClick(index, item.id)}
                    />
                  ))}
                </Flex>
              ) : (
                <SkeletonLibraryButton />
              )}

              {introList.length > 0 && (
                <Flex flexDir="column" mt="30px">
                  <Heading fontWeight="600" fontSize="18px" mt="32px">
                    Intro
                  </Heading>

                  <Flex w="full" gap="32px" flexWrap="wrap" overflowX="hidden">
                    {introList.map((i) => (
                      <CardIntroLibrary
                        key={i.id}
                        data={{
                          id: i.id,
                          name: i.name,
                          file: i.file,
                          story: i.program,
                          story_name: i.program_name,
                          created_at: i.created_at,
                          updated_at: i.updated_at,
                        }}
                        levelList={"x"}
                        openModal={() =>
                          setModalDeleteOpen({
                            title: i.name,
                            isOpen: true,
                            id: i.id,
                            type: "intro",
                          })
                        }
                      />
                    ))}
                  </Flex>
                </Flex>
              )}
              {fullFileList.length > 0 && (
                <Flex flexDir="column" mt="30px">
                  <Heading fontWeight="600" fontSize="18px" mt="32px">
                    Full File
                  </Heading>

                  <Flex w="full" gap="32px" flexWrap="wrap" overflowX="hidden">
                    {fullFileList.map((i) => (
                      <CardIntroLibrary
                        key={i.id}
                        data={{
                          id: i.id,
                          name: i.name,
                          file: i.file,
                          story: i.program,
                          story_name: i.program_name,
                          created_at: i.created_at,
                          updated_at: i.updated_at,
                        }}
                        levelList={"x"}
                        openModal={() =>
                          setModalDeleteOpen({
                            title: i.name,
                            isOpen: true,
                            id: i.id,
                            type: "full_file",
                          })
                        }
                      />
                    ))}
                  </Flex>
                </Flex>
              )}

              {libraryList.length > 0 &&
                stories.map((d, i) => (
                  <Flex flexDir="column" mt="30px" key={i}>
                    <Heading fontWeight="600" fontSize="18px" mt="32px">
                      {d}
                    </Heading>
                    {!isLoadingLibrary ? (
                      <Flex
                        w="full"
                        gap="32px"
                        flexWrap="wrap"
                        overflowX="hidden"
                      >
                        {libraryList
                          ?.filter((item) => item.story_name === d)
                          .map((i) => (
                            <CardIntroLibrary
                              key={i.id}
                              data={i}
                              levelList={levelList[selectedLevel].name}
                              openModal={() =>
                                setModalDeleteOpen({
                                  title: i.name,
                                  isOpen: true,
                                  id: i.id,
                                  type: "library",
                                })
                              }
                            />
                          ))}
                      </Flex>
                    ) : (
                      <SkeletonLibraryLesson />
                    )}
                  </Flex>
                ))}
            </TabPanel>
          ))}
          <TabPanel>
            <Flex flexDir="column" mt="30px">
              <Heading fontWeight="600" fontSize="18px" mt="32px">
                Ras Documents
              </Heading>

              <Flex w="full" gap="32px" flexWrap="wrap" overflowX="hidden">
                {dataCardRas.map((i) => (
                  <CardIntroLibrary
                    data={i}
                    levelList={"saasasas"}
                    openModal={
                      () => window.alert("This item cannot be deleted")
                      // setModalDeleteOpen({
                      //   title: i.name,
                      //   isOpen: true,
                      //   id: i.id,
                      // })
                    }
                  />
                ))}
              </Flex>
            </Flex>
          </TabPanel>
          {libraryList.length <= 0 && (
            <Center mt="32px">
              <Text fontSize="18px" fontWeight="500">
                No items found
              </Text>
            </Center>
          )}
        </TabPanels>
      </Tabs>

      <ModalDeleteLibrary
        handleDelete={handleDelete}
        isOpen={modalDeleteOpen}
        onClose={() =>
          setModalDeleteOpen({ ...modalDeleteOpen, isOpen: false })
        }
      />
    </Layout>
  );
};

export default Library;
