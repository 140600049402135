import { FC, useRef } from "react";
import ReactSelect, { ActionMeta } from "react-select";

// Rest of the code remains the same

export interface MultipleOptionsType {
  value: string;
  label: string;
}

interface MultiSelectProps {
  value: MultipleOptionsType[];
  options: MultipleOptionsType[];
  onChange: (
    newValue: MultipleOptionsType[],
    actionMeta: ActionMeta<MultipleOptionsType>
  ) => void;
  setData: (e: string) => void;
  returnSelectAllOption?: boolean;
}

const MultiSelect: FC<MultiSelectProps> = (props) => {
  const valueRef = useRef<MultipleOptionsType[]>(props.value);
  valueRef.current = props.value;

  const selectAllOption: MultipleOptionsType = {
    value: "all",
    label: "All Options",
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option: MultipleOptionsType) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() && !props.returnSelectAllOption
      ? [selectAllOption]
      : props.value;

  const handleChange = (
    newValue: any,
    actionMeta: ActionMeta<MultipleOptionsType>
  ) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option?.value === selectAllOption.value) {
      if (props.returnSelectAllOption) {
        props.onChange([selectAllOption], actionMeta);
      } else {
        props.onChange(props.options, actionMeta);
      }
    } else if (
      (action === "deselect-option" &&
        option?.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue?.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option?.value),
        actionMeta
      );
    } else {
      props.onChange((newValue as MultipleOptionsType[]) || [], actionMeta);
    }
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={handleChange}
      onInputChange={(e) => {
        props.setData(e);
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      placeholder="Search"
      isMulti
      styles={{
        control: (provided) => ({
          ...provided,
          marginTop: "20px",
          width: "50%",
          borderRadius: "16px",
        }),
        menu: (provided) => ({
          ...provided,
          width: "50%", // Set the desired width for the dropdown menu
        }),
      }}
    />
  );
};

export default MultiSelect;
