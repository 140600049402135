import { ClassroomActionTypes, ClassroomState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: ClassroomState = {
  isLoadingClassroom: false,
  classrooms: [],
  classroomById: undefined,
  classroomList: undefined,
};

const reducer: Reducer<ClassroomState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClassroomActionTypes.LOAD_CLASSROOM_REQUEST:
      return {
        ...state,
        isLoadingClassroom: true,
      };
    case ClassroomActionTypes.LOAD_CLASSROOM_SUCCESS:
      return {
        ...state,
        isLoadingClassroom: false,
        classroomList: action.payload,
      };
    case ClassroomActionTypes.LOAD_CLASSROOM_ERROR:
      return { ...INITIAL_STATE, isLoadingClassroom: false };

    case ClassroomActionTypes.REGISTER_CLASSROOM_REQUEST:
      return {
        ...state,
        isLoadingClassroom: true,
      };
    case ClassroomActionTypes.REGISTER_CLASSROOM_SUCCESS:
      return {
        ...state,
        isLoadingClassroom: false,
      };
    case ClassroomActionTypes.REGISTER_CLASSROOM_ERROR:
      return { ...INITIAL_STATE, isLoadingClassroom: false };

    case ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingClassroom: true,
      };
    case ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingClassroom: false,
        classroomById: action.payload,
      };
    case ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_ERROR:
      return { ...INITIAL_STATE, isLoadingClassroom: false };

    case ClassroomActionTypes.UPDATE_CLASSROOM_REQUEST:
      return {
        ...state,
        isLoadingClassroom: true,
      };
    case ClassroomActionTypes.UPDATE_CLASSROOM_SUCCESS:
      return {
        ...state,
        isLoadingClassroom: false,
      };
    case ClassroomActionTypes.UPDATE_CLASSROOM_ERROR:
      return { ...INITIAL_STATE, isLoadingClassroom: false };

    case ClassroomActionTypes.CLEAR_CLASSROOM_LIST:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
