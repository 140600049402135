import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

interface MenuLinkProps extends ButtonProps {
  to: string;
}

const MenuLink: React.FC<MenuLinkProps> = ({ to, children, ...rest }) => {
  const { pathname } = useLocation();

  const isCurrent = pathname.startsWith(to);

  return (
    <Link to={to}>
      <Button
        w="full"
        aria-selected={isCurrent}
        borderRadius="100px"
        justifyContent="flex-start"
        fontWeight="500"
        fontSize="14px"
        color="gray.500"
        iconSpacing="12px"
        _selected={{ bg: "gray.600", color: "white" }}
        variant="ghost"
        p="12px 14px"
        {...rest}
      >
        {children}
      </Button>
    </Link>
  );
};

export default MenuLink;
