import { Flex, FlexProps, Heading } from "@chakra-ui/react";
import React from "react";
import InputLabel from "../form/InputLabel";
import RegularMenu from "../form/RegularMenu";

interface SectionDropdownProps extends FlexProps {}

const SectionWhite: React.FC<SectionDropdownProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      {...rest}
      p="24px"
      bg="white"
      flexDir="column"
      borderRadius="16px"
      boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
    >
      {children}
    </Flex>
  );
};

export default SectionWhite;
