import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import React, { LegacyRef, forwardRef } from "react";
import LucideProps from "lucide-react";
import { JsxElement } from "typescript";

interface Props extends InputProps {
  icon: any;
  clickShow: () => void;
}

const InputPassword = (
  { type = "text", icon, clickShow, ...rest }: Props,
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <InputGroup>
      <Input
        ref={ref}
        h="48px"
        borderRadius="100px"
        bg="transparent"
        color="gray.700"
        _placeholder={{ color: "gray.400" }}
        type={type}
        {...rest}
      />
      <InputRightElement
        cursor="pointer"
        onClick={clickShow}
        color="gray.400"
        mt="12px"
        mr="12px"
        children={icon}
      />
    </InputGroup>
  );
};

export default forwardRef<HTMLInputElement, Props>(InputPassword);
