import {
  DashboardAdmin,
  DashboardAdminDataChartBar,
  DashboardAdminDataChartDonuts,
  DashboardAdminDataChartPie,
  DashboardPrincipal,
  DashboardPrincipalDataChartDonuts,
  DashboardTeacher,
  DashboardTeacherDataChartDonuts,
} from "../../../@types/interfaces/api/dashboard.inteface";

export enum DashboardActionTypes {
  LOAD_DASHBOARD_ADMIN_REQUEST = "@dashboard/LOAD_DASHBOARD_ADMIN_REQUEST",
  LOAD_DASHBOARD_ADMIN_SUCCESS = "@dashboard/LOAD_DASHBOARD_ADMIN_SUCCESS",
  LOAD_DASHBOARD_ADMIN_ERROR = "@dashboard/LOAD_DASHBOARD_ADMIN_ERROR",

  LOAD_DASHBOARD_PRINCIPAL_REQUEST = "@dashboard/LOAD_DASHBOARD_PRINCIPAL_REQUEST",
  LOAD_DASHBOARD_PRINCIPAL_SUCCESS = "@dashboard/LOAD_DASHBOARD_PRINCIPAL_SUCCESS",
  LOAD_DASHBOARD_PRINCIPAL_ERROR = "@dashboard/LOAD_DASHBOARD_PRINCIPAL_ERROR",

  LOAD_DASHBOARD_TEACHER_REQUEST = "@dashboard/LOAD_DASHBOARD_TEACHER_REQUEST",
  LOAD_DASHBOARD_TEACHER_SUCCESS = "@dashboard/LOAD_DASHBOARD_TEACHER_SUCCESS",
  LOAD_DASHBOARD_TEACHER_ERROR = "@dashboard/LOAD_DASHBOARD_TEACHER_ERROR",

  CLEAR_LIST = "@dashboard/CLEAR_LIST",
}

export interface DashboardState extends DashboardStateRequestsStatus {
  adminDashboard: DashboardAdmin | undefined;
  principalDashboard: DashboardPrincipal | undefined;
  teacherDashboard: DashboardTeacher | undefined;

  dashboardAdminDataChartDonuts: DashboardAdminDataChartDonuts;
  dashboardAdminDataChartPie: DashboardAdminDataChartPie;
  dashboardAdminDataChartBarJr: DashboardAdminDataChartBar | undefined;
  dashboardAdminDataChartBarLS: DashboardAdminDataChartBar | undefined;
  dashboardAdminDataChartBarVm: DashboardAdminDataChartBar | undefined;

  dashboardPrincipalDataChartDonuts: DashboardPrincipalDataChartDonuts;
  dashboardPrincpalDataChartPie: DashboardAdminDataChartPie;
  dashboardPrincipalDataChartBarJr: DashboardAdminDataChartBar | undefined;
  dashboardPrincipalDataChartBarLS: DashboardAdminDataChartBar | undefined;
  dashboardPrincipalDataChartBarVm: DashboardAdminDataChartBar | undefined;

  dashboardTeacherDataChartDonuts: DashboardPrincipalDataChartDonuts;
  dashboaTeacherlDataChartPie: DashboardAdminDataChartPie;
  dashboardTeacherDataChartBarJr: DashboardAdminDataChartBar | undefined;
  dashboardTeacherDataChartBarLS: DashboardAdminDataChartBar | undefined;
  dashboardTeacherDataChartBarVm: DashboardAdminDataChartBar | undefined;
}
export interface DashboardStateRequestsStatus {
  isLoading: boolean;
}
