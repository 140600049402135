import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
import { Classroom } from "../../../@types/interfaces/api/classroom.interface";
import FilterInterface from "../../../@types/interfaces/api/filters/filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { ClassroomCreate } from "../../../@types/interfaces/app/forms/create-classroom.interface";
import api from "../../../services/api";
import theme from "../../../styles/theme";

import {
  loadClassroomByIdError,
  loadClassroomByIdSuccess,
  loadClassroomError,
  loadClassroomSuccess,
  registerClassroomError,
  registerClassroomSuccess,
  updateClassroomError,
  updateClassroomSuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadClassroom(action: AnyAction) {
  const filters: FilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<Classroom>> = yield call(
      api.get,
      "/classroom",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadClassroomSuccess(data));
    }
  } catch (error) {
    yield put(loadClassroomError());
  }
}

export function* registerClassroom(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: ClassroomCreate; navigate: NavigateFunction } = action.payload;
  try {
    const response: AxiosResponse = yield call(api.post, `/classroom`, values);
    const { status, data } = response;
    if (status === 201) {
      yield put(registerClassroomSuccess());
      navigate("/classroom");
      toast({
        title: "Success",
        description: "Classroom successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerClassroomError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* loadClassroomById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.get, `/classroom/${id}`);
    const { status, data } = response;
    if (status === 200) {
      yield put(loadClassroomByIdSuccess(data));
    }
  } catch (error) {
    yield loadClassroomByIdError();
  }
}

export function* updateClassroom(action: AnyAction) {
  const {
    id,
    values,
    navigate,
  }: { id: number; values: ClassroomCreate; navigate: NavigateFunction } =
    action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/classroom/${id}`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateClassroomSuccess());
      navigate("/classroom");
      toast({
        title: "Success",
        description: "Classroom successfully updated",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(updateClassroomError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
