import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  shadows: { outline: "0 0 0 3px rgba(55, 33, 223, 0.7)" },
  styles: {
    global: {
      body: {
        bg: "#F6FCFF",
        color: "#2D3748",
      },
    },
  },
  colors: {
    white: "#FEFEFE",
    primary: "#0C4DA2",
    blue: {
      50: "#F7FAFE",
      100: "#E5F0FD",
      200: "#B6D3FA",
      300: "#6EA8F5",
      400: "#3989F2",
      500: "#09418A",
      600: "#07326C",
      700: "#052856",
      800: "#031E41",
    },
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
    tableScheme: {
      50: "#F7FAFC",
      100: "#F7FAFC",
      200: "#F7FAFC",
      300: "#F7FAFC",
      400: "#F7FAFC",
      500: "#F7FAFC",
      600: "#F7FAFC",
      700: "#F7FAFC",
      800: "#F7FAFC",
      900: "#F7FAFC",
    },
    tagScheme: {
      50: "#6EA8F5",
      100: "#6EA8F5",
      200: "#6EA8F5",
      300: "#6EA8F5",
      400: "#6EA8F5",
      500: "#6EA8F5",
      600: "#6EA8F5",
      700: "#6EA8F5",
      800: "#6EA8F5",
      900: "#6EA8F5",
    },
    tagGreenScheme: {
      50: "#41B081",
      100: "#41B081",
      200: "#41B081",
      300: "#41B081",
      400: "#41B081",
      500: "#41B081",
      600: "#41B081",
      700: "#41B081",
      800: "#41B081",
      900: "#41B081",
    },
    tagYellowScheme: {
      50: "#E8BA41",
      100: "#E8BA41",
      200: "#E8BA41",
      300: "#E8BA41",
      400: "#E8BA41",
      500: "#E8BA41",
      600: "#E8BA41",
      700: "#E8BA41",
      800: "#E8BA41",
      900: "#E8BA41",
    },
    blueScheme: {
      50: "#0C4DA2",
      100: "#0C4DA2",
      200: "#0C4DA2",
      300: "#0C4DA2",
      400: "#0C4DA2",
      500: "#0C4DA2",
      600: "#0C4DA2",
      700: "#0C4DA2",
      800: "#0C4DA2",
      900: "#0C4DA2",
    },
    alert: {
      green: "#48BB78",
      red: "#E53E3E",
      orange: "#F6AD55",
    },
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  breakpoints: {
    sm: "200px",
    md: "1440px",
  },
});

export default theme;
