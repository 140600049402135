import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import {
  DashboardAdmin,
  DashboardAdminFilter,
  DashboardPrincipal,
  DashboardTeacher,
} from "../../../@types/interfaces/api/dashboard.inteface";
import api from "../../../services/api";
import {
  loadDashboarAdminError,
  loadDashboarAdminSuccess,
  loadDashboardPrincipalError,
  loadDashboardPrincipalSuccess,
  loadDashboardTeacherError,
  loadDashboardTeacherSuccess,
} from "./action";

export function* loadDashboardAdmin(action: AnyAction) {
  const filter: DashboardAdminFilter = action.payload;

  try {
    const response: AxiosResponse<DashboardAdmin> = yield call(
      api.get,
      "/dashboard/admin",
      { params: filter }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadDashboarAdminSuccess(data));
    }
  } catch (error) {
    yield put(loadDashboarAdminError());
  }
}

export function* loadDashboardPrincipal(action: AnyAction) {
  try {
    const response: AxiosResponse<DashboardPrincipal> = yield call(
      api.get,
      "/dashboard/principal"
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadDashboardPrincipalSuccess(data));
    }
  } catch (error) {
    yield put(loadDashboardPrincipalError());
  }
}

export function* loadDashboardTeacher(action: AnyAction) {
  try {
    const response: AxiosResponse<DashboardTeacher> = yield call(
      api.get,
      "/dashboard/teacher"
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadDashboardTeacherSuccess(data));
    }
  } catch (error) {
    yield put(loadDashboardTeacherError());
  }
}
