import { ICertificate } from "../../../@types/interfaces/api/certificate.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum CertificateActionTypes {
  LOAD_CERTIFICATE_REQUEST = "@Certificate/LOAD_CERTIFICATE_REQUEST",
  LOAD_CERTIFICATE_SUCCESS = "@Certificate/LOAD_CERTIFICATE_SUCCESS",
  LOAD_CERTIFICATE_ERROR = "@Certificate/LOAD_CERTIFICATE_ERROR",
}

export interface CertificateState extends CertificateStateRequestsStatus {
  certificate: PaginationBase<ICertificate[]> | undefined;
}
export interface CertificateStateRequestsStatus {
  isLoadingCertificate: boolean;
}
