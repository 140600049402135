import {
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateUser,
  UpdateUser,
  User,
} from "../../@types/interfaces/api/user.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Form } from "../../styles/global";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  adminFormSchema,
  principalFormSchema,
  updatePrincipalFormSchema,
} from "../../utils/yup-schemas";
import { loadSchoolsRequest } from "../../stores/ducks/school/actions";
import {
  registerAdminRequest,
  registerPrincipalRequest,
  updateUserRequest,
} from "../../stores/ducks/auth/actions";
import { updateProfileRequest } from "../../stores/ducks/profile/actions";
import { Profile } from "../../@types/interfaces/api/profile.interface";
import Select from "react-select";
import InputPassword from "../form/InputPassword";
import { Eye, EyeOff } from "lucide-react";
import { generatePassword, generateUsername } from "../../utils/generates";
import useTypeDelay from "../../hooks/useTypeDelay.hook";

interface CreatePrincipalFormProps {
  user: User | undefined;
}

const CreatePrincipalForm: React.FC<CreatePrincipalFormProps> = ({ user }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { schoolsList } = useAppSelector((i) => i.school);
  const { isLoadingUsers, isLoadingUpdate } = useAppSelector((s) => s.auth);
  const [showPassword, setShowPassword] = useState(false);

  const { setData, data } = useTypeDelay(
    (v) => {
      dispatch(
        loadSchoolsRequest({
          is_active__exact: true,
          search: v,
        })
      );
    },
    () => {
      dispatch(
        loadSchoolsRequest({
          is_active__exact: true,
        })
      );
    }
  );

  const userMe = useAppSelector((i) => i.auth.user);

  const navigate = useNavigate();

  const generatedPassword = generatePassword();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm<CreateUser>({
    defaultValues: {
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      email: user ? user.email : "",
      username: user ? user.username : "",
      school: user ? user.school : 0,
      title: user ? user.title : "",
      password: user ? "" : generatedPassword,
      repeat_password: user ? "" : generatedPassword,
      user_type: user ? user.user_type : "principal",
    },
    resolver: yupResolver(
      !user ? principalFormSchema : updatePrincipalFormSchema
    ),
  });
  useEffect(() => {
    dispatch(loadSchoolsRequest({ is_active__exact: true }));
    if (userMe?.user_type !== "admin") {
      setValue("school", userMe?.school);
    }
  }, []);

  const watchFirstName = watch("first_name", "");
  const watchLastName = watch("last_name", "");

  useEffect(() => {
    if (
      watchFirstName !== undefined &&
      watchFirstName !== null &&
      watchLastName !== undefined &&
      watchLastName !== null &&
      !user
    ) {
      setValue("username", generateUsername(watchFirstName, watchLastName));
    }
  }, [watchFirstName, watchLastName]);

  const submitForm = (values: CreateUser | UpdateUser | Profile) => {
    if (user) {
      dispatch(updateProfileRequest(user.id, values as Profile));
      dispatch(updateUserRequest(user.id, values as UpdateUser, "principal"));
    } else {
      dispatch(registerPrincipalRequest(values as CreateUser, navigate));
    }
  };

  // const optionsSelect = schoolsList?.results.flatMap((v) => ({
  //   value: v.id,
  //   label: v.name,
  // }));

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          {id ? "Edit principal" : "Create New principal"}
        </Heading>

        <Grid
          mt="40px"
          gridTemplateAreas={`"first-name last-name title" 
                                "divider divider divider"
                                   "email school item"`}
          gap="24px"
        >
          <GridItem area={"first-name"}>
            <FormControl isInvalid={!!errors.first_name}>
              <InputLabel>
                First Name*
                <RegularInput mt="4px" {...register("first_name")} />
                {
                  <FormErrorMessage>
                    {errors.first_name?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"last-name"}>
            <FormControl isInvalid={!!errors.last_name}>
              <InputLabel>
                Last Name*
                <RegularInput mt="4px" {...register("last_name")} />
                {
                  <FormErrorMessage>
                    {errors.last_name?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"title"}>
            <FormControl isInvalid={!!errors.title}>
              <InputLabel>
                Title*
                <RegularInput mt="4px" {...register("title")} />
                {<FormErrorMessage>{errors.title?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"divider"}>
            <Divider my="12px" />
          </GridItem>
          <GridItem area={"email"}>
            <FormControl isInvalid={!!errors.email}>
              <InputLabel>
                Email Address*
                <RegularInput mt="4px" type="email" {...register("email")} />
                {<FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>

          <GridItem area={"item"}>
            {user && (
              <FormControl isInvalid={!!errors.user_type}>
                <InputLabel>
                  User Type*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("user_type")}
                  >
                    {["teacher", "principal"].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </RegularMenu>
                  {
                    <FormErrorMessage>
                      {errors.user_type?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            )}
          </GridItem>

          {userMe?.user_type === "admin" && (
            <GridItem area={"school"}>
              <FormControl isInvalid={!!errors.school}>
                <InputLabel>
                  School*
                  <Select
                    isDisabled={!!user}
                    // value={{
                    //     label: data,
                    //     value: getValues("school")
                    // }}
                    onChange={(value) => setValue("school", value!.value)}
                    placeholder={
                      user ? "School can't be changed" : "Search School(s):"
                    }
                    onInputChange={(e) => {
                      setData(e);
                    }}
                    options={schoolsList?.results.map((data) => ({
                      label: data.name,
                      value: data.id,
                    }))}
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        marginTop: "4px",
                        borderRadius: "100px",
                        height: "48px",
                        border: "1px solid #e6e8f0",
                        paddingInline: "12px",
                        color: "gray.500",
                        fontSize: "16px",
                        ":hover": {
                          outline: "none",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                      }),
                    }}
                  />
                  {
                    <FormErrorMessage>
                      {errors.school?.message}
                    </FormErrorMessage>
                  }
                </InputLabel>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Principal Access
        </Heading>
        <Text fontWeight="400" fontSize="16px" mt="16px" color="gray.500">
          Create Your Password
        </Text>
        <Flex mt="24px" gap="24px" w="full">
          <FormControl isInvalid={!!errors.username}>
            <InputLabel mt="16px">
              Username*
              <RegularInput mt="4px" type="text" {...register("username")} />
              {<FormErrorMessage>{errors.username?.message}</FormErrorMessage>}
            </InputLabel>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <InputLabel mt="16px">
              Password*
              <InputPassword
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("password")}
                maxLength={Number("30")}
              />
              {<FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.repeat_password}>
            <InputLabel mt="16px">
              Repeat Password*
              <InputPassword
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("repeat_password")}
                maxLength={Number("30")}
              />
              {
                <FormErrorMessage>
                  {errors.repeat_password?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
        </Flex>
      </Flex>

      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        <OutlineButton onClick={() => reset()} hidden={!!id}>
          Reset
        </OutlineButton>{" "}
        <RegularButton
          type="submit"
          isLoading={user ? isLoadingUpdate : isLoadingUsers}
        >
          Save
        </RegularButton>
      </Flex>
    </Form>
  );
};

export default CreatePrincipalForm;
