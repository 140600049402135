import {
  Center,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Link,
} from "@chakra-ui/react";
import React from "react";
import pdfImg from "../../assets/image/PDF.png";
import Download from "../../assets/image/dowload.png";
import { MoreVertical } from "lucide-react";
import MenuListItem from "../global/MenuListItem";
import { TutorialData } from "../../@types/interfaces/api/tutorial-data.interface";

interface CardPdfProps {
  doc: TutorialData;
  openModal: () => void;
}

const CardPdf: React.FC<CardPdfProps> = ({ doc, openModal }) => {
  return (
    <Flex flexDir="column" w="168px" mt="24px" position="relative">
      <Menu>
        <MenuButton
          position="absolute"
          right={2}
          top={2}
          as={IconButton}
          borderRadius="50%"
          boxSize="38px"
          variant="outline"
          flexShrink={0}
          zIndex={100}
          icon={<MoreVertical size="28px" color="white" />}
          _hover={{}}
          _active={{}}
        />
        <MenuList
          borderRadius="16px"
          boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
          border="1px solid #E2E5EE"
        >
          <MenuListItem color="red" onClick={openModal}>
            Delete Document
          </MenuListItem>
        </MenuList>
      </Menu>
      <Link href={String(doc.file)} isExternal>
        <Center bg="gray.700" h="218px" borderRadius="8px" position="relative">
          <Image src={Download} position="absolute" />
          <Image src={pdfImg} />
        </Center>
      </Link>

      <Text fontWeight="700" color="gray.500" mt="16px">
        {doc.title}
      </Text>
    </Flex>
  );
};

export default CardPdf;
