import { all, takeLeading } from "redux-saga/effects";
import {
  loadAuthUser,
  handleSignIn,
  loadAllUsers,
  loadUserById,
  bulkUpdateUsers,
  updateUser,
  registerAdmin,
  registerPrincipal,
  registerTeacher,
  recoverPassword,
  resetPassword,
  recoverUsername,
  simulateUser,
} from "./auth/sagas";
import { AuthActionTypes } from "./auth/types";
import { loadProgramStudentRequest } from "./bookmark/actions";
import {
  loadActivityStudent,
  loadLessonStudent,
  loadLevelStudent,
  loadProgramStudent,
  loadStoryStudent,
  postProgress,
} from "./bookmark/sagas";
import { BookMarkActionTypes } from "./bookmark/types";
import {
  loadDiagnosticPlacement,
  loadHistoryReport,
  loadProgramReport,
  loadReportDiagnostic,
  loadReportRas,
  loadReportSchoolProgress,
  loadReportSchoolProgressLs,
  loadReportSchoolProgressVm,
  loadReportStudentProgress,
  loadReportStudentProgressLs,
  loadReportStudentProgressVm,
} from "./buildReport/sagas";
import { BuildReportActionTypes } from "./buildReport/types";
import {
  loadClassroom,
  loadClassroomById,
  registerClassroom,
  updateClassroom,
} from "./classroom/sagas";
import { ClassroomActionTypes } from "./classroom/types";
import { loadCountry, loadState } from "./core/sagas";
import { CoreActionTypes } from "./core/types";
import {
  loadDashboardAdmin,
  loadDashboardPrincipal,
  loadDashboardTeacher,
} from "./dashboard/sagas";
import { DashboardActionTypes } from "./dashboard/types";
import {
  bulkUpdateDistrict,
  deleteDistrict,
  loadAllDistricts,
  loadDistrictById,
  registerDistrict,
  updateDistrict,
} from "./district/sagas";
import { DistrictActionTypes } from "./district/types";
import {
  createFullFile,
  createIntro,
  createLibrarySaga,
  deleteFullFile,
  deleteIntro,
  deleteLibrary,
  loadExtraFullFile,
  loadExtraIntro,
  loadLevel,
  loadLibrary,
  loadProgram,
  loadStory,
} from "./library/sagas";
import { checkLicense, loadLicenseById } from "./license/sagas";
import { LicenseActionTypes } from "./license/types";
import { createNote, deleteNote, loadNote } from "./note/sagas";
import { NoteActionTypes } from "./note/types";
import { updateProfile, updateProfilePhoto } from "./profile/sagas";
import { ProfileActionTypes } from "./profile/types";
import {
  bulkUpdateSchool,
  loadSchools,
  loadSchoolsById,
  loadSchoolsReport,
  registerSchool,
  updateSchool,
  updateStatusSchool,
} from "./school/sagas";
import { SchoolActionTypes } from "./school/types";
import {
  bulkUpdateStudents,
  loadStudent,
  loadStudentById,
  loadStudentHistoryJr,
  loadStudentHistoryLs,
  loadStudentHistoryVm,
  loadStudentImport,
  loadStudentInfo,
  loadStudentReport,
  registerStudent,
  removeRedFlag,
  updateStudent,
} from "./student/sagas";
import { StudentActionTypes } from "./student/types";
import {
  createFile,
  deleteTutorials,
  loadAllTutorials,
  updateFile,
} from "./tutorial/sagas";
import { TutorialActionTypes } from "./tutorial/types";
import { CertificateActionTypes } from "./certificate/types";
import { loadAllCertificates } from "./certificate/sagas";
import { LibraryActionTypes } from "./library/types";

export default function* rootSaga() {
  yield all([
    takeLeading(AuthActionTypes.SIGN_IN_REQUEST, handleSignIn),
    takeLeading(AuthActionTypes.LOAD_TOKEN_REQUEST, loadAuthUser),
    takeLeading(AuthActionTypes.LOAD_USERS_REQUEST, loadAllUsers),
    takeLeading(AuthActionTypes.LOAD_USER_BY_ID_REQUEST, loadUserById),
    takeLeading(AuthActionTypes.UPDATE_USER_REQUEST, updateUser),
    takeLeading(AuthActionTypes.ADMIN_REGISTER_REQUEST, registerAdmin),
    takeLeading(AuthActionTypes.PRINCIPAL_REGISTER_REQUEST, registerPrincipal),
    takeLeading(AuthActionTypes.TEACHER_REGISTER_REQUEST, registerTeacher),
    takeLeading(AuthActionTypes.BULK_UPDATE_USERS_REQUEST, bulkUpdateUsers),
    takeLeading(AuthActionTypes.SIMULATE_USER_REQUEST, simulateUser),

    takeLeading(DistrictActionTypes.LOAD_DISTRICTS_REQUEST, loadAllDistricts),
    takeLeading(
      DistrictActionTypes.DISTRICT_REGISTER_REQUEST,
      registerDistrict
    ),
    takeLeading(DistrictActionTypes.UPDATE_DISTRICT_REQUEST, updateDistrict),
    takeLeading(
      DistrictActionTypes.BULK_UPDATE_DISTRICT_REQUEST,
      bulkUpdateDistrict
    ),
    takeLeading(DistrictActionTypes.DELETE_DISTRICTS_REQUEST, deleteDistrict),
    takeLeading(
      DistrictActionTypes.LOAD_DISTRICT_BY_ID_REQUEST,
      loadDistrictById
    ),

    takeLeading(SchoolActionTypes.LOAD_SCHOOL_REQUEST, loadSchools),
    takeLeading(
      SchoolActionTypes.LOAD_SCHOOL_REPORT_REQUEST,
      loadSchoolsReport
    ),
    takeLeading(SchoolActionTypes.REGISTER_SCHOOL_REQUEST, registerSchool),
    takeLeading(SchoolActionTypes.UPDATE_SCHOOL_REQUEST, updateSchool),
    takeLeading(SchoolActionTypes.LOAD_SCHOOL_BY_ID_REQUEST, loadSchoolsById),
    takeLeading(SchoolActionTypes.BULK_UPDATE_SCHOOL_REQUEST, bulkUpdateSchool),
    takeLeading(
      SchoolActionTypes.UPDATE_STATUS_SCHOOL_REQUEST,
      updateStatusSchool
    ),

    takeLeading(LicenseActionTypes.LOAD_LICENSE_BY_ID_REQUEST, loadLicenseById),
    takeLeading(LicenseActionTypes.CHECK_LICENSE_REQUEST, checkLicense),

    takeLeading(ClassroomActionTypes.LOAD_CLASSROOM_REQUEST, loadClassroom),
    takeLeading(
      ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_REQUEST,
      loadClassroomById
    ),
    takeLeading(
      ClassroomActionTypes.REGISTER_CLASSROOM_REQUEST,
      registerClassroom
    ),
    takeLeading(ClassroomActionTypes.UPDATE_CLASSROOM_REQUEST, updateClassroom),

    takeLeading(ProfileActionTypes.UPDATE_PROFILE_REQUEST, updateProfile),
    takeLeading(
      ProfileActionTypes.UPDATE_PROFILE_PHOTO_REQUEST,
      updateProfilePhoto
    ),

    takeLeading(TutorialActionTypes.LOAD_TUTORIAL_REQUEST, loadAllTutorials),
    takeLeading(TutorialActionTypes.CREATE_TUTORIAL_REQUEST, createFile),
    takeLeading(TutorialActionTypes.DELETE_TUTORIAL_REQUEST, deleteTutorials),

    takeLeading(LibraryActionTypes.LOAD_STORY_REQUEST, loadStory),
    takeLeading(LibraryActionTypes.LOAD_PROGRAM_REQUEST, loadProgram),
    takeLeading(LibraryActionTypes.LOAD_LEVEL_REQUEST, loadLevel),
    takeLeading(LibraryActionTypes.LOAD_LIBRARY_REQUEST, loadLibrary),
    takeLeading(LibraryActionTypes.DELETE_LIBRARY_REQUEST, deleteLibrary),
    takeLeading(LibraryActionTypes.DELETE_EXTRA_REQUEST, deleteFullFile),
    takeLeading(LibraryActionTypes.DELETE_INTRO_REQUEST, deleteIntro),
    takeLeading(LibraryActionTypes.CREATE_LIBRARY_REQUEST, createLibrarySaga),
    takeLeading(LibraryActionTypes.CREATE_FF_REQUEST, createFullFile),
    takeLeading(LibraryActionTypes.CREATE_INTRO_REQUEST, createIntro),

    takeLeading(StudentActionTypes.STUDENT_REGISTER_REQUEST, registerStudent),
    takeLeading(StudentActionTypes.REMOVE_REDFLAG_REQUEST, removeRedFlag),
    takeLeading(StudentActionTypes.LOAD_STUDENT_REQUEST, loadStudent),
    takeLeading(
      StudentActionTypes.LOAD_STUDENT_REPORT_REQUEST,
      loadStudentReport
    ),
    takeLeading(
      StudentActionTypes.LOAD_STUDENT_IMPORT_REQUEST,
      loadStudentImport
    ),
    takeLeading(StudentActionTypes.LOAD_STUDENT_BY_ID_REQUEST, loadStudentById),
    takeLeading(StudentActionTypes.LOAD_STUDENT_INFO_REQUEST, loadStudentInfo),
    takeLeading(
      StudentActionTypes.LOAD_STUDENT_HISTORY_JR_REQUEST,
      loadStudentHistoryJr
    ),
    takeLeading(
      StudentActionTypes.LOAD_STUDENT_HISTORY_VM_REQUEST,
      loadStudentHistoryVm
    ),
    takeLeading(
      StudentActionTypes.LOAD_STUDENT_HISTORY_LS_REQUEST,
      loadStudentHistoryLs
    ),
    takeLeading(StudentActionTypes.UPDATE_STUDENT_REQUEST, updateStudent),
    takeLeading(
      StudentActionTypes.BULK_UPDATE_STUDENTS_REQUEST,
      bulkUpdateStudents
    ),

    takeLeading(NoteActionTypes.CREATE_NOTE_REQUEST, createNote),
    takeLeading(NoteActionTypes.LOAD_NOTE_REQUEST, loadNote),
    takeLeading(NoteActionTypes.DELETE_NOTE_REQUEST, deleteNote),

    takeLeading(CoreActionTypes.LOAD_COUNTRY_REQUEST, loadCountry),
    takeLeading(CoreActionTypes.LOAD_STATE_REQUEST, loadState),

    takeLeading(
      DashboardActionTypes.LOAD_DASHBOARD_ADMIN_REQUEST,
      loadDashboardAdmin
    ),
    takeLeading(
      DashboardActionTypes.LOAD_DASHBOARD_PRINCIPAL_REQUEST,
      loadDashboardPrincipal
    ),
    takeLeading(
      DashboardActionTypes.LOAD_DASHBOARD_TEACHER_REQUEST,
      loadDashboardTeacher
    ),

    takeLeading(BookMarkActionTypes.LOAD_PROGRAM_REQUEST, loadProgramStudent),
    takeLeading(BookMarkActionTypes.LOAD_LEVEL_REQUEST, loadLevelStudent),
    takeLeading(BookMarkActionTypes.LOAD_STORY_REQUEST, loadStoryStudent),
    takeLeading(BookMarkActionTypes.LOAD_LESSON_REQUEST, loadLessonStudent),
    takeLeading(BookMarkActionTypes.LOAD_ACTIVITY_REQUEST, loadActivityStudent),
    takeLeading(BookMarkActionTypes.POST_PROGRESS_REQUEST, postProgress),

    takeLeading(AuthActionTypes.RECOVER_PASSWORD_REQUEST, recoverPassword),
    takeLeading(AuthActionTypes.RECOVER_USERNAME_REQUEST, recoverUsername),
    takeLeading(AuthActionTypes.RESET_PASSWORD_REQUEST, resetPassword),

    takeLeading(BuildReportActionTypes.LOAD_RAS_REPORT_REQUEST, loadReportRas),
    takeLeading(
      BuildReportActionTypes.LOAD_HISTORY_REPORT_REQUEST,
      loadHistoryReport
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_REQUEST,
      loadReportDiagnostic
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_REQUEST,
      loadDiagnosticPlacement
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_REQUEST,
      loadReportStudentProgress
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_STUDENT_PROGRESS_LS_REPORT_REQUEST,
      loadReportStudentProgressLs
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_STUDENT_PROGRESS_VM_REPORT_REQUEST,
      loadReportStudentProgressVm
    ),

    takeLeading(
      BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_REQUEST,
      loadReportSchoolProgress
    ),
    takeLeading(
      BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST,
      loadReportSchoolProgressLs
    ),

    takeLeading(
      BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST,
      loadReportSchoolProgressVm
    ),

    takeLeading(
      BuildReportActionTypes.LOAD_PROGRAM_REPORT_REQUEST,
      loadProgramReport
    ),

    takeLeading(
      CertificateActionTypes.LOAD_CERTIFICATE_REQUEST,
      loadAllCertificates
    ),
  ]);
}
