import {
  Flex,
  Heading,
  Image,
  Text,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import bgImage from "../assets/image/bg-login.png";
import InputLogin from "../components/form/InputLogin";
import logo from "../assets/image/logo.png";
import { Form } from "../styles/global";
import { useNavigate, useLocation } from "react-router-dom";
import RegularButton from "../components/global/RegularButton";
import arrowIcon from "../assets/icons/arrow-rigth.svg";
import { Key } from "lucide-react";
import ModalLogin from "../components/modals/ModalLogin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetFormSchema } from "../utils/yup-schemas";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { resetPasswordRequest } from "../stores/ducks/auth/actions";
import packageJson from "../../package.json";

const Reset: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reseting } = useAppSelector((i) => i.auth);
  const [modalLoginOpen, setModalLoginOpen] = useState({ isOpen: false });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetFormSchema),
    defaultValues: {
      password: "",
      repeat_password: "",
    },
  });

  const submitForm = (data: any) => {
    dispatch(resetPasswordRequest(data, token, navigate));
  };

  return (
    <Flex w="full" h="100vh" justify="center" align="center">
      <Flex
        w="full"
        h="100vh"
        align="center"
        justify="center"
        bg="white"
        flexDir="column"
      >
        <Form
          onSubmit={handleSubmit(submitForm)}
          style={{
            width: "392px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Image src={logo} />
          <Heading fontSize="32px" mt="40px">
            Reset Password
          </Heading>
          <Text color="gray.500" mt="14px" align="center">
            Change your password to access the platform again
          </Text>
          <Flex flexDir="column" gap="16px" w="full" mt="40px">
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <InputLeftElement
                  color="gray.400"
                  children={<Key size="20" />}
                  pl="16px"
                  pt="8px"
                />
                <InputLogin
                  placeholder="Password"
                  type="password"
                  {...register("password")}
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <InputLeftElement
                  color="gray.400"
                  children={<Key size="20" />}
                  pl="16px"
                  pt="8px"
                />
                <InputLogin
                  placeholder="Repeat Password"
                  type="password"
                  {...register("repeat_password")}
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <RegularButton
            w="full"
            mt="32px"
            rightIcon={<Image src={arrowIcon} />}
            type="submit"
            isLoading={reseting}
          >
            Reset Password
          </RegularButton>
        </Form>
        <Text mb="40px" color="gray.400" fontSize="12px">
          © 2005-2022 Failure Free Reading. All rights reserved.
        </Text>
        <Text mb="40px" color="gray.400" fontSize="12px">
          Version {packageJson.version}
        </Text>
      </Flex>
      <Flex
        w="full"
        bgImage={bgImage}
        bgRepeat="no-repeat"
        bgPosition="center"
        backgroundSize="cover"
        h="100vh"
      />
      <ModalLogin
        isOpen={modalLoginOpen.isOpen}
        onClose={() => setModalLoginOpen({ isOpen: false })}
      />
    </Flex>
  );
};

export default Reset;
