import { Reducer } from "redux";
import { DistrictActionTypes, DistrictState } from "./types";

const INITIAL_STATE: DistrictState = {
  district: undefined,
  districtsList: undefined,
  districtById: undefined,
  isLoadingDistrictById: false,
  isLoadingDistricts: false,
  isLoadingUpdate: false,
};

const reducer: Reducer<DistrictState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DistrictActionTypes.LOAD_DISTRICTS_REQUEST:
      return {
        ...state,
        isLoadingDistricts: true,
      };
    case DistrictActionTypes.LOAD_DISTRICTS_SUCCESS:
      return {
        ...state,
        districtsList: action.payload,
        isLoadingDistricts: false,
      };
    case DistrictActionTypes.LOAD_DISTRICTS_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingDistricts: false,
      };
    case DistrictActionTypes.LOAD_DISTRICT_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingDistrictById: true,
      };
    case DistrictActionTypes.LOAD_DISTRICT_BY_ID_SUCCESS:
      return {
        ...state,
        districtById: action.payload,
        isLoadingDistrictById: false,
      };
    case DistrictActionTypes.LOAD_DISTRICT_BY_ID_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingDistrictById: false,
      };

    case DistrictActionTypes.UPDATE_DISTRICT_REQUEST:
      return {
        ...state,
        isLoadingUpdate: true,
      };
    case DistrictActionTypes.UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
      };
    case DistrictActionTypes.UPDATE_DISTRICT_ERROR:
      return {
        ...state,
        isLoadingUpdate: false,
      };

    case DistrictActionTypes.DISTRICT_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingdistrictById: true,
      };
    case DistrictActionTypes.DISTRICT_REGISTER_SUCCESS:
      return {
        ...state,
        isLoadingdistrictById: false,
      };
    case DistrictActionTypes.DISTRICT_REGISTER_ERROR:
      return {
        ...state,
        isLoadingdistrictById: false,
      };

    case DistrictActionTypes.DELETE_DISTRICTS_REQUEST:
      return {
        ...state,
        isLoadingdistrictById: true,
      };
    case DistrictActionTypes.DELETE_DISTRICTS_SUCCESS:
      return {
        ...state,
        isLoadingdistrictById: false,
      };
    case DistrictActionTypes.DELETE_DISTRICTS_ERROR:
      return {
        ...state,
        isLoadingdistrictById: false,
      };
    case DistrictActionTypes.CLEAR_DISTRICT_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
