import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const GoBackIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0001 29.3332C23.3639 29.3332 29.3334 23.3636 29.3334 15.9998C29.3334 8.63604 23.3639 2.6665 16.0001 2.6665C8.63628 2.6665 2.66675 8.63604 2.66675 15.9998C2.66675 23.3636 8.63628 29.3332 16.0001 29.3332Z"
        fill="#0C4DA2"
        stroke="#F7FAFC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0001 10.6665L10.6667 15.9998L16.0001 21.3332"
        fill="#0C4DA2"
      />
      <path
        d="M16.0001 10.6665L10.6667 15.9998L16.0001 21.3332"
        stroke="#F7FAFC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3334 16H10.6667"
        stroke="#F7FAFC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default GoBackIcon;
