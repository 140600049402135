import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import { Country, State } from "../../../@types/interfaces/api/core.interface";
import {
  CountryFilterInterface,
  StateFilterInterface,
} from "../../../@types/interfaces/api/filters/core.filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import api from "../../../services/api";
import {
  loadCountryError,
  loadCountrySuccess,
  loadStateError,
  loadStateSuccess,
} from "./actions";

export function* loadCountry(action: AnyAction) {
  const filters: CountryFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<Country>> = yield call(
      api.get,
      "/core/country",
      { params: filters }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadCountrySuccess(data));
    }
  } catch (error) {
    yield loadCountryError();
  }
}

export function* loadState(action: AnyAction) {
  const filters: StateFilterInterface = action.payload;

  try {
    const response: AxiosResponse<State> = yield call(api.get, "/core/state", {
      params: filters,
    });
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStateSuccess(data));
    }
  } catch (error) {
    yield loadStateError();
  }
}
