import { MenuItem, MenuItemProps } from "@chakra-ui/react";
import React from "react";

const MenuListItem: React.FC<MenuItemProps> = ({ children, ...rest }) => {
  return (
    <MenuItem
      p="16px 24px"
      fontSize="16px"
      lineHeight="100%"
      color="gray.700"
      _hover={{ color: "gray.600" }}
      _active={{}}
      _focus={{}}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

export default MenuListItem;
