import { Reducer } from "redux";
import { FilterActionTypes, FilterState } from "./types";

const INITIAL_STATE: FilterState = {
  search: "",
  isActiveFilter: null,
  redFlag: null,
  page: "1",
  filterLabel: "View All",
  school: undefined,
  schoolName: "",
};

const reducer: Reducer<FilterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterActionTypes.SET_FILTER:
      return {
        ...state,
        isActiveFilter: action.payload.isActiveFilter,
        search: action.payload.search,
        page: action.payload.page,
        redFlag: action.payload.redFlag,
        filterLabel: action.payload.filterLabel,
        school: action.payload.school,
        schoolName: action.payload.schoolName,
      };
    case FilterActionTypes.CLEAR_FILTER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
