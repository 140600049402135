import {
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Student } from "../../@types/interfaces/api/student.interface";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import ModalImportStudents from "../../components/modals/ModalImportStudents";
import ItemStudentTable from "../../components/students_components/ItemStudentTable";
import LayoutToolsStudents from "../../components/students_components/LayoutToolsStudents";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import { loadUserByIdRequest } from "../../stores/ducks/auth/actions";
import {
  loadClassroomByIdRequest,
  loadClassroomByIdSuccess,
} from "../../stores/ducks/classroom/actions";
import { loadSchoolsByIdRequest } from "../../stores/ducks/school/actions";
import { loadStudentRequest } from "../../stores/ducks/student/action";
import { studantTables } from "../../utils/Tables";

const ViewClassroom: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<Student>();
  const { classroomById, isLoadingClassroom } = useAppSelector(
    (i) => i.classroom
  );
  const { studentList, isStudentLoading, isActive } = useAppSelector(
    (i) => i.student
  );
  const userMe = useAppSelector((i) => i.auth.user);
  const [modalOpen, setModalOpen] = useState({ isOpen: false });
  const { schoolsById } = useAppSelector((i) => i.school);
  const { userById } = useAppSelector((i) => i.auth);

  const students = studentList?.results;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(loadClassroomByIdRequest(Number(id)));
    }
    return () => {
      dispatch(loadClassroomByIdSuccess(undefined));
    };
  }, [id]);

  useEffect(() => {
    if (classroomById) {
      dispatch(loadSchoolsByIdRequest(classroomById.school));
      dispatch(loadUserByIdRequest(classroomById.teacher));
    }
  }, [classroomById]);
  const pageCount = Number(studentList?.pages);

  const onChangePage = (page: string) => {
    dispatch(loadStudentRequest({ page, classroom__id__exact: Number(id) }));
  };

  return (
    <Layout text={"Classroom"}>
      <Flex
        mt="24px"
        mb="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            Classroom Details
          </Heading>

          <IconButton
            w="48px"
            h="48px"
            aria-label="eye"
            borderRadius="full"
            bg="white"
            border="1px solid #E2E8F0"
            icon={<Pencil size="24px" />}
            onClick={() => navigate(`/classroom/create-classroom/${id}`)}
          />
        </Flex>
        <Text fontWeight="400" fontSize="16px" color="gray.500">
          This is the classroom information
        </Text>
        <Grid mt="34px" gap="24px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">Classroom Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Teacher/principal</Text>
          </GridItem>

          <GridItem>
            {userMe?.user_type === "admin" && (
              <Text fontWeight="600">School</Text>
            )}
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Created At</Text>
          </GridItem>
          <GridItem>
            <Text>{classroomById?.name}</Text>
          </GridItem>
          <GridItem>
            <Text>{userById?.first_name}</Text>
          </GridItem>
          <GridItem>
            <Text>{schoolsById?.name}</Text>
          </GridItem>
          <GridItem>
            <Text>{moment(schoolsById?.created_at).format("MM/DD/YYYY")}</Text>
          </GridItem>
        </Grid>
      </Flex>
      <LayoutToolsStudents
        onOpen={() => setModalOpen({ isOpen: true })}
        selectedItems={items}
        onClickActions={clearSelections}
      />
      {studentList && !isStudentLoading ? (
        <CustomTable
          headers={studantTables}
          isFullSelected={() => isFullSelected(studentList.results)}
          onClickCheck={() => handleAll(studentList.results)}
          onClickHeader={(v) => console.log(v)}
        >
          {students?.map((item) => (
            <ItemStudentTable
              key={item.user}
              student={item}
              onClickCheckbox={() => handleItem(item)}
              isSelected={items.some((s) => s.user === item.user)}
            />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}

      {students && students.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}

      <ModalImportStudents
        isOpen={modalOpen.isOpen}
        onClose={() => setModalOpen({ isOpen: false })}
      />
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />

      {/* {!isLoadingClassroom && (
        <SettingsClassroomView classroom={classroomById} />
      )} */}
    </Layout>
  );
};

export default ViewClassroom;
