import { Link } from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";

interface MailToProps extends ComponentProps {
  email: string | undefined | null;
  subject?: string;
}

const MailTo: React.FC<MailToProps> = ({
  email,
  subject,
  children,
  ...props
}) => {
  return (
    <Link href={`mailto:${email}?subject=${subject || ""}`}>{children}</Link>
  );
};

export default MailTo;
