import { Divider, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import InputLabel from "../../components/form/InputLabel";
import RegularInput from "../../components/form/RegularInput";
import RegularMenu from "../../components/form/RegularMenu";
import CreateDistrictForm from "../../components/forms/CreateDistrictForm";
import Layout from "../../components/global/Layout";
import OutlineButton from "../../components/global/OutlineButton";
import RegularButton from "../../components/global/RegularButton";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadCountryRequest,
  loadStateRequest,
} from "../../stores/ducks/core/actions";
import { loadDistrictByIdRequest } from "../../stores/ducks/district/actions";
import { Form } from "../../styles/global";

const CreateDistrict: React.FC = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const { districtById, isLoadingDistrictById } = useAppSelector(
    (s) => s.district
  );

  useEffect(() => {
    if (id) {
      dispatch(loadDistrictByIdRequest(Number(id)));
    }

    return () => {
      dispatch(loadDistrictByIdRequest(undefined));
    };
  }, [id]);
  return (
    <Layout text={"Districts"}>
      {!isLoadingDistrictById && (
        <CreateDistrictForm
          district={districtById}
          loading={isLoadingDistrictById}
        />
      )}
    </Layout>
  );
};

export default CreateDistrict;
