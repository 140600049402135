import {
  IBuildReportDiagnostic,
  IBuildReportRas,
  IDiagnosticPlacement,
  IHistoryReportData,
  ISchoolProgress,
  IStudentProgress,
} from "../../../@types/interfaces/api/buildReport.interface";
import {
  DashboardAdminDataChartBar,
  IProgramReport,
} from "../../../@types/interfaces/api/dashboard.inteface";

export enum BuildReportActionTypes {
  LOAD_RAS_REPORT_REQUEST = "@buildReport/LOAD_RAS_REPORT_REQUEST",
  LOAD_RAS_REPORT_SUCCESS = "@buildReport/LOAD_RAS_REPORT_SUCCESS",
  LOAD_RAS_REPORT_ERROR = "@buildReport/LOAD_RAS_REPORT_ERROR",

  LOAD_DIAGNOSTIC_REPORT_REQUEST = "@buildReport/LOAD_DIAGNOSTIC_REPORT_REQUEST",
  LOAD_DIAGNOSTIC_REPORT_SUCCESS = "@buildReport/LOAD_DIAGNOSTIC_REPORT_SUCCESS",
  LOAD_DIAGNOSTIC_REPORT_ERROR = "@buildReport/LOAD_DIAGNOSTIC_REPORT_ERROR",

  LOAD_DIAGNOSTIC_PLACEMENT_REPORT_REQUEST = "@buildReport/LOAD_DIAGNOSTIC_PLACEMENT_REPORT_REQUEST",
  LOAD_DIAGNOSTIC_PLACEMENT_REPORT_SUCCESS = "@buildReport/LOAD_DIAGNOSTIC_PLACEMENT_REPORT_SUCCESS",
  LOAD_DIAGNOSTIC_PLACEMENT_REPORT_ERROR = "@buildReport/LOAD_DIAGNOSTIC_PLACEMENT_REPORT_ERROR",

  LOAD_STUDENT_PROGRESS_REPORT_REQUEST = "@buildReport/LOAD_STUDENT_PROGRESS_REPORT_REQUEST",
  LOAD_STUDENT_PROGRESS_REPORT_SUCCESS = "@buildReport/LOAD_STUDENT_PROGRESS_REPORT_SUCCESS",
  LOAD_STUDENT_PROGRESS_REPORT_ERROR = "@buildReport/LOAD_STUDENT_PROGRESS_REPORT_ERROR",

  LOAD_SCHOOL_PROGRESS_REPORT_REQUEST = "@buildReport/LOAD_SCHOOL_PROGRESS_REPORT_REQUEST",
  LOAD_SCHOOL_PROGRESS_REPORT_SUCCESS = "@buildReport/LOAD_SCHOOL_PROGRESS_REPORT_SUCCESS",
  LOAD_SCHOOL_PROGRESS_REPORT_ERROR = "@buildReport/LOAD_SCHOOL_PROGRESS_REPORT_ERROR",

  LOAD_STUDENT_PROGRESS_LS_REPORT_SUCCESS = "@buildReport/LOAD_STUDENT_PROGRESS_LS_REPORT_SUCCESS",
  LOAD_STUDENT_PROGRESS_VM_REPORT_SUCCESS = "@buildReport/LOAD_STUDENT_PROGRESS_VM_REPORT_SUCCESS",
  LOAD_STUDENT_PROGRESS_LS_REPORT_REQUEST = "@buildReport/LOAD_STUDENT_PROGRESS_LS_REPORT_REQUEST",
  LOAD_STUDENT_PROGRESS_VM_REPORT_REQUEST = "@buildReport/LOAD_STUDENT_PROGRESS_VM_REPORT_REQUEST",

  LOAD_SCHOOL_PROGRESS_LS_REPORT_SUCCESS = "@buildReport/LOAD_SCHOOL_PROGRESS_LS_REPORT_SUCCESS",
  LOAD_SCHOOL_PROGRESS_VM_REPORT_SUCCESS = "@buildReport/LOAD_SCHOOL_PROGRESS_VM_REPORT_SUCCESS",
  LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST = "@buildReport/LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST",
  LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST = "@buildReport/LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST",

  LOAD_HISTORY_REPORT_REQUEST = "@buildReport/LOAD_HISTORY_REPORT_REQUEST",
  LOAD_HISTORY_REPORT_SUCCESS = "@buildReport/LOAD_HISTORY_REPORT_SUCCESS",
  LOAD_HISTORY_REPORT_ERROR = "@buildReport/LOAD_HISTORY_REPORT_ERROR",

  LOAD_PROGRAM_REPORT_REQUEST = "@buildReport/LOAD_PROGRAM_REPORT_REQUEST",
  LOAD_PROGRAM_REPORT_SUCCESS = "@buildReport/LOAD_PROGRAM_REPORT_SUCCESS",
  LOAD_PROGRAM_REPORT_ERROR = "@buildReport/LOAD_PROGRAM_REPORT_ERROR",

  CLEAR_REPORT = "@buildReport/CLEAR_REPORT",
}

export interface BuildReportState extends BuildReportStateRequestsStatus {
  rasReportData: IBuildReportRas | undefined;
  diagnosticReportData: IBuildReportDiagnostic | undefined;
  diagnosticPlacementData: IDiagnosticPlacement | undefined;
  historyReportData: IHistoryReportData | undefined;

  studentProgressDataJr: IStudentProgress | undefined;
  studentProgressDataLs: IStudentProgress | undefined;
  studentProgressDataVm: IStudentProgress | undefined;

  schoolProgressDataJr: ISchoolProgress | undefined;
  schoolProgressDataLs: ISchoolProgress | undefined;
  schoolProgressDataVm: ISchoolProgress | undefined;

  dataChartBarJr: DashboardAdminDataChartBar | undefined;
  dataChartBarLs: DashboardAdminDataChartBar | undefined;
  dataChartBarVm: DashboardAdminDataChartBar | undefined;

  programReport: IProgramReport | undefined;
}
export interface BuildReportStateRequestsStatus {
  isLoading: boolean;
}
