import {
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Student } from "../../@types/interfaces/api/student.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearBookMark,
  loadActivityStudentRequest,
  loadLessonStudentRequest,
  loadLevelStudentRequest,
  loadProgramStudentRequest,
  loadStoryStudentRequest,
  postProgressRequest,
} from "../../stores/ducks/bookmark/actions";
import { activityName, levelName } from "../../utils/bookMark";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";

interface ModalImportProps {
  onClose(): void;
  isOpen: boolean;
  StudentById: Student | undefined;
  studentActivity: string;
}

const ModalBookmarkedStudents: React.FC<ModalImportProps> = ({
  onClose,
  isOpen,
  StudentById,
  studentActivity,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { program, level, story, lesson, activity } = useAppSelector(
    (b) => b.bookMark
  );

  const [activityId, setActivityId] = useState<number | undefined>();
  const [isDiagnostic, setIsDiagnostic] = useState(false);
  const [typeProgram, setTypeProgram] = useState("");

  const currentLesson = () => {
    if (StudentById?.in_ras) {
      return "Reading Attitude Survey";
    }
    if (StudentById?.in_diagnostic_part_1) {
      return "Diagnostic Prescriptive";
    }
    if (StudentById?.in_diagnostic_part_2) {
      return "Diagnostic Pre Test";
    }
  };

  const labelLesson = typeProgram === "3" ? "Unit" : "Lesson";

  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="680px" p="40px" borderRadius="16px" overflow="auto">
        <Heading fontSize="20px" fontWeight="600" color="blue.600">
          Edit Bookmarked Lesson
        </Heading>
        <Flex
          border="1px solid #E2E8F0"
          borderRadius="16px"
          p="16px"
          flexDir="column"
          gap="12px"
          mt="24px"
        >
          <Heading fontSize="16px" fontWeight="600" color="blue.600">
            Current Bookmark
          </Heading>
          <Text fontSize="14px" fontWeight="500">
            {currentLesson() ? currentLesson() : studentActivity}
          </Text>
        </Flex>
        <Flex flexDir="column" gap="12px" mt="24px" mb="24px">
          <Heading fontSize="18px" fontWeight="600" color="blue.600">
            Choose New Bookmark Application
          </Heading>
          <Text fontSize="16px" fontWeight="500">
            All visible boxes must be selected
          </Text>
        </Flex>
        <Flex flexDir="column" gap="12px">
          <Flex mt="34px">
            <Switch
              mr="24px"
              isChecked={isDiagnostic}
              onChange={() => {
                if (!activity) setIsDiagnostic(() => !isDiagnostic);
                if (!isDiagnostic) {
                  dispatch(clearBookMark());
                } else {
                  dispatch(loadProgramStudentRequest(Number(id)));
                }
              }}
            />
            <Text>Diagnostic Prescriptive </Text>
          </Flex>

          <RegularMenu
            placeholder="Program"
            isDisabled={isDiagnostic}
            onChange={(e) => {
              if (!isDiagnostic) {
                dispatch(
                  loadLevelStudentRequest({
                    order_by: "level_order",
                    program__id: Number(e.target.value),
                  })
                );
                setTypeProgram(e.target.value);
              }
            }}
          >
            {program?.results &&
              program.results.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </RegularMenu>
          <RegularMenu
            placeholder="Level"
            isDisabled={level?.results === undefined}
            onChange={(e) => {
              if (!isDiagnostic) {
                dispatch(
                  loadStoryStudentRequest({
                    order_by: "story_order",
                    level__id: Number(e.target.value),
                  })
                );
              }
            }}
          >
            {level?.results &&
              level.results.map((l) => (
                <option key={l.id} value={l.id}>
                  {levelName[l.name]}
                </option>
              ))}
          </RegularMenu>
          <RegularMenu
            placeholder="Story"
            isDisabled={story?.results === undefined}
            onChange={(e) => {
              if (!isDiagnostic) {
                dispatch(
                  loadLessonStudentRequest({
                    order_by: "lesson_order",
                    story__id: Number(e.target.value),
                  })
                );
              }
            }}
          >
            {story?.results &&
              story.results.map((l) => (
                <option key={l.id} value={l.id}>
                  {l.name.replace(/&#39;/g, "'")}
                </option>
              ))}
          </RegularMenu>
          <RegularMenu
            placeholder="Lesson"
            isDisabled={lesson?.results === undefined}
            onChange={(e) => {
              if (!isDiagnostic) {
                dispatch(
                  loadActivityStudentRequest({
                    order_by: "activity_order",
                    lesson__id: Number(e.target.value),
                    student_id: Number(id),
                  })
                );
              }
            }}
          >
            {lesson?.results &&
              lesson.results.map((l, index, array) => {
                let label = labelLesson;
                if (typeProgram === "1") {
                  if (index === array.length - 1) {
                    label = "Post-test";
                  } else if (index === array.length - 2) {
                    label = "Reading Comprehension";
                  }
                  return (
                    <option key={l.id} value={l.id}>
                      {l.lesson_order === 0
                        ? "Story Pre-test"
                        : `${label} ${l.lesson_order}`}
                    </option>
                  );
                }
                if (typeProgram === "2") {
                  if (index === array.length - 1) {
                    label = "Post Test";
                  } else if (index === array.length - 2) {
                    label = "Vocabulary Builder";
                  } else if (index === array.length - 3) {
                    label = "Spelling Program";
                  }
                  return (
                    <option key={l.id} value={l.id}>
                      {l.lesson_order === 0
                        ? "Story Pre-test"
                        : `${label} ${l.lesson_order}`}
                    </option>
                  );
                }
                if (typeProgram === "3") {
                  if (index === array.length - 1) {
                    label = "Post Test";
                  }
                  return (
                    <option key={l.id} value={l.id}>
                      {l.lesson_order === 0
                        ? "Story Pre-test"
                        : `${label} ${l.lesson_order}`}
                    </option>
                  );
                }
              })}
          </RegularMenu>
          <RegularMenu
            placeholder="Activity"
            isDisabled={activity === undefined}
            onChange={(e) => {
              if (!isDiagnostic) {
                setActivityId(Number(e.target.value));
              }
            }}
          >
            {activity &&
              activity.map((l, index) => (
                <option key={l.id} value={l.id}>
                  {typeProgram === "3"
                    ? activityName[l.mechanic_type] + " " + (index + 1)
                    : activityName[l.mechanic_type]}
                </option>
              ))}
          </RegularMenu>
        </Flex>
        <ModalFooter justifyContent="space-between" mt="24px">
          <OutlineButton
            onClick={() => {
              onClose();
              setActivityId(undefined);
            }}
          >
            Cancel
          </OutlineButton>
          {StudentById && (
            <RegularButton
              isDisabled={!isDiagnostic && !activityId}
              onClick={() => {
                if (isDiagnostic) {
                  dispatch(postProgressRequest(StudentById.user, null));
                  onClose();
                  setActivityId(undefined);
                  return;
                }
                if (activityId) {
                  dispatch(postProgressRequest(StudentById.user, activityId));
                  onClose();
                  setActivityId(undefined);
                }
              }}
            >
              Save New Bookmark Location
            </RegularButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBookmarkedStudents;
