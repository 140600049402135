import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const SeatsIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#FF7A00" fillOpacity="0.1" />
      <path
        d="M15.667 21.3333V24C16.7279 24 17.7453 24.4214 18.4954 25.1716C19.2456 25.9217 19.667 26.9391 19.667 28C19.667 29.0609 19.2456 30.0783 18.4954 30.8284C17.7453 31.5786 16.7279 32 15.667 32V34.6667C15.667 36.1333 16.867 37.3333 18.3337 37.3333H37.0003C37.7076 37.3333 38.3858 37.0524 38.8859 36.5523C39.386 36.0522 39.667 35.3739 39.667 34.6667V32C38.6061 32 37.5887 31.5786 36.8386 30.8284C36.0884 30.0783 35.667 29.0609 35.667 28C35.667 26.9391 36.0884 25.9217 36.8386 25.1716C37.5887 24.4214 38.6061 24 39.667 24V21.3333C39.667 20.6261 39.386 19.9478 38.8859 19.4477C38.3858 18.9476 37.7076 18.6667 37.0003 18.6667H18.3337C17.6264 18.6667 16.9481 18.9476 16.448 19.4477C15.9479 19.9478 15.667 20.6261 15.667 21.3333V21.3333Z"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0005 18.6667V21.3333"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0005 34.6667V37.3333"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0005 26.6667V29.3333"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default SeatsIcon;
