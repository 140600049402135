import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import React, { LegacyRef, forwardRef } from "react";

const RegularInput = (
  { type = "text", ...rest }: InputProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <Input
      ref={ref}
      h="48px"
      borderRadius="100px"
      bg="transparent"
      color="gray.700"
      _placeholder={{ color: "gray.400" }}
      type={type}
      {...rest}
    />
  );
};

export default forwardRef<HTMLInputElement, InputProps>(RegularInput);
