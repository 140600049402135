import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateAdminForm from "../../components/forms/CreateAdminForm";
import Layout from "../../components/global/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadUserByIdRequest,
  loadUserByIdSuccess,
} from "../../stores/ducks/auth/actions";

const CreateAdmin: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userById, isLoadingUserById } = useAppSelector((s) => s.auth);

  useEffect(() => {
    if (id) {
      dispatch(loadUserByIdRequest(Number(id)));
    }

    return () => {
      dispatch(loadUserByIdSuccess(undefined));
    };
  }, [id]);

  return (
    <Layout text={"Administrator"}>
      {!isLoadingUserById && <CreateAdminForm user={userById} />}
    </Layout>
  );
};

export default CreateAdmin;
