import * as yup from "yup";

export const loginFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  password: yup.string().required("This field is required"),
});

export const resetFormSchema = yup.object().shape({
  password: yup.string().required("This field is required"),
  repeat_password: yup.string().required("This field is required"),
});

export const recoverFormSchema = yup.object().shape({
  email: yup.string().email().required("This field is required"),
});

export const adminFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
});

export const uddateAdminFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
});

export const updateProfileFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
  password: yup.string(),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
});

export const districtFormSchema = yup.object().shape({
  name: yup.string().min(3).required("This field is required"),
  address: yup.string().required("This field is required"),
  city: yup.string().required("This field is required"),
  country: yup.string(),
  state: yup.string().max(2).required("This field is required"),
  phone_number: yup.string().required("This field is required"),
  zip: yup.string().required("This field is required"),
  primary_contact_name: yup.string().required("This field is required"),
  primary_contact_email: yup
    .string()
    .email()
    .required("This field is required"),
});

export const classroomFormSchema = yup.object().shape({
  name: yup.string().min(3).required("This field is required"),
  teacher: yup.string().required("This field is required"),
  school: yup
    .string()
    .nullable()
    .test("is-required", "This field is required", function (value) {
      return !!value || value === "0";
    }),
});
export const schoolFormSchema = yup.object().shape({
  name: yup.string().min(3).required("This field is required"),
  address: yup.string().required("This field is required"),
  city: yup.string().required("This field is required"),
  state: yup.string().max(2).required("This field is required"),
  country: yup.string(),
  phone_number: yup.string().required("This field is required"),
  zip: yup.string().required("This field is required"),
  district: yup.string(),
  licenses_number: yup.number(),
  license_starting_date: yup.string(),
  license_expiration_date: yup.string(),
  primary_contact_name: yup.string().required("This field is required"),
  primary_contact_email: yup.string().required("This field is required"),
});

export const principalFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  school: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
  title: yup.string().required("This field is required"),
});

export const updatePrincipalFormSchema = yup.object().shape({
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
  title: yup.string().required("This field is required"),
  school: yup.string().required("This field is required"),
  user_type: yup.string().required("This field is required"),
});

export const teacherFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  school: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
});
export const updateTeacherFormSchema = yup.object().shape({
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  email: yup.string().email().required("This field is required"),
  school: yup.string().required("This field is required"),
  user_type: yup.string().required("This field is required"),
});
export const StudentFormSchema = yup.object().shape({
  username: yup.string().min(3).required("This field is required"),
  password: yup.string().min(8).required("This field is required"),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  gender: yup.string().required("This field is required"),
  grade: yup.string().required("This field is required"),
  school: yup.string(),
  classroom: yup.string(),
  birthday: yup.string().required("This field is required"),
  language: yup.string().required("This field is required"),
  licenses_number: yup.number(),
  license_starting_date: yup.string(),
  license_expiration_date: yup.string(),
  activeFieldLicense: yup.boolean(),
});

export const StudentEditFormSchema = yup.object().shape({
  username: yup.string().min(3),
  password: yup.string(),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  first_name: yup.string().required("This field is required"),
  last_name: yup.string().required("This field is required"),
  gender: yup.string().required("This field is required"),
  grade: yup.string().required("This field is required"),
  school: yup.string(),
  // classroom: yup.string(),
  birthday: yup.string().required("This field is required"),
  language: yup.string().required("This field is required"),
  licenses_number: yup.number(),
  license_starting_date: yup.string(),
  license_expiration_date: yup.string(),
  // email: yup.string().email().required("This field is required"),
});
