import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import {
  FilterBuildReport,
  FilterSchoolProgress,
  FilterStudentProgress,
  HistoryReportFilters,
  IBuildReportDiagnostic,
  IBuildReportRas,
  IDiagnosticPlacement,
  IHistoryReportData,
  ISchoolProgress,
  IStudentProgress,
} from "../../../@types/interfaces/api/buildReport.interface";
import api from "../../../services/api";
import {
  IFilterProgramReport,
  IProgramReport,
} from "../../../@types/interfaces/api/dashboard.inteface";
import {
  loadDiagnosticPlacementError,
  loadDiagnosticPlacementSuccess,
  loadHistoryReportError,
  loadHistoryReportSuccess,
  loadProgramReportError,
  loadProgramReportSuccess,
  loadReportDiagnosticError,
  loadReportDiagnosticSuccess,
  loadReportRasError,
  loadReportRasSuccess,
  loadReportSchoolProgressError,
  loadReportSchoolProgressLsSuccess,
  loadReportSchoolProgressSuccess,
  loadReportSchoolProgressVmSuccess,
  loadReportStudentProgressError,
  loadReportStudentProgressLsSuccess,
  loadReportStudentProgressSuccess,
  loadReportStudentProgressVmSuccess,
} from "./actions";

// const { toast } = createStandaloneToast({ theme });

export function* loadReportRas(action: AnyAction) {
  const filters: FilterBuildReport = action.payload;

  try {
    const response: AxiosResponse<IBuildReportRas[]> = yield call(
      api.get,
      "/build-report/ras",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportRasSuccess(data));
    }
  } catch (error) {
    yield put(loadReportRasError());
  }
}

export function* loadReportDiagnostic(action: AnyAction) {
  const filters: FilterBuildReport = action.payload;

  try {
    const response: AxiosResponse<IBuildReportDiagnostic[]> = yield call(
      api.get,
      "/build-report/diagnostic",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportDiagnosticSuccess(data));
    }
  } catch (error) {
    yield put(loadReportDiagnosticError());
  }
}

export function* loadDiagnosticPlacement(action: AnyAction) {
  const filters: FilterBuildReport = action.payload;

  try {
    const response: AxiosResponse<IDiagnosticPlacement> = yield call(
      api.get,
      "/build-report/diagnostic-placement",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadDiagnosticPlacementSuccess(data));
    }
  } catch (error) {
    yield put(loadDiagnosticPlacementError());
  }
}

export function* loadReportStudentProgress(action: AnyAction) {
  const filters: FilterStudentProgress = action.payload;

  try {
    const response: AxiosResponse<IStudentProgress> = yield call(
      api.get,
      "/build-report/student-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportStudentProgressSuccess(data));
    }
  } catch (error) {
    yield put(loadReportStudentProgressError());
  }
}

export function* loadReportStudentProgressLs(action: AnyAction) {
  const filters: FilterStudentProgress = action.payload;

  try {
    const response: AxiosResponse<IStudentProgress> = yield call(
      api.get,
      "/build-report/student-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportStudentProgressLsSuccess(data));
    }
  } catch (error) {
    yield put(loadReportStudentProgressError());
  }
}

export function* loadReportStudentProgressVm(action: AnyAction) {
  const filters: FilterStudentProgress = action.payload;

  try {
    const response: AxiosResponse<IStudentProgress> = yield call(
      api.get,
      "/build-report/student-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportStudentProgressVmSuccess(data));
    }
  } catch (error) {
    yield put(loadReportStudentProgressError());
  }
}

//---------------------------------- school progress

export function* loadReportSchoolProgress(action: AnyAction) {
  const filters: FilterSchoolProgress = action.payload;

  try {
    const response: AxiosResponse<ISchoolProgress> = yield call(
      api.get,
      "/build-report/school-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportSchoolProgressSuccess(data));
    }
  } catch (error) {
    yield put(loadReportSchoolProgressError());
  }
}
export function* loadReportSchoolProgressLs(action: AnyAction) {
  const filters: FilterSchoolProgress = action.payload;

  try {
    const response: AxiosResponse<ISchoolProgress> = yield call(
      api.get,
      "/build-report/school-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportSchoolProgressLsSuccess(data));
    }
  } catch (error) {
    yield put(loadReportSchoolProgressError());
  }
}
export function* loadReportSchoolProgressVm(action: AnyAction) {
  const filters: FilterSchoolProgress = action.payload;

  try {
    const response: AxiosResponse<ISchoolProgress> = yield call(
      api.get,
      "/build-report/school-progress",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadReportSchoolProgressVmSuccess(data));
    }
  } catch (error) {
    yield put(loadReportSchoolProgressError());
  }
}

export function* loadHistoryReport(action: AnyAction) {
  const filters: HistoryReportFilters = action.payload;

  try {
    const response: AxiosResponse<IHistoryReportData> = yield call(
      api.get,
      "/build-report/history",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadHistoryReportSuccess(data));
    }
  } catch (error) {
    yield put(loadHistoryReportError());
  }
}

export function* loadProgramReport(action: AnyAction) {
  const filters: IFilterProgramReport = action.payload;

  try {
    const response: AxiosResponse<IProgramReport[]> = yield call(
      api.get,
      "/build-report/program",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadProgramReportSuccess(data));
    }
  } catch (error) {
    yield put(loadProgramReportError());
  }
}
