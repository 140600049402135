import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
import {
  ExtraFilter,
  LevelFilter,
  LibraryFilter,
} from "../../../@types/interfaces/api/library.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  CreateFullFileInterface,
  CreateIntroInterface,
  CreateLibraryInterface,
} from "../../../@types/interfaces/app/forms/create-library.interface";
import api from "../../../services/api";
import theme from "../../../styles/theme";

import {
  createFFError,
  createFFSuccess,
  createIntroError,
  createIntroSuccess,
  createLibraryError,
  createLibrarySuccess,
  deleteFullFileError,
  deleteFullFileSuccess,
  deleteLibraryError,
  deleteLibrarySuccess,
  loadExtraError,
  loadFullFileSuccess,
  loadIntroSuccess,
  loadLevelError,
  loadLevelSuccess,
  loadLibraryError,
  loadLibraryRequest,
  loadLibrarySuccess,
  loadProgramError,
  loadProgramSuccess,
  loadStoryError,
  loadStoryRequest,
  loadStorySuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadStory(action: AnyAction) {
  try {
    const response: AxiosResponse = yield call(api.get, "/story", {
      params: { pagination: false },
    });
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStorySuccess(data));
    }
  } catch (error) {
    yield loadStoryError();
  }
}

export function* loadProgram(action: AnyAction) {
  try {
    const response: AxiosResponse = yield call(api.get, "/program");
    const { status, data } = response;

    if (status === 200) {
      yield put(loadProgramSuccess(data.results));
    }
  } catch (error) {
    yield loadProgramError();
  }
}

export function* loadLevel(action: AnyAction) {
  const levelFilter: LevelFilter = action.payload;
  try {
    const response: AxiosResponse = yield call(api.get, "/level", {
      params: levelFilter,
    });
    if (levelFilter && levelFilter.program__id) {
      yield call(loadExtraIntro, levelFilter.program__id);
    }
    const { status, data } = response;
    if (status === 200) {
      yield put(loadLevelSuccess(data.results));
    }
  } catch (error) {
    yield loadLevelError();
  }
}

export function* loadLibrary(action: AnyAction) {
  const filter: LibraryFilter = action.payload;
  try {
    const response: AxiosResponse = yield call(api.get, "/library/lesson", {
      params: filter,
    });
    if (filter.story__level__id) {
      yield call(loadExtraFullFile, filter.story__level__id);
    }
    const { status, data } = response;
    if (status === 200) {
      yield put(loadLibrarySuccess(data));
    }
  } catch (error) {
    yield loadLibraryError();
  }
}

export function* loadExtraFullFile(level__id: number) {
  // const filter: ExtraFilter = action.payload;
  try {
    const response: AxiosResponse = yield call(api.get, "/library/full-file", {
      params: { level__id },
    });
    const { status, data } = response;
    if (status === 200) {
      yield put(loadFullFileSuccess(data));
    }
  } catch (error) {
    yield loadExtraError();
  }
}
export function* loadExtraIntro(program_id: number) {
  // const filter: ExtraFilter = action.payload;
  try {
    const response: AxiosResponse = yield call(api.get, "/library/intro", {
      params: { program__id: program_id, intro: true },
    });
    const { status, data } = response;
    if (status === 200) {
      yield put(loadIntroSuccess(data));
    }
  } catch (error) {
    yield loadExtraError();
  }
}
export function* updateFileLib(file: FormData, id: number) {
  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/library/${id}/file`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error: any) {
    console.log(error);
  }
}

export function* updateFileFF(file: FormData, id: number) {
  try {
    yield call(api.patch, `/library/${id}/ff/file`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    console.log(error);
  }
}

export function* updateFileIntro(file: FormData, id: number) {
  try {
    yield call(api.patch, `/library/${id}/intro/file`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    console.log(error);
  }
}

export function* createLibrarySaga(action: AnyAction) {
  const {
    values,
    navigate,
    file,
  }: {
    values: CreateLibraryInterface;
    navigate: NavigateFunction;
    file: FormData;
  } = action.payload;
  try {
    const newValues = {
      name: values.name,
      story: values.story,
    };

    const response: AxiosResponse = yield call(
      api.post,
      `/library/lesson`,
      newValues
    );
    const { status, data } = response;
    if (status === 201) {
      yield call(updateFileLib, file, data.id);
      yield put(createLibrarySuccess());
      navigate("/library");
      toast({
        title: "Success",
        description: "Lesson successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(createLibraryError());
    yield put(loadStoryRequest());

    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* createFullFile(action: AnyAction) {
  const {
    values,
    navigate,
    file,
  }: {
    values: CreateFullFileInterface;
    navigate: NavigateFunction;
    file: FormData;
  } = action.payload;
  try {
    const newValues: CreateFullFileInterface = {
      name: values.name,
      level: values.level,
      language: values.language,
    };
    const response: AxiosResponse = yield call(
      api.post,
      `/library/full-file`,
      newValues
    );
    const { status, data } = response;
    if (status === 201) {
      yield call(updateFileFF, file, data.id);
      yield put(createFFSuccess());
      navigate("/library");
      toast({
        title: "Success",
        description: "Lesson successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(createFFError());
    yield put(loadStoryRequest());

    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* createIntro(action: AnyAction) {
  const {
    values,
    navigate,
    file,
  }: {
    values: CreateIntroInterface;
    navigate: NavigateFunction;
    file: FormData;
  } = action.payload;
  try {
    const newValues: CreateIntroInterface = {
      name: values.name,
      program: values.program,
    };
    const response: AxiosResponse = yield call(
      api.post,
      `/library/intro`,
      newValues
    );
    const { status, data } = response;
    if (status === 201) {
      yield call(updateFileIntro, file, data.id);
      yield put(createIntroSuccess());
      navigate("/library");
      toast({
        title: "Success",
        description: "Lesson successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(createIntroError());
    yield put(loadStoryRequest());

    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* deleteLibrary(action: AnyAction) {
  const id: number = action.payload.id;
  const filter: LibraryFilter = action.payload.filter;
  try {
    const response: AxiosResponse = yield call(
      api.delete,
      `/library/lesson/${id}`
    );
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteLibrarySuccess());
      yield put(loadLibraryRequest(filter));

      toast({
        title: "Success",
        description: "District successfully deleted",
        status: "success",
      });
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(deleteLibraryError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* deleteFullFile(action: AnyAction) {
  const id: number = action.payload.id;
  const filter: LibraryFilter = action.payload.filter;
  try {
    const response: AxiosResponse = yield call(
      api.delete,
      `/library/full-file/${id}`
    );
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteFullFileSuccess());
      yield put(loadLibraryRequest(filter));

      toast({
        title: "Success",
        description: "District successfully deleted",
        status: "success",
      });
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(deleteFullFileError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* deleteIntro(action: AnyAction) {
  const id: number = action.payload.id;
  const filter: LibraryFilter = action.payload.filter;
  try {
    const response: AxiosResponse = yield call(
      api.delete,
      `/library/intro/${id}`
    );
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteFullFileSuccess());
      yield put(loadLibraryRequest(filter));

      toast({
        title: "Success",
        description: "District successfully deleted",
        status: "success",
      });
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(deleteFullFileError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
