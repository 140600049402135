import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import {
  CreateNote,
  Note,
} from "../../../@types/interfaces/api/note.interface";
import api from "../../../services/api";
import {
  createNoteError,
  createNoteSuccess,
  deleteNoteErrorr,
  deleteNoteSuccess,
  loadNoteErrorr,
  loadNoteRequest,
  loadNoteSuccess,
} from "./actions";
import theme from "../../../styles/theme";
import NoteFilterInterface from "../../../@types/interfaces/api/filters/note.filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

const { toast } = createStandaloneToast({ theme });

export function* createNote(action: AnyAction) {
  const values: string = action.payload.values;
  const id: number = action.payload.id;
  try {
    const dataValues = { note: values, user: id };
    const response: AxiosResponse = yield call(api.post, "/note", dataValues);
    const { status, data } = response;
    if (status === 201) {
      yield put(createNoteSuccess());
    }
  } catch (error: any) {
    yield put(createNoteError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* loadNote(action: AnyAction) {
  const filters: NoteFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<Note[]>> = yield call(
      api.get,
      "/note",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadNoteSuccess(data.results));
    }
  } catch (error: any) {
    yield put(loadNoteErrorr());
  }
}

export function* deleteNote(action: AnyAction) {
  const id: number = action.payload;
  try {
    const response: AxiosResponse = yield call(api.delete, `/note/${id}`);
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteNoteSuccess());
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(deleteNoteErrorr());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
