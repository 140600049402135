import { AuthActionTypes } from "./types";
import {
  CreateUser,
  UpdateUser,
  User,
  UserType,
} from "../../../@types/interfaces/api/user.interface";
import LoginResponse from "../../../@types/interfaces/api/login.response.interface";
import api from "../../../services/api";
import LoginCredentials from "../../../@types/interfaces/api/login-credencials.interface";
import { NavigateFunction } from "react-router-dom";
import UsersFilterInterface from "../../../@types/interfaces/api/filters/users.filter.interface";
import UsersBulkUpdateInterface from "../../../@types/interfaces/api/users-bulk-update.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export const signInRequest = (
  credentials: LoginCredentials,
  navigate: NavigateFunction
) => ({
  type: AuthActionTypes.SIGN_IN_REQUEST,
  payload: { credentials, navigate },
});

export const signInSuccess = (resp: LoginResponse) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${resp.access}`;
  localStorage.setItem("ffr_token", resp.access);
  localStorage.setItem("ffr_refresh", resp.refresh);

  return {
    type: AuthActionTypes.SIGN_IN_SUCCESS,
  };
};

export const signInError = () => ({
  type: AuthActionTypes.SIGN_IN_ERROR,
});

export const resetPasswordRequest = (
  data: any,
  token: any,
  navigate: NavigateFunction
) => ({
  type: AuthActionTypes.RESET_PASSWORD_REQUEST,
  payload: { data, token, navigate },
});

export const resetPasswordSuccess = () => ({
  type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
});

export const recoverPasswordRequest = (data: any) => ({
  type: AuthActionTypes.RECOVER_PASSWORD_REQUEST,
  payload: data,
});

export const recoverPasswordSuccess = () => ({
  type: AuthActionTypes.RECOVER_PASSWORD_SUCCESS,
});

export const recoverUsernameRequest = (data: any) => ({
  type: AuthActionTypes.RECOVER_USERNAME_REQUEST,
  payload: data,
});

export const recoverUsernameSuccess = () => ({
  type: AuthActionTypes.RECOVER_USERNAME_SUCCESS,
});

export const simulateUserRequest = (id: number) => ({
  type: AuthActionTypes.SIMULATE_USER_REQUEST,
  payload: id,
});

export const logOut = (navigate: NavigateFunction) => {
  localStorage.removeItem("ffr_token");
  localStorage.removeItem("ffr_refresh");
  delete api.defaults.headers.common["Authorization"];
  navigate("/admin");
  return {
    type: AuthActionTypes.LOG_OUT,
  };
};

export const loadTokenRequest = () => {
  const token = localStorage.getItem("ffr_token");

  if (!token) {
    return {
      type: AuthActionTypes.LOAD_TOKEN_ERROR,
    };
  }

  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return {
    type: AuthActionTypes.LOAD_TOKEN_REQUEST,
  };
};

export const loadTokenSuccess = (user: User) => ({
  type: AuthActionTypes.LOAD_TOKEN_SUCCESS,
  payload: user,
});

export const loadTokenError = () => {
  delete api.defaults.headers.common["Authorization"];
  localStorage.removeItem("ffr_token");
  localStorage.removeItem("ffr_refresh");

  return {
    type: AuthActionTypes.LOAD_TOKEN_ERROR,
  };
};

export const loadUsersRequest = (filters?: UsersFilterInterface) => ({
  type: AuthActionTypes.LOAD_USERS_REQUEST,
  payload: filters,
});

export const loadUsersSuccess = (users: PaginationBase<User[]>) => ({
  type: AuthActionTypes.LOAD_USERS_SUCCESS,
  payload: users,
});
export const loadUsersErrorr = () => ({
  type: AuthActionTypes.LOAD_USERS_ERROR,
});

export const loadUserByIdRequest = (id: number) => ({
  type: AuthActionTypes.LOAD_USER_BY_ID_REQUEST,
  payload: id,
});

export const loadUserByIdSuccess = (user: User | undefined) => ({
  type: AuthActionTypes.LOAD_USER_BY_ID_SUCCESS,
  payload: user,
});
export const loadUserByIdError = () => ({
  type: AuthActionTypes.LOAD_USER_BY_ID_ERROR,
});

export const bulkUpdateUsersRequest = (
  ids: number[],
  data: UsersBulkUpdateInterface,
  userType: UserType
) => ({
  type: AuthActionTypes.BULK_UPDATE_USERS_REQUEST,
  payload: { data, ids, userType },
});

export const updateUserRequest = (
  id: number,
  values: UpdateUser,
  userType?: UserType
) => {
  return {
    type: AuthActionTypes.UPDATE_USER_REQUEST,
    payload: { id, userType, values },
  };
};

export const updateUserSuccess = () => {
  return {
    type: AuthActionTypes.UPDATE_USER_SUCCESS,
  };
};
export const updateUserError = () => {
  return {
    type: AuthActionTypes.UPDATE_USER_ERROR,
  };
};

export const registerAdminRequest = (
  values: CreateUser,
  navigate: NavigateFunction
) => {
  return {
    type: AuthActionTypes.ADMIN_REGISTER_REQUEST,
    payload: { values, navigate },
  };
};

export const registerAdminSuccess = () => {
  return {
    type: AuthActionTypes.ADMIN_REGISTER_SUCCESS,
  };
};
export const registerAdminError = () => {
  return {
    type: AuthActionTypes.ADMIN_REGISTER_ERROR,
  };
};

export const registerPrincipalRequest = (
  values: CreateUser,
  navigate: NavigateFunction
) => {
  return {
    type: AuthActionTypes.PRINCIPAL_REGISTER_REQUEST,
    payload: { values, navigate },
  };
};

export const registerPrincipalSuccess = () => {
  return {
    type: AuthActionTypes.PRINCIPAL_REGISTER_SUCCESS,
  };
};
export const registerPrincipalError = () => {
  return {
    type: AuthActionTypes.PRINCIPAL_REGISTER_ERROR,
  };
};

export const registerTeacherRequest = (
  values: CreateUser,
  navigate: NavigateFunction
) => {
  return {
    type: AuthActionTypes.TEACHER_REGISTER_REQUEST,
    payload: { values, navigate },
  };
};

export const registerTeacherSuccess = () => {
  return {
    type: AuthActionTypes.TEACHER_REGISTER_SUCCESS,
  };
};
export const registerTeacherError = () => {
  return {
    type: AuthActionTypes.TEACHER_REGISTER_ERROR,
  };
};

export const clearUsersList = () => {
  return {
    type: AuthActionTypes.CLEAR_USER_LIST,
  };
};

export const clearUsers = () => {
  return {
    type: AuthActionTypes.CLEAR_USER,
  };
};
