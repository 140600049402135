import {
  Extra,
  Level,
  Library,
  Program,
  Story,
} from "../../../@types/interfaces/api/library.interface";

export enum LibraryActionTypes {
  LOAD_STORY_REQUEST = "@library/LOAD_STORY_REQUEST",
  LOAD_STORY_SUCCESS = "@library/LOAD_STORY_SUCCESS",
  LOAD_STORY_ERROR = "@library/LOAD_STORY_ERROR",

  LOAD_PROGRAM_REQUEST = "@library/LOAD_PROGRAM_REQUEST",
  LOAD_PROGRAM_SUCCESS = "@library/LOAD_PROGRAM_SUCCESS",
  LOAD_PROGRAM_ERROR = "@library/LOAD_PROGRAM_ERROR",

  LOAD_LEVEL_REQUEST = "@library/LOAD_LEVEL_REQUEST",
  LOAD_LEVEL_SUCCESS = "@library/LOAD_LEVEL_SUCCESS",
  LOAD_LEVEL_ERROR = "@library/LOAD_LEVEL_ERROR",

  LOAD_LIBRARY_REQUEST = "@library/LOAD_LIBRARY_REQUEST",
  LOAD_LIBRARY_SUCCESS = "@library/LOAD_LIBRARY_SUCCESS",
  LOAD_LIBRARY_ERROR = "@library/LOAD_LIBRARY_ERROR",

  LOAD_FF_REQUEST = "@library/LOAD_FF_REQUEST",
  LOAD_FF_SUCCESS = "@library/LOAD_FF_SUCCESS",
  LOAD_INTRO_REQUEST = "@library/LOAD_INTRO_REQUEST",
  LOAD_INTRO_SUCCESS = "@library/LOAD_INTRO_SUCCESS",
  LOAD_EXTRA_ERROR = "@library/LOAD_EXTRA_ERROR",

  DELETE_LIBRARY_REQUEST = "@library/DELETE_LIBRARY_REQUEST",
  DELETE_LIBRARY_SUCCESS = "@library/DELETE_LIBRARY_SUCCESS",
  DELETE_LIBRARY_ERROR = "@library/DELETE_LIBRARY_ERROR",

  DELETE_EXTRA_REQUEST = "@library/DELETE_EXTRA_REQUEST",
  DELETE_INTRO_REQUEST = "@library/DELETE_INTRO_REQUEST",
  DELETE_EXTRA_SUCCESS = "@library/DELETE_EXTRA_SUCCESS",
  DELETE_EXTRA_ERROR = "@library/DELETE_EXTRA_ERROR",

  CREATE_LIBRARY_REQUEST = "@library/CREATE_LIBRARY_REQUEST",
  CREATE_LIBRARY_SUCCESS = "@library/CREATE_LIBRARY_SUCCESS",
  CREATE_LIBRARY_ERROR = "@library/CREATE_LIBRARY_ERROR",

  CREATE_FF_REQUEST = "@library/CREATE_FF_REQUEST",
  CREATE_FF_SUCCESS = "@library/CREATE_FF_SUCCESS",
  CREATE_FF_ERROR = "@library/CREATE_FF_ERROR",

  CREATE_INTRO_REQUEST = "@library/CREATE_INTRO_REQUEST",
  CREATE_INTRO_SUCCESS = "@library/CREATE_INTRO_SUCCESS",
  CREATE_INTRO_ERROR = "@library/CREATE_INTRO_ERROR",
}

export interface LibraryState extends LibraryStateRequestsStatus {
  storyList: Story[];
  programList: Program[];
  levelList: Level[];
  libraryList: Library[];
  fullFileList: Extra[];
  introList: Extra[];
}

interface LibraryStateRequestsStatus {
  isLoadingLevel: boolean;
  isLoadingLibrary: boolean;
}
