import {
  Avatar,
  Button,
  Circle,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ChevronDown, User, LogOut } from "lucide-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MenuListItem from "./MenuListItem";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOut } from "../../stores/ducks/auth/actions";
import GoBackIcon from "../../assets/icons/dashboard/GoBackIcon";

interface TopbarProps {
  text: string;
}

const Topbar: React.FC<TopbarProps> = ({ text }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((i) => i.auth);
  const { pathname } = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const includesPath =
    pathname.includes("create") ||
    pathname.includes("view") ||
    pathname.includes("edit") ||
    pathname.includes("enroll");

  return (
    <Flex
      w="full"
      h="80px"
      p="16px 24px"
      borderBottom="1px solid #E2E8F0"
      bg="white"
      pos="sticky"
      align="center"
      top="0"
      left="0"
      zIndex="100"
    >
      <Flex w="full" justify="space-between" align="center">
        <Flex align="center">
          {includesPath && (
            <IconButton
              icon={<GoBackIcon />}
              aria-label="go back icon"
              width="32px"
              height="32px"
              variant="ghost"
              _hover={{}}
              _active={{}}
              onClick={() => navigate(-1)}
            />
          )}

          <Heading fontWeight="600" fontSize="20px" color="blue.600">
            {text}
          </Heading>
        </Flex>

        <Menu>
          <MenuButton
            as={Button}
            fontSize="14px"
            bg="transparent"
            h="auto"
            px="0"
            _hover={{}}
            _active={{}}
          >
            <Flex align="center" gap="12px">
              <Avatar
                src={user?.profile_photo ? user?.profile_photo : undefined}
              />

              <Flex align="flex-start" flexDir="column" gap="8px">
                <Text fontSize="14px" lineHeight="14px" color="#475569">
                  {user?.first_name}
                </Text>
                <Text
                  fontWeight="500"
                  lineHeight="12px"
                  fontSize="12px"
                  color="#A0AEC0"
                  textTransform="capitalize"
                >
                  {user?.user_type}
                </Text>
              </Flex>
              <ChevronDown size="20px" color="#64748B" />
            </Flex>
          </MenuButton>
          <MenuList
            minW="0"
            w="150px"
            maxW="150px"
            borderRadius="24px 24px 24px 24px"
            border="1.5px solid #E2E5EE"
            p="0"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
          >
            <Link to="/profile">
              <MenuListItem
                color="#A0AEC0"
                borderBottom="1.5px solid #E2E5EE"
                icon={<User color="#A0AEC0" />}
              >
                Profile
              </MenuListItem>
            </Link>

            <MenuListItem
              color="#A0AEC0"
              icon={<LogOut color="#A0AEC0" />}
              onClick={() => dispatch(logOut(navigate))}
            >
              Logout
            </MenuListItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Topbar;
