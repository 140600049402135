import { ApexOptions } from "apexcharts";

interface pieChart {
  chartId: string;
  labels: string[] | undefined;
  onSliceClick?: (label: string) => void;
}

export const pieChartConfig = ({
  chartId,
  labels,
  onSliceClick,
}: pieChart): ApexOptions => ({
  chart: {
    type: "pie",
    id: chartId,
    width: 300,
    events: {
      dataPointSelection: (
        event: MouseEvent | PointerEvent,
        chartContext: any,
        config: any
      ) => {
        if (labels && onSliceClick) {
          const label = labels[config.dataPointIndex];
          onSliceClick(label);
        }
      },
    },
  },
  fill: {
    type: "gradient",
  },
  markers: {
    size: 0,
  },
  dataLabels: { enabled: false },
  labels,
  colors: [
    "#FF1313",
    "#3BB800",
    "#FF6B00",
    "#08D6BD",
    "#7EC8E3",
    "#68B0AB",
    "#0047AB",
    "#003366",
  ],
});
