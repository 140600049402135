import {
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/Layout";
import MailTo from "../../components/global/MailTo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadUserByIdRequest,
  loadUserByIdSuccess,
  simulateUserRequest,
} from "../../stores/ducks/auth/actions";
import {
  loadSchoolsByIdRequest,
  loadSchoolsByIdSuccess,
} from "../../stores/ducks/school/actions";
import OutlineButton from "../../components/global/OutlineButton";

const ViewPrincipal: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userById, isLoadingUserById, user, isSignInLoading } = useAppSelector(
    (i) => i.auth
  );
  const { schoolsById, isLoadingSchool } = useAppSelector((i) => i.school);

  useEffect(() => {
    if (id) {
      dispatch(loadUserByIdRequest(Number(id)));
    }
    return () => {
      dispatch(loadUserByIdSuccess(undefined));
    };
  }, [id]);

  useEffect(() => {
    if (userById) {
      dispatch(loadSchoolsByIdRequest(userById.school));
    }
  }, [userById]);
  return (
    <Layout text={"Principals"}>
      <Flex justify="flex-end" gap="10px">
        {user?.user_type === "admin" && userById && (
          <OutlineButton
            bg="white"
            isLoading={isSignInLoading}
            onClick={() => dispatch(simulateUserRequest(userById?.id))}
          >
            Simulate User
          </OutlineButton>
        )}
      </Flex>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            Principal Details
          </Heading>

          {user?.user_type === "admin" && (
            <IconButton
              w="48px"
              h="48px"
              aria-label="eye"
              borderRadius="full"
              bg="white"
              border="1px solid #E2E8F0"
              icon={<Pencil size="24px" />}
              onClick={() => navigate(`/principals/create-principal/${id}`)}
            />
          )}
        </Flex>
        <Text fontWeight="400" fontSize="16px" color="gray.500">
          This is the principal information
        </Text>
        <Grid mt="34px" gap="16px" templateColumns="repeat(5, 1fr)">
          <GridItem>
            <Text fontWeight="600">First Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Last Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Email Address</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Username</Text>
          </GridItem>
          <GridItem></GridItem>

          <GridItem>
            <Text>{userById?.first_name}</Text>
          </GridItem>
          <GridItem>
            <Text>{userById?.last_name}</Text>
          </GridItem>
          <GridItem>
            <MailTo email={userById?.email}>
              <Text color="primary">{userById?.email}</Text>
            </MailTo>
          </GridItem>
          <GridItem>
            <Text>{userById?.username}</Text>
          </GridItem>
          <GridItem></GridItem>
        </Grid>

        <Grid mt="50px" gap="16px" templateColumns="repeat(5, 1fr)">
          <GridItem>
            {user?.user_type === "admin" && (
              <Text fontWeight="600">School</Text>
            )}
          </GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
          {!isLoadingSchool && user?.user_type === "admin" && (
            <GridItem>
              <Text>{schoolsById?.name}</Text>
            </GridItem>
          )}

          <GridItem></GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
        </Grid>
      </Flex>
    </Layout>
  );
};

export default ViewPrincipal;
