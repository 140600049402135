import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "../pages/administrator/Admin";
import CreateLibrary from "../pages/library_page/CreateLibrary";
import Library from "../pages/library_page/Library";

const LibraryRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Library />} />
      <Route path="/create-library" element={<CreateLibrary />}>
        <Route path=":id" element={<CreateLibrary />} />
        <Route path="" element={<CreateLibrary />} />
      </Route>
    </Routes>
  );
};

export default LibraryRoutes;
