import {
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ChevronsUpDown } from "lucide-react";
import React from "react";
import { useLocation } from "react-router-dom";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";
import { TableHeader } from "../../@types/interfaces/app/table-props.interfaces";

interface CustomTableProps extends ComponentProps {
  headers: TableHeader[];
  onClickHeader?: (name: any) => void;
  onClickCheck?: () => void;
  isFullSelected?: () => boolean;
}

const CustomTable: React.FC<CustomTableProps> = ({
  children,
  headers,
  onClickCheck,
  onClickHeader = () => null,
  isFullSelected = () => false,
}) => {
  const { pathname } = useLocation();

  return (
    <TableContainer
      mt="24px"
      border="1px solid #E2E8F0"
      borderRadius="16px"
      maxW="100%"
    >
      <Table variant="striped" colorScheme="tableScheme" bg="white" maxW="100%">
        <Thead>
          <Tr gap="0px">
            <Th w="10px" p="18px 16px">
              {onClickCheck && !pathname.includes("view-classroom") && (
                <Checkbox
                  colorScheme="tagScheme"
                  isChecked={isFullSelected()}
                  onChange={() => onClickCheck()}
                />
              )}
            </Th>

            {headers.map((item) => (
              <Th
                py="16px"
                px="6px"
                color="blue.600"
                fontWeight="600"
                maxW="150px"
                textTransform="unset"
                whiteSpace="pre-wrap"
                key={item.name}
                onClick={() => onClickHeader(item.sort_field)}
              >
                <Flex align="center" cursor="pointer" minW="120px">
                  <Text>{item.name}</Text>
                  <Flex w="16px">
                    {item.clicable && <ChevronsUpDown size="18px" />}
                  </Flex>
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
