import React from "react";
import { Badge, Text } from "@chakra-ui/react";

interface BadgescoreProps {
  status:
    | "pre_teaching_lesson"
    | "pre_test"
    | "post_test"
    | "talking_book"
    | "word_recognition_exercises"
    | "reinforcement_activities"
    | "independent_activities_drag_&_fill_exercises"
    | "independent_activities_matching"
    | "independent_activities_scrambled_sentence"
    | "independent_activities_short_answer_q&a"
    | "independent_activities_alphabetical_order"
    | "reading_comprehension"
    | "pre_test_verbal_master"
    | "post_test_verbal_master"
    | "word_verbal_master"
    | "unit_review_synonym"
    | "unit_review_definition"
    | "unit_review_fill_the_blank"
    | "unit_review_comprehension"
    | "spelling_program"
    | "vocabulary_builder"
    | "minigame";
}

const Badgescore: React.FC<BadgescoreProps> = ({ status }) => {
  const badgeText = {
    pre_test: {
      text: "Pre-Test Score",
      bgColor: "#E8BA41",
      textColor: "#ffff",
    },
    post_test: {
      text: "Post-Test Score",
      bgColor: "#41B081",
      textColor: "#ffff",
    },
    talking_book: {
      text: "Talking Book",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    pre_teaching_lesson: {
      text: "Pre Teaching",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    word_recognition_exercises: {
      text: "Word Recognition",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    reinforcement_activities: {
      text: "Reinforcement Activities",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    "independent_activities_drag_&_fill_exercises": {
      text: "Drag & Fill Exercises",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    independent_activities_matching: {
      text: "Matching",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    independent_activities_scrambled_sentence: {
      text: "Scrambled Sentence",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    independent_activities_alphabetical_order: {
      text: "Alphabetical Order",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    "independent_activities_short_answer_q&a": {
      text: "Short Answer",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    reading_comprehension: {
      text: "Reading Comprehension",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    pre_test_verbal_master: {
      text: "Pre Test Verbal Master",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    post_test_verbal_master: {
      text: "Post Test Verbal Master",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    word_verbal_master: {
      text: "Word Verbal Master",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    unit_review_synonym: {
      text: "Unit Review Synonym",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    unit_review_definition: {
      text: "Unit Review Definition",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    unit_review_fill_the_blank: {
      text: "Unit Review Fill the Blank",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    unit_review_comprehension: {
      text: "Unit Review Comprehension",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    spelling_program: {
      text: "Spelling Program",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    vocabulary_builder: {
      text: "Vocabulary Builder",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
    minigame: {
      text: "Mini game",
      bgColor: "#E2E8F0",
      textColor: "#031E41",
    },
  };

  return (
    <Badge
      variant="subtle"
      bgColor={badgeText[status].bgColor}
      color={badgeText[status].textColor}
      fontSize="12px"
      lineHeight="20px"
      px="12px"
      borderRadius="100px"
      textTransform="unset"
      fontWeight="400"
    >
      {badgeText[status].text}
    </Badge>
  );
};

export default Badgescore;
