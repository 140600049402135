import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ChevronDown } from "lucide-react";
import React from "react";
import MenuListItem from "./MenuListItem";
import OutlineButton from "./OutlineButton";

interface MenuFilterActiveProps {
  handleClick: (e: any) => void;
  namePage: string;
  active: boolean | null;
}

const MenuFilterActive: React.FC<MenuFilterActiveProps> = ({
  handleClick,
  namePage,
  active,
}) => {
  return (
    <Menu>
      <MenuButton
        as={OutlineButton}
        w="210px"
        bg="transparent"
        rightIcon={<ChevronDown size="16px" />}
        _active={{ bg: "white" }}
        _expanded={{ borderRadius: "24px 24px 0 0" }}
        textTransform="capitalize"
      >
        {active
          ? `Active ${namePage}`
          : active === false
          ? `Inactive ${namePage}`
          : "View All"}
      </MenuButton>
      <MenuList
        minW="0"
        w="210px"
        maxW="210px"
        borderRadius="0 0 24px 24px"
        border="1.5px solid #E2E5EE"
        borderTop="none"
        p="0"
        marginTop="-8px"
        onClick={handleClick}
      >
        <MenuListItem value="active" color={"alert.green"}>
          Active {namePage}
        </MenuListItem>
        <MenuListItem
          borderTop="1px solid #E2E5EE"
          color={"alert.red"}
          value="inactive"
        >
          Inactive {namePage}
        </MenuListItem>

        <MenuListItem borderTop="1px solid #E2E5EE" value="all">
          View All
        </MenuListItem>
      </MenuList>
    </Menu>
  );
};

export default MenuFilterActive;
