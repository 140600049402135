import {
  Table,
  TableBodyProps,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";

const TableSchoolReport: React.FC<ComponentProps> = ({ children }) => {
  return (
    <TableContainer
      border="1px solid #E2E8F0"
      borderRadius="16px"
      mt="16px"
      boxShadow=" 0px 2px 10px 0px rgba(17, 20, 61, 0.06)"
    >
      <Table variant="striped" colorScheme="tableScheme">
        <Thead>
          <Tr>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Student Name
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              fontSize="16px"
              textTransform="unset"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              DP Date
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Diagnostic Placement
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Current Bookmark
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Stories/ Clusters Completed
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Average Pre-Test
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="150px"
              whiteSpace="pre-wrap"
            >
              Average Post-Test
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableSchoolReport;
