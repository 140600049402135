import { Center, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { User } from "../../@types/interfaces/api/user.interface";
import ItemAdminTable from "../../components/admin_components/ItemAdminTable";
import LayoutToolsAdmin from "../../components/admin_components/LayoutToolsAdmin";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import { useSorting } from "../../hooks/useSorting";
import {
  clearUsersList,
  loadUsersRequest,
} from "../../stores/ducks/auth/actions";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";
import { adminTables } from "../../utils/Tables";

const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const { usersList, isLoadingUsers } = useAppSelector((s) => s.auth);
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<User>();
  const userMe = useAppSelector((i) => i.auth.user);
  const { sorting, listSorted, setListSorted } = useSorting<User>();

  const pageCount = Number(usersList?.pages);
  const filterProps = useAppSelector((i) => i.filters);

  const onChangePage = (page: string) => {
    dispatch(
      loadUsersRequest({
        user_type__in: "admin",
        page,
        search: filterProps.search,
        is_active__exact: filterProps.isActiveFilter,
      })
    );
    dispatch(setFilters({ ...filterProps, page }));
  };

  useEffect(() => {
    if (usersList?.results) {
      setListSorted(usersList?.results);
    }
  }, [usersList]);
  useEffect(() => {
    return () => {
      dispatch(clearUsersList());
      dispatch(clearFilter());
    };
  }, []);

  const itemsFiltred = items.filter((item) => userMe?.id !== item.id);

  return (
    <Layout text="Administrator">
      <LayoutToolsAdmin
        selectedItems={itemsFiltred}
        onClickActions={clearSelections}
      />
      {listSorted && !isLoadingUsers ? (
        <CustomTable
          headers={adminTables}
          isFullSelected={() => isFullSelected(listSorted)}
          onClickCheck={() => handleAll(listSorted)}
          onClickHeader={(v) => sorting(v)}
        >
          {listSorted.map((d) => (
            <ItemAdminTable
              key={d.id}
              onClickCheckbox={() => handleItem(d)}
              isSelected={items.some((s) => s.id === d.id)}
              user={d}
            />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}

      {usersList && usersList.results.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
    </Layout>
  );
};

export default Admin;
