import {
  Table,
  TableBodyProps,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";

const TableProgressWords: React.FC<ComponentProps> = ({ children }) => {
  return (
    <TableContainer border="1px solid #E2E8F0" borderRadius="16px" mt="16px">
      <Table variant="striped" size="lg" colorScheme="tableScheme">
        <Thead bg="white">
          <Tr>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
            >
              Story Name
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              fontSize="16px"
              textTransform="unset"
            >
              Words
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
            >
              Total Words
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableProgressWords;
