import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { Eye, Pencil } from "lucide-react";
import React, { LegacyRef } from "react";

const EditButton = (
  { children, ...rest }: ButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) => {
  return (
    <IconButton
      ref={ref}
      aria-label="eye"
      borderRadius="full"
      bg="gray.200"
      icon={<Pencil size="16px" />}
      {...rest}
    />
  );
};

export default React.forwardRef<HTMLButtonElement, ButtonProps>(EditButton);
