import {
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MoreVertical, PlayCircle, Trash } from "lucide-react";
import React from "react";
import { TutorialData } from "../../@types/interfaces/api/tutorial-data.interface";
import thumb from "../../assets/image/thumb.png";
import movie from "../../assets/videos/videoplayback.mp4";
import MenuListItem from "../global/MenuListItem";

interface CardVideoProps {
  openModal: () => void;
  openVideo: () => void;
  video: TutorialData;
}

const CardVideo: React.FC<CardVideoProps> = ({
  openModal,
  openVideo,
  video,
}) => {
  return (
    <Flex flexDir="column" w="207px" mt="16px">
      <Flex position="relative">
        <Flex
          position="absolute"
          top="28%"
          left="33%"
          onClick={openVideo}
          cursor="pointer"
        >
          <PlayCircle color="#ffffffc7" size="64px" />
        </Flex>

        <Image src={thumb} width="207px" height="137px" />
        <Menu>
          <MenuButton
            position="absolute"
            right={1}
            top={1}
            as={IconButton}
            borderRadius="50%"
            boxSize="38px"
            variant="outline"
            bg="rgba(76, 76, 76, 0.548)"
            flexShrink={0}
            icon={<MoreVertical size="28px" color="white" />}
            _hover={{}}
            _active={{}}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <MenuListItem color="red" onClick={openModal}>
              Delete File
            </MenuListItem>
          </MenuList>
        </Menu>
      </Flex>

      <Flex justify="space-between" align="center">
        <Flex flexDir="column">
          <Text fontWeight="600" color="gray.500" mt="16px">
            {video.title}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardVideo;
