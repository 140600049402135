import { Center, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ClassroomList } from "../../@types/interfaces/api/classroom.interface";
import ItemClassroomTable from "../../components/classroom_components/ItemClassroomTable";
import LayoutTollsClassroom from "../../components/classroom_components/LayoutToolsClassroom";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useSorting } from "../../hooks/useSorting";
import {
  clearClassroomList,
  loadClassroomRequest,
} from "../../stores/ducks/classroom/actions";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";
import { classroomTables } from "../../utils/Tables";

const Classrooms: React.FC = () => {
  const dispatch = useAppDispatch();
  const { classroomList, isLoadingClassroom } = useAppSelector(
    (s) => s.classroom
  );
  const { sorting, listSorted, setListSorted } = useSorting<ClassroomList>();
  const filterProps = useAppSelector((i) => i.filters);

  const pageCount = Number(classroomList?.pages);

  const onChangePage = (page: string) => {
    dispatch(loadClassroomRequest({ page, search: filterProps.search }));
    dispatch(setFilters({ ...filterProps, page }));
  };

  useEffect(() => {
    if (classroomList?.results) {
      setListSorted(classroomList?.results);
    }
  }, [classroomList]);
  useEffect(() => {
    return () => {
      dispatch(clearClassroomList());
      dispatch(clearFilter());
    };
  }, []);

  return (
    <Layout text="Classroom">
      <LayoutTollsClassroom />
      {!isLoadingClassroom ? (
        <CustomTable
          headers={classroomTables}
          onClickHeader={(v) => sorting(v)}
        >
          {listSorted?.map((item) => (
            <ItemClassroomTable classroom={item} key={item.id} />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}
      {listSorted && listSorted.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
    </Layout>
  );
};

export default Classrooms;
