import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateClassroomForm from "../../components/forms/CreateClassroomForm";
import Layout from "../../components/global/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { clearUsers, clearUsersList } from "../../stores/ducks/auth/actions";
import {
  loadClassroomByIdRequest,
  loadClassroomByIdSuccess,
} from "../../stores/ducks/classroom/actions";
import { useSorting } from "../../hooks/useSorting";
import { User } from "../../@types/interfaces/api/user.interface";

const CreateClassroom: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { isLoadingClassroom, classroomById } = useAppSelector(
    (i) => i.classroom
  );
  const { setListSorted } = useSorting<User>();

  useEffect(() => {
    if (id) {
      dispatch(loadClassroomByIdRequest(Number(id)));
    }
    return () => {
      dispatch(clearUsers());
      dispatch(loadClassroomByIdSuccess(undefined));
      dispatch(clearUsersList());
      setListSorted(undefined);
    };
  }, [id]);

  return (
    <Layout text={"Classroom"}>
      {!isLoadingClassroom && <CreateClassroomForm classroom={classroomById} />}
    </Layout>
  );
};

export default CreateClassroom;
