import { Underline } from "lucide-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateSchoolForm from "../../components/forms/CreateSchoolForm";
import Layout from "../../components/global/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadDistrictByIdRequest,
  loadDistrictsRequest,
} from "../../stores/ducks/district/actions";
import {
  loadLicenseIdRequest,
  loadLicenseIdSuccess,
} from "../../stores/ducks/license/actions";
import {
  loadSchoolsByIdRequest,
  loadSchoolsByIdSuccess,
} from "../../stores/ducks/school/actions";

const CreateSchools: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { schoolsById, isLoadingSchool } = useAppSelector((i) => i.school);
  const { licenseById, isLoadingLicense } = useAppSelector((i) => i.license);

  useEffect(() => {
    if (id) {
      dispatch(loadSchoolsByIdRequest(Number(id)));
    }
    dispatch(loadDistrictsRequest({ is_active: true }));
    return () => {
      dispatch(loadSchoolsByIdSuccess(undefined));
    };
  }, [id]);

  useEffect(() => {
    if (schoolsById) {
      dispatch(loadLicenseIdRequest(schoolsById.license));
    }
    return () => {
      dispatch(loadLicenseIdSuccess(undefined));
    };
  }, [schoolsById]);

  return (
    <Layout text={"Schools"}>
      {!isLoadingSchool && !isLoadingLicense && (
        <CreateSchoolForm
          school={schoolsById}
          license={licenseById}
          loading={isLoadingSchool}
        />
      )}
    </Layout>
  );
};

export default CreateSchools;
