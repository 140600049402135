import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateSchools from "../pages/schools/CreateSchools";
import Schools from "../pages/schools/Schools";
import ViewSchool from "../pages/schools/ViewSchool";

const SchoolsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Schools />} />
      <Route path="/create-schools" element={<CreateSchools />}>
        <Route path=":id" element={<CreateSchools />} />
        <Route path="" element={<CreateSchools />} />
      </Route>
      <Route path="/view-schools" element={<ViewSchool />}>
        <Route path=":id" element={<ViewSchool />} />
        <Route path="" element={<ViewSchool />} />
      </Route>
    </Routes>
  );
};

export default SchoolsRoutes;
