import React from "react";
import { Route, Routes } from "react-router-dom";
import Certificates from "../pages/certificates_page/Certificates";

const CertificatesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Certificates />} />
      {/* <Route path="/create-tutorials" element={<CreateTutorials />}>
        <Route path=":id" element={<CreateTutorials />} />
      </Route> */}
    </Routes>
  );
};

export default CertificatesRoutes;
