import { Button, ButtonProps } from "@chakra-ui/react";
import React, { LegacyRef } from "react";

const OutlineButton = (
  { children, ...rest }: ButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) => {
  return (
    <Button
      ref={ref}
      color="gray.700"
      borderRadius="full"
      fontSize="16px"
      lineHeight="28px"
      fontWeight="600"
      border="1px solid #E2E8F0"
      p="10px 24px"
      h="48px"
      bg="bg"
      _hover={{}}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default React.forwardRef<HTMLButtonElement, ButtonProps>(OutlineButton);
