import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
import {
  FilterActivity,
  FilterLesson,
  FilterLevel,
  FilterStory,
  IActivity,
  ILesson,
  ILevel,
  IProgram,
  Iprogress,
  IStory,
} from "../../../@types/interfaces/api/bookMark.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import api from "../../../services/api";
import {
  loadActivityStudentError,
  loadActivityStudentSuccess,
  loadLessonStudentError,
  loadLessonStudentSuccess,
  loadLevelStudentError,
  loadLevelStudentSuccess,
  loadProgramStudentError,
  loadProgramStudentSuccess,
  loadStoryStudentError,
  loadStoryStudentSuccess,
  postProgressError,
  postProgressSuccess,
} from "./actions";
import { ApplicationState, getGlobalState } from "..";
import { loadStudentRequest } from "../student/action";

const { toast } = createStandaloneToast({ theme });

export function* loadProgramStudent(action: AnyAction) {
  const id = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IProgram>> = yield call(
      api.get,
      `/program`,
      { params: { student_id: id } }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadProgramStudentSuccess(data));
    }
  } catch (error) {
    yield put(loadProgramStudentError());
  }
}

export function* loadLevelStudent(action: AnyAction) {
  const filters: FilterLevel = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<ILevel>> = yield call(
      api.get,
      "/level",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadLevelStudentSuccess(data));
    }
  } catch (error) {
    yield put(loadLevelStudentError());
  }
}

export function* loadStoryStudent(action: AnyAction) {
  const filters: FilterStory = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IStory>> = yield call(
      api.get,
      "/story",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadStoryStudentSuccess(data));
    }
  } catch (error) {
    yield put(loadStoryStudentError());
  }
}

export function* loadLessonStudent(action: AnyAction) {
  const filters: FilterLesson = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<ILesson>> = yield call(
      api.get,
      "/lesson",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadLessonStudentSuccess(data));
    }
  } catch (error) {
    yield put(loadLessonStudentError());
  }
}

export function* loadActivityStudent(action: AnyAction) {
  const filters: FilterActivity = action.payload;

  try {
    const response: AxiosResponse<IActivity> = yield call(
      api.get,
      "/activity",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadActivityStudentSuccess(data));
    }
  } catch (error) {
    yield put(loadActivityStudentError());
  }
}

export function* postProgress(action: AnyAction) {
  const student = action.payload.idStudent;
  const activity = action.payload.idActivity;

  const dataProgress: Iprogress = {
    activity,
    student,
    finished: false,
  };

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/progress/${student}`,
      dataProgress
    );
    if (response.status == 200) {
      yield put(postProgressSuccess());
      yield put(loadStudentRequest({}));
      toast({
        title: "Success",
        description: "Student successfully updated",
        status: "success",
      });
    }
  } catch (e: any) {
    const errors = e.response.data;
    yield put(postProgressError());

    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
