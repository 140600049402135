import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import api from "../../../services/api";
import {
  ICertificate,
  filterCertificate,
} from "../../../@types/interfaces/api/certificate.interface";
import { loadCertificateErrorr, loadCertificateSuccess } from "./actions";

export function* loadAllCertificates(action: AnyAction) {
  const filters: filterCertificate = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<ICertificate[]>> = yield call(
      api.get,
      "/certificate",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadCertificateSuccess(data));
    }
  } catch (error: any) {
    yield put(loadCertificateErrorr());
  }
}
