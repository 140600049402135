import { TutorialActionTypes, TutorialState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: TutorialState = {
  isLoadingTutorial: false,
  tutorial: [],
};

const reducer: Reducer<TutorialState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TutorialActionTypes.CREATE_TUTORIAL_REQUEST:
      return {
        ...state,
        isLoadingTutorial: true,
      };
    case TutorialActionTypes.CREATE_TUTORIAL_SUCCESS:
      return {
        ...state,
        isLoadingTutorial: false,
      };
    case TutorialActionTypes.CREATE_TUTORIAL_ERROR:
      return { ...INITIAL_STATE, isLoadingTutorial: false };

    case TutorialActionTypes.LOAD_TUTORIAL_REQUEST:
      return {
        ...state,
        isLoadingTutorial: true,
      };
    case TutorialActionTypes.LOAD_TUTORIAL_SUCCESS:
      return {
        ...state,
        isLoadingTutorial: false,
        tutorial: action.payload,
      };
    case TutorialActionTypes.LOAD_TUTORIAL_ERROR:
      return { ...INITIAL_STATE, isLoadingTutorial: false };

    case TutorialActionTypes.DELETE_TUTORIAL_REQUEST:
      return {
        ...state,
        isLoadingTutorial: true,
      };
    case TutorialActionTypes.DELETE_TUTORIAL_SUCCESS:
      return {
        ...state,
        isLoadingTutorial: false,
      };
    case TutorialActionTypes.DELETE_TUTORIAL_ERROR:
      return { ...INITIAL_STATE, isLoadingTutorial: false };

    default:
      return state;
  }
};

export default reducer;
