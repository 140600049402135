import { Profile } from "../../../@types/interfaces/api/profile.interface";
import { ProfileActionTypes } from "./types";

export const updateProfileRequest = (id: number, values: Profile) => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_REQUEST,
    payload: { id, values },
  };
};
export const updateProfileSuccess = () => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_SUCCESS,
  };
};
export const updateProfileError = () => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_ERROR,
  };
};

export const updateProfilePhotoRequest = (values: FormData) => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_PHOTO_REQUEST,
    payload: values,
  };
};
export const updateProfilePhotoSuccess = (newUrl: string) => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_PHOTO_SUCCESS,
    payload: newUrl
  };
};
export const updateProfilePhotoError = () => {
  return {
    type: ProfileActionTypes.UPDATE_PROFILE_PHOTO_ERROR,
  };
};
