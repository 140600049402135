import { Flex, Image, Select, SelectProps } from "@chakra-ui/react";
import React, { LegacyRef, forwardRef } from "react";

const RegularMenu = (
  { children, ...rest }: SelectProps,
  ref: LegacyRef<HTMLSelectElement>
) => {
  return (
    <Flex
      mt="4px"
      h="48px"
      border="1px solid #e6e8f0"
      borderRadius="100px"
      align="center"
      p="12px 16px"
      minW="300px"
    >
      <Select
        ref={ref}
        minW="300px"
        border="none"
        color="gray.500"
        _hover={{ outline: "none" }}
        _active={{}}
        _focus={{}}
        focusBorderColor="white"
        h="48px"
        {...rest}
      >
        {children}
      </Select>
    </Flex>
  );
};

export default forwardRef<HTMLSelectElement, SelectProps>(RegularMenu);
