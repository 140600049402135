import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import { License } from "../../../@types/interfaces/api/license.interface";
import api from "../../../services/api";
import {
  checkLicenseIdErrorr,
  checkLicenseIdSuccess,
  loadLicenseIdErrorr,
  loadLicenseIdSuccess,
  registerLicenseError,
  registerLicenseSuccess,
} from "./actions";

export function* createLicense(license: License) {
  try {
    const response: AxiosResponse = yield call(api.post, `/license`, license);
    const { status, data } = response;
    if (status === 201) {
      yield put(registerLicenseSuccess());
      return data;
    }
  } catch (error: any) {
    yield put(registerLicenseError());
  }
}

export function* updateLicense(license: License, id: number) {
  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/license/${id}`,
      license
    );
    const { status, data } = response;
    if (status === 201) {
      yield put(registerLicenseSuccess());
      return data;
    }
  } catch (error: any) {
    yield put(registerLicenseError());
  }
}

export function* loadLicenseById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.get, `/license/${id}`);
    const { status, data } = response;
    if (status === 200) {
      yield put(loadLicenseIdSuccess(data));
    }
  } catch (error) {
    yield loadLicenseIdErrorr();
  }
}

export function* checkLicense(action: AnyAction) {
  try {
    const response: AxiosResponse = yield call(api.get, `/license/check`);
    const { status, data } = response;
    if (status === 200) {
      yield put(checkLicenseIdSuccess(data));
    }
  } catch (error) {
    yield checkLicenseIdErrorr();
  }
}
