import {
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
  Link,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import React, { useEffect, useState } from "react";
import EditButton from "../../components/global/circle_button/EditButton";
import Layout from "../../components/global/Layout";
import OutlineButton from "../../components/global/OutlineButton";
import RegularButton from "../../components/global/RegularButton";
import LayoutToolsStudents from "../../components/students_components/LayoutToolsStudents";
import LayoutToolsView from "../../components/students_components/LayoutToolsView";
import ItemViewStudent from "../../components/students_components/ItemViewStudent";
import TableViewStudent from "../../components/students_components/TableViewStudent";
import { useNavigate, useParams } from "react-router-dom";
import ModalBookmarkedStudents from "../../components/modals/ModalBookmarkedStudents";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadNoteRequest,
  loadNoteSuccess,
} from "../../stores/ducks/note/actions";
import { loadSchoolsRequest } from "../../stores/ducks/school/actions";
import {
  loadStudentByIdRequest,
  loadStudentByIdSuccess,
  loadStudentHistoryJrRequest,
  loadStudentHistoryLsRequest,
  loadStudentHistoryVmRequest,
  loadStudentInfoRequest,
  removeRedFlagRequest,
} from "../../stores/ducks/student/action";
import moment from "moment";
import {
  loadClassroomByIdRequest,
  loadClassroomByIdSuccess,
} from "../../stores/ducks/classroom/actions";
import {
  clearBookMark,
  loadProgramStudentRequest,
} from "../../stores/ducks/bookmark/actions";
import Pagination from "../../components/global/pagination/Pagination";
import ModalRedFlag from "../../components/modals/ModalRedFlag";

const ViewStudents: React.FC = () => {
  const [modalOpen, setModalOpen] = useState({ isOpen: false });
  const [modalBookmarkOpen, setModalBookmarkOpen] = useState({ isOpen: false });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { noteList } = useAppSelector((i) => i.note);
  const {
    StudentById,
    studentInfo,
    studentHistoryJr,
    studentHistoryVm,
    studentHistoryLs,
  } = useAppSelector((i) => i.student);
  const { classroomById } = useAppSelector((i) => i.classroom);
  const [modalDeleteOpen, setModalDeleteOpen] = useState({
    isOpen: false,
    title: "",
  });

  const handleDelete = () => {
    if (id) {
      dispatch(removeRedFlagRequest(id));
      setModalDeleteOpen({
        ...modalDeleteOpen,
        isOpen: false,
      });
      dispatch(loadStudentByIdRequest(Number(id)));
    }
  };

  useEffect(() => {
    if (id) {
      setModalDeleteOpen({
        ...modalDeleteOpen,
        title: StudentById?.first_name ?? "",
      });
      dispatch(loadStudentByIdRequest(Number(id)));
      dispatch(loadNoteRequest({ user__id: Number(id) }));
      dispatch(loadStudentInfoRequest(Number(id)));
      dispatch(
        loadStudentHistoryJrRequest(Number(id), { program__id: 1, limit: 3 })
      );
      dispatch(
        loadStudentHistoryVmRequest(Number(id), { program__id: 3, limit: 3 })
      );
      dispatch(
        loadStudentHistoryLsRequest(Number(id), { program__id: 2, limit: 3 })
      );
    }
    return () => {
      dispatch(loadNoteSuccess(undefined));
      dispatch(loadStudentByIdSuccess(undefined));
    };
  }, [id]);

  useEffect(() => {
    if (StudentById && StudentById.classroom) {
      dispatch(loadClassroomByIdRequest(StudentById.classroom));
    }
    return () => {
      dispatch(loadClassroomByIdSuccess(undefined));
    };
  }, [StudentById]);

  return (
    <Layout text={"Students"}>
      <Flex justify="flex-end" gap="10px">
        <OutlineButton
          bg="white"
          disabled={!StudentById?.is_red_flag}
          onClick={() =>
            setModalDeleteOpen({ ...modalDeleteOpen, isOpen: true })
          }
          color="red"
        >
          Remove Red Flag
        </OutlineButton>
        <OutlineButton
          bg="white"
          onClick={() => {
            dispatch(loadProgramStudentRequest(Number(id)));
            setModalBookmarkOpen({ isOpen: true });
          }}
        >
          Edit Bookmark (Lesson Location)
        </OutlineButton>
      </Flex>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            Student Details
          </Heading>
          <IconButton
            w="48px"
            h="48px"
            aria-label="eye"
            borderRadius="full"
            bg="white"
            border="1px solid #E2E8F0"
            icon={<Pencil size="24px" />}
            onClick={() => navigate(`/students/edit-students/${id}`)}
          />
        </Flex>
        <Grid mt="24px" templateColumns="repeat(5, 1fr)" gap="24px">
          <GridItem>
            <Text fontWeight="600">First Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Last Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Gender</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Grade</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Date of Birth</Text>
          </GridItem>

          <GridItem>
            <Text>{StudentById?.first_name}</Text>
          </GridItem>
          <GridItem>
            <Text>{StudentById?.last_name}</Text>
          </GridItem>
          <GridItem>
            <Text>
              {StudentById?.gender === "F"
                ? "Female"
                : StudentById?.gender === "M"
                ? "Male"
                : "None"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>{StudentById?.grade}</Text>
          </GridItem>
          <GridItem>
            <Text>{moment(StudentById?.birthday).format("MM-DD-yy")}</Text>
          </GridItem>
        </Grid>
        <Grid mt="24px" templateColumns="repeat(5, 1fr)" gap="24px">
          <GridItem>
            <Text fontWeight="600">Instructional Language</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Classroom</Text>
          </GridItem>
          <GridItem />
          <GridItem />
          <GridItem />
          <GridItem>
            <Text textTransform="capitalize">
              {StudentById?.language === "ENG" ? "English" : "Spanish"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>{classroomById?.name}</Text>
          </GridItem>
          <GridItem />
          <GridItem />
          <GridItem />
        </Grid>
        <Flex flexDir="column" mt="32px">
          <Heading fontWeight="600" fontSize="18px">
            Notes
          </Heading>
          {noteList?.map((item) => (
            <Flex
              flexDir="column"
              bg="gray.50"
              p="16px"
              borderRadius="8px"
              mt="8px"
              w="fit-content"
              gap="16px"
              key={item.id}
            >
              <Text fontStyle="italic">
                [ {moment(item?.updated_at).format("MM-DD-yy")} ] - {item.note}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Instructional History
        </Heading>
        <Grid
          mt="32px"
          gridTemplateAreas={`"status diagnosed current total" 
                              "status-text diagnosed-text current-text total-text "  `}
          gap="24px"
        >
          <GridItem area={"status"}>
            <Text fontWeight="600">Status</Text>
          </GridItem>
          <GridItem area={"diagnosed"}>
            <Text fontWeight="600">Diagnosed Level</Text>
          </GridItem>
          <GridItem area={"current"}>
            <Text fontWeight="600">Current Bookmark</Text>
          </GridItem>
          <GridItem area={"total"}>
            <Text fontWeight="600"> Total Time on Current Bookmark</Text>
          </GridItem>
          <GridItem area={"status-text"}>
            <Text>{StudentById?.is_active ? "Active" : "Inactive"}</Text>
          </GridItem>
          <GridItem area={"diagnosed-text"}>
            {studentInfo?.diagnostic_placement != null ? (
              <Text>{studentInfo?.diagnostic_placement.name}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
          <GridItem area={"current-text"} maxW="380px">
            {studentInfo?.current_lesson != null ? (
              <Text>{studentInfo?.current_lesson}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
          <GridItem area={"total-text"}>
            {studentInfo?.total_time_in_program != null ? (
              <Text>{studentInfo.total_time_in_program}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
        </Grid>
        <Heading fontWeight="600" fontSize="18px" mt="24px">
          Scores
        </Heading>
        <Text fontWeight="400" mt="16px" color="gray.500">
          Only test scores appear below (Pre/ Post & Review). This is only a
          quick overview. For complete score data go to{" "}
          <Link
            color="primary"
            fontWeight="600"
            onClick={() => navigate("/build-report")}
          >
            <span>Build Report.</span>
          </Link>
        </Text>

        <Heading fontWeight="600" fontSize="18px" mt="40px">
          Joseph's Readers History
        </Heading>

        <TableViewStudent>
          {studentHistoryJr &&
            studentHistoryJr?.length > 0 &&
            studentHistoryJr.map((s, index) => (
              <ItemViewStudent key={index} student={s} />
            ))}
        </TableViewStudent>
        {studentHistoryJr && studentHistoryJr?.length === 0 && (
          <Center mt="32px" w="full">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}
        {studentHistoryJr && studentHistoryJr.length >= 3 && (
          <Link
            color="primary"
            mt="16px"
            fontWeight="600"
            onClick={() =>
              dispatch(
                loadStudentHistoryJrRequest(Number(id), {
                  program__id: 1,
                  limit: 100,
                })
              )
            }
          >
            <Text>Show All History</Text>
          </Link>
        )}

        {/* <Pagination
          mt="16px"
          m="auto"
          onChangePage={onChangePage}
          pageCount={5}
        /> */}

        <Heading fontWeight="600" fontSize="18px" mt="40px">
          Life Skills History
        </Heading>
        <TableViewStudent>
          {studentHistoryLs &&
            studentHistoryLs?.length > 0 &&
            studentHistoryLs.map((s, index) => (
              <ItemViewStudent key={index} student={s} />
            ))}
        </TableViewStudent>
        {studentHistoryLs && studentHistoryLs?.length === 0 && (
          <Center mt="32px" w="full">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}
        {studentHistoryLs && studentHistoryLs.length >= 3 && (
          <Link
            color="primary"
            mt="16px"
            fontWeight="600"
            onClick={() =>
              dispatch(
                loadStudentHistoryLsRequest(Number(id), {
                  program__id: 2,
                  limit: 100,
                })
              )
            }
          >
            <Text>Show All History</Text>
          </Link>
        )}

        <Heading fontWeight="600" fontSize="18px" mt="40px">
          Verbal Master History
        </Heading>
        <TableViewStudent>
          {studentHistoryVm &&
            studentHistoryVm?.length > 0 &&
            studentHistoryVm.map((s, index) => (
              <ItemViewStudent key={index} student={s} />
            ))}
        </TableViewStudent>
        {studentHistoryVm && studentHistoryVm?.length === 0 && (
          <Center mt="32px" w="full">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}
        {studentHistoryVm && studentHistoryVm.length >= 3 && (
          <Link
            color="primary"
            mt="16px"
            fontWeight="600"
            onClick={() =>
              dispatch(
                loadStudentHistoryVmRequest(Number(id), {
                  program__id: 3,
                  limit: 100,
                })
              )
            }
          >
            <Text>Show All History</Text>
          </Link>
        )}

        {/* <Heading fontWeight="600" fontSize="18px" mt="40px">
          Bookmark History
        </Heading>
        <TableContainer
          border="1px solid #E2E8F0"
          borderRadius="16px"
          mt="16px"
        >
          <Table variant="simple" size="lg">
            <Thead bg="gray.50">
              <Tr>
                <Th
                  py="16px"
                  px="16px"
                  color="gray.700"
                  fontWeight="600"
                  textTransform="unset"
                  fontSize="16px"
                >
                  Date Completed
                </Th>
                <Th
                  py="16px"
                  px="16px"
                  color="gray.700"
                  fontWeight="600"
                  textTransform="unset"
                  fontSize="16px"
                >
                  Level
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td p="18px 16px">
                  <Text>Sep 14, 2022</Text>
                </Td>
                <Td p="18px 16px">
                  <Text>
                    Verbal Master Level 4, Cluster 4, Unit 21, Unit
                    Review-Synonym
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Link color="primary" mt="16px" fontWeight="600">
          <Text>Show All History</Text>
        </Link> */}
      </Flex>
      <ModalBookmarkedStudents
        studentActivity={studentInfo?.current_lesson ?? ""}
        StudentById={StudentById}
        isOpen={modalBookmarkOpen.isOpen}
        onClose={() => {
          dispatch(clearBookMark());
          setModalBookmarkOpen({ isOpen: false });
        }}
      />
      <ModalRedFlag
        handleDelete={handleDelete}
        isOpen={modalDeleteOpen}
        onClose={() =>
          setModalDeleteOpen({ ...modalDeleteOpen, isOpen: false })
        }
      />
    </Layout>
  );
};

export default ViewStudents;
