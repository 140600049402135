import {
  Button,
  Flex,
  Progress,
  Stack,
  Tag,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { StudentHistory } from "../../@types/interfaces/api/student.interface";
import Badgescore from "./BadgeScore";

interface ItemViewStudentProps {
  student: StudentHistory;
}

const ItemViewStudent: React.FC<ItemViewStudentProps> = ({ student }) => {
  const isNoScoreLessonTypes = [
    "independent_activities_scrambled_sentence",
    "independent_activities_short_answer_q&a",
    // "word_recognition_exercises",
    "word_verbal_master",
    "independent_activities_alphabetical_order",
    // "reinforcement_activities",
    "spelling_program",
    "vocabulary_builder",
    "unit_review_synonym",
    "unit_review_definition",
    "unit_review_fill_the_blank",
    "unit_review_comprehension",
    "minigame",
  ];

  const isNoScore = isNoScoreLessonTypes.some(
    (type) => type === student.lesson_type
  );

  return (
    <Tr>
      <Td p="18px 16px" maxW="200px">
        <Badgescore status={student.lesson_type} />
      </Td>
      <Td p="18px 16px">
        <Text>{moment(student.date).format("MM/DD/YYYY, HH:mm")}</Text>
      </Td>

      <Td p="18px 16px" maxW="300px" whiteSpace="pre-wrap">
        <Text>{student.level}</Text>
      </Td>
      <Td p="18px 16px" w="300px">
        {!isNoScore ? (
          <Stack w="full" flexDir="row" align="center" gap="16px">
            <Progress
              w="90%"
              colorScheme="blueScheme"
              borderRadius="full"
              size="md"
              value={student.score}
            />
            <Text color="primary" fontWeight="700">
              {student.score}%
            </Text>
          </Stack>
        ) : (
          <Text>non-evaluative lesson</Text>
        )}
      </Td>

      <Td p="18px 16px">
        <Button
          h="32px"
          borderRadius="100px"
          p="10px 12px"
          bg="primary"
          color="white"
          fontSize="14px"
          _hover={{}}
          isDisabled={student.lesson_type === "minigame"}
          onClick={() =>
            window.open(
              `/build-report/lesson/${student.history_id}-${student.lesson_type}`
            )
          }
        >
          View
        </Button>
      </Td>
    </Tr>
  );
};

export default ItemViewStudent;
