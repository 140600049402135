import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  District,
  DistrictUpdateCreate,
} from "../../@types/interfaces/api/district.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Form } from "../../styles/global";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { districtFormSchema } from "../../utils/yup-schemas";
import {
  registerDistrictRequest,
  updateDistrictRequest,
} from "../../stores/ducks/district/actions";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import {
  loadCountryRequest,
  loadStateRequest,
} from "../../stores/ducks/core/actions";
import RegularMenu from "../form/RegularMenu";
import SearchSelect from "../global/SearchSelect";
import { addPhoneMask } from "../../utils/phone";

interface CreateDistrictFormProps {
  district: District | undefined;
  loading: boolean;
}

const CreateDistrictForm: React.FC<CreateDistrictFormProps> = ({
  district,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { countries, states } = useAppSelector((i) => i.core);
  const navigate = useNavigate();

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(loadCountryRequest({ search: s }));
    },
    () => {
      dispatch(loadCountryRequest({}));
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DistrictUpdateCreate>({
    defaultValues: {
      name: district ? district.name : "",
      address: district ? district.address : "",
      state: district ? district.state : "",
      city: district ? district.city : "",
      zip: district ? district.zip : "",
      country: district ? district.country : "",
      phone_number: district ? addPhoneMask(district.phone_number) : "",
      primary_contact_name: district ? district.primary_contact_name : "",
      primary_contact_email: district ? district.primary_contact_email : "",
    },
    resolver: yupResolver(districtFormSchema),
  });

  const submitForm = (values: DistrictUpdateCreate) => {
    if (district) {
      dispatch(updateDistrictRequest(district.id, { ...values }));
    } else {
      dispatch(registerDistrictRequest({ ...values }, navigate));
    }
  };

  const onMouseClick = (short_name: string) => {
    setValue("country", short_name);
    dispatch(
      loadStateRequest({
        country: short_name,
      })
    );
    setData("");
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          {district?.id ? "Edit District" : "Create New District"}
        </Heading>
        <Text fontWeight="400" fontSize="16px" mt="16px" color="gray.500">
          {district?.id
            ? "Edit district information"
            : "Insert the district information to create"}
        </Text>

        <Grid
          mt="40px"
          gridTemplateAreas={`"district-name address country state"
                            "divider divider divider divider"
                               "city zip phone phone"`}
          gap="24px"
        >
          <GridItem area={"district-name"}>
            <FormControl isInvalid={!!errors.name}>
              <InputLabel>
                District Name*
                <RegularInput mt="4px" {...register("name")} />
                {<FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"address"}>
            <FormControl isInvalid={!!errors.address}>
              <InputLabel>
                Address*
                <RegularInput mt="4px" {...register("address")} />
                {<FormErrorMessage>{errors.address?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"country"}>
            <FormControl isInvalid={!!errors.state}>
              <InputLabel>
                Country*
                <Flex flexDir="column" position="relative">
                  <RegularInput
                    placeholder="type something.."
                    mt="4px"
                    type="text"
                    {...register("country")}
                    onChange={(e: any) => setData(e.target.value)}
                  />
                  {
                    <FormErrorMessage>
                      {errors.country?.message}
                    </FormErrorMessage>
                  }

                  {data && countries?.results[0] && (
                    <SearchSelect
                      list={countries}
                      onMouseClick={(short_name) => onMouseClick(short_name)}
                    />
                  )}
                </Flex>
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"state"}>
            <FormControl isInvalid={!!errors.state}>
              <InputLabel>
                State*
                {states && states.length > 0 ? (
                  <RegularMenu
                    placeholder="select state"
                    {...register("state")}
                  >
                    {states?.map((item) => (
                      <option key={item.id} value={item.short_name}>
                        {item.long_name}
                      </option>
                    ))}
                  </RegularMenu>
                ) : (
                  <RegularInput mt="4px" {...register("state")} maxLength={2} />
                )}
                {<FormErrorMessage>{errors.state?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"divider"}>
            <Divider my="12px" />
          </GridItem>
          <GridItem area={"city"}>
            <FormControl isInvalid={!!errors.city}>
              <InputLabel>
                City*
                <RegularInput mt="4px" {...register("city")} />
                {<FormErrorMessage>{errors.city?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"zip"}>
            <FormControl isInvalid={!!errors.zip}>
              <InputLabel>
                Zip*
                <RegularInput
                  mt="4px"
                  color="gray.500"
                  maxLength={6}
                  {...register("zip")}
                />
                {<FormErrorMessage>{errors.zip?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"phone"}>
            <FormControl isInvalid={!!errors.phone_number}>
              <InputLabel>
                Phone Number*
                <RegularInput
                  mt="4px"
                  color="gray.500"
                  placeholder="(555) 555-5555"
                  {...register("phone_number", {
                    onChange: (v) =>
                      setValue("phone_number", addPhoneMask(v.target.value)),
                  })}
                />
                {
                  <FormErrorMessage>
                    {errors.phone_number?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
        </Grid>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Primary District Contact
        </Heading>

        <Flex mt="24px" gap="24px" w="full">
          <FormControl isInvalid={!!errors.primary_contact_name}>
            <InputLabel mt="16px">
              Title of Contact*
              <RegularInput mt="4px" {...register("primary_contact_name")} />
              {
                <FormErrorMessage>
                  {errors.primary_contact_name?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>

          <FormControl isInvalid={!!errors.primary_contact_email}>
            <InputLabel mt="16px">
              Email of Contact*
              <RegularInput
                mt="4px"
                type="email"
                {...register("primary_contact_email")}
              />
              {
                <FormErrorMessage>
                  {errors.primary_contact_email?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
        </Flex>
      </Flex>
      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        <OutlineButton hidden={!!district} onClick={() => reset()}>
          Reset
        </OutlineButton>
        <RegularButton type="submit" isLoading={loading}>
          Save
        </RegularButton>
      </Flex>
    </Form>
  );
};

export default CreateDistrictForm;
