import axios, {AxiosError} from "axios";

interface FailedRequest {
    onSuccess: (token: string) => void;
    onFailure: (err: AxiosError) => void;
}

let isRefreshing = false;
let failedRequestQueue: FailedRequest[] = [];

const api = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

api.interceptors.response.use(undefined, (e: AxiosError) => {
    if (e.response?.status === 401) {
        const refreshToken = localStorage.get("ffr_refresh");
        const token = localStorage.get("ffr_token");

        if (refreshToken) {
            const originalConfig = e.config;

            if (!isRefreshing) {
                isRefreshing = true;

                api
                    .post("/auth/refresh", {
                        refresh: refreshToken,
                    })
                    .then((s) => {
                        const {access, refresh} = s.data;

                        localStorage.set("ffr_refresh", refresh);
                        localStorage.set("ffr_token", access);

                        api.defaults.headers.common["Authorization"] = `Bearer ${access}`;

                        failedRequestQueue.forEach((request) => request.onSuccess(access));
                        failedRequestQueue = [];
                    })
                    .catch((e) => {
                        failedRequestQueue.forEach((request) => request.onFailure(e));
                        failedRequestQueue = [];

                        localStorage.remove("ffr_refresh");
                        localStorage.remove("ffr_token");

                        window.location.href = "/admin";
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            }

            return new Promise((resolve, reject) => {
                failedRequestQueue.push({
                    onSuccess: (token: string) => {
                        if (originalConfig!.headers) {
                            originalConfig!.headers["Authorization"] = `Bearer ${token}`;
                        }
                        resolve(api(originalConfig!));
                    },
                    onFailure: (err: AxiosError) => {
                        reject(err);
                    },
                });
            });
        } else {
            return Promise.reject(e);
        }
    }

    return Promise.reject(e);
});

export default api;
