import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const TeacherIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#A946E5" fillOpacity="0.1" />
      <path
        d="M34.3336 36C34.3336 35.2928 34.0526 34.6145 33.5525 34.1144C33.0524 33.6143 32.3742 33.3333 31.6669 33.3333H23.6669C22.9597 33.3333 22.2814 33.6143 21.7813 34.1144C21.2812 34.6145 21.0002 35.2928 21.0002 36"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0003 17.3333H18.3337C16.8609 17.3333 15.667 18.5273 15.667 20V38.6667C15.667 40.1394 16.8609 41.3333 18.3337 41.3333H37.0003C38.4731 41.3333 39.667 40.1394 39.667 38.6667V20C39.667 18.5273 38.4731 17.3333 37.0003 17.3333Z"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6669 28C29.1397 28 30.3336 26.8061 30.3336 25.3333C30.3336 23.8606 29.1397 22.6667 27.6669 22.6667C26.1942 22.6667 25.0002 23.8606 25.0002 25.3333C25.0002 26.8061 26.1942 28 27.6669 28Z"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3337 14.6667V17.3333"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0002 14.6667V17.3333"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default TeacherIcon;
