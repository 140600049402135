import { ApexOptions } from "apexcharts";

interface areaChart {
  chartId: string;
  categories: string[] | undefined;
}

export const areaChartConfig = ({
  chartId,
  categories,
}: areaChart): ApexOptions => ({
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
    id: chartId,
    animations: { enabled: false, dynamicAnimation: { enabled: false } },
  },
  dataLabels: { enabled: false },
  xaxis: {
    categories,
    labels: {
      style: {
        colors: "#7EC8E3",
        fontSize: "12px",
      },
    },
    type: "datetime",
  },

  tooltip: {
    x: {
      format: "MM/dd/yyyy",
    },
    // y: {
    //     formatter: function (val) {
    //         return String(val);
    //     },
    // },
    style: {
      fontSize: "16px",
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#7EC8E3",
        fontSize: "12px",
      },
      offsetX: -15,
      formatter: (val) => val.toLocaleString("en-US", { notation: "standard" }),
    },
  },
  stroke: {
    width: 2,
    curve: "smooth",
  },
  grid: {
    xaxis: {
      lines: { show: true },
    },
    yaxis: {
      lines: { show: false },
    },
  },
  fill: {
    gradient: {
      opacityFrom: 0.62,
      opacityTo: 0.1,
    },
  },

  markers: {
    size: 0,
  },
  colors: ["#E53E3E"],
});
