import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Eye, EyeOff } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CreateUser,
  UpdateUser,
  User,
} from "../../@types/interfaces/api/user.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  registerAdminRequest,
  updateUserRequest,
} from "../../stores/ducks/auth/actions";
import { Form } from "../../styles/global";
import {
  adminFormSchema,
  uddateAdminFormSchema,
} from "../../utils/yup-schemas";
import InputLabel from "../form/InputLabel";
import InputPassword from "../form/InputPassword";
import RegularInput from "../form/RegularInput";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import { generatePassword, generateUsername } from "../../utils/generates";

interface CreateAdminFormProps {
  user: User | undefined;
}

const CreateAdminForm: React.FC<CreateAdminFormProps> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { isLoadingUsers, isLoadingUpdate } = useAppSelector((s) => s.auth);
  const [showPassword, setShowPassword] = useState(false);

  const generatedPassword = generatePassword();

  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateUser>({
    defaultValues: {
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      email: user ? user.email : "",
      username: user ? user.username : "",
      password: user ? "" : generatedPassword,
      repeat_password: user ? "" : generatedPassword,
    },

    resolver: yupResolver(!user ? adminFormSchema : uddateAdminFormSchema),
  });

  const watchFirstName = watch("first_name", "");
  const watchLastName = watch("last_name", "");

  useEffect(() => {
    if (
      watchFirstName !== undefined &&
      watchFirstName !== null &&
      watchLastName !== undefined &&
      watchLastName !== null
    ) {
      setValue("username", generateUsername(watchFirstName, watchLastName));
    }
  }, [watchFirstName, watchLastName]);

  const submitForm = (values: CreateUser | UpdateUser) => {
    if (user) {
      dispatch(updateUserRequest(user.id, values as UpdateUser, "admin"));
    } else {
      dispatch(registerAdminRequest(values as CreateUser, navigate));
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          {user ? "Edit Admin" : "Create New Admin"}
        </Heading>

        <Grid
          mt="40px"
          gridTemplateAreas={`"first-name last-name email"   `}
          gap="24px"
        >
          <GridItem area={"first-name"}>
            <FormControl isInvalid={!!errors.first_name}>
              <InputLabel>
                First Name*
                <RegularInput mt="4px" {...register("first_name")} />
                {
                  <FormErrorMessage>
                    {errors.first_name?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"last-name"}>
            <FormControl isInvalid={!!errors.last_name}>
              <InputLabel>
                Last Name*
                <RegularInput mt="4px" {...register("last_name")} />
                {
                  <FormErrorMessage>
                    {errors.last_name?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"email"}>
            <FormControl isInvalid={!!errors.email}>
              <InputLabel>
                Email Address*
                <RegularInput mt="4px" type="email" {...register("email")} />
                {<FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
        </Grid>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Admin Access
        </Heading>
        <Text fontWeight="400" fontSize="16px" mt="16px" color="gray.500">
          Create Your Password
        </Text>
        <Flex mt="24px" gap="24px" w="full">
          <FormControl isInvalid={!!errors.username}>
            <InputLabel mt="16px">
              Username*
              <RegularInput mt="4px" type="text" {...register("username")} />
              {<FormErrorMessage>{errors.username?.message}</FormErrorMessage>}
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <InputLabel mt="16px">
              Password*
              <InputPassword
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("password")}
                maxLength={Number("30")}
              />
              {<FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.repeat_password}>
            <InputLabel mt="16px">
              Repeat Password*
              <InputPassword
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("repeat_password")}
                maxLength={Number("30")}
              />
              {
                <FormErrorMessage>
                  {errors.repeat_password?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
        </Flex>
      </Flex>

      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        <OutlineButton hidden={!!user} onClick={() => reset()}>
          Reset
        </OutlineButton>
        <RegularButton
          type="submit"
          isLoading={user ? isLoadingUpdate : isLoadingUsers}
        >
          Save
        </RegularButton>
      </Flex>
    </Form>
  );
};

export default CreateAdminForm;
