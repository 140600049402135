import {
  Classroom,
  ClassroomList,
} from "../../../@types/interfaces/api/classroom.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum ClassroomActionTypes {
  LOAD_CLASSROOM_REQUEST = "@classroom/LOAD_CLASSROOM_REQUEST",
  LOAD_CLASSROOM_SUCCESS = "@classroom/LOAD_CLASSROOM_SUCCESS",
  LOAD_CLASSROOM_ERROR = "@classroom/LOAD_CLASSROOM_ERROR",

  LOAD_CLASSROOM_BY_ID_REQUEST = "@classroom/LOAD_CLASSROOM_BY_ID_REQUEST",
  LOAD_CLASSROOM_BY_ID_SUCCESS = "@classroom/LOAD_CLASSROOM_BY_ID_SUCCESS",
  LOAD_CLASSROOM_BY_ID_ERROR = "@classroom/LOAD_CLASSROOM_BY_ID_ERROR",

  REGISTER_CLASSROOM_REQUEST = "@classroom/REGISTER_CLASSROOM_REQUEST",
  REGISTER_CLASSROOM_SUCCESS = "@classroom/REGISTER_CLASSROOM_SUCCESS",
  REGISTER_CLASSROOM_ERROR = "@classroom/REGISTER_CLASSROOM_ERROR",

  UPDATE_CLASSROOM_REQUEST = "@classroom/UPDATE_CLASSROOM_REQUEST",
  UPDATE_CLASSROOM_SUCCESS = "@classroom/UPDATE_CLASSROOM_SUCCESS",
  UPDATE_CLASSROOM_ERROR = "@classroom/UPDATE_CLASSROOM_ERROR",

  CLEAR_CLASSROOM_LIST = "@classroom/CLEAR_CLASSROOM_LIST",
}

export interface ClassroomState extends ClassroomStateRequestsStatus {
  classrooms: Classroom[];
  classroomList: PaginationBase<ClassroomList[]> | undefined;
  classroomById: Classroom | undefined;
}
export interface ClassroomStateRequestsStatus {
  isLoadingClassroom: boolean;
}
