import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { School } from "../../../@types/interfaces/api/school.interface";

export enum SchoolActionTypes {
  LOAD_SCHOOL_REQUEST = "@school/LOAD_SCHOOL_REQUEST",
  LOAD_SCHOOL_REPORT_REQUEST = "@school/LOAD_SCHOOL_REPORT_REQUEST",
  LOAD_SCHOOL_SUCCESS = "@school/LOAD_SCHOOL_SUCCESS",
  LOAD_SCHOOL_REPORT_SUCCESS = "@school/LOAD_SCHOOL_REPORT_SUCCESS",
  LOAD_SCHOOL_ERROR = "@school/LOAD_SCHOOL_ERROR",

  LOAD_SCHOOL_BY_ID_REQUEST = "@school/LOAD_SCHOOL_BY_ID_REQUEST",
  LOAD_SCHOOL_BY_ID_SUCCESS = "@school/LOAD_SCHOOL_BY_ID_SUCCESS",
  LOAD_SCHOOL_BY_ID_ERROR = "@school/LOAD_SCHOOL_BY_ID_ERROR",

  REGISTER_SCHOOL_REQUEST = "@school/REGISTER_SCHOOL_REQUEST",
  REGISTER_SCHOOL_SUCCESS = "@school/REGISTER_SCHOOL_SUCCESS",
  REGISTER_SCHOOL_ERROR = "@school/REGISTER_SCHOOL_ERROR",

  UPDATE_SCHOOL_REQUEST = "@school/UPDATE_SCHOOL_REQUEST",
  UPDATE_SCHOOL_SUCCESS = "@school/UPDATE_SCHOOL_SUCCESS",
  UPDATE_SCHOOL_ERROR = "@school/UPDATE_SCHOOL_ERROR",

  UPDATE_STATUS_SCHOOL_REQUEST = "@school/UPDATE_STATUS_SCHOOL_REQUEST",
  UPDATE_STATUS_SCHOOL_SUCCESS = "@school/UPDATE_STATUS_SCHOOL_SUCCESS",
  UPDATE_STATUS_SCHOOL_ERROR = "@school/UPDATE_STATUS_SCHOOL_ERROR",

  BULK_UPDATE_SCHOOL_REQUEST = "@school/BULK_UPDATE_SCHOOL_REQUEST",
  BULK_UPDATE_SCHOOL_SUCCESS = "@school/BULK_UPDATE_SCHOOL_SUCCESS",
  BULK_UPDATE_SCHOOL_ERROR = "@school/BULK_UPDATE_SCHOOL_ERROR",

  CLEAR_SCHOOL_LIST = "@school/CLEAR_SCHOOL_LIST",
}

export interface SchoolState extends SchoolStateRequestsStatus {
  schools: School[];
  schoolsList: PaginationBase<School[]> | undefined;
  schoolsListReport: PaginationBase<School[]> | undefined;
  schoolsById: School | undefined;
}
export interface SchoolStateRequestsStatus {
  isLoadingSchool: boolean;
}
