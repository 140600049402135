import { NavigateFunction } from "react-router-dom";
import { Classroom } from "../../../@types/interfaces/api/classroom.interface";
import ClassroomFilterInterface from "../../../@types/interfaces/api/filters/classroom.filter.interface";
import FilterInterface from "../../../@types/interfaces/api/filters/filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { School } from "../../../@types/interfaces/api/school.interface";
import { ClassroomCreate } from "../../../@types/interfaces/app/forms/create-classroom.interface";
import { ClassroomActionTypes } from "./types";

export const loadClassroomRequest = (
  valuesFilter: ClassroomFilterInterface
) => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_REQUEST,
    payload: valuesFilter,
  };
};
export const loadClassroomSuccess = (values: PaginationBase<Classroom>) => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_SUCCESS,
    payload: values,
  };
};
export const loadClassroomError = () => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_ERROR,
  };
};

export const registerClassroomRequest = (
  values: ClassroomCreate,
  navigate: NavigateFunction
) => {
  return {
    type: ClassroomActionTypes.REGISTER_CLASSROOM_REQUEST,
    payload: { values, navigate },
  };
};
export const registerClassroomSuccess = () => {
  return {
    type: ClassroomActionTypes.REGISTER_CLASSROOM_SUCCESS,
  };
};
export const registerClassroomError = () => {
  return {
    type: ClassroomActionTypes.REGISTER_CLASSROOM_ERROR,
  };
};

export const loadClassroomByIdRequest = (id: number) => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_REQUEST,
    payload: id,
  };
};
export const loadClassroomByIdSuccess = (values: Classroom | undefined) => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_SUCCESS,
    payload: values,
  };
};
export const loadClassroomByIdError = () => {
  return {
    type: ClassroomActionTypes.LOAD_CLASSROOM_BY_ID_ERROR,
  };
};

export const updateClassroomRequest = (
  id: number,
  values: ClassroomCreate,
  navigate: NavigateFunction
) => {
  return {
    type: ClassroomActionTypes.UPDATE_CLASSROOM_REQUEST,
    payload: { id, values, navigate },
  };
};
export const updateClassroomSuccess = () => {
  return {
    type: ClassroomActionTypes.UPDATE_CLASSROOM_SUCCESS,
  };
};
export const updateClassroomError = () => {
  return {
    type: ClassroomActionTypes.UPDATE_CLASSROOM_ERROR,
  };
};

export const clearClassroomList = () => {
  return {
    type: ClassroomActionTypes.CLEAR_CLASSROOM_LIST,
  };
};
