import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";
import React from "react";

const SkeletonLibraryLesson: React.FC = () => {
  return (
    <Flex gap="16px" mt="12px">
      <Skeleton w="168px" h="217px" borderRadius="16px" />
      <Skeleton w="168px" h="217px" borderRadius="16px" />
      <Skeleton w="168px" h="217px" borderRadius="16px" />
      <Skeleton w="168px" h="217px" borderRadius="16px" />
    </Flex>
  );
};

export default SkeletonLibraryLesson;
