import { Country, State } from "../../../@types/interfaces/api/core.interface";
import {
  CountryFilterInterface,
  StateFilterInterface,
} from "../../../@types/interfaces/api/filters/core.filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { CoreActionTypes } from "./types";

export const loadCountryRequest = (valuesFilter: CountryFilterInterface) => {
  return {
    type: CoreActionTypes.LOAD_COUNTRY_REQUEST,
    payload: valuesFilter,
  };
};
export const loadCountrySuccess = (values: PaginationBase<Country>) => {
  return {
    type: CoreActionTypes.LOAD_COUNTRY_SUCCESS,
    payload: values,
  };
};
export const loadCountryError = () => {
  return {
    type: CoreActionTypes.LOAD_COUNTRY_ERROR,
  };
};

export const loadStateRequest = (valuesFilter: StateFilterInterface) => {
  return {
    type: CoreActionTypes.LOAD_STATE_REQUEST,
    payload: valuesFilter,
  };
};
export const loadStateSuccess = (values: State) => {
  return {
    type: CoreActionTypes.LOAD_STATE_SUCCESS,
    payload: values,
  };
};
export const loadStateError = () => {
  return {
    type: CoreActionTypes.LOAD_STATE_ERROR,
  };
};
