import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import alertImg from "../../assets/image/alert-circle.png";
import { useAppDispatch } from "../../hooks/redux";
import { registerStudentSuccess } from "../../stores/ducks/student/action";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: boolean;
}

const ModalAlertLicense: React.FC<ModalPrintAccessProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        p="32px"
        w="403px"
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
        mt="14%"
      >
        <Image boxSize="80px" src={alertImg} />
        <Heading fontSize="24px" fontWeight="500" color="gray.700" mt="16px">
          Number of licences exceeded
        </Heading>

        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="15px"
            lineHeight="150%"
            color="gray.500"
          >
            The student has been successfully created. Their status will remain
            inactive until release seat for this student. You need to change his
            status manually.
          </Text>
        </Flex>

        <RegularButton
          w="full"
          onClick={() => {
            dispatch(registerStudentSuccess(true));
            onClose();
          }}
          mt="24px"
        >
          Ok
        </RegularButton>
      </ModalContent>
    </Modal>
  );
};

export default ModalAlertLicense;
