import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import ItemRasReport from "../../components/build_report_components/ItemRasReport";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadReportRasRequest,
} from "../../stores/ducks/buildReport/actions";

import { useLocation } from "react-router-dom";
import { DataRas } from "../../@types/interfaces/api/buildReport.interface";
import LabelComponent from "./LabelComponent";

const ReadingAttitudeSurveyReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { rasReportData, isLoading } = useAppSelector((d) => d.buildReport);
  const { user } = useAppSelector((d) => d.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ids = searchParams.get("students");
  const schools = searchParams.get("schools");
  const currentYear = searchParams.get("year");

  useEffect(() => {
    if (ids && currentYear && schools && user?.user_type === "admin") {
      dispatch(
        loadReportRasRequest({
          students: ids,
          school_ids: schools,
          year: currentYear,
        })
      );
    }
    if (ids && currentYear && user?.user_type != "admin") {
      dispatch(loadReportRasRequest({ students: ids, year: currentYear }));
    }

    return () => {
      dispatch(clearReportList());
    };
  }, [ids, currentYear]);

  const totalResultPre = (data: DataRas[]): number => {
    return data.reduce((acc, data) => acc + data.pre_point, 0);
  };
  const totalResultPost = (data: DataRas[]): number => {
    return data.reduce((acc, data) => acc + data.post_point, 0);
  };

  const verifyTotalKey = (total: number): string => {
    if (total >= 15 && total <= 26) {
      return "very negative attitude";
    }
    if (total >= 27 && total <= 38) {
      return "negative attitude";
    }
    if (total >= 39 && total <= 50) {
      return "good attitude";
    }
    if (total >= 51 && total <= 62) {
      return "positive attitude";
    }
    if (total >= 63 && total <= 75) {
      return "very positive attitude";
    }
    return "";
  };

  if (isLoading) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  return (
    <LayoutBuildReport
      text="Student Reading Attitude Survey Report"
      date={rasReportData?.report_date ?? String(new Date())}
    >
      {rasReportData &&
        rasReportData.items.map((ras, i) => (
          <Fragment key={i}>
            <SectionWhite mt={i > 0 ? "40px" : ""}>
              <Text fontSize="18px" fontWeight="600">
                Report Details
              </Text>
              <Grid mt="16px" gap="24px" templateColumns="repeat(6, 1fr)">
                <GridItem>
                  <Text fontWeight="600">Student Name</Text>
                </GridItem>{" "}
                <GridItem>
                  <Text fontWeight="600">Teacher Name</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">Classroom</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">School</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">District</Text>
                </GridItem>
                <GridItem></GridItem>
                <GridItem>
                  <Text>{ras?.report_details.student ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{ras?.report_details.teacher ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{ras?.report_details.classroom ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{ras?.report_details.district ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{ras?.report_details.school ?? "No Data"}</Text>
                </GridItem>
                <GridItem></GridItem>
              </Grid>
            </SectionWhite>
            {ras?.data.length === 0 ? (
              <Text textAlign="center" my="40px">
                No Data
              </Text>
            ) : (
              <>
                <SectionWhite mt="24px">
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="gray.500"
                    ml="auto"
                  >
                    Pre Attitude {moment(ras?.pre_date).format("MM-DD-YYYY")} -
                    Post Attitude {moment(ras?.post_date).format("MM-DD-YYYY")}
                  </Text>
                  <TableContainer
                    border="1px solid #E2E8F0"
                    borderRadius="16px"
                    mt="16px"
                    boxShadow=" 0px 2px 10px 0px rgba(17, 20, 61, 0.06)"
                  >
                    <Table variant="striped" colorScheme="tableScheme">
                      <Thead>
                        <Tr>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                          >
                            Question
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            fontSize="16px"
                            textTransform="unset"
                          >
                            Pre Attitude
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            maxW="200px"
                          >
                            Post Attitude
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {ras?.data.map((item, index) => (
                          <ItemRasReport data={item} key={index} />
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th textAlign="end" color="blue.600" fontWeight="600">
                            Total:
                          </Th>
                          <Th>
                            <Flex align="center" gap="8px">
                              <Text
                                display="inline-block"
                                bg={
                                  totalResultPre(ras.data) > 45
                                    ? "#41B081"
                                    : "none"
                                }
                                color={
                                  totalResultPre(ras.data) > 45
                                    ? "white"
                                    : "black"
                                }
                                p={
                                  totalResultPre(ras.data) > 45
                                    ? "3px 8px"
                                    : "0px"
                                }
                                fontSize="18px"
                              >
                                {totalResultPre(ras.data)}
                              </Text>
                              <Text>
                                {verifyTotalKey(totalResultPre(ras.data))}
                              </Text>
                            </Flex>
                          </Th>
                          <Th>
                            <Flex align="center" gap="8px">
                              <Text
                                display="inline-block"
                                bg={
                                  totalResultPost(ras.data) > 45
                                    ? "#41B081"
                                    : "none"
                                }
                                color={
                                  totalResultPost(ras.data) > 45
                                    ? "white"
                                    : "black"
                                }
                                p={
                                  totalResultPost(ras.data) > 45
                                    ? "3px 8px"
                                    : "0px"
                                }
                                fontSize="18px"
                              >
                                {totalResultPost(ras.data)}
                              </Text>
                              <Text>
                                {verifyTotalKey(totalResultPost(ras.data))}
                              </Text>
                            </Flex>
                          </Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </SectionWhite>

                {ras?.evolution && (
                  <SectionWhite mt="24px">
                    <Text fontSize="18px" fontWeight="600" mt="40px">
                      Change in total attitude from Pre to Post:{" "}
                      {ras?.evolution.difference}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      color="gray.500"
                      mt="8px"
                    >
                      {ras?.report_details.student} shows a{" "}
                      {ras?.evolution.difference}/75{" "}
                      {ras?.evolution.difference < 0 ? "negative" : "positive"}{" "}
                      ({ras?.evolution.percentage}%) change in attitude towards
                      his reading after using Failure Free Reading.
                    </Text>
                  </SectionWhite>
                )}
                <LabelComponent />
              </>
            )}
          </Fragment>
        ))}
    </LayoutBuildReport>
  );
};

export default ReadingAttitudeSurveyReport;
