import { NavigateFunction } from "react-router-dom";
import {
  Extra,
  ExtraFilter,
  Level,
  LevelFilter,
  Library,
  LibraryFilter,
  Program,
  Story,
} from "../../../@types/interfaces/api/library.interface";
import { Profile } from "../../../@types/interfaces/api/profile.interface";
import { CreateLibraryInterface } from "../../../@types/interfaces/app/forms/create-library.interface";
import { LibraryActionTypes } from "./types";

export const loadStoryRequest = () => {
  return {
    type: LibraryActionTypes.LOAD_STORY_REQUEST,
  };
};
export const loadStorySuccess = (values: Story[]) => {
  return {
    type: LibraryActionTypes.LOAD_STORY_SUCCESS,
    payload: values,
  };
};
export const loadStoryError = () => {
  return {
    type: LibraryActionTypes.LOAD_STORY_ERROR,
  };
};

export const loadProgramRequest = () => {
  return {
    type: LibraryActionTypes.LOAD_PROGRAM_REQUEST,
  };
};
export const loadProgramSuccess = (values: Program[]) => {
  return {
    type: LibraryActionTypes.LOAD_PROGRAM_SUCCESS,
    payload: values,
  };
};
export const loadProgramError = () => {
  return {
    type: LibraryActionTypes.LOAD_PROGRAM_ERROR,
  };
};

export const loadLevelRequest = (filter?: LevelFilter) => {
  return {
    type: LibraryActionTypes.LOAD_LEVEL_REQUEST,
    payload: filter,
  };
};
export const loadLevelSuccess = (values: Level[]) => {
  return {
    type: LibraryActionTypes.LOAD_LEVEL_SUCCESS,
    payload: values,
  };
};
export const loadLevelError = () => {
  return {
    type: LibraryActionTypes.LOAD_LEVEL_ERROR,
  };
};

export const loadLibraryRequest = (filter?: LibraryFilter) => {
  return {
    type: LibraryActionTypes.LOAD_LIBRARY_REQUEST,
    payload: filter,
  };
};
export const loadLibrarySuccess = (values: Library[]) => {
  return {
    type: LibraryActionTypes.LOAD_LIBRARY_SUCCESS,
    payload: values,
  };
};
export const loadLibraryError = () => {
  return {
    type: LibraryActionTypes.LOAD_LIBRARY_ERROR,
  };
};

export const loadFullFileRequest = (filter?: ExtraFilter) => {
  return {
    type: LibraryActionTypes.LOAD_FF_REQUEST,
    payload: filter,
  };
};
export const loadFullFileSuccess = (values: Extra[]) => {
  return {
    type: LibraryActionTypes.LOAD_FF_SUCCESS,
    payload: values,
  };
};
export const loadIntroRequest = (filter?: ExtraFilter) => {
  return {
    type: LibraryActionTypes.LOAD_INTRO_REQUEST,
    payload: filter,
  };
};
export const loadIntroSuccess = (values: Extra[]) => {
  return {
    type: LibraryActionTypes.LOAD_INTRO_SUCCESS,
    payload: values,
  };
};
export const loadExtraError = () => {
  return {
    type: LibraryActionTypes.LOAD_EXTRA_ERROR,
  };
};
export const createFFRequest = (
  values: CreateLibraryInterface,
  navigate: NavigateFunction,
  file: FormData
) => {
  return {
    type: LibraryActionTypes.CREATE_FF_REQUEST,
    payload: { values, navigate, file },
  };
};
export const createFFSuccess = () => {
  return {
    type: LibraryActionTypes.CREATE_FF_SUCCESS,
  };
};
export const createFFError = () => {
  return {
    type: LibraryActionTypes.CREATE_FF_ERROR,
  };
};

export const createIntroRequest = (
  values: CreateLibraryInterface,
  navigate: NavigateFunction,
  file: FormData
) => {
  return {
    type: LibraryActionTypes.CREATE_INTRO_REQUEST,
    payload: { values, navigate, file },
  };
};
export const createIntroSuccess = () => {
  return {
    type: LibraryActionTypes.CREATE_INTRO_SUCCESS,
  };
};
export const createIntroError = () => {
  return {
    type: LibraryActionTypes.CREATE_FF_ERROR,
  };
};
export const createLibraryRequest = (
  values: CreateLibraryInterface,
  navigate: NavigateFunction,
  file: FormData
) => {
  return {
    type: LibraryActionTypes.CREATE_LIBRARY_REQUEST,
    payload: { values, navigate, file },
  };
};
export const createLibrarySuccess = () => {
  return {
    type: LibraryActionTypes.CREATE_LIBRARY_SUCCESS,
  };
};
export const createLibraryError = () => {
  return {
    type: LibraryActionTypes.CREATE_LIBRARY_ERROR,
  };
};

export const deleteLibraryRequest = (id: number, filter?: LibraryFilter) => {
  return {
    type: LibraryActionTypes.DELETE_LIBRARY_REQUEST,
    payload: { id, filter },
  };
};
export const deleteLibrarySuccess = () => {
  return {
    type: LibraryActionTypes.DELETE_LIBRARY_SUCCESS,
  };
};
export const deleteLibraryError = () => {
  return {
    type: LibraryActionTypes.DELETE_LIBRARY_ERROR,
  };
};

export const deleteFullFileRequest = (id: number, filter?: LibraryFilter) => {
  return {
    type: LibraryActionTypes.DELETE_EXTRA_REQUEST,
    payload: { id, filter },
  };
};
export const deleteIntroRequest = (id: number, filter?: LibraryFilter) => {
  return {
    type: LibraryActionTypes.DELETE_INTRO_REQUEST,
    payload: { id, filter },
  };
};
export const deleteFullFileSuccess = () => {
  return {
    type: LibraryActionTypes.DELETE_EXTRA_SUCCESS,
  };
};
export const deleteFullFileError = () => {
  return {
    type: LibraryActionTypes.DELETE_EXTRA_ERROR,
  };
};
