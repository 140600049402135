import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ReportsIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#0CA299" fillOpacity="0.1" />
      <path
        d="M31.6665 14.6667H20.3332C19.6259 14.6667 18.9476 14.9476 18.4476 15.4477C17.9475 15.9478 17.6665 16.6261 17.6665 17.3333V38.6667C17.6665 39.3739 17.9475 40.0522 18.4476 40.5523C18.9476 41.0524 19.6259 41.3333 20.3332 41.3333H36.3332C37.0404 41.3333 37.7187 41.0524 38.2188 40.5523C38.7189 40.0522 38.9998 39.3739 38.9998 38.6667V22L31.6665 14.6667Z"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9995 14.6667V22.6667H38.9995"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6662 29.3333H22.9995"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6662 34.6667H22.9995"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6662 24H22.9995"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ReportsIcon;
