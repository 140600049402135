import { NavigateFunction } from "react-router-dom";
import SchoolFilterInterface from "../../../@types/interfaces/api/filters/school.filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import SchoolBulkUpdateInterface from "../../../@types/interfaces/api/school-bulk-update.interface";
import { School } from "../../../@types/interfaces/api/school.interface";
import {
  SchoolCreate,
  SchoolStatus,
} from "../../../@types/interfaces/app/forms/create-school.interface";
import { SchoolActionTypes } from "./types";
import { clearPhoneMask } from "../../../utils/phone";

export const loadSchoolsRequest = (valuesFilter: SchoolFilterInterface) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_REQUEST,
    payload: valuesFilter,
  };
};
export const loadSchoolsReportRequest = (
  valuesFilter: SchoolFilterInterface
) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadSchoolsSuccess = (values: PaginationBase<School>) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_SUCCESS,
    payload: values,
  };
};
export const loadSchoolsReportSuccess = (values: PaginationBase<School>) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadSchoolsError = () => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_ERROR,
  };
};

export const loadSchoolsByIdRequest = (id: number) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_BY_ID_REQUEST,
    payload: id,
  };
};
export const loadSchoolsByIdSuccess = (values: School | undefined) => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_BY_ID_SUCCESS,
    payload: values,
  };
};
export const loadSchoolsByIdError = () => {
  return {
    type: SchoolActionTypes.LOAD_SCHOOL_BY_ID_ERROR,
  };
};

export const registerSchoolsRequest = (
  values: SchoolCreate,
  navigate: NavigateFunction
) => {
  return {
    type: SchoolActionTypes.REGISTER_SCHOOL_REQUEST,
    payload: {
      values: { ...values, phone_number: clearPhoneMask(values.phone_number) },
      navigate,
    },
  };
};
export const registerSchoolsSuccess = () => {
  return {
    type: SchoolActionTypes.REGISTER_SCHOOL_SUCCESS,
  };
};
export const registerSchoolsError = () => {
  return {
    type: SchoolActionTypes.REGISTER_SCHOOL_ERROR,
  };
};

export const updateSchoolsRequest = (
  values: SchoolCreate,
  navigate: NavigateFunction
) => {
  return {
    type: SchoolActionTypes.UPDATE_SCHOOL_REQUEST,
    payload: {
      navigate,
      values: { ...values, phone_number: clearPhoneMask(values.phone_number) },
    },
  };
};
export const updateSchoolsSuccess = () => {
  return {
    type: SchoolActionTypes.UPDATE_SCHOOL_SUCCESS,
  };
};
export const updateSchoolsError = () => {
  return {
    type: SchoolActionTypes.UPDATE_SCHOOL_ERROR,
  };
};

export const updateStatusSchoolsRequest = (
  values: SchoolStatus,
  id: number
) => {
  return {
    type: SchoolActionTypes.UPDATE_STATUS_SCHOOL_REQUEST,
    payload: { values, id },
  };
};
export const updateStatusSchoolsSuccess = () => {
  return {
    type: SchoolActionTypes.UPDATE_STATUS_SCHOOL_SUCCESS,
  };
};
export const updateStatusSchoolsError = () => {
  return {
    type: SchoolActionTypes.UPDATE_STATUS_SCHOOL_ERROR,
  };
};

export const bulkUpdateSchoolRequest = (
  ids: number[],
  data: SchoolBulkUpdateInterface
) => ({
  type: SchoolActionTypes.BULK_UPDATE_SCHOOL_REQUEST,
  payload: { data, ids },
});

export const clearSchoolList = () => {
  return {
    type: SchoolActionTypes.CLEAR_SCHOOL_LIST,
  };
};
