import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateDistrict from "../pages/districts/CreateDistrict";
import Districts from "../pages/districts/Districts";
import ViewDistrict from "../pages/districts/ViewDistrict";

const DistrictsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Districts />} />
      <Route path="/create-district" element={<CreateDistrict />}>
        <Route path=":id" element={<CreateDistrict />} />
        <Route path="" element={<CreateDistrict />} />
      </Route>
      <Route path="/view-district" element={<ViewDistrict />}>
        <Route path=":id" element={<ViewDistrict />} />
        <Route path="" element={<ViewDistrict />} />
      </Route>
    </Routes>
  );
};

export default DistrictsRoutes;
