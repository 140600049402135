import {
  Button,
  Checkbox,
  Flex,
  Heading,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  Student,
  StudentListImport,
} from "../../@types/interfaces/api/student.interface";
import StudentsBulkUpdateInterface from "../../@types/interfaces/api/students-bulk-update.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { loadClassroomRequest } from "../../stores/ducks/classroom/actions";
import {
  bulkUpdateStudentsRequest,
  loadStudentImportRequest,
  loadStudentRequest,
} from "../../stores/ducks/student/action";
import ItemAdminTable from "../admin_components/ItemAdminTable";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import HeadStudentTableModal from "../students_components/HeadStudentTableModal";
import ItemStudentTableModal from "../students_components/ItemStudentTableModal";

interface ModalImportProps {
  onClose(): void;
  isOpen: boolean;
}

const ModalImportStudents: React.FC<ModalImportProps> = ({
  onClose,
  isOpen,
}) => {
  const dispatch = useAppDispatch();
  const [newClassroom, setNewClassroom] =
    useState<StudentsBulkUpdateInterface>();
  const { studentList, isStudentLoading, studentListImport } = useAppSelector(
    (i) => i.student
  );
  const { classroomList } = useAppSelector((i) => i.classroom);
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<StudentListImport>();

  const selectedIds = items.map((d) => d.user);

  const onClickAction = (data: StudentsBulkUpdateInterface) => {
    dispatch(bulkUpdateStudentsRequest(selectedIds, data));
    clearSelections();
  };

  useEffect(() => {
    if (isOpen === true) {
      dispatch(loadClassroomRequest({}));
    }
  }, [isOpen]);

  const { setData } = useTypeDelay(
    (s) => {
      if (isOpen) {
        dispatch(loadStudentImportRequest({ search: s }));
      }
    },
    () => {
      if (isOpen) {
        dispatch(loadStudentImportRequest({}));
      }
    },
    "",
    isOpen
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px" p="40px" borderRadius="16px">
        <ModalHeader>
          <Heading fontSize="20px" fontWeight="600" color="blue.600">
            Import Students
          </Heading>
          <Flex mt="24px" gap="16px" justify="space-between">
            <InputGroup as={Flex} w="50%">
              <InputLeftElement
                mt="4px"
                ml="12px"
                children={<Search size="24" color="#A0AEC0" />}
              />
              <RegularInput
                placeholder="Search Students"
                pl="52px"
                onChange={(e: any) => setData(e.target.value)}
              />
            </InputGroup>
            <Flex>
              <RegularMenu
                placeholder="Select Classroom"
                onChange={(e: any) =>
                  setNewClassroom({ classroom: Number(e.target.value) })
                }
              >
                {classroomList?.results.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </RegularMenu>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#CBD5E0",
              borderRadius: "100px",
            },
          }}
        >
          <TableContainer border="1px solid #E2E8F0" borderRadius="16px">
            <Table variant="simple" size="lg">
              <Thead>
                {studentListImport && !isStudentLoading && (
                  <HeadStudentTableModal
                    isFullSelected={() => isFullSelected(studentListImport)}
                    onClickCheck={() => handleAll(studentListImport)}
                  />
                )}
              </Thead>
              <Tbody>
                {studentListImport.map((i) => (
                  <ItemStudentTableModal
                    key={i.user}
                    student={i}
                    onClickCheckbox={() => handleItem(i)}
                    isSelected={items.some((s) => s.user === i.user)}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter justifyContent="space-between" mt="24px">
          <OutlineButton onClick={onClose}>Cancel</OutlineButton>
          {newClassroom && (
            <RegularButton
              onClick={() =>
                onClickAction({ classroom: newClassroom.classroom })
              }
            >
              Import Students
            </RegularButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalImportStudents;
