import {
  Flex,
  InputGroup,
  InputLeftElement,
  Spinner,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DiagnosticPlacementData } from "../../@types/interfaces/api/buildReport.interface";
import { TableHeader } from "../../@types/interfaces/app/table-props.interfaces";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import RegularInput from "../../components/form/RegularInput";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useDebounce from "../../hooks/useDebouce";
import {
  clearReportList,
  loadDiagnosticPlacementRequest,
} from "../../stores/ducks/buildReport/actions";

const DiagnosticPlacement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { diagnosticPlacementData, isLoading } = useAppSelector(
    (d) => d.buildReport
  );
  const { user } = useAppSelector((d) => d.auth);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [diagnosticData, setDiagnosticData] = useState<
    DiagnosticPlacementData[]
  >([]);

  const school = searchParams.get("school");
  const currentYear = searchParams.get("year");
  const level = searchParams.get("level");

  useEffect(() => {
    if (
      currentYear &&
      user &&
      ["admin", "principal", "teacher"].includes(user.user_type)
    ) {
      dispatch(
        loadDiagnosticPlacementRequest({
          school_ids: school ?? "",
          year: currentYear,
          level_id: level ?? "",
        })
      );
    }

    return () => {
      dispatch(clearReportList());
    };
  }, [school, currentYear, user, dispatch]);

  useEffect(() => {
    setDiagnosticData(diagnosticPlacementData?.data ?? []);
  }, [diagnosticPlacementData]);

  const handleChange = (s: string) => {
    if (!diagnosticPlacementData) return;
    if (s) {
      const filtred = diagnosticPlacementData.data.filter(
        (a) =>
          a.student.toLowerCase().trim().includes(s.toLowerCase().trim()) ||
          a.classroom.toLowerCase().trim().includes(s.toLowerCase().trim())
      );
      setDiagnosticData(filtred);
    } else {
      setDiagnosticData(diagnosticPlacementData?.data ?? []);
    }
  };

  const search = useDebounce(handleChange, 500);

  if (isLoading || !diagnosticPlacementData) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  return (
    <LayoutBuildReport
      text="Student Diagnostic Prescriptive Placement Report"
      // date={diagnosticPlacementData.report_date}
      date={diagnosticPlacementData.report_date}
    >
      <SectionWhite mb="24px">
        <Flex flexDir="row" justifyContent="space-around">
          <Text fontSize="14px" fontWeight="500" color="gray.500">
            Life Skills Equivalence
          </Text>
          <Text fontSize="14px" fontWeight="500" color="gray.500">
            BLUE = RED
          </Text>
          <Text fontSize="14px" fontWeight="500" color="gray.500">
            PURPLE = GREEN
          </Text>
          <Text fontSize="14px" fontWeight="500" color="gray.500">
            MAROON = ORANGE
          </Text>
          <Text fontSize="14px" fontWeight="500" color="gray.500">
            YELLOW = TEAL
          </Text>
        </Flex>
      </SectionWhite>

      <SectionWhite>
        <InputGroup as={Flex} maxW="40% ">
          <InputLeftElement
            mt="4px"
            ml="12px"
            children={<Search size="24" color="#A0AEC0" />}
          />
          <RegularInput
            placeholder="Search Student or Classroom "
            pl="52px"
            onChange={(e: any) => search(e.target.value)}
          />
        </InputGroup>
        <CustomTable headers={header}>
          {diagnosticData.map((a) => {
            return <DiagnosticPlacementTableLine data={a} />;
          })}
        </CustomTable>
      </SectionWhite>
    </LayoutBuildReport>
  );
};

const header: TableHeader[] = [
  { name: "Classroom", clicable: false },
  { name: "Student Name ", clicable: false },
  { name: "Current Grade", clicable: false },
  { name: "Diagnostic Placement", clicable: false },
];

const DiagnosticPlacementTableLine = (props: {
  data: DiagnosticPlacementData;
}) => {
  return (
    <Tr h="20px">
      <Td />
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        <Text>{props.data.classroom}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        <Text>{props.data.student}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{props.data.grade}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{props.data.placement}</Text>
      </Td>
    </Tr>
  );
};

export default DiagnosticPlacement;
