import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadHistoryReportRequest,
} from "../../stores/ducks/buildReport/actions";
import { activityName } from "../../utils/bookMark";
import ItemLessonTableReport from "./ItemLessonTableReport";

const LessonReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { historyReportData, isLoading } = useAppSelector((d) => d.buildReport);

  const getUrlData = (): string[] => {
    if (id) {
      return id.split("-");
    } else {
      return ["0", "0"];
    }
  };

  useEffect(() => {
    dispatch(
      loadHistoryReportRequest({
        history_id: Number(getUrlData()[0]),
        mechanic_type: getUrlData()[1],
      })
    );

    return () => {
      dispatch(clearReportList());
    };
  }, [id]);

  if (isLoading) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  return (
    <LayoutBuildReport
      text={activityName[getUrlData()[1]]}
      date={String(new Date())}
    >
      <SectionWhite>
        <Text fontSize="18px" fontWeight="600">
          Report Details
        </Text>
        <Grid mt="16px" gap="24px" templateColumns="repeat(6, 1fr)">
          <GridItem>
            <Text fontWeight="600">Student Name</Text>
          </GridItem>{" "}
          <GridItem>
            <Text fontWeight="600">Teacher Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Classroom</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">School</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">District</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Time in Lesson</Text>
          </GridItem>
          <GridItem>
            <Text>
              {historyReportData?.report_details.student ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {historyReportData?.report_details.teacher ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {historyReportData?.report_details.classroom ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>{historyReportData?.report_details.school ?? "No Data"}</Text>
          </GridItem>
          <GridItem>
            <Text>
              {historyReportData?.report_details.district ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>{historyReportData?.report_details.time ?? "No Data"}</Text>
          </GridItem>
        </Grid>
      </SectionWhite>
      {/* {rasReportData?.data.length === 0 ? (
        <Text textAlign="center" mt="40px">
          No Data
        </Text>
      ) : ( */}
      <>
        <SectionWhite mt="24px">
          <TableContainer
            border="1px solid #E2E8F0"
            borderRadius="16px"
            mt="16px"
            boxShadow=" 0px 2px 10px 0px rgba(17, 20, 61, 0.06)"
          >
            <Table variant="striped" colorScheme="tableScheme">
              <Thead>
                <Tr>
                  <Th
                    py="16px"
                    px="16px"
                    color="blue.600"
                    fontWeight="600"
                    textTransform="unset"
                    fontSize="16px"
                  >
                    Question
                  </Th>
                  <Th
                    py="16px"
                    px="16px"
                    color="blue.600"
                    fontWeight="600"
                    fontSize="16px"
                    textTransform="unset"
                  >
                    Student Answer
                  </Th>
                  <Th
                    py="16px"
                    px="16px"
                    color="blue.600"
                    fontWeight="600"
                    textTransform="unset"
                    fontSize="16px"
                    maxW="200px"
                  >
                    Right Answer
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {historyReportData &&
                  historyReportData.data.map((i, index) => (
                    <ItemLessonTableReport key={index} data={i} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </SectionWhite>
      </>
      {/* )} */}
    </LayoutBuildReport>
  );
};

export default LessonReport;
