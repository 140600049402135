import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import deleteImg from "../../assets/image/x-circle.png";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: { isOpen: boolean; title: string; id: number };
  handleDelete: () => void;
}

const ModalDelete: React.FC<ModalPrintAccessProps> = ({
  onClose,
  isOpen,
  handleDelete,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen.isOpen}>
      <ModalOverlay />
      <ModalContent
        p="32px"
        w="403px"
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
        mt="14%"
      >
        <Image boxSize="80px" src={deleteImg} />
        <Heading fontSize="24px" fontWeight="500" color="gray.700" mt="16px">
          Delete File
        </Heading>

        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="15px"
            lineHeight="150%"
            color="gray.500"
          >
            Are you sure to delete the <b>{isOpen.title}</b> ?
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mt="32px" w="full" gap="10px">
          <OutlineButton onClick={onClose} w="169px">
            Close
          </OutlineButton>
          <RegularButton
            w="169px"
            bgColor="#E53E3E"
            _hover={{}}
            onClick={() => handleDelete()}
          >
            Delete
          </RegularButton>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ModalDelete;
