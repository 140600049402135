import {
  TableHeader,
  TableHeaderClassroom,
  TableHeaderSchool,
} from "../@types/interfaces/app/table-props.interfaces";

export const studantTables: TableHeader[] = [
  { name: "Student Name", clicable: true, sort_field: "first_name" },
  { name: "Progress Report", clicable: true, sort_field: "" },
  { name: "Current Lesson", clicable: true, sort_field: "" },
  { name: "Diagnostic Date", clicable: true, sort_field: "" },
  { name: "Diagnostic Placement", clicable: true, sort_field: "" },
  { name: "Last Activity", clicable: true, sort_field: "" },
  { name: "Grade Level", clicable: false, sort_field: "grade" },
  { name: "Status", clicable: false, sort_field: "is_active" },
];

export const districtTables: TableHeader[] = [
  { name: "District Name", clicable: true, sort_field: "name" },
  { name: "State", clicable: true, sort_field: "state" },
  { name: "City", clicable: true, sort_field: "city" },
  {
    name: "Contact Name",
    clicable: true,
    sort_field: "primary_contact_name",
  },
  { name: "Phone Number", clicable: true, sort_field: "phone_number" },
  { name: "Schools", clicable: false, sort_field: "schools" },
  { name: "Status", clicable: false, sort_field: "is_active" },
];

export const principalsTables: TableHeader[] = [
  { name: "Principal Name", clicable: true, sort_field: "first_name" },
  { name: "Email Address", clicable: true, sort_field: "email" },
  { name: "School", clicable: true, sort_field: "school" },
  { name: "Status", clicable: false, sort_field: "is_active" },
];

export const classroomTables: TableHeaderClassroom[] = [
  { name: "Classroom Name", clicable: true, sort_field: "name" },
  { name: "Teacher", clicable: true, sort_field: "teacher" },
  { name: "School", clicable: true, sort_field: "school" },
  { name: "Students", clicable: false, sort_field: "students" },
  { name: "Used (24 Hrs)", clicable: false, sort_field: "used_24h" },
  { name: "Used (7 Days)", clicable: false, sort_field: "used_7d" },
  { name: "Used (30 Days)", clicable: false, sort_field: "used_30d" },
  { name: "Used (90 Days)", clicable: false, sort_field: "used_90d" },
  { name: "Used SY (1 Year)", clicable: false, sort_field: "used_1y" },
  {
    name: "Last Student Activity",
    clicable: false,
    sort_field: "last_student_login",
  },
  {
    name: "Last Teacher Login",
    clicable: false,
    sort_field: "last_teacher_login",
  },
  { name: "", clicable: false, sort_field: "id" },
];

export const teachersTables: TableHeader[] = [
  { name: "Teacher Name", clicable: true, sort_field: "first_name" },
  { name: "Email Address ", clicable: true, sort_field: "email" },
  { name: "School", clicable: true, sort_field: "school" },
  { name: "Username", clicable: true, sort_field: "username" },
  { name: "Status", clicable: false, sort_field: "is_active" },
];
export const schoolsTables: TableHeaderSchool[] = [
  { name: "School Name", clicable: true, sort_field: "name" },
  { name: "District", clicable: true, sort_field: "district" },
  {
    name: "Last Student Activity",
    clicable: false,
    sort_field: "last_student_login",
  },
  {
    name: "Last Teacher Login",
    clicable: false,
    sort_field: "last_teacher_login",
  },
  // { name: "Address ", clicable: false, sort_field: "address" },
  // { name: "City", clicable: false, sort_field: "city" },
  { name: "Seats allocated", clicable: false, sort_field: "total_seats" },
  { name: "Enrolled (Set active)", clicable: false, sort_field: "seats_used" },
  {
    name: "Student in Lesson",
    clicable: false,
    sort_field: "students_in_activities",
  },
  { name: "Used (24 Hrs)", clicable: false, sort_field: "used_24h" },
  { name: "Used (7 Days)", clicable: false, sort_field: "used_7d" },
  { name: "Used (30 Days)", clicable: false, sort_field: "used_30d" },
  { name: "Used (90 Days)", clicable: false, sort_field: "used_90d" },
  { name: "Used SY (1 Year)", clicable: false, sort_field: "used_1y" },
  { name: "School Created", clicable: false, sort_field: "created_at" },
  { name: "", clicable: false, sort_field: "zip" },

  // { name: "Status", clicable: false, sort_field: "is_active" },
];
export const adminTables: TableHeader[] = [
  { name: "Admin Name", clicable: true, sort_field: "first_name" },
  { name: "Email Address ", clicable: true, sort_field: "email" },
  { name: "Username", clicable: true, sort_field: "username" },
  { name: "Last Activity", clicable: false, sort_field: "" },
  { name: "Status", clicable: false },
];
