import {
  FilterBuildReportRas,
  FilterLoadReportPlacementRequest,
  FilterSchoolProgress,
  FilterStudentProgress,
  HistoryReportFilters,
  IBuildReportDiagnostic,
  IBuildReportRas,
  IDiagnosticPlacement,
  IHistoryReportData,
  ISchoolProgress,
  IStudentProgress,
} from "../../../@types/interfaces/api/buildReport.interface";
import {
  DashboardAdminDataChartBar,
  IFilterProgramReport,
  IProgramReport,
} from "../../../@types/interfaces/api/dashboard.inteface";
import {
  replaceNameJr,
  replaceNameLs,
  replaceNameVm,
} from "../../../utils/replaceNameCharts";
import { BuildReportActionTypes } from "./types";

export const loadReportRasRequest = (valuesFilter: FilterBuildReportRas) => {
  return {
    type: BuildReportActionTypes.LOAD_RAS_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportRasSuccess = (values: IBuildReportRas[]) => {
  return {
    type: BuildReportActionTypes.LOAD_RAS_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadReportRasError = () => {
  return {
    type: BuildReportActionTypes.LOAD_RAS_REPORT_ERROR,
  };
};

export const loadReportDiagnosticRequest = (
  valuesFilter: FilterBuildReportRas
) => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportDiagnosticSuccess = (
  values: IBuildReportDiagnostic[]
) => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadReportDiagnosticError = () => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_ERROR,
  };
};

export const loadDiagnosticPlacementRequest = (
  valuesFilter: FilterLoadReportPlacementRequest
) => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadDiagnosticPlacementSuccess = (
  values: IDiagnosticPlacement
) => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadDiagnosticPlacementError = () => {
  return {
    type: BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_ERROR,
  };
};

export const loadHistoryReportRequest = (
  valuesFilter: HistoryReportFilters
) => {
  return {
    type: BuildReportActionTypes.LOAD_HISTORY_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadHistoryReportSuccess = (values: IHistoryReportData) => {
  return {
    type: BuildReportActionTypes.LOAD_HISTORY_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadHistoryReportError = () => {
  return {
    type: BuildReportActionTypes.LOAD_HISTORY_REPORT_ERROR,
  };
};

export const loadReportStudentProgressRequest = (
  valuesFilter: FilterStudentProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportStudentProgressSuccess = (values: IStudentProgress) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadReportStudentProgressError = () => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_ERROR,
  };
};

export const loadReportSchoolProgressRequest = (
  valuesFilter: FilterSchoolProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportSchoolProgressSuccess = (data: ISchoolProgress) => {
  const dataChartBarJr: DashboardAdminDataChartBar = {
    categories: Object.keys(data.program_report.kpis).map(
      (i) => replaceNameJr[i]
    ),
    colors: ["#FF1313", "#3BB800", "#FF6B00", "#08D6BD"],
    series: [
      {
        name: "Joseph's Readers",
        data: Object.values(data.program_report.kpis),
      },
    ],
    totalData: Object.values(data.program_report.kpis).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_SUCCESS,
    payload: { data, dataChartBarJr },
  };
};

export const loadReportSchoolProgressLsRequest = (
  valuesFilter: FilterSchoolProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportSchoolProgressLsSuccess = (data: ISchoolProgress) => {
  const dataChartBarLs: DashboardAdminDataChartBar = {
    categories: Object.keys(data.program_report.kpis).map(
      (i) => replaceNameLs[i]
    ),
    colors: ["#3989F2", "#9C39FF", "#9A4A00", "#FFE500"],
    series: [
      {
        name: "Life Skills",
        data: Object.values(data.program_report.kpis),
      },
    ],
    totalData: Object.values(data.program_report.kpis).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_SUCCESS,
    payload: { data, dataChartBarLs },
  };
};
export const loadReportSchoolProgressVmRequest = (
  valuesFilter: FilterSchoolProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportSchoolProgressVmSuccess = (data: ISchoolProgress) => {
  const dataChartBarVm: DashboardAdminDataChartBar = {
    categories: Object.keys(data.program_report.kpis).map(
      (i) => replaceNameVm[i]
    ),
    colors: ["#7EC8E3", "#68B0AB", "#0047AB", "#003366"],
    series: [
      {
        name: "Verbal Master",
        data: Object.values(data.program_report.kpis),
      },
    ],
    totalData: Object.values(data.program_report.kpis).reduce(
      (acc, value) => acc + value,
      0
    ),
  };

  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_SUCCESS,
    payload: { data, dataChartBarVm },
  };
};
export const loadReportSchoolProgressError = () => {
  return {
    type: BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_ERROR,
  };
};
export const loadReportStudentProgressLsRequest = (
  valuesFilter: FilterStudentProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_LS_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportStudentProgressVmRequest = (
  valuesFilter: FilterStudentProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_VM_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadReportStudentProgressLsSuccess = (
  values: IStudentProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_LS_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadReportStudentProgressVmSuccess = (
  values: IStudentProgress
) => {
  return {
    type: BuildReportActionTypes.LOAD_STUDENT_PROGRESS_VM_REPORT_SUCCESS,
    payload: values,
  };
};

export const loadProgramReportRequest = (
  valuesFilter: IFilterProgramReport
) => {
  return {
    type: BuildReportActionTypes.LOAD_PROGRAM_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadProgramReportSuccess = (values: IProgramReport[]) => {
  return {
    type: BuildReportActionTypes.LOAD_PROGRAM_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadProgramReportError = () => {
  return {
    type: BuildReportActionTypes.LOAD_PROGRAM_REPORT_ERROR,
  };
};

export const clearReportList = () => {
  return {
    type: BuildReportActionTypes.CLEAR_REPORT,
  };
};
