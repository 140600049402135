import { SchoolActionTypes, SchoolState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: SchoolState = {
  isLoadingSchool: false,
  schools: [],
  schoolsList: undefined,
  schoolsById: undefined,
  schoolsListReport: undefined,
};

const reducer: Reducer<SchoolState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolActionTypes.LOAD_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.LOAD_SCHOOL_REPORT_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.LOAD_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
        schoolsList: action.payload,
      };
    case SchoolActionTypes.LOAD_SCHOOL_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
        schoolsListReport: action.payload,
      };
    case SchoolActionTypes.LOAD_SCHOOL_ERROR:
      return { ...INITIAL_STATE, isLoadingSchool: false };

    case SchoolActionTypes.LOAD_SCHOOL_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.LOAD_SCHOOL_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
        schoolsById: action.payload,
      };
    case SchoolActionTypes.LOAD_SCHOOL_BY_ID_ERROR:
      return { ...INITIAL_STATE, isLoadingSchool: false };

    case SchoolActionTypes.REGISTER_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.REGISTER_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
      };
    case SchoolActionTypes.REGISTER_SCHOOL_ERROR:
      return { ...INITIAL_STATE, isLoadingSchool: false };

    case SchoolActionTypes.UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
      };
    case SchoolActionTypes.UPDATE_SCHOOL_ERROR:
      return { ...INITIAL_STATE, isLoadingSchool: false };

    case SchoolActionTypes.UPDATE_STATUS_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingSchool: true,
      };
    case SchoolActionTypes.UPDATE_STATUS_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingSchool: false,
      };
    case SchoolActionTypes.UPDATE_STATUS_SCHOOL_ERROR:
      return INITIAL_STATE;
    case SchoolActionTypes.CLEAR_SCHOOL_LIST:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
