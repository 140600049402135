import { LicenseActionTypes } from "./types";
import {
  CheckLicense,
  License,
} from "../../../@types/interfaces/api/license.interface";

export const loadLicensesRequest = (filters?: License) => ({
  type: LicenseActionTypes.LOAD_LICENSE_REQUEST,
  payload: filters,
});

export const loadLicensesSuccess = (Licenses: License[]) => ({
  type: LicenseActionTypes.LOAD_LICENSE_SUCCESS,
  payload: Licenses,
});
export const loadLicensesErrorr = () => ({
  type: LicenseActionTypes.LOAD_LICENSE_ERROR,
});

export const loadLicenseIdRequest = (id: number) => ({
  type: LicenseActionTypes.LOAD_LICENSE_BY_ID_REQUEST,
  payload: id,
});

export const loadLicenseIdSuccess = (License: License | undefined) => ({
  type: LicenseActionTypes.LOAD_LICENSE_BY_ID_SUCCESS,
  payload: License,
});
export const loadLicenseIdErrorr = () => ({
  type: LicenseActionTypes.LOAD_LICENSE_BY_ID_ERROR,
});
export const registerLicenseRequest = (values: License) => {
  return {
    type: LicenseActionTypes.REGISTER_LICENSE_REQUEST,
    payload: values,
  };
};

export const registerLicenseSuccess = () => {
  return {
    type: LicenseActionTypes.REGISTER_LICENSE_SUCCESS,
  };
};
export const registerLicenseError = () => {
  return {
    type: LicenseActionTypes.REGISTER_LICENSE_ERROR,
  };
};

export const checkLicenseIdRequest = () => ({
  type: LicenseActionTypes.CHECK_LICENSE_REQUEST,
});

export const checkLicenseIdSuccess = (
  LicenseCheck: CheckLicense | undefined
) => ({
  type: LicenseActionTypes.CHECK_LICENSE_SUCCESS,
  payload: LicenseCheck,
});
export const checkLicenseIdErrorr = () => ({
  type: LicenseActionTypes.CHECK_LICENSE_ERROR,
});
