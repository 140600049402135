export const generateUsername = (firstName: string, lastName: string) => {
    const p1 = firstName.toLowerCase().replace(" ", "")
    const p2 = lastName.toLowerCase().replace(" ", "")

    return p1 + p2
}

export const generatePassword = (strength = 'strong') => {
    const alphaNumericChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const specialChars = '!@#$%^&*';
    const characters = strength === 'weak' ? alphaNumericChars : alphaNumericChars + specialChars;

    const password_length = Math.floor(Math.random() * (16 - 8 + 1)) + 8;
    let password = '';

    for (let i = 0; i < password_length; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        password += characters[randomCharIndex];
    }

    return password;
}