import { Checkbox, Flex, Switch, Td, Text, Tr } from "@chakra-ui/react";
import { Eye, Pencil } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { District } from "../../@types/interfaces/api/district.interface";
import { useAppDispatch } from "../../hooks/redux";
import {
  deleteDistrictRequest,
  updateDistrictRequest,
} from "../../stores/ducks/district/actions";
import BadgeStatus from "../global/BadgeStatus";
import EditButton from "../global/circle_button/EditButton";
import EmailButton from "../global/circle_button/EmailButton";
import TrashButton from "../global/circle_button/TrashButton";
import ViewButton from "../global/circle_button/ViewButton";
import TelTo from "../global/TelTo";
import { addPhoneMask } from "../../utils/phone";

interface ItemDistrictTableProps {
  district: District;
  isSelected: boolean;
  onClickCheckbox: () => void;
}

const ItemDistrictTable: React.FC<ItemDistrictTableProps> = ({
  district,
  isSelected,
  onClickCheckbox,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        <Checkbox
          colorScheme="tagScheme"
          isChecked={isSelected}
          onChange={onClickCheckbox}
        />
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="300px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{district.name}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{district.state}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{district.city}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{district.primary_contact_name}</Text>
      </Td>

      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <TelTo phone={Number(district?.phone_number)}>
          <Text color="primary">{addPhoneMask(district?.phone_number)}</Text>
        </TelTo>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        {district.schools ? (
          <Text>{district.schools}</Text>
        ) : (
          <Text>No Data</Text>
        )}
      </Td>
      <Td
        py="16px"
        pl="0"
        pr="50px"
        fontWeight="400"
        color="gray.700"
        w="120px"
      >
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={district.is_active ? "active" : "inactive"} />
          <Switch
            size="sm"
            colorScheme="tagScheme"
            ml="6px"
            isChecked={district.is_active}
            onChange={(e: any) =>
              dispatch(
                updateDistrictRequest(district.id, {
                  ...district,
                  is_active: e.target.checked,
                })
              )
            }
          />
        </Flex>
      </Td>

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <ViewButton
          onClick={() => navigate(`/districts/view-district/${district.id}`)}
        />
        <EditButton
          ml="4px"
          onClick={() => navigate(`/districts/create-district/${district.id}`)}
        />
        {/* <TrashButton
          ml="4px"
          onClick={() => dispatch(deleteDistrictRequest(district.id))}
        /> */}
      </Td>
    </Tr>
  );
};

export default ItemDistrictTable;
