import {
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/Layout";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { loadLicenseIdRequest } from "../../stores/ducks/license/actions";
import { loadSchoolsByIdRequest } from "../../stores/ducks/school/actions";
import moment from "moment";
import TelTo from "../../components/global/TelTo";
import MailTo from "../../components/global/MailTo";
import { addPhoneMask } from "../../utils/phone";

const ViewSchool: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { schoolsById } = useAppSelector((i) => i.school);
  const { licenseById } = useAppSelector((i) => i.license);

  useEffect(() => {
    if (!id) return;
    dispatch(loadSchoolsByIdRequest(Number(id)));
    if (schoolsById) {
      dispatch(loadLicenseIdRequest(schoolsById.license));
    }
  }, [id]);

  useEffect(() => {
    if (schoolsById) {
      dispatch(loadLicenseIdRequest(schoolsById.license));
    }
  }, [schoolsById]);

  return (
    <Layout text={"Schools"}>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            School Details
          </Heading>

          <IconButton
            w="48px"
            h="48px"
            aria-label="eye"
            borderRadius="full"
            bg="white"
            border="1px solid #E2E8F0"
            icon={<Pencil size="24px" />}
            onClick={() => navigate(`/schools/create-schools/${id}`)}
          />
        </Flex>
        <Text fontWeight="400" fontSize="16px" mt="8px" color="gray.500">
          This is the school information
        </Text>
        <Grid mt="34px" gap="16px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">School Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">District</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Address</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">City</Text>
          </GridItem>
          <GridItem>
            <Text>{schoolsById?.name}</Text>
          </GridItem>
          <GridItem>
            {schoolsById?.district ? (
              <Text>{schoolsById?.district}</Text>
            ) : (
              <Text>No Data</Text>
            )}
          </GridItem>
          <GridItem>
            <Text>{schoolsById?.address}</Text>
          </GridItem>
          <GridItem>
            <Text>{schoolsById?.city}</Text>
          </GridItem>
        </Grid>

        <Grid mt="50px" gap="16px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">State</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Zip</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Phone Number</Text>
          </GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Text>{schoolsById?.state}</Text>
          </GridItem>
          <GridItem>
            <Text>{schoolsById?.zip}</Text>
          </GridItem>
          {schoolsById?.phone_number && (
            <GridItem>
              <TelTo phone={Number(schoolsById?.phone_number)}>
                <Text color="primary">
                  {addPhoneMask(schoolsById?.phone_number)}
                </Text>
              </TelTo>
            </GridItem>
          )}
          <GridItem></GridItem>
        </Grid>

        <Grid mt="50px" gap="16px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">Primary Contact Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Primary Contact Email</Text>
          </GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Text>{schoolsById?.primary_contact_name}</Text>
          </GridItem>
          <GridItem>
            <MailTo email={schoolsById?.primary_contact_email}>
              <Text color="primary">{schoolsById?.primary_contact_email}</Text>
            </MailTo>
          </GridItem>
          <GridItem></GridItem>
          <GridItem></GridItem>
        </Grid>
      </Flex>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Licenses
        </Heading>

        <Grid mt="50px" gap="16px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">Number of Licenses</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">License Starting Date</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">License Expiration Date</Text>
          </GridItem>
          <GridItem></GridItem>
          <GridItem>
            <Text>{licenseById?.licenses_number}</Text>
          </GridItem>
          <GridItem>
            <Text>
              {moment(licenseById?.license_starting_date).format(
                "MM/D/YYYY, HH:mm"
              )}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {moment(licenseById?.license_expiration_date).format(
                "MM/D/YYYY, HH:mm"
              )}
            </Text>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </Flex>
    </Layout>
  );
};

export default ViewSchool;
