import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const DistrictIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#FF7A00" fillOpacity="0.1" />
      <path
        d="M38.3336 25.3333C38.3336 33.3333 27.6669 41.3333 27.6669 41.3333C27.6669 41.3333 17.0002 33.3333 17.0002 25.3333C17.0002 22.5043 18.1241 19.7912 20.1244 17.7909C22.1248 15.7905 24.8379 14.6667 27.6669 14.6667C30.4959 14.6667 33.209 15.7905 35.2094 17.7909C37.2098 19.7912 38.3336 22.5043 38.3336 25.3333V25.3333Z"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.667 29.3333C29.8761 29.3333 31.667 27.5425 31.667 25.3333C31.667 23.1242 29.8761 21.3333 27.667 21.3333C25.4579 21.3333 23.667 23.1242 23.667 25.3333C23.667 27.5425 25.4579 29.3333 27.667 29.3333Z"
        stroke="#FF7A00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default DistrictIcon;
