import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ProgramIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#0CA299" fillOpacity="0.1" />
      <path
        d="M14.9996 16H22.9996C24.4141 16 25.7707 16.5619 26.7709 17.5621C27.7711 18.5623 28.333 19.9188 28.333 21.3333V40C28.333 38.9391 27.9115 37.9217 27.1614 37.1716C26.4112 36.4214 25.3938 36 24.333 36H14.9996V16Z"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6663 16H33.6663C32.2519 16 30.8953 16.5619 29.8951 17.5621C28.8949 18.5623 28.333 19.9188 28.333 21.3333V40C28.333 38.9391 28.7544 37.9217 29.5046 37.1716C30.2547 36.4214 31.2721 36 32.333 36H41.6663V16Z"
        stroke="#48BB78"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ProgramIcon;
