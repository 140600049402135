import { Flex, FlexProps, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";
import { donutsChartConfig } from "../../utils/charts/donutsChartConfig";
import { pieChartConfig } from "../../utils/charts/pieChartConfig";

interface ContainerChartsProps extends ComponentProps, FlexProps {
  title: string;
  series: number[] | undefined;
  labels: string[] | undefined;
  total?: number;
  type: "area" | "bar" | "donut" | "pie";
  getLabel?: (label: string) => void;
}

const ContainerCharts: React.FC<ContainerChartsProps> = ({
  title,
  series,
  type,
  labels,
  total,
  getLabel,
  ...rest
}) => {
  const [width, setWidth] = useState<number>(0);
  const chartRef = useRef(null);

  const parent = useRef<HTMLDivElement>();
  //set chart width based on parent
  const loadBounding = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      parent.current = node;
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    const listener = () => {
      if (!parent.current) return;
      setWidth(parent.current.getBoundingClientRect().width);
    };

    //ensure chart resize based on window resize
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const handleSliceClick = (label: string) => {
    getLabel && getLabel(label);
  };

  const chartOptions = {
    series,
    options:
      type === "donut"
        ? donutsChartConfig({
            chartId: `${type}-chart`,
            labels,
          })
        : pieChartConfig({
            chartId: `${type}-chart`,
            labels: labels,
            onSliceClick: handleSliceClick,
          }),
  };

  return (
    <Flex
      minW="350px"
      w="100%"
      bg="white"
      flexDir="column"
      p="24px"
      boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      borderRadius="16px"
      {...rest}
    >
      <Flex flexDir="column">
        <Text fontWeight="600">{title}</Text>
        <Text fontSize="14px" color="gray.500">
          Total {total}
        </Text>
        <Flex
          flex={1}
          ref={loadBounding}
          css={`
            .apexcharts-legend {
              padding: 0 !important;
            }
          `}
        >
          <Chart
            ref={chartRef}
            width={width}
            height="240px"
            type={type}
            series={chartOptions.series}
            options={{ ...chartOptions.options }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContainerCharts;
