import {
  Flex,
  Heading,
  Image,
  Text,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import React, { useState } from "react";
import bgImage from "../assets/image/bg-login.png";
import InputLogin from "../components/form/InputLogin";
import logo from "../assets/image/logo.png";
import { Form } from "../styles/global";
import RegularButton from "../components/global/RegularButton";
import arrowIcon from "../assets/icons/arrow-rigth.svg";
import { Mail } from "lucide-react";
import ModalLogin from "../components/modals/ModalLogin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { recoverFormSchema } from "../utils/yup-schemas";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  recoverPasswordRequest,
  recoverUsernameRequest,
} from "../stores/ducks/auth/actions";
import packageJson from "../../package.json";

const Recover: React.FC = () => {
  const dispatch = useAppDispatch();
  const { recovering } = useAppSelector((i) => i.auth);
  const [modalLoginOpen, setModalLoginOpen] = useState({ isOpen: false });
  const [radioValue, setRadioValue] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(recoverFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const submitForm = (data: any) => {
    if (radioValue === "password") {
      dispatch(recoverPasswordRequest(data));
    } else {
      dispatch(recoverUsernameRequest(data));
    }
  };

  return (
    <Flex w="full" h="100vh" justify="center" align="center">
      <Flex
        w="full"
        h="100vh"
        align="center"
        justify="center"
        bg="white"
        flexDir="column"
      >
        <Form
          onSubmit={handleSubmit(submitForm)}
          style={{
            width: "392px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Image src={logo} />
          <Heading fontSize="32px" mt="40px">
            Recover credentials
          </Heading>
          <Text color="gray.500" mt="14px" align="center">
            Put your account email below to reset your credentials
          </Text>
          <Flex flexDir="column" gap="16px" w="full" mt="40px">
            <FormControl isInvalid={!!errors.email}>
              <RadioGroup
                mb="32px"
                width="100%"
                display="flex"
                justifyContent="space-between"
                onChange={setRadioValue}
              >
                <Radio value="username" defaultValue="">
                  <Text>Recover Username</Text>
                </Radio>
                <Radio value="password" defaultValue="">
                  <Text>Recover Password</Text>
                </Radio>
              </RadioGroup>
              {radioValue !== "" && (
                <InputGroup>
                  <InputLeftElement
                    color="gray.400"
                    children={<Mail size="20" />}
                    pl="16px"
                    pt="8px"
                  />
                  <InputLogin
                    placeholder="E-mail"
                    type="email"
                    {...register("email")}
                  />
                </InputGroup>
              )}
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <RegularButton
            w="full"
            mt="32px"
            rightIcon={<Image src={arrowIcon} />}
            type="submit"
            isLoading={recovering}
            disabled={radioValue === ""}
          >
            Send e-mail
          </RegularButton>
        </Form>
        <Text mb="40px" color="gray.400" fontSize="12px">
          © 2005-2022 Failure Free Reading. All rights reserved.
        </Text>
        <Text mb="40px" color="gray.400" fontSize="12px">
          Version {packageJson.version}
        </Text>
      </Flex>
      <Flex
        w="full"
        bgImage={bgImage}
        bgRepeat="no-repeat"
        bgPosition="center"
        backgroundSize="cover"
        h="100vh"
      />
      <ModalLogin
        isOpen={modalLoginOpen.isOpen}
        onClose={() => setModalLoginOpen({ isOpen: false })}
      />
    </Flex>
  );
};

export default Recover;
