import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Check, Eye, EyeOff, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Form } from "../../styles/global";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import ModalPrintAccess from "../modals/ModalPrintAccess";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Student,
  UpdateStudent,
} from "../../@types/interfaces/api/student.interface";
import {
  StudentEditFormSchema,
  StudentFormSchema,
} from "../../utils/yup-schemas";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { loadClassroomRequest } from "../../stores/ducks/classroom/actions";
import { loadSchoolsRequest } from "../../stores/ducks/school/actions";
import moment from "moment";
import {
  deleteNoteRequest,
  loadNoteRequest,
} from "../../stores/ducks/note/actions";
import {
  loadStudentByIdRequest,
  updateStudentRequest,
} from "../../stores/ducks/student/action";
import { useNavigate, useParams } from "react-router-dom";
import InputPassword from "../form/InputPassword";

interface StudentEdit {
  studentById: Student | undefined;
}

const EditStudentForm: React.FC<StudentEdit> = ({ studentById }) => {
  const toast = useToast();

  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState({ isOpen: false });
  const userMe = useAppSelector((i) => i.auth.user);
  const dispatch = useAppDispatch();
  const { classroomList } = useAppSelector((i) => i.classroom);
  const { noteList } = useAppSelector((i) => i.note);
  const idUser = Number(id);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (studentById?.school) {
      dispatch(
        loadClassroomRequest({ school__id__exact: studentById?.school })
      );
    }
    if (id) {
      dispatch(loadNoteRequest({ user__id: Number(id) }));
      if (userMe?.user_type !== "admin") {
        setValue("school", userMe?.school);
      }
    }
  }, [id, studentById]);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateStudent>({
    defaultValues: {
      first_name: studentById?.first_name,
      last_name: studentById?.last_name,
      grade: studentById?.grade,
      gender: studentById?.gender,
      birthday: moment(studentById?.birthday).format("YYYY-MM-DD"),
      language: studentById?.language,
      classroom: studentById?.classroom,
      username: studentById?.username,
      jr_talking_book: studentById?.jr_talking_book,
      jr_matching_activities: studentById?.jr_matching_activities,
      jr_scrambled_activities: studentById?.jr_scrambled_activities,
      jr_short_answer_activities: studentById?.jr_short_answer_activities,
      jr_alphabetical_activities: studentById?.jr_alphabetical_activities,
      ls_talking_book: studentById?.ls_talking_book,
      ls_alphabetical_activities: studentById?.ls_alphabetical_activities,
      ls_matching_activities: studentById?.ls_matching_activities,
      ls_scrambled_activities: studentById?.ls_scrambled_activities,
      ls_short_answer_activities: studentById?.ls_short_answer_activities,
      vm_short_answer_activities: studentById?.vm_short_answer_activities,
      ls_activities: studentById?.ls_activities,
      password: "",
      repeat_password: "",
    },
    resolver: yupResolver(StudentEditFormSchema),
  });

  const submitForm = (values: UpdateStudent) => {
    const isAfter = moment(values.birthday).isAfter(new Date());

    const date = moment(values.birthday, "YYYY-MM-DD").format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );

    const dateFormatted: UpdateStudent = {
      ...values,
      birthday: date,
    };

    if (isAfter) {
      toast({
        title: "invalid date of birth",
        description: "The date must be less than the current one",
        status: "error",
      });
    } else {
      if (studentById) {
        dispatch(
          updateStudentRequest(studentById.user, dateFormatted, navigate)
        );
      }
    }
  };

  const handleDelete = (id: number) => {
    dispatch(deleteNoteRequest(id));
    dispatch(loadStudentByIdRequest(idUser));
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Edit Student Information
        </Heading>

        <Grid
          mt="40px"
          gridTemplateAreas={`"first-name last-name gender" 
                                "divider divider divider"
                                   "grade date language"
                                   "divider2 divider2 divider2"
                                            "class1 class2 class"`}
          gap="24px"
        >
          <GridItem area={"first-name"}>
            <InputLabel>
              First Name*
              <RegularInput mt="4px" {...register("first_name")} />
            </InputLabel>
          </GridItem>
          <GridItem area={"last-name"}>
            <InputLabel>
              Last Name*
              <RegularInput mt="4px" {...register("last_name")} />
            </InputLabel>
          </GridItem>
          <GridItem area={"gender"}>
            <InputLabel>
              Gender*
              <RegularMenu
                mt="4px"
                placeholder="Select"
                {...register("gender")}
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="none">None</option>
              </RegularMenu>
            </InputLabel>
          </GridItem>
          <GridItem area={"divider"}>
            <Divider my="12px" />
          </GridItem>
          <GridItem area={"grade"}>
            <InputLabel>
              Grade*
              <RegularMenu mt="4px" placeholder="Select" {...register("grade")}>
                <option value="k">K</option>
                <option value="1"> 1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8 </option>
                <option value="9">9 </option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12 </option>
                <option value="adult">adult </option>
              </RegularMenu>
            </InputLabel>
          </GridItem>
          <GridItem area={"date"}>
            <InputLabel>
              Date of Birth
              <RegularInput
                mt="4px"
                type="date"
                color="gray.500"
                {...register("birthday")}
              />
            </InputLabel>
          </GridItem>
          <GridItem area={"language"}>
            <InputLabel>
              Instructional Language
              <RegularMenu
                mt="4px"
                placeholder="Select"
                {...register("language")}
              >
                <option value="ENG">English</option>
                <option value="SP">Spanish</option>
              </RegularMenu>
            </InputLabel>
          </GridItem>
          {studentById?.school && (
            <>
              <GridItem area={"divider2"}>
                <Divider my="12px" />
              </GridItem>
              <GridItem area={"class1"}>
                <InputLabel>
                  Classroom*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("classroom")}
                  >
                    {classroomList?.results.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </RegularMenu>
                </InputLabel>
                {/* <InputLabel>
              Schools*
              <RegularMenu
                isDisabled={userMe?.user_type !== "admin"}
                mt="4px"
                placeholder="Select"
                {...register("school")}
              >
                {schoolsList?.results.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </RegularMenu>
            </InputLabel> */}
              </GridItem>
              <GridItem area={"class2"}></GridItem>
              <GridItem area={"class"}></GridItem>
            </>
          )}
        </Grid>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Note
        </Heading>
        <InputLabel mt="24px">
          Create a new note
          <Textarea
            mt="4px"
            placeholder="Type here..."
            _placeholder={{ color: "gray.400" }}
            {...register("note")}
          />
        </InputLabel>
        {/* comments section ---------------------------- */}
        {noteList?.map((item) => (
          <Flex align="center" gap="16px" key={item.id}>
            <Text
              bg="gray.50"
              p="16px"
              borderRadius="8px"
              fontStyle="italic"
              mt="16px"
            >
              [ {moment(item?.updated_at).format("MM-DD-yy")} ] - {item.note}
            </Text>
            <IconButton
              w="48px"
              h="48px"
              aria-label="eye"
              borderRadius="full"
              bg="white"
              mt="10px"
              border="1px solid #E2E8F0"
              icon={<Trash size="24px" />}
              onClick={() => handleDelete(item.id)}
            />
          </Flex>
        ))}

        {/* comments section ---------------------------- */}
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Selected Additional Activities
        </Heading>

        <Flex mt="34px">
          <Switch mr="24px" {...register("jr_talking_book")} />
          <Text>Joseph's Readers - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}>Talking book exercise </span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("jr_matching_activities")} />
          <Text>Joseph's Readers - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}>Matching Activities </span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("jr_scrambled_activities")} />
          <Text>Joseph's Readers - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}> Scrambled Activities</span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("jr_short_answer_activities")} />
          <Text>Joseph's Readers - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}> Short Answer Activities</span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("jr_alphabetical_activities")} />
          <Text>Joseph's Readers - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}>
            {" "}
            Alphabetical Order Activities
          </span>
        </Flex>

        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_activities")} />
          <Text>Life Skills - Activities: </Text>
          <span style={{ fontWeight: "600" }}>Life Skills Program</span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_talking_book")} />
          <Text>Life Skills - Activities: </Text>
          <span style={{ fontWeight: "600" }}>Talking book exercise </span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_matching_activities")} />
          <Text>Life Skills - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}> Matching Activities</span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_scrambled_activities")} />
          <Text>Life Skills - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}>
            {" "}
            Scrambled Sentence Activities
          </span>
        </Flex>

        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_short_answer_activities")} />
          <Text>Life Skills - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}> Short Answer Activities</span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("ls_alphabetical_activities")} />
          <Text>Life Skills - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}>
            {" "}
            Alphabetical Order Activities
          </span>
        </Flex>
        <Flex mt="34px">
          <Switch mr="24px" {...register("vm_short_answer_activities")} />
          <Text>Verbal Master - Idependent Activities: </Text>
          <span style={{ fontWeight: "600" }}> Short Answer Activities</span>
        </Flex>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Independent Student Access
        </Heading>
        <Text fontWeight="400" fontSize="16px" mt="16px" color="gray.500">
          With Independent Student Access, students can access lessons on their
          own, using an alphanumeric PIN.
        </Text>
        <Flex mt="24px" gap="24px" w="full">
          <Flex flex={1}>
            <InputLabel w="full">
              Username*
              <RegularInput
                mt="4px"
                {...register("username")}
                maxLength={Number("30")}
              />
              <Text fontWeight="400" fontSize="14px" color="gray.500">
                Space and capital letters aren't allowed
              </Text>
            </InputLabel>
          </Flex>
          <Flex flex={1}>
            <InputLabel w="full">
              PIN code*
              <InputPassword
                autoComplete="new-password"
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("password")}
                maxLength={Number("30")}
              />
            </InputLabel>
          </Flex>
          <Flex flex={1}>
            <InputLabel w="full">
              Repeat PIN code*
              <InputPassword
                autoComplete="new-password"
                mt="8px"
                icon={showPassword ? <Eye size="20" /> : <EyeOff size="20" />}
                clickShow={() => setShowPassword(!showPassword)}
                type={showPassword ? "text" : "password"}
                {...register("repeat_password")}
                maxLength={Number("30")}
              />
            </InputLabel>
          </Flex>
        </Flex>
      </Flex>
      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        {/* <OutlineButton>Reset</OutlineButton>{" "} */}
        <RegularButton
          // onClick={() => setModalOpen({ isOpen: true })}
          type="submit"
        >
          Save
        </RegularButton>
      </Flex>
      <ModalPrintAccess
        isOpen={modalOpen.isOpen}
        onClose={() => setModalOpen({ isOpen: false })}
      />
    </Form>
  );
};

export default EditStudentForm;
