import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ItemsBrDiagnostic } from "../../@types/interfaces/api/buildReport.interface";
import GradeLevels from "../../components/build_report_components/GradeLevels";
import ItemDiagnosticReport from "../../components/build_report_components/ItemDiagnosticReport";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadReportDiagnosticRequest,
} from "../../stores/ducks/buildReport/actions";

const DiagnosticPrecriptiveReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { diagnosticReportData, isLoading } = useAppSelector(
    (d) => d.buildReport
  );
  const { user } = useAppSelector((d) => d.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ids = searchParams.get("students");
  const schools = searchParams.get("schools");
  const currentYear = searchParams.get("year");

  useEffect(() => {
    if (ids && currentYear && schools && user?.user_type === "admin") {
      dispatch(
        loadReportDiagnosticRequest({
          students: ids,
          school_ids: schools,
          year: currentYear,
        })
      );
    }

    if (ids && currentYear && user?.user_type != "admin") {
      dispatch(
        loadReportDiagnosticRequest({ students: ids, year: currentYear })
      );
    }

    return () => {
      dispatch(clearReportList());
    };
  }, [ids, schools, currentYear]);

  const currentGrade = (data: ItemsBrDiagnostic[]) => {
    if (data.length !== 0) {
      return data.slice(-1)[0].grade;
    }
  };
  const lastLevel = (data: ItemsBrDiagnostic[]) => {
    if (data.length !== 0) {
      return data.slice(-1)[0].level;
    }
  };
  const firstLevel = (data: ItemsBrDiagnostic[]) => {
    if (data.length !== 0) {
      return data[0].level;
    }
  };

  if (isLoading) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  return (
    <LayoutBuildReport
      text="Student Diagnostic Prescriptive Report"
      date={diagnosticReportData?.report_date ?? String(new Date())}
    >
      <SectionWhite mb="24px">
        <Text fontSize="14px" fontWeight="500" color="gray.500">
          DP = Diagnostic Prescriptive
        </Text>
        <Text fontSize="14px" fontWeight="500" color="gray.500">
          JR = Joseph's Readers
        </Text>
        <Text fontSize="14px" fontWeight="500" color="gray.500">
          VM = Verbal Master
        </Text>
        <Text fontSize="14px" fontWeight="500" color="gray.500">
          LS = Life Skills
        </Text>
      </SectionWhite>
      {diagnosticReportData &&
        diagnosticReportData.items.map((diag, i) => (
          <SectionWhite mb="40px" gap="40px" key={i}>
            <Flex flexDir="column">
              <Text fontSize="18px" fontWeight="600">
                Teacher Details
              </Text>
              <Grid mt="24px" gap="16px" templateColumns="repeat(6, 1fr)">
                <GridItem>
                  <Text fontWeight="600">Student Name</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">Teacher Name</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">Classroom</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">School</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600">District</Text>
                </GridItem>
                <GridItem></GridItem>
                <GridItem>
                  <Text>{diag?.report_details.student ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{diag?.report_details.teacher ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{diag?.report_details.classroom ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{diag?.report_details.school ?? "No Data"}</Text>
                </GridItem>
                <GridItem>
                  <Text>{diag?.report_details.district ?? "No Data"}</Text>
                </GridItem>
                <GridItem></GridItem>
              </Grid>
            </Flex>
            {diag?.data.items.length === 0 ? (
              <Text textAlign="center" my="40px">
                No Data
              </Text>
            ) : (
              <>
                {diag && diag?.data.items.length > 1 && (
                  <Flex flexDir="column" mt="24px">
                    <Text fontSize="18px" fontWeight="600">
                      What does this mean?
                    </Text>
                    <Text fontSize="14px" fontWeight="500" color="gray.500">
                      During the Initial Test, {diag?.report_details.student}{" "}
                      who is at a grade {currentGrade(diag.data.items)}{" "}
                      instructional level has tested at a frustration level of
                      the ({firstLevel(diag.data.items)}) in the Failure Free
                      Reading Program. After the PMD test,{" "}
                      {diag?.report_details.student} tested at a reading
                      comprehension frustration grade level equivalence of the (
                      {lastLevel(diag.data.items)}). This means that{" "}
                      {diag?.report_details.student} moved up{" "}
                      {diag?.data.levels_increased} grade level(s) in reading
                      comprehension and {diag?.data.levels_increased} level(s)
                      in the Failure Free Reading materials.
                    </Text>
                  </Flex>
                )}

                {diag && diag?.data.levels_increased > 0 && (
                  <Flex flexDir="column" mt="24px" w="fit-content">
                    <Text fontSize="18px" fontWeight="600">
                      {diag?.report_details.student} has increased{" "}
                      {diag?.data.levels_increased} grade level(s)!
                    </Text>
                  </Flex>
                )}

                <GradeLevels />

                <Flex flexDir="column" mt="24px">
                  <Text fontSize="18px" fontWeight="600">
                    Current Student Grade: {currentGrade(diag.data.items)}
                  </Text>
                  {diag && diag?.data.items.length > 1 && (
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      color="gray.500"
                      mt="12px"
                    >
                      {diag?.report_details.student} has increased{" "}
                      {diag?.data.levels_increased} grade level(s)!
                    </Text>
                  )}

                  <TableContainer
                    border="1px solid #E2E8F0"
                    borderRadius="16px"
                    mt="40px"
                    boxShadow=" 0px 2px 10px 0px rgba(17, 20, 61, 0.06)"
                  >
                    <Table variant="striped" colorScheme="tableScheme">
                      <Thead>
                        <Tr>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                          >
                            Test Date
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            fontSize="16px"
                            textTransform="unset"
                          >
                            Grade
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            whiteSpace="pre-wrap"
                            maxW="150px"
                          >
                            Frustration/Challenge Level
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            1.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            1.5
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            2.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            3.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            4.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            4.5
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            5.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            6.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            8.0
                          </Th>

                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            10.0
                          </Th>
                          <Th
                            py="16px"
                            px="16px"
                            color="blue.600"
                            fontWeight="600"
                            textTransform="unset"
                            fontSize="16px"
                            bg="blue.200"
                          >
                            12.0
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {diag?.data.items.map((item, index) => (
                          <ItemDiagnosticReport data={item} key={index} />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </>
            )}
          </SectionWhite>
        ))}
    </LayoutBuildReport>
  );
};

export default DiagnosticPrecriptiveReport;
