import { combineReducers } from "redux";

import auth from "./auth";
import district from "./district";
import school from "./school";
import license from "./license";
import classroom from "./classroom";
import profile from "./profile";
import tutorial from "./tutorial";
import library from "./library";
import student from "./student";
import note from "./note";
import core from "./core";
import dashboard from "./dashboard";
import bookMark from "./bookmark";
import buildReport from "./buildReport";
import certificate from "./certificate";
import filters from "./filters";

export default combineReducers({
  auth,
  district,
  school,
  license,
  classroom,
  profile,
  tutorial,
  library,
  student,
  note,
  core,
  dashboard,
  bookMark,
  buildReport,
  certificate,
  filters,
});
