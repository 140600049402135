import React from "react";
import { Route, Routes } from "react-router-dom";
import CreatePrincipal from "../pages/principals/CreatePrincipal";
import Principals from "../pages/principals/Principals";
import ViewPrincipal from "../pages/principals/ViewPrincipal";

const PrincipalsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Principals />} />
      <Route path="/create-principal" element={<CreatePrincipal />}>
        <Route path=":id" element={<CreatePrincipal />} />
        <Route path="" element={<CreatePrincipal />} />
      </Route>
      <Route path="/view-principal" element={<ViewPrincipal />}>
        <Route path=":id" element={<ViewPrincipal />} />
        <Route path="" element={<ViewPrincipal />} />
      </Route>
    </Routes>
  );
};

export default PrincipalsRoutes;
