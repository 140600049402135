import {
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  MenuButton,
  MenuList,
  Menu,
  IconButton,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import React from "react";
import { Plus, Search } from "lucide-react";
import RegularInput from "../form/RegularInput";
import MenuItemDrop from "../global/MenuFilterDrop";
import RegularButton from "../global/RegularButton";
import { Import, UserPlus, MoreVertical } from "lucide-react";
import MenuListItem from "../global/MenuListItem";
import { useNavigate } from "react-router-dom";
import MenuFilterDrop from "../global/MenuFilterDrop";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { loadTutorialRequest } from "../../stores/ducks/tutorial/actions";

const LayoutToolsTutorials: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((i) => i.auth);

  const { setData } = useTypeDelay(
    (s) => {
      dispatch(loadTutorialRequest({ search: s }));
    },
    () => {
      dispatch(loadTutorialRequest());
    }
  );
  return (
    <Flex w="full" justify="space-between">
      <InputGroup as={Flex} maxW="40% ">
        <InputLeftElement
          mt="4px"
          ml="12px"
          children={<Search size="24" color="#A0AEC0" />}
        />
        <RegularInput
          placeholder="Search "
          pl="52px"
          onChange={(e) => setData(e.target.value)}
        />
      </InputGroup>
      <Flex gap="16px" align="center">
        {user?.user_type === "admin" && (
          <RegularButton
            leftIcon={<Plus size="18px" />}
            onClick={() => navigate("/tutorials/create-tutorials")}
          >
            Create New
          </RegularButton>
        )}
      </Flex>
    </Flex>
  );
};

export default LayoutToolsTutorials;
