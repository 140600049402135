import { Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Cardcertificate from "../../components/certificates_components/Cardcertificate";
import InputLabel from "../../components/form/InputLabel";
import RegularMenu from "../../components/form/RegularMenu";
import Layout from "../../components/global/Layout";
import certRed from "../../../src/assets/image/certificates/c-red.png";
import certBlue from "../../../src/assets/image/certificates/c-blue.png";
import certGreen from "../../../src/assets/image/certificates/c-green.png";
import certMaroon from "../../../src/assets/image/certificates/c-maroon.png";
import certOrange from "../../../src/assets/image/certificates/c-orange.png";
import certPurple from "../../../src/assets/image/certificates/c-purple.png";
import certTeal from "../../../src/assets/image/certificates/c-teal.png";
import certVm from "../../../src/assets/image/certificates/c-vm.png";
import certYellow from "../../../src/assets/image/certificates/c-yellow.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { loadCertificateRequest } from "../../stores/ducks/certificate/actions";
const allCertificates = [
  {
    title: "First Lesson",
    level_name: "red",
    level: 1,
    url: "#",
    colorText: "#FF1313",
    urlImage: certRed,
  },
  {
    title: "second Lesson",
    level_name: "green",
    level: 2,
    url: "#",
    colorText: "#3BB800",
    urlImage: certGreen,
  },
  {
    title: "3 Lesson",
    level_name: "orange",
    level: 3,
    url: "#",
    colorText: "#FF6B00",
    urlImage: certOrange,
  },
  {
    title: "4 Lesson",
    level_name: "teal",
    level: 4,
    url: "#",
    colorText: "#08D6BD",
    urlImage: certTeal,
  },
  {
    title: "5 Lesson",
    level_name: "blue",
    level: 5,
    url: "#",
    colorText: "#4D89FF",
    urlImage: certBlue,
  },
  // {
  //   title: "6 Lesson",
  //   level_name: "purple",
  //   level: 6,
  //   url: "#",
  //   colorText: "#CC00FF",
  //   urlImage: certPurple,
  // },
  {
    title: "7 Lesson",
    level_name: "maroon",
    level: 7,
    url: "#",
    colorText: "#7A3C18",
    urlImage: certMaroon,
  },
  {
    title: "8 Lesson",
    level_name: "yellow",
    level: 8,
    url: "#",
    colorText: "#F1A533",
    urlImage: certYellow,
  },
  {
    title: "9 Lesson",
    level_name: "Verbal Master ",
    level: 9,
    url: "#",
    colorText: "#7EC8E3",
    urlImage: certVm,
  },
];

interface CertificateProps {
  title: string;
  level: number;
  url: string;
  urlImage: string;
  level_name: string;
}

const Certificates: React.FC = () => {
  const dispatch = useAppDispatch();
  const { certificate } = useAppSelector((c) => c.certificate);

  return (
    <Layout text="Certificates">
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Failure Free Certificates
        </Heading>
        <Text fontWeight="400" fontSize="16px" color="gray.500" mt="16px">
          Select the level to download the certificate
        </Text>
        <Flex w="25%">
          <InputLabel mt="32px">
            Level Color*
            <RegularMenu
              mt="4px"
              placeholder="Select"
              w="300px"
              onChange={(e) =>
                dispatch(
                  loadCertificateRequest({ level__id: Number(e.target.value) })
                )
              }
              textTransform="capitalize"
            >
              {allCertificates.map((i) => (
                <option
                  key={i.level}
                  value={i.level}
                  style={{ color: i.colorText }}
                >
                  {i.level_name} Level
                </option>
              ))}
            </RegularMenu>
          </InputLabel>
        </Flex>
      </Flex>
      <Flex flexDir="column" mt="24px">
        <Heading fontWeight="600" fontSize="18px">
          Level Certificates
        </Heading>
        <Flex gap="24px" flexWrap="wrap">
          {certificate?.results.map((i) => (
            <Cardcertificate data={i} key={i.level} />
          ))}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Certificates;
