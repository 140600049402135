import {DistrictActionTypes} from "./types";
import {NavigateFunction} from "react-router-dom";
import DistrictsFilterInterface from "../../../@types/interfaces/api/filters/district.filter.interface";
import {
    District,
    DistrictUpdateCreate,
} from "../../../@types/interfaces/api/district.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import DistrictBulkUpdateInterface from "../../../@types/interfaces/api/district-bulk-update.interface";
import {clearPhoneMask} from "../../../utils/phone";

export const loadDistrictsRequest = (filters?: DistrictsFilterInterface) => ({
    type: DistrictActionTypes.LOAD_DISTRICTS_REQUEST,
    payload: filters,
});

export const loadDistrictsSuccess = (Districts: PaginationBase<District>) => ({
    type: DistrictActionTypes.LOAD_DISTRICTS_SUCCESS,
    payload: Districts,
});
export const loadDistrictsErrorr = () => ({
    type: DistrictActionTypes.LOAD_DISTRICTS_ERROR,
});

export const loadDistrictByIdRequest = (id: number | undefined) => ({
    type: DistrictActionTypes.LOAD_DISTRICT_BY_ID_REQUEST,
    payload: id,
});

export const loadDistrictByIdSuccess = (District: District | undefined) => ({
    type: DistrictActionTypes.LOAD_DISTRICT_BY_ID_SUCCESS,
    payload: District,
});
export const loadDistrictByIdError = () => ({
    type: DistrictActionTypes.LOAD_DISTRICT_BY_ID_ERROR,
});

export const updateDistrictRequest = (
    id: number,
    values: DistrictUpdateCreate
) => {
    return {
        type: DistrictActionTypes.UPDATE_DISTRICT_REQUEST,
        payload: {id, values: {...values, phone_number: clearPhoneMask(values.phone_number)}},
    };
};

export const updateDistrictSuccess = () => {
    return {
        type: DistrictActionTypes.UPDATE_DISTRICT_SUCCESS,
    };
};
export const updateDistrictError = () => {
    return {
        type: DistrictActionTypes.UPDATE_DISTRICT_ERROR,
    };
};

export const registerDistrictRequest = (
    values: DistrictUpdateCreate,
    navigate: NavigateFunction
) => {
    return {
        type: DistrictActionTypes.DISTRICT_REGISTER_REQUEST,
        payload: {values: {...values, phone_number: clearPhoneMask(values.phone_number)}, navigate},
    };
};

export const registerDistrictSuccess = () => {
    return {
        type: DistrictActionTypes.DISTRICT_REGISTER_SUCCESS,
    };
};
export const registerDistrictError = () => {
    return {
        type: DistrictActionTypes.DISTRICT_REGISTER_ERROR,
    };
};

export const deleteDistrictRequest = (id: number) => {
    return {
        type: DistrictActionTypes.DELETE_DISTRICTS_REQUEST,
        payload: id,
    };
};

export const deleteDistrictSuccess = () => {
    return {
        type: DistrictActionTypes.DELETE_DISTRICTS_SUCCESS,
    };
};
export const deleteDistrictError = () => {
    return {
        type: DistrictActionTypes.DELETE_DISTRICTS_ERROR,
    };
};

export const bulkUpdateDistrictRequest = (
    ids: number[],
    data: DistrictBulkUpdateInterface
) => ({
    type: DistrictActionTypes.BULK_UPDATE_DISTRICT_REQUEST,
    payload: {data, ids},
});

export const clearDistrictList = () => {
    return {
        type: DistrictActionTypes.CLEAR_DISTRICT_LIST,
    };
};
