import {
  Flex,
  IconButton,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { MoreVertical, Plus, Search } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../@types/interfaces/api/user.interface";
import UsersBulkUpdateInterface from "../../@types/interfaces/api/users-bulk-update.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import {
  bulkUpdateUsersRequest,
  loadUsersRequest,
} from "../../stores/ducks/auth/actions";
import { setFilters } from "../../stores/ducks/filters/actions";
import RegularInput from "../form/RegularInput";
import MenuFilterActive from "../global/MenuFilterActive";
import MenuListItem from "../global/MenuListItem";
import RegularButton from "../global/RegularButton";

interface LayoutTollsTeachersProps {
  selectedItems: User[];
  onClickActions?: () => void;
}

const LayoutTollsTeachers: React.FC<LayoutTollsTeachersProps> = ({
  selectedItems,
  onClickActions = () => null,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedIds = selectedItems.map((d) => d.id);
  const { user } = useAppSelector((i) => i.auth);
  const [active, setActive] = useState<boolean | null>(null);

  const onClickAction = (data: UsersBulkUpdateInterface) => {
    dispatch(bulkUpdateUsersRequest(selectedIds, data, "teacher"));
    onClickActions();
  };

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(setFilters({ search: s, isActiveFilter: active }));

      dispatch(
        loadUsersRequest({
          user_type__in: "teacher,principal",
          search: s,
          is_active__exact: active,
        })
      );
    },
    () => {
      dispatch(setFilters({ search: "", isActiveFilter: active }));

      dispatch(loadUsersRequest({ user_type__in: "teacher" }));
    }
  );

  const filterActive = (e: any) => {
    if (e.target.value === "active") {
      setActive(true);
      dispatch(setFilters({ search: data, isActiveFilter: true }));
      dispatch(
        loadUsersRequest({
          is_active__exact: true,
          user_type__in: "teacher",
          search: data,
        })
      );
    } else if (e.target.value === "inactive") {
      setActive(false);
      dispatch(setFilters({ search: data, isActiveFilter: false }));
      dispatch(
        loadUsersRequest({
          is_active__exact: false,
          user_type__in: "teacher",
          search: data,
        })
      );
    } else {
      setActive(null);
      dispatch(setFilters({ search: data, isActiveFilter: null }));
      dispatch(
        loadUsersRequest({
          is_active__exact: null,
          user_type__in: "teacher",
          search: data,
        })
      );
    }
  };

  return (
    <Flex w="full" justify="space-between">
      <Flex w="full" gap="16px">
        <InputGroup as={Flex} maxW="40% ">
          <InputLeftElement
            mt="4px"
            ml="12px"
            children={<Search size="24" color="#A0AEC0" />}
          />
          <RegularInput
            placeholder="Search "
            pl="52px"
            onChange={(e: any) => setData(e.target.value)}
          />
        </InputGroup>
        <MenuFilterActive
          handleClick={filterActive}
          namePage="Teacher"
          active={active}
        />
      </Flex>
      <Flex gap="16px" align="center">
        <RegularButton
          leftIcon={<Plus size="18px" />}
          onClick={() => navigate("/teachers/create-teachers")}
        >
          Create New Teachers
        </RegularButton>
        <Menu>
          <MenuButton
            as={IconButton}
            borderRadius="50%"
            w="48px"
            h="48px"
            fontSize="24px"
            variant="outline"
            icon={<MoreVertical fontSize="24px" />}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <MenuListItem
              onClick={() => onClickAction({ is_active: true })}
              color="green"
            >
              Activate Teachers
            </MenuListItem>
            <MenuListItem
              borderTop="1px solid #E2E5EE"
              onClick={() => onClickAction({ is_active: false })}
              color="red"
            >
              Inactivate Teachers
            </MenuListItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default LayoutTollsTeachers;
