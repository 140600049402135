import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import DistrictsFilterInterface from "../../../@types/interfaces/api/filters/district.filter.interface";
import {
  District,
  DistrictUpdateCreate,
} from "../../../@types/interfaces/api/district.interface";
import {
  deleteDistrictError,
  deleteDistrictSuccess,
  loadDistrictByIdError,
  loadDistrictByIdSuccess,
  loadDistrictsErrorr,
  loadDistrictsRequest,
  loadDistrictsSuccess,
  registerDistrictError,
  registerDistrictSuccess,
  updateDistrictError,
  updateDistrictSuccess,
} from "./actions";
import { NavigateFunction } from "react-router-dom";
import DistrictBulkUpdateInterface from "../../../@types/interfaces/api/district-bulk-update.interface";
import store from "..";

const { toast } = createStandaloneToast({ theme });

export function* loadAllDistricts(action: AnyAction) {
  const filters: DistrictsFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<District>> = yield call(
      api.get,
      "/district",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadDistrictsSuccess(data));
    }
  } catch (error: any) {
    yield put(loadDistrictsErrorr());
  }
}

export function* loadDistrictById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse<District> = yield call(
      api.get,
      `/district/${id}`
    );
    const { data, status } = response;
    if (status === 200) {
      yield put(loadDistrictByIdSuccess(data));
    }
  } catch (e: any) {
    yield put(loadDistrictByIdError());
  }
}

export function* updateDistrict(action: AnyAction) {
  const {
    id,
    values,
  }: {
    id: number;
    values: DistrictUpdateCreate;
  } = action.payload;

  const { isActiveFilter, page, search } = store.getState().filters;

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/district/${id}`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateDistrictSuccess());
      yield put(
        loadDistrictsRequest({ page, search, is_active: isActiveFilter })
      );

      toast({
        title: "Success",
        description: "District successfully updated",
        status: "success",
      });
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(updateDistrictError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* registerDistrict(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: DistrictUpdateCreate; navigate: NavigateFunction } =
    action.payload;
  try {
    const response: AxiosResponse = yield call(api.post, `/district`, values);
    const { status, data } = response;
    if (status === 201) {
      yield put(registerDistrictSuccess());
      navigate("/districts");
      toast({
        title: "Success",
        description: "District successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerDistrictError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* deleteDistrict(action: AnyAction) {
  const id: number = action.payload;
  try {
    const response: AxiosResponse = yield call(api.delete, `/district/${id}`);
    const { status, data } = response;
    if (status === 204) {
      yield put(deleteDistrictSuccess());
      yield put(loadDistrictsRequest({}));

      toast({
        title: "Success",
        description: "District successfully deleted",
        status: "success",
      });
    }
  } catch (error: any) {
    const errors = error.response.data;
    yield put(deleteDistrictError());
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* bulkUpdateDistrict(action: AnyAction) {
  const ids: string[] = action.payload.ids;
  const data: DistrictBulkUpdateInterface[] = action.payload.data;

  const requestData = ids.map((id) => ({
    id,
    ...data,
  }));

  try {
    const response: AxiosResponse<District> = yield call(
      api.patch,
      `/district/bulk`,
      requestData
    );
    const { status } = response;
    if (status === 200) {
      yield put(loadDistrictsRequest({}));

      toast({
        title: "Success",
        description: "School successfully updated",
        status: "success",
      });
    }
  } catch (e: any) {
    toast({
      title: "Erro",
      description: e.message,
      status: "error",
    });
  }
}
