import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
import store, { ApplicationState, getGlobalState } from "..";
import SchoolFilterInterface from "../../../@types/interfaces/api/filters/school.filter.interface";
import { License } from "../../../@types/interfaces/api/license.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import SchoolBulkUpdateInterface from "../../../@types/interfaces/api/school-bulk-update.interface";
import { School } from "../../../@types/interfaces/api/school.interface";
import {
  SchoolCreate,
  SchoolStatus,
} from "../../../@types/interfaces/app/forms/create-school.interface";
import api from "../../../services/api";
import theme from "../../../styles/theme";
import { createLicense, updateLicense } from "../license/sagas";

import {
  loadSchoolsByIdError,
  loadSchoolsByIdSuccess,
  loadSchoolsError,
  loadSchoolsReportSuccess,
  loadSchoolsRequest,
  loadSchoolsSuccess,
  registerSchoolsError,
  registerSchoolsSuccess,
  updateSchoolsError,
  updateSchoolsSuccess,
  updateStatusSchoolsError,
  updateStatusSchoolsSuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadSchools(action: AnyAction) {
  const filters: SchoolFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<School>> = yield call(
      api.get,
      "/school",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadSchoolsSuccess(data));
    }
  } catch (error) {
    yield loadSchoolsError();
  }
}

export function* loadSchoolsReport(action: AnyAction) {
  const filters: SchoolFilterInterface = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<School>> = yield call(
      api.get,
      "/school",
      {
        params: filters,
      }
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(loadSchoolsReportSuccess(data));
    }
  } catch (error) {
    yield loadSchoolsError();
  }
}

export function* loadSchoolsById(action: AnyAction) {
  const id: number = action.payload;

  try {
    const response: AxiosResponse = yield call(api.get, `/school/${id}`);
    const { status, data } = response;
    if (status === 200) {
      yield put(loadSchoolsByIdSuccess(data));
    }
  } catch (error) {
    yield loadSchoolsByIdError();
  }
}

export function* registerSchool(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: SchoolCreate; navigate: NavigateFunction } = action.payload;
  try {
    const licenseRequest: License = yield call(createLicense, values.license);

    const response: AxiosResponse = yield call(api.post, `/school`, {
      ...values,
      license: licenseRequest.id,
    });
    const { status, data } = response;
    if (status === 201) {
      yield put(registerSchoolsSuccess());
      navigate("/schools");
      toast({
        title: "Success",
        description: "School successfully registered",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(registerSchoolsError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* updateSchool(action: AnyAction) {
  const {
    values,
    navigate,
  }: { values: SchoolCreate; navigate: NavigateFunction } = action.payload;
  const {
    license: { licenseById },
    school: { schoolsById },
  }: ApplicationState = yield select(getGlobalState);
  try {
    yield call(updateLicense, values.license, licenseById?.id as number);

    const { license, ...rest } = values;

    const response: AxiosResponse = yield call(
      api.patch,
      `/school/${schoolsById?.id}`,
      rest
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateSchoolsSuccess());
      navigate("/schools");
      toast({
        title: "Success",
        description: "School successfully updated",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(updateSchoolsError());
    toast({
      title: "Erro",
      description: error.message,
      status: "error",
    });
  }
}

export function* updateStatusSchool(action: AnyAction) {
  const { values, id }: { values: SchoolStatus; id: number } = action.payload;
  const { isActiveFilter, page, search } = store.getState().filters;

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/school/${id}`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateStatusSchoolsSuccess());
      yield put(
        loadSchoolsRequest({ page, search, is_active__exact: isActiveFilter })
      );
      toast({
        title: "Success",
        description: "School successfully updated",
        status: "success",
      });
    }
  } catch (error: any) {
    yield put(updateStatusSchoolsError());
    toast({
      title: "Erro",
      description: error.message,
      status: "error",
    });
  }
}

export function* bulkUpdateSchool(action: AnyAction) {
  const ids: string[] = action.payload.ids;
  const data: SchoolBulkUpdateInterface[] = action.payload.data;

  const requestData = ids.map((id) => ({
    id,
    ...data,
  }));

  try {
    const response: AxiosResponse<School> = yield call(
      api.patch,
      `/school/bulk`,
      requestData
    );
    const { status } = response;
    if (status === 200) {
      yield put(loadSchoolsRequest({}));
      toast({
        title: "Success",
        description: "School successfully updated",
        status: "success",
      });
    }
  } catch (e: any) {
    console.log(e.message);
    toast({
      title: "Erro",
      description: e.message,
      status: "error",
    });
  }
}
