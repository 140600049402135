import {
  TutorialData,
  TutorialDataCreate,
} from "../../../@types/interfaces/api/tutorial-data.interface";
import TutorialFilterInterface from "../../../@types/interfaces/api/filters/tutorial.filter.interface";
import { NavigateFunction } from "react-router-dom";
import NoteFilterInterface from "../../../@types/interfaces/api/filters/note.filter.interface";
import { NoteActionTypes } from "./types";
import {
  CreateNote,
  Note,
} from "../../../@types/interfaces/api/note.interface";

export const loadNoteRequest = (filters?: NoteFilterInterface) => ({
  type: NoteActionTypes.LOAD_NOTE_REQUEST,
  payload: filters,
});

export const loadNoteSuccess = (notes: Note[] | undefined) => ({
  type: NoteActionTypes.LOAD_NOTE_SUCCESS,
  payload: notes,
});
export const loadNoteErrorr = () => ({
  type: NoteActionTypes.LOAD_NOTE_ERROR,
});

export const createNoteRequest = (values: string, id: number) => {
  return {
    type: NoteActionTypes.CREATE_NOTE_REQUEST,
    payload: { values, id },
  };
};

export const createNoteSuccess = () => {
  return {
    type: NoteActionTypes.CREATE_NOTE_SUCCESS,
  };
};
export const createNoteError = () => {
  return {
    type: NoteActionTypes.CREATE_NOTE_ERROR,
  };
};

export const deleteNoteRequest = (id: number) => ({
  type: NoteActionTypes.DELETE_NOTE_REQUEST,
  payload: id,
});

export const deleteNoteSuccess = () => ({
  type: NoteActionTypes.DELETE_NOTE_SUCCESS,
});
export const deleteNoteErrorr = () => ({
  type: NoteActionTypes.DELETE_NOTE_ERROR,
});
