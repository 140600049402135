import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import movie from "../../assets/videos/videoplayback.mp4";

interface ModalVideoProps {
  onClose(): void;
  isOpen: { isOpen: boolean; urlVideo: string; nameVideo: string };
}

const ModalVideo: React.FC<ModalVideoProps> = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen.isOpen} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent h="615px" borderRadius="16px" bg="black">
        <Flex textAlign="center" mt="16px">
          <Text
            position="absolute"
            top={5}
            left={4}
            color="#ffff"
            bg="#0000006a"
            p="6px 16px"
            borderRadius="50px"
          >
            {isOpen.nameVideo}
          </Text>
          <video width="100%" controls autoPlay>
            <source src={isOpen.urlVideo} type="video/mp4" />
          </video>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ModalVideo;
