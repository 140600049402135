import { Checkbox, Flex, Switch, Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../@types/interfaces/api/user.interface";
import BadgeStatus from "../global/BadgeStatus";
import EditButton from "../global/circle_button/EditButton";
import TrashButton from "../global/circle_button/TrashButton";
import ViewButton from "../global/circle_button/ViewButton";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateUserRequest } from "../../stores/ducks/auth/actions";
import MailTo from "../global/MailTo";

interface ItemAdminTableProps {
  user: User;
  isSelected: boolean;
  onClickCheckbox: () => void;
}

const ItemAdminTable: React.FC<ItemAdminTableProps> = ({
  user: { username, last_login, first_name, email, is_active, id },
  isSelected,
  onClickCheckbox,
  user,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userMe = useAppSelector((i) => i.auth.user);
  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        {userMe?.id !== id && (
          <Checkbox
            isChecked={userMe?.id !== id && isSelected}
            onChange={onClickCheckbox}
            colorScheme="tagScheme"
          />
        )}
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{first_name}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <MailTo email={email}>
          <Text color="primary">{email}</Text>
        </MailTo>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{username}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>
          {last_login
            ? moment(last_login).format("MM/DD/YYYY, HH:mm")
            : "No data"}
        </Text>
      </Td>
      <Td
        py="16px"
        pl="0"
        pr="50px"
        fontWeight="400"
        color="gray.700"
        w="120px"
      >
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={is_active ? "active" : "inactive"} />
          {userMe?.id !== id && (
            <Switch
              size="sm"
              colorScheme="tagScheme"
              ml="6px"
              isChecked={is_active}
              onChange={(e: any) =>
                dispatch(
                  updateUserRequest(
                    id,
                    {
                      ...user,
                      user_type: "admin",
                      is_active: e.target.checked,
                    },
                    "admin"
                  )
                )
              }
            />
          )}
        </Flex>
      </Td>

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <ViewButton onClick={() => navigate(`/admin/view-admin/${id}`)} />
        <EditButton
          ml="4px"
          onClick={() => navigate(`/admin/create-admin/${id}`)}
        />
      </Td>
    </Tr>
  );
};

export default ItemAdminTable;
