import {
  FilterActivity,
  FilterLesson,
  FilterLevel,
  FilterStory,
  IActivity,
  ILesson,
  ILevel,
  IProgram,
  IStory,
} from "../../../@types/interfaces/api/bookMark.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { BookMarkActionTypes } from "./types";

export const loadProgramStudentRequest = (id: number) => {
  return {
    type: BookMarkActionTypes.LOAD_PROGRAM_REQUEST,
    payload: id,
  };
};
export const loadProgramStudentSuccess = (values: PaginationBase<IProgram>) => {
  return {
    type: BookMarkActionTypes.LOAD_PROGRAM_SUCCESS,
    payload: values,
  };
};
export const loadProgramStudentError = () => {
  return {
    type: BookMarkActionTypes.LOAD_PROGRAM_ERROR,
  };
};

export const loadLevelStudentRequest = (filter: FilterLevel) => {
  return {
    type: BookMarkActionTypes.LOAD_LEVEL_REQUEST,
    payload: filter,
  };
};
export const loadLevelStudentSuccess = (values: PaginationBase<ILevel>) => {
  return {
    type: BookMarkActionTypes.LOAD_LEVEL_SUCCESS,
    payload: values,
  };
};
export const loadLevelStudentError = () => {
  return {
    type: BookMarkActionTypes.LOAD_LEVEL_ERROR,
  };
};

export const loadStoryStudentRequest = (filter: FilterStory) => {
  return {
    type: BookMarkActionTypes.LOAD_STORY_REQUEST,
    payload: filter,
  };
};
export const loadStoryStudentSuccess = (values: PaginationBase<IStory>) => {
  return {
    type: BookMarkActionTypes.LOAD_STORY_SUCCESS,
    payload: values,
  };
};
export const loadStoryStudentError = () => {
  return {
    type: BookMarkActionTypes.LOAD_STORY_ERROR,
  };
};

export const loadLessonStudentRequest = (filter: FilterLesson) => {
  return {
    type: BookMarkActionTypes.LOAD_LESSON_REQUEST,
    payload: filter,
  };
};
export const loadLessonStudentSuccess = (values: PaginationBase<ILesson>) => {
  return {
    type: BookMarkActionTypes.LOAD_LESSON_SUCCESS,
    payload: values,
  };
};
export const loadLessonStudentError = () => {
  return {
    type: BookMarkActionTypes.LOAD_LESSON_ERROR,
  };
};

export const loadActivityStudentRequest = (filter: FilterActivity) => {
  return {
    type: BookMarkActionTypes.LOAD_ACTIVITY_REQUEST,
    payload: filter,
  };
};
export const loadActivityStudentSuccess = (values: IActivity) => {
  return {
    type: BookMarkActionTypes.LOAD_ACTIVITY_SUCCESS,
    payload: values,
  };
};
export const loadActivityStudentError = () => {
  return {
    type: BookMarkActionTypes.LOAD_ACTIVITY_ERROR,
  };
};

export const postProgressRequest = (
  idStudent: number,
  idActivity: number | null
) => {
  return {
    type: BookMarkActionTypes.POST_PROGRESS_REQUEST,
    payload: { idStudent, idActivity },
  };
};
export const postProgressSuccess = () => {
  return {
    type: BookMarkActionTypes.POST_PROGRESS_SUCCESS,
  };
};
export const postProgressError = () => {
  return {
    type: BookMarkActionTypes.POST_PROGRESS_ERROR,
  };
};

export const clearBookMark = () => {
  return {
    type: BookMarkActionTypes.CLEAR_BOOKMARK,
  };
};
