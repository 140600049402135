import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/global/Layout";
import {
  Circle,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import RegularInput from "../../components/form/RegularInput";
import InputLabel from "../../components/form/InputLabel";
import RegularMenu from "../../components/form/RegularMenu";
import OutlineButton from "../../components/global/OutlineButton";
import RegularButton from "../../components/global/RegularButton";
import { Form } from "../../styles/global";
import FileLibrary from "../../components/library_components/FileLibrary";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  createFFRequest,
  createIntroRequest,
  createLibraryRequest,
  loadLevelRequest,
  loadStoryRequest,
} from "../../stores/ducks/library/actions";
import { useForm } from "react-hook-form";
import { CreateLibraryInterface } from "../../@types/interfaces/app/forms/create-library.interface";
import { useNavigate } from "react-router-dom";
import { select } from "redux-saga/effects";

const language = [
  {
    name: "English",
    id: "eng",
  },
  {
    name: "Spanish",
    id: "sp",
  },
];
const programs = [
  {
    name: "Joseph Readers",
    id: 1,
  },
  {
    name: "Life Skills",
    id: 2,
  },
  {
    name: "Verbal Master",
    id: 3,
  },
];

const CreateLibrary: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const dispatch = useAppDispatch();
  const { storyList, levelList, isLoadingLibrary } = useAppSelector(
    (i) => i.library
  );
  const navigate = useNavigate();
  const [selectedMode, setSelectedMode] = useState<
    "library" | "full_file" | "intro"
  >("library");
  const [fileIntro, setFileIntro] = useState("1");

  const { register, handleSubmit, setValue } =
    useForm<CreateLibraryInterface>();

  useEffect(() => {
    dispatch(loadStoryRequest());
    dispatch(loadLevelRequest());
  }, []);

  const submitForm = (values: CreateLibraryInterface) => {
    if (selectedMode === "full_file") {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        dispatch(createFFRequest(values, navigate, formData));
      }
      delete values.story;
    } else if (selectedMode === "library") {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        dispatch(createLibraryRequest(values, navigate, formData));
      }
    } else {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        dispatch(createIntroRequest(values, navigate, formData));
      }
    }
  };

  const renderInput = () => {
    if (selectedMode === "library") {
      return (
        <InputLabel>
          Story*
          <RegularMenu mt="4px" placeholder="Select" {...register("story")}>
            {storyList?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </RegularMenu>
        </InputLabel>
      );
    } else if (selectedMode === "full_file") {
      return (
        <InputLabel>
          Level*
          <RegularMenu mt="4px" placeholder="Select" {...register("level")}>
            {levelList?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </RegularMenu>
        </InputLabel>
      );
    } else {
      return (
        <InputLabel>
          Program*
          <RegularMenu mt="4px" placeholder="Select" {...register("program")}>
            {programs?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </RegularMenu>
        </InputLabel>
      );
    }
  };

  return (
    <Layout text={"Library"}>
      <Form onSubmit={handleSubmit(submitForm)}>
        <Flex
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          <Heading fontWeight="600" fontSize="18px">
            Upload a New File
          </Heading>
          <Flex gap="16px" mt="26px">
            <OutlineButton
              aria-selected={selectedMode === "library"}
              _selected={{ border: `2px solid #0C4DA2 `, color: "primary" }}
              onClick={() => setSelectedMode("library")}
            >
              Library
            </OutlineButton>
            <OutlineButton
              aria-selected={selectedMode == "full_file"}
              _selected={{ border: `2px solid #0C4DA2 `, color: "primary" }}
              onClick={() => setSelectedMode("full_file")}
            >
              Full File
            </OutlineButton>
            <OutlineButton
              aria-selected={selectedMode === "intro"}
              _selected={{ border: `2px solid #0C4DA2 `, color: "primary" }}
              onClick={() => setSelectedMode("intro")}
            >
              Intro
            </OutlineButton>
          </Flex>

          <Grid
            mt="40px"
            gridTemplateAreas={`"text1 text2 text3" 
                                "divider2 divider2 divider2"
                                "divider3 divider3 divider3"
                                       "note note note"`}
            gap="24px"
          >
            <GridItem area={"text1"}>
              <InputLabel>
                Name*
                <RegularInput mt="4px" {...register("name")} />
              </InputLabel>
            </GridItem>
            <GridItem area={"text2"}>{renderInput()}</GridItem>
            <GridItem area={"text3"}>
              {selectedMode === "full_file" && (
                <InputLabel>
                  Language*
                  <RegularMenu
                    mt="4px"
                    placeholder="Select"
                    {...register("language")}
                  >
                    {language?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </RegularMenu>
                </InputLabel>
              )}
            </GridItem>

            <GridItem area={"divider2"}>
              <Divider my="12px" />
            </GridItem>
            <GridItem area={"divider3"}></GridItem>

            <GridItem area={"note"}>
              <FileLibrary
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                text="PDF (Max 10MB)"
              />
            </GridItem>
          </Grid>
        </Flex>

        <Flex
          mt="24px"
          mb="100px"
          gap="12px"
          w="full"
          justifyContent="flex-end"
        >
          {/* <OutlineButton>Reset</OutlineButton>{" "} */}
          <RegularButton type="submit" isLoading={isLoadingLibrary}>
            Save
          </RegularButton>
        </Flex>
      </Form>
    </Layout>
  );
};

export default CreateLibrary;
