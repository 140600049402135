import { Flex, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LayoutBuildReport from "../../components/build_report_components/LayoutBuildReport";
import SectionWhite from "../../components/build_report_components/SectionWhite";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReportList,
  loadReportSchoolProgressLsRequest,
  loadReportSchoolProgressRequest,
  loadReportSchoolProgressVmRequest,
} from "../../stores/ducks/buildReport/actions";

import { useLocation, useParams } from "react-router-dom";
import ItemSchoolReport from "../../components/build_report_components/ItemSchoolReport";
import TableSchoolReport from "../../components/build_report_components/TableSchoolReport";
import ContainerChartsArea from "../dashboard/ContainerChartsArea";

const SchoolReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    rasReportData,
    schoolProgressDataJr,
    dataChartBarJr,
    schoolProgressDataLs,
    schoolProgressDataVm,
    dataChartBarLs,
    dataChartBarVm,
    isLoading,
  } = useAppSelector((d) => d.buildReport);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const currentYear = searchParams.get("year");

  useEffect(() => {
    if (currentYear) {
      dispatch(
        loadReportSchoolProgressRequest({
          school_id: Number(id),
          program_id: 1,
          year: currentYear,
        }),
      );
      dispatch(
        loadReportSchoolProgressLsRequest({
          school_id: Number(id),
          program_id: 2,
          year: currentYear,
        }),
      );

      dispatch(
        loadReportSchoolProgressVmRequest({
          school_id: Number(id),
          program_id: 3,
          year: currentYear,
        }),
      );
    }

    return () => {
      dispatch(clearReportList());
    };
  }, [id, currentYear]);

  if (isLoading) {
    return (
      <Flex w="full" h="full" mt="25%" align="center" justify="center">
        <Spinner thickness="5px" size="xl" color="primary" />
      </Flex>
    );
  }

  return (
    <LayoutBuildReport
      text="School Progress Report"
      date={schoolProgressDataJr?.report_date ?? String(new Date())}
    >
      <SectionWhite>
        <Text fontSize="18px" fontWeight="600">
          Report Details
        </Text>
        <Grid mt="16px" gap="24px" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <Text fontWeight="600">School</Text>
          </GridItem>{" "}
          <GridItem>
            <Text fontWeight="600">District</Text>
          </GridItem>{" "}
          <GridItem></GridItem>
          <GridItem>
            <Text>
              {schoolProgressDataJr?.report_details.school ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem>
            <Text>
              {schoolProgressDataJr?.report_details.district ?? "No Data"}
            </Text>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </SectionWhite>
      <Grid
        templateColumns="repeat( auto-fit, minmax(350px, 1fr) )"
        mt="24px"
        gap="24px"
      >
        {dataChartBarJr && (
          <GridItem>
            <ContainerChartsArea
              title="Joseph's Readers"
              series={dataChartBarJr.series}
              categories={dataChartBarJr.categories}
              colors={dataChartBarJr.colors}
              total={dataChartBarJr.totalData}
              type="bar"
            />
          </GridItem>
        )}
        {dataChartBarLs && (
          <GridItem>
            <ContainerChartsArea
              title="Life Skills"
              series={dataChartBarLs.series}
              categories={dataChartBarLs.categories}
              colors={dataChartBarLs.colors}
              total={dataChartBarLs.totalData}
              type="bar"
            />
          </GridItem>
        )}
        {dataChartBarVm && (
          <GridItem>
            <ContainerChartsArea
              title="Verbal Master"
              series={dataChartBarVm.series}
              categories={dataChartBarVm.categories}
              colors={dataChartBarVm.colors}
              total={dataChartBarVm.totalData}
              type="bar"
            />
          </GridItem>
        )}
      </Grid>
      {schoolProgressDataJr?.program_report.data.length === 0 ? (
        <Text textAlign="center" mt="40px">
          No Data
        </Text>
      ) : (
        <>
          <SectionWhite mt="24px">
            <Text
              fontSize="18px"
              fontWeight="600"
              color="primary"
              border="2px solid #0C4DA2"
              borderRadius="100px"
              w="-webkit-fit-content"
              p="8px 12px"
            >
              Joseph's Readers
            </Text>
            <TableSchoolReport>
              {schoolProgressDataJr?.program_report.data.map((data) => (
                <ItemSchoolReport key={data.student_name} data={data} />
              ))}
            </TableSchoolReport>
          </SectionWhite>
        </>
      )}
      {schoolProgressDataLs?.program_report.data.length === 0 ? (
        <Text textAlign="center" mt="40px">
          No Data
        </Text>
      ) : (
        <>
          <SectionWhite mt="24px">
            <Text
              fontSize="18px"
              fontWeight="600"
              color="primary"
              border="2px solid #0C4DA2"
              borderRadius="100px"
              w="-webkit-fit-content"
              p="8px 12px"
            >
              Life Skills
            </Text>
            <TableSchoolReport>
              {schoolProgressDataLs?.program_report.data.map((data) => (
                <ItemSchoolReport key={data.student_name} data={data} />
              ))}
            </TableSchoolReport>
          </SectionWhite>
        </>
      )}
      {schoolProgressDataVm?.program_report.data.length === 0 ? (
        <Text textAlign="center" mt="40px">
          No Data
        </Text>
      ) : (
        <>
          <SectionWhite mt="24px">
            <Text
              fontSize="18px"
              fontWeight="600"
              color="primary"
              border="2px solid #0C4DA2"
              borderRadius="100px"
              w="-webkit-fit-content"
              p="8px 12px"
            >
              Verbal Master
            </Text>
            <TableSchoolReport>
              {schoolProgressDataVm?.program_report.data.map((data) => (
                <ItemSchoolReport key={data.student_name} data={data} />
              ))}
            </TableSchoolReport>
          </SectionWhite>
        </>
      )}
    </LayoutBuildReport>
  );
};

export default SchoolReport;
