import { Flex, Text } from "@chakra-ui/react";
import { AlertCircle, X } from "lucide-react";
import React, { useState } from "react";

const MessageAlert: React.FC = () => {
  const [closeMsg, setCloseMsg] = useState(false);

  return (
    <Flex
      bg="alert.red"
      h="36px"
      py="8px"
      px="24px"
      color="white"
      justify="space-between"
      hidden={closeMsg}
    >
      <Flex>
        <AlertCircle size="20px" />
        <Text fontSize="14px" ml="12px">
          Your licence has expired! To renew it, please contact the sales
          support.
        </Text>
      </Flex>
      <X size="20px" cursor="pointer" onClick={() => setCloseMsg(true)} />
    </Flex>
  );
};

export default MessageAlert;
