import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Square,
  Text,
} from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import { IItemsProgramReport } from "../../@types/interfaces/api/dashboard.inteface";
import { activityName, levelName } from "../../utils/bookMark";
import CardDailyLesson from "./CardDailyLesson";
import SectionWhite from "./SectionWhite";

interface ReportProgramElementProps {
  data: IItemsProgramReport;
}

const ReportProgramElement: React.FC<ReportProgramElementProps> = ({
  data,
}) => {
  const configs: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    colors: ["#0C4DA2"],
    dataLabels: {
      offsetY: -4,
      enabled: true,
      style: {
        fontSize: "15px", // Increase the font size
      },
      formatter(val, opts) {
        if ((val as number) < 0) {
          return "N/A";
        } else {
          return String(parseInt(val as any));
        }
      },
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
      },
    },

    grid: {
      xaxis: {
        lines: { show: true },
      },

      yaxis: {
        lines: { show: true },
      },

      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },

    xaxis: {
      categories: [""],
      labels: {
        offsetY: -1,
        style: {
          fontSize: "15px",
        },
        formatter: function (value) {
          if (typeof value !== "string") {
            return "";
          }
          const parts = value.split("-");
          if (parts.length > 1) {
            return parts;
          }
          return value;
        },
      },
    },
    yaxis: {
      title: {
        text: "Score",
      },
      min: -1,
      max: 12,
      tickAmount: 12,

      labels: {
        style: {
          fontSize: "15px",
        },
        formatter(val, opts) {
          if (val < 0) {
            return "N/A";
          } else {
            return String(parseInt(val as any));
          }
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <>
      <SectionWhite>
        <Grid mt="16px" gap="24px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">Student Name</Text>
          </GridItem>{" "}
          <GridItem>
            <Text fontWeight="600">Teacher Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Classroom</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">School</Text>
          </GridItem>
          <GridItem>
            <Text>{data.report_details.student ?? "No Data"}</Text>
          </GridItem>
          <GridItem>
            <Text>{data.report_details.teacher ?? "No Data"}</Text>
          </GridItem>
          <GridItem>
            <Text>{data.report_details.classroom ?? "No Data"}</Text>
          </GridItem>
          <GridItem>
            <Text>{data.report_details.school ?? "No Data"}</Text>
          </GridItem>
        </Grid>
      </SectionWhite>

      {data.data.length > 0 ? (
        data.data.map((item, index) => (
          <Flex flexDir="column" key={index}>
            <SectionWhite mt="24px" gap="24px" mb="24px">
              <Flex align="center" gap="6px">
                <Square size="16px" bg={item.level_color} borderRadius="4px" />{" "}
                <Text fontSize="18px" fontWeight="600">
                  {levelName[item.level_name]}
                </Text>
                <Text fontSize="16px" fontWeight="600">
                  - {item.story_name}
                </Text>
              </Flex>
              <Text fontSize="16px" fontWeight="600">
                Daily Score
              </Text>

              <Flex w="full">
                <Flex align="center" gap="16px" flexWrap="wrap" flex={1}>
                  {item.daily_scores.map((score, index) => (
                    <CardDailyLesson key={index} score={score} index={index} />
                  ))}
                </Flex>
                <Flex
                  justify="center"
                  align="center"
                  flexDir="column"
                  px="16px"
                  py="4px"
                  borderRadius="4px"
                  border="1px solid #EDF2F7"
                  maxW="180px"
                  h="-webkit-fit-content"
                  bg={
                    item.daily_scores_total < 70 && item.daily_scores_total > 0
                      ? "#ff7777"
                      : ""
                  }
                >
                  <Text fontWeight="600">Total Daily Score :</Text>
                  <Text fontWeight="600" color="gray.500">
                    {item.daily_scores_total < 0
                      ? "-"
                      : item.daily_scores_total + "%"}
                  </Text>
                </Flex>
              </Flex>

              <Divider />
              <Flex flexDir="column" w="full" gap="40px">
                {item.activities &&
                  item.activities.map((chart, index) => {
                    return (
                      <Box w="100%" key={index}>
                        <Chart
                          width="100%"
                          height={
                            chart.total_activities <= 4
                              ? "200px"
                              : chart.total_activities <= 9
                                ? "300px"
                                : "400px"
                          }
                          type="line"
                          series={[
                            {
                              name: "Score",
                              data: chart.series,
                            },
                          ]}
                          options={{
                            ...configs,
                            xaxis: {
                              ...configs.xaxis,
                              categories: item.lessons,
                            },
                            yaxis: {
                              ...configs.yaxis,
                              max: chart.total_activities,
                              tickAmount: chart.total_activities,
                            },
                            title: {
                              ...configs.title,
                              text: activityName[chart.mechanic_type],
                            },
                          }}
                        />
                      </Box>
                    );
                  })}
              </Flex>
            </SectionWhite>
            <Divider />
          </Flex>
        ))
      ) : (
        <>
          <SectionWhite my="24px" justify="center" align="center">
            <Text fontWeight="500" color="gray.500">
              No Data
            </Text>
          </SectionWhite>
          <Divider mb="24px" />
        </>
      )}
    </>
  );
};

export default ReportProgramElement;
