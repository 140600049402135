import { CertificateActionTypes } from "./types";

import {
  ICertificate,
  filterCertificate,
} from "../../../@types/interfaces/api/certificate.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export const loadCertificateRequest = (filters?: filterCertificate) => ({
  type: CertificateActionTypes.LOAD_CERTIFICATE_REQUEST,
  payload: filters,
});

export const loadCertificateSuccess = (
  Certificate: PaginationBase<ICertificate[]>
) => ({
  type: CertificateActionTypes.LOAD_CERTIFICATE_SUCCESS,
  payload: Certificate,
});
export const loadCertificateErrorr = () => ({
  type: CertificateActionTypes.LOAD_CERTIFICATE_ERROR,
});
