import { createStandaloneToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import { Profile } from "../../../@types/interfaces/api/profile.interface";
import api from "../../../services/api";
import {
  updateProfileError,
  updateProfilePhotoError,
  updateProfilePhotoSuccess,
  updateProfileSuccess,
} from "./actions";
import theme from "../../../styles/theme";

const { toast } = createStandaloneToast({ theme });

export function* updateProfile(action: AnyAction) {
  const { id, values }: { id: number; values: Profile } = action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/profile/${id}`,
      values
    );
    const { status, data } = response;
    if (status === 200) {
      yield put(updateProfileSuccess());
    }
  } catch (error: any) {
    yield put(updateProfileError());
  }
}

export function* updateProfilePhoto(action: AnyAction) {
  const values: FormData = action.payload;

  try {
    const response: AxiosResponse = yield call(
      api.post,
      `/profile/photo`,
      values,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { status, data } = response;
    if (status === 200) {
      yield put(updateProfilePhotoSuccess(data.image));
    }
  } catch (error: any) {
    yield put(updateProfilePhotoError());
    const errors = error.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
