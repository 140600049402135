import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { User } from "../../../@types/interfaces/api/user.interface";

export enum AuthActionTypes {
  LOG_OUT = "@auth/LOG_OUT",

  SIGN_IN_REQUEST = "@auth/SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS = "@auth/SIGN_IN_SUCCESS",
  SIGN_IN_ERROR = "@auth/SIGN_IN_ERROR",

  LOAD_TOKEN_REQUEST = "@auth/LOAD_TOKEN_REQUEST",
  LOAD_TOKEN_SUCCESS = "@auth/LOAD_TOKEN_SUCCESS",
  LOAD_TOKEN_ERROR = "@auth/LOAD_TOKEN_ERROR",

  LOAD_USERS_REQUEST = "@auth/LOAD_USERS_REQUEST",
  LOAD_USERS_SUCCESS = "@auth/LOAD_USERS_SUCCESS",
  LOAD_USERS_ERROR = "@auth/LOAD_USERS_ERROR",

  LOAD_USER_BY_ID_REQUEST = "@auth/LOAD_USER_BY_ID_REQUEST",
  LOAD_USER_BY_ID_SUCCESS = "@auth/LOAD_USER_BY_ID_SUCCESS",
  LOAD_USER_BY_ID_ERROR = "@auth/LOAD_USER_BY_ID_ERROR",

  BULK_UPDATE_USERS_REQUEST = "@auth/BULK_UPDATE_USERS_REQUEST",
  BULK_UPDATE_USERS_SUCCESS = "@auth/BULK_UPDATE_USERS_SUCCESS",
  BULK_UPDATE_USERS_ERROR = "@auth/BULK_UPDATE_USERS_ERROR",

  UPDATE_USER_SUCCESS = "@auth/UPDATE_USER_SUCCESS",
  UPDATE_USER_REQUEST = "@auth/UPDATE_USER_REQUEST",
  UPDATE_USER_ERROR = "@auth/UPDATE_USER_ERROR",

  ADMIN_REGISTER_SUCCESS = "@auth/ADMIN_REGISTER_SUCCESS",
  ADMIN_REGISTER_REQUEST = "@auth/ADMIN_REGISTER_REQUEST",
  ADMIN_REGISTER_ERROR = "@auth/ADMIN_REGISTER_ERROR",

  PRINCIPAL_REGISTER_SUCCESS = "@auth/PRINCIPAL_REGISTER_SUCCESS",
  PRINCIPAL_REGISTER_REQUEST = "@auth/PRINCIPAL_REGISTER_REQUEST",
  PRINCIPAL_REGISTER_ERROR = "@auth/PRINCIPAL_REGISTER_ERROR",

  TEACHER_REGISTER_SUCCESS = "@auth/TEACHER_REGISTER_SUCCESS",
  TEACHER_REGISTER_REQUEST = "@auth/TEACHER_REGISTER_REQUEST",
  TEACHER_REGISTER_ERROR = "@auth/TEACHER_REGISTER_ERROR",

  RECOVER_PASSWORD_REQUEST = "@auth/RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS = "@auth/RECOVER_PASSWORD_SUCCESS",

  RECOVER_USERNAME_REQUEST = "@auth/RECOVER_USERNAME_REQUEST",
  RECOVER_USERNAME_SUCCESS = "@auth/RECOVER_USERNAME_SUCCESS",

  RESET_PASSWORD_REQUEST = "@auth/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "@auth/RESET_PASSWORD_SUCCESS",

  SIMULATE_USER_REQUEST = "@auth/SIMULATE_USER_REQUEST",

  CLEAR_USER_LIST = "@auth/CLEAR_USER_LIST",
  CLEAR_USER = "@auth/CLEAR_USER",
}

export interface AuthState extends AuthStateRequestsStatus {
  authenticated: boolean;
  recovered: boolean;
  recovering: boolean;
  reseting: boolean;
  user: User | undefined;
  usersList: PaginationBase<User[]> | undefined;
  userById: User | undefined;
}

interface AuthStateRequestsStatus {
  isSignInLoading: boolean;
  isTokenLoading: boolean;
  isLoadingUsers: boolean;
  isLoadingUserById: boolean;
  isLoadingUpdate: boolean;
}
