import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ClassroomIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#A946E5" fillOpacity="0.1" />
      <path
        d="M33.0002 40V37.3333C33.0002 35.9188 32.4383 34.5623 31.4381 33.5621C30.4379 32.5619 29.0813 32 27.6668 32H19.6668C18.2523 32 16.8958 32.5619 15.8956 33.5621C14.8954 34.5623 14.3335 35.9188 14.3335 37.3333V40"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6668 26.6667C26.6123 26.6667 29.0002 24.2789 29.0002 21.3333C29.0002 18.3878 26.6123 16 23.6668 16C20.7213 16 18.3335 18.3878 18.3335 21.3333C18.3335 24.2789 20.7213 26.6667 23.6668 26.6667Z"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.0005 40V37.3333C40.9996 36.1516 40.6063 35.0037 39.8823 34.0698C39.1583 33.1358 38.1447 32.4688 37.0005 32.1733"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0005 16.1733C34.1477 16.4671 35.1645 17.1343 35.8907 18.0698C36.6168 19.0052 37.0109 20.1558 37.0109 21.34C37.0109 22.5242 36.6168 23.6748 35.8907 24.6103C35.1645 25.5457 34.1477 26.2129 33.0005 26.5067"
        stroke="#AB00C7"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ClassroomIcon;
