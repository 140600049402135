import {
  IActivity,
  ILesson,
  ILevel,
  IProgram,
  IStory,
} from "../../../@types/interfaces/api/bookMark.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum BookMarkActionTypes {
  LOAD_PROGRAM_REQUEST = "@bookMark/LOAD_PROGRAM_REQUEST",
  LOAD_PROGRAM_SUCCESS = "@bookMark/LOAD_PROGRAM_SUCCESS",
  LOAD_PROGRAM_ERROR = "@bookMark/LOAD_PROGRAM_ERROR",

  LOAD_STORY_REQUEST = "@bookMark/LOAD_STORY_REQUEST",
  LOAD_STORY_SUCCESS = "@bookMark/LOAD_STORY_SUCCESS",
  LOAD_STORY_ERROR = "@bookMark/LOAD_STORY_ERROR",

  LOAD_LEVEL_REQUEST = "@bookMark/LOAD_LEVEL_REQUEST",
  LOAD_LEVEL_SUCCESS = "@bookMark/LOAD_LEVEL_SUCCESS",
  LOAD_LEVEL_ERROR = "@bookMark/LOAD_LEVEL_ERROR",

  LOAD_LESSON_REQUEST = "@bookMark/LOAD_LESSON_REQUEST",
  LOAD_LESSON_SUCCESS = "@bookMark/LOAD_LESSON_SUCCESS",
  LOAD_LESSON_ERROR = "@bookMark/LOAD_LESSON_ERROR",

  LOAD_ACTIVITY_REQUEST = "@bookMark/LOAD_ACTIVITY_REQUEST",
  LOAD_ACTIVITY_SUCCESS = "@bookMark/LOAD_ACTIVITY_SUCCESS",
  LOAD_ACTIVITY_ERROR = "@bookMark/LOAD_ACTIVITY_ERROR",

  POST_PROGRESS_REQUEST = "@bookMark/POST_PROGRESS_REQUEST",
  POST_PROGRESS_SUCCESS = "@bookMark/POST_PROGRESS_SUCCESS",
  POST_PROGRESS_ERROR = "@bookMark/POST_PROGRESS_ERROR",

  CLEAR_BOOKMARK = "@bookMark/CLEAR_BOOKMARK",
}

export interface BookMarkState extends BookMarkStateRequestsStatus {
  program: PaginationBase<IProgram[]> | undefined;
  level: PaginationBase<ILevel[]> | undefined;
  story: PaginationBase<IStory[]> | undefined;
  lesson: PaginationBase<ILesson[]> | undefined;
  activity: IActivity[] | undefined;
}
export interface BookMarkStateRequestsStatus {
  isLoading: boolean;
}
