import {
  Checkbox,
  Flex,
  Icon,
  Switch,
  Tag,
  TagLabel,
  Td,
  Text,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Flag } from "lucide-react";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Student } from "../../@types/interfaces/api/student.interface";
import { useAppDispatch } from "../../hooks/redux";
import { updateUserRequest } from "../../stores/ducks/auth/actions";
import { tagText } from "../../utils/bookMark";
import BadgeStatus from "../global/BadgeStatus";
import EditButton from "../global/circle_button/EditButton";
import ViewButton from "../global/circle_button/ViewButton";

interface ItemStudentsProps {
  student: Student;
  isSelected?: boolean;
  onClickCheckbox?: () => void;
}

const ItemStudentTable: React.FC<ItemStudentsProps> = ({
  student,
  isSelected,
  onClickCheckbox,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const changeStatus = (e: any) => {
    dispatch(
      updateUserRequest(
        student.user,
        {
          ...student,
          user_type: "student",
          is_active: e.target.checked,
        },
        "student"
      )
    );
  };

  const isCheckbox = pathname.includes("view-classroom");
  const currentYear = new Date().getFullYear();

  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        {!isCheckbox && (
          <Checkbox
            colorScheme="tagScheme"
            isChecked={isSelected}
            onChange={onClickCheckbox}
          />
        )}
      </Td>
      <Td py="16px" pl="10px" fontWeight="400" color="gray.700">
        <Flex whiteSpace="pre-wrap" maxW="180px">
          <Text>
            {student.first_name} {student.last_name}
          </Text>
          {student.is_red_flag && (
            <Tooltip label="Red flag student" borderRadius="8px" bg="gray.600">
              <Icon color="red" ml="8px" as={Flag} />
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        <Flex flexWrap="wrap">
          {student.progress_report.length > 0 ? (
            student.progress_report.map((item, index) => (
              <Tag
                borderRadius="full"
                variant="solid"
                colorScheme="tagScheme"
                mt="4px"
                ml="4px"
                key={index}
                cursor="pointer"
                onClick={() => {
                  if (item === "RAS" || item === "DIAG") {
                    window.open(
                      `/build-report/${tagText[item]}?students=${student.user}&schools=''&year=${currentYear}`
                    );
                  } else {
                    window.open(
                      `/build-report/program?students=${student.user}&schools=''&program=${tagText[item]}&year=${currentYear}`
                    );
                  }
                }}
              >
                <TagLabel>{item}</TagLabel>
              </Tag>
            ))
          ) : (
            <Tag
              borderRadius="full"
              variant="solid"
              colorScheme="tagScheme"
              ml="4px"
            >
              No Data
            </Tag>
          )}
        </Flex>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        {student.current_lesson ? (
          <Tooltip
            borderRadius="8px"
            bg="gray.600"
            label={student.current_lesson}
          >
            <Text maxW="180px">
              {student.current_lesson.substring(0, 20)}
              {student.current_lesson.length > 20 ? "..." : ""}
            </Text>
          </Tooltip>
        ) : (
          <Text maxW="180px">No Data</Text>
        )}
      </Td>
      <Td
        py="16px"
        pl="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
      >
        {student.diagnostic_placement ? (
          <Text>
            {moment(student.diagnostic_placement.diagnostic_date).format(
              "MM/DD/YY"
            )}
          </Text>
        ) : (
          <Text>No Data</Text>
        )}
      </Td>
      <Td
        py="16px"
        pl="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
      >
        {student.diagnostic_placement ? (
          <Text>{student.diagnostic_placement.name}</Text>
        ) : (
          <Text>No Data</Text>
        )}
      </Td>

      <Td
        py="16px"
        pl="10px"
        fontWeight="400"
        color="gray.700"
        maxW="126x"
        whiteSpace="pre-wrap"
      >
        <Text>
          {student.last_login
            ? moment(student.last_login).format("MM/DD/YY HH:mm")
            : "No Data"}
        </Text>
      </Td>

      <Td py="16px" pl="10px" fontWeight="400" color="gray.700">
        <Text>{student.grade}</Text>
      </Td>
      <Td py="16px" px="0px" fontWeight="400" color="gray.700">
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={student.is_active ? "active" : "inactive"} />
          {!id && (
            <Switch
              size="sm"
              colorScheme="tagScheme"
              isChecked={student.is_active}
              onChange={changeStatus}
            />
          )}
        </Flex>
      </Td>
      <Td py="16px" fontWeight="400" color="gray.700" pr="8px">
        <ViewButton
          onClick={() => navigate(`/students/view-students/${student.user}`)}
          hidden={
            pathname === "/teachers/view-teachers" ||
            pathname === "/classroom/view-classroom"
          }
        />
        <EditButton
          ml="4px"
          onClick={() => navigate(`/students/edit-students/${student.user}`)}
          hidden={
            pathname === "/teachers/view-teachers" ||
            pathname === "/classroom/view-classroom"
          }
        />
      </Td>
    </Tr>
  );
};

export default ItemStudentTable;
