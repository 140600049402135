import { FilterActionTypes, FilterState } from "./types";

export const setFilters = (filters: FilterState) => ({
  type: FilterActionTypes.SET_FILTER,
  payload: filters,
});

export const clearFilter = () => ({
  type: FilterActionTypes.CLEAR_FILTER,
});
