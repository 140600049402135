import {
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { MoreVertical } from "lucide-react";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { School } from "../../@types/interfaces/api/school.interface";
import { useAppDispatch } from "../../hooks/redux";
import MenuListItem from "../global/MenuListItem";

interface ItemSchoolProps {
  school: School;
  isSelected: boolean;
  onClickCheckbox?: () => void;
}

const ItemSchoolsTable: React.FC<ItemSchoolProps> = ({
  school,
  isSelected,
  onClickCheckbox,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //teste commit
  return (
    <Tr h="20px">
      <Td w="10px" p="18px 16px">
        {onClickCheckbox && (
          <Checkbox
            colorScheme="tagScheme"
            isChecked={isSelected}
            onChange={onClickCheckbox}
          />
        )}
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        <Text>{school.name}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="180px"
      >
        <Text>{school.district}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>
          {school.last_student_login
            ? moment(school.last_student_login).format("MM/D/YYYY")
            : "No data"}
        </Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>
          {school.last_teacher_login
            ? moment(school.last_teacher_login).format("MM/D/YYYY")
            : "No data"}
        </Text>
      </Td>

      {/* <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        whiteSpace="pre-wrap"
        maxW="200px"
      >
        <Text>{school.address}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.city}</Text>
      </Td> */}
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.total_seats}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.seats_used}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.students_in_activities}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.used_24h}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.used_7d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.used_30d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.used_90d}</Text>
      </Td>
      <Td py="16px" px="10px" fontWeight="400" color="gray.700">
        <Text>{school.used_1y}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="100px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>
          {school.created_at
            ? moment(school.created_at).format("MM/D/YYYY")
            : "No data"}
        </Text>
      </Td>

      {/* <Td
        py="16px"
        pl="0"
        pr="50px"
        fontWeight="400"
        color="gray.700"
        w="120px"
      >
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={school.is_active ? "active" : "inactive"} />
          <Switch
            size="sm"
            colorScheme="tagScheme"
            ml="6px"
            isChecked={school.is_active}
            onChange={(e: any) =>
              dispatch(
                updateStatusSchoolsRequest(
                  { is_active: e.target.checked },
                  school.id
                )
              )
            }
          />
        </Flex>
      </Td> */}

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <Menu>
          <MenuButton
            as={IconButton}
            borderRadius="50%"
            w="48px"
            h="48px"
            fontSize="24px"
            variant="outline"
            icon={<MoreVertical fontSize="24px" />}
          />
          <MenuList
            borderRadius="16px"
            boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
            border="1px solid #E2E5EE"
          >
            <MenuListItem
              onClick={() => navigate(`/schools/view-schools/${school.id}`)}
            >
              View School
            </MenuListItem>

            <MenuListItem
              borderTop="1px solid #E2E5EE"
              onClick={() => navigate(`/schools/create-schools/${school.id}`)}
            >
              Edit School
            </MenuListItem>
            <MenuListItem
              borderTop="1px solid #E2E5EE"
              onClick={() =>
                window.open(
                  `/build-report/school-progress/${school.id}?year=${String(
                    new Date().getFullYear()
                  )}`
                )
              }
            >
              School Progress
            </MenuListItem>
            <MenuListItem
              borderTop="1px solid #E2E5EE"
              onClick={() =>
                window.open(
                  `/build-report/ras?students=all&schools=${
                    school.id
                  }&year=${String(new Date().getFullYear())}`
                )
              }
            >
              Ras Pre/Post Report
            </MenuListItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

export default ItemSchoolsTable;
