import { Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import MultiSelect, {
  MultipleOptionsType,
} from "../../components/build_report_components/MultSelect";
import Layout from "../../components/global/Layout";
import RegularButton from "../../components/global/RegularButton";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import { loadSchoolsReportRequest } from "../../stores/ducks/school/actions";
import { loadStudentReportRequest } from "../../stores/ducks/student/action";
import { dataYear } from "../../utils/data-year";

const BuildReport: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { studentListReport } = useAppSelector((s) => s.student);
  const { schoolsListReport } = useAppSelector((s) => s.school);
  const { user } = useAppSelector((d) => d.auth);

  const currentYear = new Date().getFullYear();
  const [url, setUrl] = useState({
    type: "",
    id: "",
    name: "",
    year: String(currentYear),
  });
  const [selectedSchool, setSelectedSchool] = useState<MultipleOptionsType[]>(
    []
  );
  const [selectedStudent, setSelectedStudent] = useState<MultipleOptionsType[]>(
    []
  );
  const programsReport = [
    { id: "ras", name: "Reading Attitude Survey" },
    { id: "diagnostic", name: "Diagnostic Prescriptive" },
    {
      id: "diagnostic-placement",
      name: "Diagnostic Prescriptive Placement Report",
    },
    { id: "school-progress", name: "School Progress" },
    { id: "program-1", name: "Joseph's Readers Student Progress" },
    { id: "program-2", name: "Life Skills Student Progress" },
    { id: "program-3", name: "Verbal Master Student Progress" },
  ];
  const isNotAdmin = user?.user_type !== "admin";
  const isAdmin = user?.user_type === "admin";
  const schoolSelected = user && isNotAdmin && url.type === "school-progress";
  const showSelectSchool = isAdmin && schoolsListReport?.results && url.type;
  const canSelectOneSchool = [
    "school-progress",
    "diagnostic-placement",
  ].includes(url?.type);
  const showSelectStudent =
    studentListReport &&
    url.type !== "school-progress" &&
    url.type !== "diagnostic-placement" &&
    url.type &&
    selectedSchool.length > 0;

  const disabledSubmit = (): boolean => {
    if (url.type === "diagnostic-placement" && (url.id !== "" || isNotAdmin)) {
      return false;
    }
    if (url.type !== "school-progress" && selectedStudent.length === 0) {
      return true;
    }
    if (url.type === "school-progress" && url.id === "") {
      return true;
    }
    return false;
  };

  const valueRefSchool = useRef("");
  const valueRefStudent = useRef("");
  const valueRefProgram = useRef("");

  const { setData } = useTypeDelay(
    (s) => {
      if (!isNotAdmin) {
        dispatch(
          loadSchoolsReportRequest({
            search: s,
            is_active__exact: true,
            pagination: "true",
          })
        );
      }
    },
    () => {
      if (!isNotAdmin) {
        dispatch(loadSchoolsReportRequest({ pagination: "true" }));
      }
    }
  );

  const { setData: setStudent } = useTypeDelay(
    (s) => {
      if (isNotAdmin && user && url.type) {
        dispatch(
          loadStudentReportRequest({
            user__profile__school__id__in: String(user.school),
            pagination: "true",
            search: s,
          })
        );
      }
      if (!isNotAdmin && selectedSchool) {
        const idsSchool = selectedSchool
          .map((school) => school.value)
          .join(",");
        valueRefSchool.current = idsSchool;
        dispatch(
          loadStudentReportRequest({
            user__profile__school__id__in: valueRefSchool.current,
            pagination: "true",
            search: s,
          })
        );
      }
    },
    () => {
      if (isNotAdmin && user && url.type) {
        dispatch(
          loadStudentReportRequest({
            user__profile__school__id__in: String(user.school),
            pagination: "true",
            search: "",
          })
        );
      }
      if (!isNotAdmin && selectedSchool) {
        const idsSchool = selectedSchool
          .map((school) => school.value)
          .join(",");
        valueRefSchool.current = idsSchool;
        dispatch(
          loadStudentReportRequest({
            user__profile__school__id__in: valueRefSchool.current,
            pagination: "true",
            search: "",
          })
        );
      }
    }
  );

  useEffect(() => {
    if (schoolSelected) {
      setUrl({ ...url, id: String(user.school) });
    }
    if (user && isNotAdmin) {
      setSelectedSchool([{ value: String(user.school), label: "" }]);
    }

    if (url.name === "Joseph's Readers Student Progress") {
      valueRefProgram.current = "1";
    } else if (url.name === "Life Skills Student Progress") {
      valueRefProgram.current = "2";
    } else if (url.name === "Verbal Master Student Progress") {
      valueRefProgram.current = "3";
    } else {
      valueRefProgram.current = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url.name, user]);

  useEffect(() => {
    if (isNotAdmin && user && url.type) {
      dispatch(
        loadStudentReportRequest({
          user__profile__school__id__in: String(user.school),
          pagination: "true",
        })
      );
    }
    if (selectedSchool && !isNotAdmin) {
      const idsSchool = selectedSchool.map((school) => school.value).join(",");
      valueRefSchool.current = idsSchool;
      dispatch(
        loadStudentReportRequest({
          user__profile__school__id__in: valueRefSchool.current,
          pagination: "true",
        })
      );
    }
    valueRefStudent.current = selectedStudent
      .map((student) => student.value)
      .join(",");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool, selectedStudent]);

  const submitReport = () => {
    setUrl({ id: "", type: "", name: "", year: String(currentYear) });

    const inProgram = url.type.includes("program");
    if (inProgram) {
      window.open(
        `/build-report/${inProgram && "program"}?students=${
          valueRefStudent.current
        }&schools=${valueRefSchool.current}&program=${
          valueRefProgram.current
        }&year=${url.year}`
      );
    } else if (url.type === "school-progress") {
      window.open(`/build-report/${url.type}/${url.id}?year=${url.year}`);
    } else if (url.type === "diagnostic-placement") {
      window.open(
        `/build-report/diagnostic-placement?school=${url.id}&year=${url.year}`
      );
    } else {
      window.open(
        `/build-report/${url.type}?students=${valueRefStudent.current}&schools=${valueRefSchool.current}&year=${url.year}`
      );
    }

    window.location.reload();
  };

  return (
    <Layout text={"Build Report"}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        borderRadius="16px"
        justify="space-between"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex flexDir="column" w="full">
          <Heading fontWeight="600" fontSize="18px">
            Choose Report Type
          </Heading>

          <Select
            placeholder="Select Type"
            onChange={(value) =>
              setUrl({ ...url, type: value!.value, name: value!.label })
            }
            options={programsReport.map((data) => ({
              label: data.name,
              value: data.id,
            }))}
            styles={{
              control: (provided) => ({
                ...provided,
                marginTop: "20px",
                width: "50%",
                borderRadius: "16px",
              }),
              menu: (provided) => ({
                ...provided,
                width: "50%",
              }),
            }}
            autoFocus
            isSearchable
          />
        </Flex>
        <Flex flexDir="column" w="200px">
          <Heading fontWeight="600" fontSize="18px">
            Year Filter
          </Heading>

          <Select
            placeholder="Select Year"
            onChange={(value) => setUrl({ ...url, year: value?.value! })}
            options={dataYear().map((data) => ({
              label: data.label,
              value: data.value,
            }))}
            defaultValue={dataYear().find(
              (data) => data.value === String(currentYear)
            )}
            styles={{
              control: (provided) => ({
                ...provided,
                marginTop: "20px",
                width: "100%",
                borderRadius: "16px",
              }),
              menu: (provided) => ({
                ...provided,
                width: "100%",
              }),
            }}
            autoFocus
            isSearchable
          />
        </Flex>
      </Flex>

      {showSelectSchool && (
        <Flex
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
          mt="24px"
        >
          <Heading fontWeight="600" fontSize="18px">
            Select or Type School(s)
          </Heading>
          {canSelectOneSchool ? (
            <Select
              placeholder="Search School(s):"
              onChange={(value) => setUrl({ ...url, id: String(value!.value) })}
              onInputChange={(e) => {
                setData(e);
              }}
              options={
                schoolsListReport &&
                schoolsListReport?.results.map((data) => ({
                  label: data.name,
                  value: data.id,
                }))
              }
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  marginTop: "20px",
                  width: "50%",
                  borderRadius: "16px",
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "50%",
                }),
              }}
            />
          ) : (
            <MultiSelect
              options={schoolsListReport?.results.map((data) => ({
                label: data.name,
                value: String(data.id),
              }))}
              value={selectedSchool}
              onChange={setSelectedSchool}
              setData={(e) => setData(e)}
              // returnSelectAllOption
            />
          )}
        </Flex>
      )}

      {showSelectStudent && (
        <Flex
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
          mt="24px"
        >
          <Heading fontWeight="600" fontSize="18px">
            Select or Type Student(s)
          </Heading>

          <MultiSelect
            options={studentListReport?.results.map((data) => ({
              label: data.first_name + " " + data.last_name,
              value: String(data.user),
            }))}
            value={selectedStudent}
            onChange={setSelectedStudent}
            setData={(e) => setStudent(e)}
            returnSelectAllOption
          />
        </Flex>
        //warn bug in report
      )}

      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        mt="24px"
      >
        <Heading fontWeight="600" fontSize="18px">
          Step 3 - Your Report is Ready to Run
        </Heading>

        <Flex mt="40px" gap="24px">
          <RegularButton isDisabled={disabledSubmit()} onClick={submitReport}>
            Run Report
          </RegularButton>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default BuildReport;
