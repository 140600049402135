import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

const InputLabel: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      as="label"
      color="gray.700"
      fontSize="16px"
      fontWeight="500"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default InputLabel;
