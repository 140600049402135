import {
  CheckLicense,
  License,
} from "../../../@types/interfaces/api/license.interface";

export enum LicenseActionTypes {
  LOAD_LICENSE_REQUEST = "@license/LOAD_LICENSE_REQUEST",
  LOAD_LICENSE_SUCCESS = "@license/LOAD_LICENSE_SUCCESS",
  LOAD_LICENSE_ERROR = "@license/LOAD_LICENSE_ERROR",

  LOAD_LICENSE_BY_ID_REQUEST = "@license/LOAD_LICENSE_BY_ID_REQUEST",
  LOAD_LICENSE_BY_ID_SUCCESS = "@license/LOAD_LICENSE_BY_ID_SUCCESS",
  LOAD_LICENSE_BY_ID_ERROR = "@license/LOAD_LICENSE_BY_ID_ERROR",

  REGISTER_LICENSE_REQUEST = "@license/REGISTER_LICENSE_REQUEST",
  REGISTER_LICENSE_SUCCESS = "@license/REGISTER_LICENSE_SUCCESS",
  REGISTER_LICENSE_ERROR = "@license/REGISTER_LICENSE_ERROR",

  CHECK_LICENSE_REQUEST = "@license/CHECK_LICENSE_REQUEST",
  CHECK_LICENSE_SUCCESS = "@license/CHECK_LICENSE_SUCCESS",
  CHECK_LICENSE_ERROR = "@license/CHECK_LICENSE_ERROR",
}

export interface LicenseState extends LicenseStateRequestsStatus {
  licenses: License[];
  licenseById: License | undefined;
  checkLicense: CheckLicense | undefined;
}
export interface LicenseStateRequestsStatus {
  isLoadingLicense: boolean;
}
