import { Checkbox, Td, Text, Th, Tr } from "@chakra-ui/react";
import React from "react";

interface HeadStudentTable {
  onClickCheck?: () => void;
  isFullSelected?: () => boolean;
}

const HeadStudentTableModal: React.FC<HeadStudentTable> = ({
  onClickCheck,
  isFullSelected = () => false,
}) => {
  return (
    <Tr>
      {onClickCheck && (
        <Th width="1px" py="16px" pl="16px">
          <Checkbox
            colorScheme="tagScheme"
            isChecked={isFullSelected()}
            onChange={() => onClickCheck()}
          />
        </Th>
      )}

      <Th
        py="16px"
        px="0"
        color="blue.600"
        fontWeight="600"
        textTransform="unset"
        fontSize="14px"
      >
        To convert
      </Th>
      <Th py="16px" px="0" color="blue.600" fontWeight="600" fontSize="14px">
        DOB
      </Th>
      <Th
        py="16px"
        px="0"
        color="blue.600"
        fontWeight="600"
        textTransform="unset"
        fontSize="14px"
      >
        Classroom
      </Th>
      <Th
        py="16px"
        px="0"
        color="blue.600"
        fontWeight="600"
        textTransform="unset"
        fontSize="14px"
      >
        Current Bookmark
      </Th>
    </Tr>
  );
};

export default HeadStudentTableModal;
