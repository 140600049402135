import { Country, State } from "../../../@types/interfaces/api/core.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum CoreActionTypes {
  LOAD_COUNTRY_REQUEST = "@core/LOAD_COUNTRY_REQUEST",
  LOAD_COUNTRY_SUCCESS = "@core/LOAD_COUNTRY_SUCCESS",
  LOAD_COUNTRY_ERROR = "@core/LOAD_COUNTRY_ERROR",

  LOAD_STATE_REQUEST = "@core/LOAD_STATE_REQUEST",
  LOAD_STATE_SUCCESS = "@core/LOAD_STATE_SUCCESS",
  LOAD_STATE_ERROR = "@core/LOAD_STATE_ERROR",
}

export interface CoreState extends CoreStateRequestsStatus {
  countries: PaginationBase<Country[]> | undefined;
  states: State[] | undefined;
}
export interface CoreStateRequestsStatus {
  isLoading: boolean;
}
