import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputLabel from "../../components/form/InputLabel";
import RegularInput from "../../components/form/RegularInput";
import RegularMenu from "../../components/form/RegularMenu";
import Layout from "../../components/global/Layout";
import OutlineButton from "../../components/global/OutlineButton";
import RegularButton from "../../components/global/RegularButton";
import FileLibrary from "../../components/library_components/FileLibrary";
import { Form } from "../../styles/global";
import { useForm } from "react-hook-form";
import { TutorialDataCreate } from "../../@types/interfaces/api/tutorial-data.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createTutorialRequest } from "../../stores/ducks/tutorial/actions";
import { useNavigate } from "react-router-dom";
import FileTutorial from "../../components/tutorials_components/FileTutorial";

const CreateTutorials: React.FC = () => {
  const [selectType, setSelectType] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoadingTutorial } = useAppSelector((i) => i.tutorial);
  const { register, handleSubmit, setValue } = useForm<TutorialDataCreate>({
    defaultValues: {
      title: "",
      type: "video",
    },
  });

  const submitForm = (values: TutorialDataCreate) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      dispatch(createTutorialRequest(values, navigate, formData));
    }
  };

  const setTypeVideo = () => {
    setValue("type", "video");
    setSelectType(true);
  };
  const setTypeDocument = () => {
    setValue("type", "document");
    setSelectType(false);
  };

  return (
    <Layout text="tutorials">
      <Form onSubmit={handleSubmit(submitForm)}>
        <Flex
          w="full"
          p="24px"
          bg="white"
          flexDir="column"
          borderRadius="16px"
          boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
        >
          <Heading fontWeight="600" fontSize="18px">
            Upload a New File
          </Heading>

          <Flex gap="16px" mt="26px">
            {/* <RadioGroup defaultValue="video" onChange={setValue} value={value}>
              <Stack spacing={4} direction="row">
                <Radio value="video">Video</Radio>
                <Radio value="document">Document Guides</Radio>
              </Stack>
            </RadioGroup> */}

            <OutlineButton
              aria-selected={selectType}
              _selected={{ border: `2px solid #0C4DA2 `, color: "primary" }}
              onClick={setTypeVideo}
            >
              Video
            </OutlineButton>
            <OutlineButton
              aria-selected={!selectType}
              _selected={{ border: `2px solid #0C4DA2 `, color: "primary" }}
              onClick={setTypeDocument}
            >
              Document Guides
            </OutlineButton>
          </Flex>

          <Grid
            mt="40px"
            gridTemplateAreas={`"text1 text2 text3" 
                                "divider2 divider2 divider2" 
                                "note note note"`}
            gap="24px"
          >
            <GridItem area={"text1"}>
              <InputLabel>
                Tutorial Name*
                <RegularInput mt="4px" {...register("title")} />
              </InputLabel>
            </GridItem>

            <GridItem area={"text2"}></GridItem>

            <GridItem area={"text3"}></GridItem>

            <GridItem area={"divider2"}>
              <Divider my="12px" />
            </GridItem>
            <GridItem area={"note"}>
              <FileTutorial
                selectType={selectType}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                text={selectType ? "MP4, AVI (Max 100MB)" : ".pdf (Max 10MB)"}
              />
            </GridItem>
          </Grid>
        </Flex>

        <Flex
          mt="24px"
          mb="100px"
          gap="12px"
          w="full"
          justifyContent="flex-end"
        >
          {/* <OutlineButton>Reset</OutlineButton>{" "} */}
          <RegularButton type="submit" isLoading={isLoadingTutorial}>
            Save
          </RegularButton>
        </Flex>
      </Form>
    </Layout>
  );
};

export default CreateTutorials;
