import {
  Button,
  Flex,
  Progress,
  Stack,
  Tag,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { StudentHistory } from "../../@types/interfaces/api/student.interface";
import { WordsReport } from "../../@types/interfaces/api/buildReport.interface";

interface ItemViewStudentProps {
  word: WordsReport;
}

const ItemProgressWords: React.FC<ItemViewStudentProps> = ({ word }) => {
  const words = word.words.split(",");

  return (
    <Tr>
      <Td p="18px 16px" maxW="200px" whiteSpace="pre-wrap">
        <Text>{word.story_name}</Text>
      </Td>
      <Td p="18px 16px" display="flex" flexWrap="wrap" gap="4px">
        {words.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span>-</span>}
            <Text>{item}</Text>
          </React.Fragment>
        ))}
      </Td>

      <Td p="18px 16px" maxW="300px" whiteSpace="pre-wrap">
        <Text fontWeight="600">{word.total_words}</Text>
      </Td>
    </Tr>
  );
};

export default ItemProgressWords;
