import { BuildReportActionTypes, BuildReportState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: BuildReportState = {
  isLoading: false,
  rasReportData: undefined,
  diagnosticReportData: undefined,
  diagnosticPlacementData: undefined,
  studentProgressDataJr: undefined,
  studentProgressDataVm: undefined,
  studentProgressDataLs: undefined,
  dataChartBarJr: undefined,
  dataChartBarLs: undefined,
  dataChartBarVm: undefined,
  schoolProgressDataJr: undefined,
  schoolProgressDataLs: undefined,
  schoolProgressDataVm: undefined,
  historyReportData: undefined,
  programReport: undefined,
};

const reducer: Reducer<BuildReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BuildReportActionTypes.LOAD_RAS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_RAS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rasReportData: action.payload,
      };
    case BuildReportActionTypes.LOAD_RAS_REPORT_ERROR:
      return {
        ...state,
        isLoading: true,
      };

    case BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        diagnosticReportData: action.payload,
      };

    case BuildReportActionTypes.LOAD_DIAGNOSTIC_REPORT_ERROR:
      return {
        ...state,
        isLoading: true,
      };

    case BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        diagnosticPlacementData: action.payload,
      };
    case BuildReportActionTypes.LOAD_DIAGNOSTIC_PLACEMENT_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentProgressDataJr: action.payload,
      };
    case BuildReportActionTypes.LOAD_STUDENT_PROGRESS_REPORT_ERROR:
      return {
        ...state,
        isLoading: true,
      };

    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schoolProgressDataJr: action.payload.data,
        dataChartBarJr: action.payload.dataChartBarJr,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_REPORT_ERROR:
      return {
        ...state,
        isLoading: true,
      };

    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_STUDENT_PROGRESS_LS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentProgressDataLs: action.payload,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_STUDENT_PROGRESS_VM_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentProgressDataVm: action.payload,
      };

    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_LS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schoolProgressDataLs: action.payload.data,
        dataChartBarLs: action.payload.dataChartBarLs,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_SCHOOL_PROGRESS_VM_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schoolProgressDataVm: action.payload.data,
        dataChartBarVm: action.payload.dataChartBarVm,
      };
    case BuildReportActionTypes.LOAD_HISTORY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_HISTORY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        historyReportData: action.payload,
      };
    case BuildReportActionTypes.LOAD_HISTORY_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case BuildReportActionTypes.LOAD_PROGRAM_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BuildReportActionTypes.LOAD_PROGRAM_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        programReport: action.payload,
      };
    case BuildReportActionTypes.LOAD_PROGRAM_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case BuildReportActionTypes.CLEAR_REPORT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
