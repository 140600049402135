import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const SchoolsIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="56" rx="28" fill="#E5F0FD" />
      <path
        d="M20 41.3333V17.3333C20 16.9733 20 16.6 20.0933 16.24C20.1749 15.8808 20.3556 15.5517 20.6149 15.2901C20.8742 15.0285 21.2016 14.8448 21.56 14.76C21.9467 14.6667 23.64 14.6667 24 14.6667H33.3333C33.6933 14.6667 34.0667 14.6667 34.4267 14.76C34.7858 14.8416 35.1149 15.0223 35.3765 15.2816C35.6381 15.5408 35.8218 15.8682 35.9067 16.2267C36 16.6 36 16.9733 36 17.3333V41.3333H20Z"
        stroke="#0C4DA2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 30.6667V38.6667C14.6667 40.1333 15.8667 41.3333 17.3333 41.3333H20V28H17.3333C16.9733 28 16.6 28 16.24 28.0933C15.88 28.1867 15.5467 28.36 15.28 28.6267C15.0267 28.88 14.8533 29.2133 14.76 29.5733C14.6847 29.9327 14.6534 30.2998 14.6667 30.6667Z"
        stroke="#0C4DA2"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.76 24.0933C39.4007 24.0181 39.0335 23.9867 38.6667 24H36V41.3333H38.6667C39.3739 41.3333 40.0522 41.0524 40.5523 40.5523C41.0524 40.0522 41.3333 39.3739 41.3333 38.6667V26.6667C41.3333 26.2933 41.3333 25.9333 41.24 25.5733C41.1467 25.2133 40.9733 24.88 40.7067 24.6133C40.4533 24.36 40.12 24.1867 39.76 24.0933V24.0933Z"
        stroke="#2D3748"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 20H30.6667"
        stroke="#2D3748"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 25.3333H30.6667"
        stroke="#2D3748"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 30.6667H30.6667"
        stroke="#2D3748"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3334 36H30.6667"
        stroke="#2D3748"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default SchoolsIcon;
