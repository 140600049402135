import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";
import React from "react";

const SkeletonTable: React.FC = () => {
  return (
    <Flex flexDir="column" gap="10px" mt="24px">
      <Skeleton w="full" h="73px" borderRadius="16px" />
      <Skeleton w="full" h="73px" borderRadius="16px" />
      <Skeleton w="full" h="73px" borderRadius="16px" />
      <Skeleton w="full" h="73px" borderRadius="16px" />
      <Skeleton w="full" h="73px" borderRadius="16px" />
    </Flex>
  );
};

export default SkeletonTable;
