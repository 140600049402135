import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Form } from "../../styles/global";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import RegularMenu from "../form/RegularMenu";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import { useForm } from "react-hook-form";
import { School } from "../../@types/interfaces/api/school.interface";
import {
  clearDistrictList,
  loadDistrictByIdRequest,
  loadDistrictsRequest,
} from "../../stores/ducks/district/actions";
import {
  registerSchoolsRequest,
  updateSchoolsRequest,
} from "../../stores/ducks/school/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { schoolFormSchema } from "../../utils/yup-schemas";
import { License } from "../../@types/interfaces/api/license.interface";
import { SchoolCreate } from "../../@types/interfaces/app/forms/create-school.interface";
import moment from "moment";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import {
  loadCountryRequest,
  loadStateRequest,
} from "../../stores/ducks/core/actions";
import SearchSelect from "../global/SearchSelect";
import { addPhoneMask } from "../../utils/phone";
import Select from "react-select";

interface CreateSchoolFormProps {
  school: School | undefined;
  license: License | undefined;
  loading: boolean;
}

const CreateSchoolForm: React.FC<CreateSchoolFormProps> = ({
  school,
  license,
  loading,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { districtsList, districtById } = useAppSelector((s) => s.district);
  const { countries, states } = useAppSelector((i) => i.core);
  const districtArray = districtsList?.results;
  const [district, setDistrict] = useState({
    value: Number(districtById?.id),
    label: districtById?.name,
  });

  useEffect(() => {
    if (school) {
      dispatch(loadDistrictByIdRequest(Number(school.district)));
    }
    if (districtById) {
      setDistrict({
        value: Number(districtById?.id),
        label: districtById?.name,
      });
    }

    return () => {
      dispatch(loadDistrictByIdRequest(undefined));
    };
  }, [id, districtById]);

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(loadCountryRequest({ search: s }));
    },
    () => {
      dispatch(loadCountryRequest({}));
    }
  );
  const { setData: setDataDistrict, data: dataDistrict } = useTypeDelay(
    (s) => {
      dispatch(
        loadDistrictsRequest({
          search: s,
        })
      );
    },
    () => {
      dispatch(loadDistrictsRequest({}));
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SchoolCreate>({
    defaultValues: {
      name: school ? school.name : "",
      district: school ? school.district : undefined,
      address: school ? school.address : "",
      city: school ? school.city : "",
      state: school ? school.state : "",
      zip: school ? school.zip : "",
      country: school ? school.country : "",
      phone_number: school ? addPhoneMask(school.phone_number) : "",
      primary_contact_name: school ? school.primary_contact_name : "",
      primary_contact_email: school ? school.primary_contact_email : "",
      license: {
        licenses_number: license ? license.licenses_number : undefined,
        license_starting_date: license
          ? moment(license.license_starting_date).format("YYYY-MM-DD")
          : "",
        license_expiration_date: license
          ? moment(license.license_expiration_date).format("YYYY-MM-DD")
          : "",
      },
    },
    resolver: yupResolver(schoolFormSchema),
  });

  const submitForm = (values: SchoolCreate) => {
    const tomorrow = moment().add(1, "day");

    const isAfterOrEqualLicenseStart = moment(
      values.license?.license_starting_date
    ).isSameOrAfter(moment(), "day");

    const isAfterLicenseExpiration = moment(
      values.license?.license_expiration_date
    ).isSameOrAfter(tomorrow, "day");

    const isValidLicense =
      isAfterOrEqualLicenseStart && isAfterLicenseExpiration;

    const date1 = moment(
      values.license.license_starting_date,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const date2 = moment(
      values.license.license_expiration_date,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    const licenseFormated: SchoolCreate = {
      ...values,
      license: {
        ...values.license,
        license_expiration_date: date2,
        license_starting_date: date1,
      },
    };
    if (!isValidLicense && !id) {
      toast({
        title: "invalid date license",
        description:
          "The start date needs to be from today onwards, and the end date needs to be at least the day after.",
        status: "error",
      });
    } else {
      if (id) {
        dispatch(updateSchoolsRequest(licenseFormated, navigate));
      } else {
        dispatch(registerSchoolsRequest(licenseFormated, navigate));
      }
    }
  };

  const onMouseClick = (short_name: string) => {
    setValue("country", short_name);
    dispatch(
      loadStateRequest({
        country: short_name,
      })
    );
    setData("");
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          {id ? "Edit Schools" : "Create New Schools"}
        </Heading>

        <Grid
          mt="40px"
          gridTemplateAreas={`"School-name District Address"
                                "divider divider divider"
                                   "country State city "
                                   "divider1 divider1 divider1"
                                   "Zip phone  principalE"`}
          gap="24px"
        >
          <GridItem area={"School-name"}>
            <FormControl isInvalid={!!errors.name}>
              <InputLabel>
                School Name*
                <RegularInput mt="4px" {...register("name")} />
                {<FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"District"}>
            <FormControl isInvalid={!!errors.district} color="gray.500">
              <InputLabel>
                District
                <Select
                  onChange={(value) => {
                    setValue("district", value!.value);
                    setDistrict({
                      value: Number(value?.value),
                      label: value?.label,
                    });
                  }}
                  value={{ value: district.value, label: district.label }}
                  placeholder={"Search District:"}
                  onInputChange={(e) => {
                    setDataDistrict(e);
                  }}
                  options={districtArray?.map((data) => ({
                    label: data.name,
                    value: data.id,
                  }))}
                  isSearchable
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral80: "#718096",
                    },
                  })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      marginTop: "4px",
                      borderRadius: "100px",
                      height: "48px",
                      border: "1px solid #e6e8f0",
                      paddingInline: "12px",
                      fontSize: "16px",
                      color: "#718096",
                      ":hover": {
                        outline: "none",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                    }),
                  }}
                />
                {
                  <FormErrorMessage>
                    {errors.district?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"Address"}>
            <FormControl isInvalid={!!errors.address}>
              <InputLabel>
                Address*
                <RegularInput
                  mt="4px"
                  color="gray.500"
                  {...register("address")}
                />
                {<FormErrorMessage>{errors.address?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"divider"}>
            <Divider my="12px" />
          </GridItem>
          <GridItem area={"city"}>
            <FormControl isInvalid={!!errors.city}>
              <InputLabel>
                City*
                <RegularInput mt="4px" color="gray.500" {...register("city")} />
                {<FormErrorMessage>{errors.city?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"country"}>
            <FormControl isInvalid={!!errors.state}>
              <InputLabel>
                Country*
                <Flex flexDir="column" position="relative">
                  <RegularInput
                    placeholder="type something.."
                    mt="4px"
                    type="text"
                    {...register("country")}
                    onChange={(e: any) => setData(e.target.value)}
                    onBlur={() => setData("")}
                  />
                  {
                    <FormErrorMessage>
                      {errors.country?.message}
                    </FormErrorMessage>
                  }
                  {data && countries?.results[0] && (
                    <SearchSelect
                      list={countries}
                      onMouseClick={(short_name) => onMouseClick(short_name)}
                    />
                  )}
                </Flex>
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"State"}>
            <FormControl isInvalid={!!errors.state}>
              <InputLabel>
                State*
                {states && states.length > 0 ? (
                  <RegularMenu
                    placeholder="select state"
                    {...register("state")}
                  >
                    {states?.map((item) => (
                      <option key={item.id} value={item.short_name}>
                        {item.long_name}
                      </option>
                    ))}
                  </RegularMenu>
                ) : (
                  <RegularInput
                    maxLength={2}
                    mt="4px"
                    color="gray.500"
                    {...register("state")}
                  />
                )}
                {<FormErrorMessage>{errors.state?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"Zip"}>
            <FormControl isInvalid={!!errors.zip}>
              <InputLabel>
                Zip*
                <RegularInput
                  mt="4px"
                  color="gray.500"
                  maxLength={6}
                  {...register("zip")}
                />
                {<FormErrorMessage>{errors.zip?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          </GridItem>
          <GridItem area={"divider1"}>
            <Divider my="12px" />
          </GridItem>
          <GridItem area={"phone"}>
            <FormControl isInvalid={!!errors.phone_number}>
              <InputLabel>
                Phone Number
                <RegularInput
                  mt="4px"
                  color="gray.500"
                  {...register("phone_number", {
                    onChange: (v) =>
                      setValue("phone_number", addPhoneMask(v.target.value)),
                  })}
                />
                {
                  <FormErrorMessage>
                    {errors.phone_number?.message}
                  </FormErrorMessage>
                }
              </InputLabel>
            </FormControl>
          </GridItem>
        </Grid>
      </Flex>

      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Licenses*
        </Heading>

        <Flex mt="24px" gap="24px" w="full">
          <FormControl isInvalid={!!errors.license?.licenses_number}>
            <InputLabel mt="16px">
              Number of Licenses*
              <RegularInput
                mt="4px"
                {...register("license.licenses_number")}
                isRequired
              />
              {
                <FormErrorMessage>
                  {errors.license?.licenses_number?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.license?.license_starting_date}>
            <InputLabel mt="16px">
              License Starting Date
              <RegularInput
                mt="4px"
                type="date"
                {...register("license.license_starting_date")}
                isRequired
              />
              {
                <FormErrorMessage>
                  {errors.license?.license_starting_date?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.license?.license_expiration_date}>
            <InputLabel mt="16px">
              License Expiration Date
              <RegularInput
                mt="4px"
                type="date"
                {...register("license.license_expiration_date")}
                isRequired
              />
              {
                <FormErrorMessage>
                  {errors.license?.license_expiration_date?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
        </Flex>
      </Flex>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          Primary School Contact
        </Heading>

        <Flex mt="24px" gap="24px" w="full">
          <FormControl isInvalid={!!errors.primary_contact_name}>
            <InputLabel mt="16px">
              Title of Contact*
              <RegularInput mt="4px" {...register("primary_contact_name")} />
              {
                <FormErrorMessage>
                  {errors.primary_contact_name?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
          <FormControl isInvalid={!!errors.primary_contact_email}>
            <InputLabel mt="16px">
              Email of Contact*
              <RegularInput mt="4px" {...register("primary_contact_email")} />
              {
                <FormErrorMessage>
                  {errors.primary_contact_email?.message}
                </FormErrorMessage>
              }
            </InputLabel>
          </FormControl>
        </Flex>
      </Flex>
      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        <OutlineButton onClick={() => reset()} hidden={!!id}>
          Reset
        </OutlineButton>
        <RegularButton type="submit" isLoading={loading}>
          Save
        </RegularButton>
      </Flex>
    </Form>
  );
};

export default CreateSchoolForm;
