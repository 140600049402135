import { BookMarkActionTypes, BookMarkState } from "./types";
import { Reducer } from "redux";

const INITIAL_STATE: BookMarkState = {
  isLoading: false,
  program: undefined,
  level: undefined,
  story: undefined,
  lesson: undefined,
  activity: undefined,
};

const reducer: Reducer<BookMarkState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BookMarkActionTypes.LOAD_PROGRAM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.LOAD_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        program: action.payload,
      };
    case BookMarkActionTypes.LOAD_PROGRAM_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.LOAD_LEVEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.LOAD_LEVEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        level: action.payload,
      };
    case BookMarkActionTypes.LOAD_LEVEL_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.LOAD_STORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.LOAD_STORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        story: action.payload,
      };
    case BookMarkActionTypes.LOAD_STORY_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.LOAD_LESSON_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.LOAD_LESSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lesson: action.payload,
      };
    case BookMarkActionTypes.LOAD_LESSON_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.LOAD_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.LOAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activity: action.payload,
      };
    case BookMarkActionTypes.LOAD_ACTIVITY_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.POST_PROGRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BookMarkActionTypes.POST_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case BookMarkActionTypes.POST_PROGRESS_ERROR:
      return { ...INITIAL_STATE, isLoading: false };

    case BookMarkActionTypes.CLEAR_BOOKMARK:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
