import { NavigateFunction } from "react-router-dom";
import FilterInterface from "../../../@types/interfaces/api/filters/filter.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  CreateStudent,
  HistoryProgram,
  Student,
  StudentHistory,
  StudentInfo,
  StudentListImport,
  UpdateStudent,
} from "../../../@types/interfaces/api/student.interface";
import StudentsBulkUpdateInterface from "../../../@types/interfaces/api/students-bulk-update.interface";
import { StudentActionTypes } from "./types";

export const loadStudentRequest = (valuesFilter: FilterInterface) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadStudentReportRequest = (valuesFilter: FilterInterface) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_REPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadStudentSuccess = (values: PaginationBase<Student[]>) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_SUCCESS,
    payload: values,
  };
};
export const loadStudentReportSuccess = (values: PaginationBase<Student[]>) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_REPORT_SUCCESS,
    payload: values,
  };
};
export const loadStudentError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_ERROR,
  };
};

export const loadStudentImportRequest = (valuesFilter: FilterInterface) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_IMPORT_REQUEST,
    payload: valuesFilter,
  };
};
export const loadStudentImportSuccess = (values: StudentListImport[]) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_IMPORT_SUCCESS,
    payload: values,
  };
};
export const loadStudentImportError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_IMPORT_ERROR,
  };
};
export const loadStudentInfoRequest = (id: number) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_INFO_REQUEST,
    payload: id,
  };
};
export const loadStudentInfoSuccess = (values: StudentInfo) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_INFO_SUCCESS,
    payload: values,
  };
};
export const loadStudentInfoError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_INFO_ERROR,
  };
};
export const loadStudentHistoryJrRequest = (
  id: number,
  HistoryProgram: HistoryProgram
) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_JR_REQUEST,
    payload: { id, HistoryProgram },
  };
};
export const loadStudentHistoryJrSuccess = (values: StudentHistory[]) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_JR_SUCCESS,
    payload: values,
  };
};
export const loadStudentHistoryJrError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_JR_ERROR,
  };
};

export const loadStudentHistoryVmRequest = (
  id: number,
  HistoryProgram: HistoryProgram
) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_VM_REQUEST,
    payload: { id, HistoryProgram },
  };
};
export const loadStudentHistoryVmSuccess = (values: StudentHistory[]) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_VM_SUCCESS,
    payload: values,
  };
};
export const loadStudentHistoryVmError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_VM_ERROR,
  };
};

export const loadStudentHistoryLsRequest = (
  id: number,
  HistoryProgram: HistoryProgram
) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_LS_REQUEST,
    payload: { id, HistoryProgram },
  };
};
export const loadStudentHistoryLsSuccess = (values: StudentHistory[]) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_LS_SUCCESS,
    payload: values,
  };
};
export const loadStudentHistoryLsError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_HISTORY_LS_ERROR,
  };
};
export const registerStudentRequest = (
  values: CreateStudent,
  navigate: NavigateFunction
) => {
  return {
    type: StudentActionTypes.STUDENT_REGISTER_REQUEST,
    payload: { values, navigate },
  };
};
export const registerStudentSuccess = (isActive: boolean) => {
  return {
    type: StudentActionTypes.STUDENT_REGISTER_SUCCESS,
    payload: isActive,
  };
};
export const registerStudentError = () => {
  return {
    type: StudentActionTypes.STUDENT_REGISTER_ERROR,
  };
};

export const loadStudentByIdRequest = (id: number) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_BY_ID_REQUEST,
    payload: id,
  };
};
export const loadStudentByIdSuccess = (values: Student | undefined) => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_BY_ID_SUCCESS,
    payload: values,
  };
};
export const loadStudentByIdError = () => {
  return {
    type: StudentActionTypes.LOAD_STUDENT_BY_ID_ERROR,
  };
};

export const updateStudentRequest = (
  id: number,
  values: UpdateStudent,
  navigate: NavigateFunction
) => {
  return {
    type: StudentActionTypes.UPDATE_STUDENT_REQUEST,
    payload: { id, values, navigate },
  };
};

export const updateStudentSuccess = () => {
  return {
    type: StudentActionTypes.UPDATE_STUDENT_SUCCESS,
  };
};
export const updateStudentError = () => {
  return {
    type: StudentActionTypes.UPDATE_STUDENT_ERROR,
  };
};

export const bulkUpdateStudentsRequest = (
  users: number[],
  data: StudentsBulkUpdateInterface
) => ({
  type: StudentActionTypes.BULK_UPDATE_STUDENTS_REQUEST,
  payload: { data, users },
});

export const bulkUpdateStudentsSuccess = () => {
  return {
    type: StudentActionTypes.BULK_UPDATE_STUDENTS_SUCCESS,
  };
};
export const bulkUpdateStudentsError = () => {
  return {
    type: StudentActionTypes.BULK_UPDATE_STUDENTS_ERROR,
  };
};

export const clearStudentList = () => {
  return {
    type: StudentActionTypes.CLEAR_STUDENT_LIST,
  };
};

export const removeRedFlagRequest = (id: string) => {
  return {
    type: StudentActionTypes.REMOVE_REDFLAG_REQUEST,
    payload: id,
  };
};
export const removeRedFlagSuccess = () => {
  return {
    type: StudentActionTypes.REMOVE_REDFLAG_SUCCESS,
  };
};

export const removeRedFlagError = () => {
  return {
    type: StudentActionTypes.REMOVE_REDFLAG_ERROR,
  };
};
