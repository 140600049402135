type levelTypes = {
  [key: string]: string;
};

type lessonsTypes = {
  [key: number]: string;
};

export const levelName: levelTypes = {
  RED: "Joseph's Readers Red Level",
  GREEN: "Joseph's Readers Green Level",
  ORANGE: "Joseph's Readers Orange Level",
  TEAL: "Joseph's Readers Teal Level",
  BLUE: "Life Skills Blue Level",
  PURPLE: "Life Skills Purple Level",
  MAROON: "Life Skills Maroon Level",
  YELLOW: "Life Skills Yellow Level",
  "Verbal Master 1": "Verbal Master 1",
  "Verbal Master 2": "Verbal Master 2",
  "Verbal Master 3": "Verbal Master 3",
  "Verbal Master 4": "Verbal Master 4",
};

export const activityName: levelTypes = {
  pre_teaching_lesson: "Pre Teaching Lesson",
  pre_test: "Pre Test",
  post_test: "Post Test",
  talking_book: "Talking Book",
  word_recognition_exercises: "Word Recognition",
  reinforcement_activities: "Reinforcement Activities",
  "independent_activities_drag_&_fill_exercises": "Drag & Fill Exercises",
  independent_activities_matching: "Matching",
  independent_activities_scrambled_sentence: "Scrambled Sentence",
  independent_activities_alphabetical_order: "Alphabetical Order",
  "independent_activities_short_answer_q&a": "Short Answer",
  reading_comprehension: "Reading Comprehension",

  pre_test_verbal_master: "Pre Test Verbal Master",
  post_test_verbal_master: "Post Test Verbal Master",
  word_verbal_master: "Word",
  unit_review_synonym: "Unit Review Synonym",
  unit_review_definition: "Unit Review Definition",
  unit_review_fill_the_blank: "Unit Review Fill the Blank",
  unit_review_comprehension: "Unit Review Reading Comprehension",

  spelling_program: "Spelling Program",
  vocabulary_builder: "Vocabulary Builder",
  minigame: "Mini Game",
};

export const tagText: levelTypes = {
  RAS: "ras",
  DIAG: "diagnostic",
  JR: "1",
  LS: "2",
  VM: "3",
};
