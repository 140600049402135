import {
  Checkbox,
  Flex,
  Heading,
  Image,
  Text,
  Link as ChakraLink,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import bgImage from "../assets/image/bg-login.png";
import InputLogin from "../components/form/InputLogin";
import logo from "../assets/image/logo.png";
import { Form } from "../styles/global";
import { Link, useNavigate } from "react-router-dom";
import RegularButton from "../components/global/RegularButton";
import arrowIcon from "../assets/icons/arrow-rigth.svg";
import { Mail, Key, User, Eye, EyeOff } from "lucide-react";
import ModalLogin from "../components/modals/ModalLogin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginFormSchema } from "../utils/yup-schemas";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { signInRequest } from "../stores/ducks/auth/actions";
import LoginCredentials from "../@types/interfaces/api/login-credencials.interface";
import packageJson from "../../package.json";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSignInLoading } = useAppSelector((i) => i.auth);
  const [modalLoginOpen, setModalLoginOpen] = useState({ isOpen: false });
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LoginCredentials>({
    resolver: yupResolver(loginFormSchema),
  });

  const submitForm = (data: LoginCredentials) => {
    const fullCredentials: LoginCredentials = { ...data, platform: "admin" };
    dispatch(signInRequest(fullCredentials, navigate));
    if (checked) {
      localStorage.setItem("abcx", data.username);
      localStorage.setItem("xyz", data.password);
    } else {
      localStorage.removeItem("abcx");
      localStorage.removeItem("xyz");
    }
  };

  useEffect(() => {
    const dataLocalUsername = localStorage.getItem("abcx");
    const dataLocalPassword = localStorage.getItem("xyz");
    if (dataLocalUsername && dataLocalPassword) {
      setChecked(true);
      reset({
        username: dataLocalUsername,
        password: dataLocalPassword,
      });
    }
  }, []);

  return (
    <Flex w="full" h="100vh" justify="center" align="center">
      <Flex
        w="full"
        h="100vh"
        align="center"
        justify="center"
        bg="white"
        flexDir="column"
      >
        <Form
          onSubmit={handleSubmit(submitForm)}
          style={{
            width: "392px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Image src={logo} />
          <Heading fontSize="32px" mt="40px">
            Access Platform
          </Heading>
          <Text color="gray.500" mt="14px">
            Manage student's experience within the system
          </Text>
          <Flex flexDir="column" gap="16px" w="full" mt="40px">
            <FormControl isInvalid={!!errors.username}>
              <InputGroup>
                <InputLeftElement
                  color="gray.400"
                  children={<User size="20" />}
                  pl="16px"
                  pt="8px"
                />
                <InputLogin
                  placeholder="Username"
                  type="text"
                  {...register("username")}
                  maxLength={30}
                />
              </InputGroup>
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <InputLeftElement
                  color="gray.400"
                  children={<Key size="20" />}
                  pl="16px"
                  pt="8px"
                />
                <InputLogin
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                />
                <InputRightElement
                  cursor="pointer"
                  onClick={() => setShowPassword(!showPassword)}
                  color="gray.400"
                  mt="6px"
                  mr="12px"
                  children={
                    showPassword ? <Eye size="20" /> : <EyeOff size="20" />
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
            justify="space-between"
            gap="60px"
            align="center"
            w="full"
            mt="14px"
          >
            <Checkbox
              colorScheme={"tagScheme"}
              color="gray.500"
              isChecked={checked}
              onChange={(e: any) => setChecked(e.target.checked)}
              whiteSpace="nowrap"
            >
              <Text fontSize="14px">Remember me</Text>
            </Checkbox>
            <ChakraLink
              textDecoration="underline"
              color="blue.300"
              as={Link}
              to="/recover"
              fontSize="14px"
              alignSelf="center"
            >
              Forgot your credentials?
            </ChakraLink>
          </Flex>
          <RegularButton
            w="full"
            mt="32px"
            rightIcon={<Image src={arrowIcon} />}
            type="submit"
            isLoading={isSignInLoading}
          >
            Sign in
          </RegularButton>
        </Form>
        <Text mb="40px" color="gray.400" fontSize="12px">
          © 2005-2022 Failure Free Reading. All rights reserved.
        </Text>
        <Text mb="40px" color="gray.400" fontSize="12px">
          Version {packageJson.version}
        </Text>
      </Flex>
      <Flex
        w="full"
        bgImage={bgImage}
        bgRepeat="no-repeat"
        bgPosition="center"
        backgroundSize="cover"
        h="100vh"
      />
      <ModalLogin
        isOpen={modalLoginOpen.isOpen}
        onClose={() => setModalLoginOpen({ isOpen: false })}
      />
    </Flex>
  );
};

export default Login;
