import { Center, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { School } from "../../@types/interfaces/api/school.interface";
import Layout from "../../components/global/Layout";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import ItemSchoolsTable from "../../components/schools_components/ItemSchoolsTable";
import LayoutToolsSchools from "../../components/schools_components/LayoutToolsSchools";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useBulkSelect } from "../../hooks/useBulkSelect.hook";
import { useSorting } from "../../hooks/useSorting";
import { clearFilter, setFilters } from "../../stores/ducks/filters/actions";
import {
  clearSchoolList,
  loadSchoolsRequest,
} from "../../stores/ducks/school/actions";
import { schoolsTables } from "../../utils/Tables";

const Schools: React.FC = () => {
  const dispatch = useAppDispatch();
  const { schoolsList, isLoadingSchool } = useAppSelector((i) => i.school);
  const { handleItem, handleAll, items, isFullSelected, clearSelections } =
    useBulkSelect<School>();
  const { sorting, listSorted, setListSorted } = useSorting<School>();
  const filterProps = useAppSelector((i) => i.filters);

  const pageCount = Number(schoolsList?.pages);

  const onChangePage = (page: string) => {
    dispatch(
      loadSchoolsRequest({
        page,
        search: filterProps.search,
        is_active__exact: filterProps.isActiveFilter,
      })
    );
    dispatch(setFilters({ ...filterProps, page }));
  };

  useEffect(() => {
    if (schoolsList?.results) {
      setListSorted(schoolsList?.results);
    }
  }, [schoolsList]);
  useEffect(() => {
    return () => {
      dispatch(clearSchoolList());
      dispatch(clearFilter());
    };
  }, []);

  return (
    <Layout text="Schools">
      <LayoutToolsSchools
        selectedItems={items}
        onClickActions={clearSelections}
      />
      {listSorted && !isLoadingSchool ? (
        <CustomTable
          isFullSelected={() => isFullSelected(listSorted)}
          headers={schoolsTables}
          // onClickCheck={() => handleAll(listSorted)}
          onClickHeader={(v) => sorting(v)}
        >
          {listSorted.map((item) => (
            <ItemSchoolsTable
              school={item}
              key={item.id}
              // onClickCheckbox={() => handleItem(item)}
              isSelected={items.some((s) => s.id === item.id)}
            />
          ))}
        </CustomTable>
      ) : (
        <SkeletonTable />
      )}
      {listSorted && listSorted.length <= 0 && (
        <Center mt="32px">
          <Text fontSize="18px" fontWeight="500">
            No items found
          </Text>
        </Center>
      )}
      <Pagination onChangePage={onChangePage} pageCount={pageCount} />
    </Layout>
  );
};

export default Schools;
