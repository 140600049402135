import { Button, Flex, FlexProps } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import ReactPaginate from "react-paginate";
import "./index.css";

interface PaginationPropsI extends FlexProps {
  onChangePage: (page: string) => void;
  pageCount: number;
}

const Pagination: React.FC<PaginationPropsI> = ({
  onChangePage,
  pageCount,
  ...rest
}) => {
  const handlePageClick = (data: any) => {
    const selectedPage = data.selected + 1;
    onChangePage(String(selectedPage));
  };

  return (
    <Flex {...rest} m="auto">
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <Button variant="ghost" borderRadius="50%" p="0" color="#adb5bd">
            <ChevronRight />
          </Button>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <Button variant="ghost" borderRadius="50%" p="0">
            <ChevronLeft color="#adb5bd" />
          </Button>
        }
        containerClassName="pagination"
        pageClassName="page-num"
        pageLinkClassName="page-link"
        activeClassName="active"
      />
    </Flex>
  );
};

export default Pagination;
