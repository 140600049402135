import { Note } from "../../../@types/interfaces/api/note.interface";
import { Profile } from "../../../@types/interfaces/api/profile.interface";

export enum NoteActionTypes {
  CREATE_NOTE_REQUEST = "@note/CREATE_NOTE_REQUEST",
  CREATE_NOTE_SUCCESS = "@note/CREATE_NOTE_SUCCESS",
  CREATE_NOTE_ERROR = "@note/CREATE_NOTE_ERROR",

  LOAD_NOTE_REQUEST = "@note/LOAD_NOTE_REQUEST",
  LOAD_NOTE_SUCCESS = "@note/LOAD_NOTE_SUCCESS",
  LOAD_NOTE_ERROR = "@note/LOAD_NOTE_ERROR",

  DELETE_NOTE_REQUEST = "@note/DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCESS = "@note/DELETE_NOTE_SUCCESS",
  DELETE_NOTE_ERROR = "@note/DELETE_NOTE_ERROR",
}

export interface NoteState extends NoteStateRequestsStatus {
  note: Note | undefined;
  noteList: Note[];
}
export interface NoteStateRequestsStatus {
  isLoadingNote: boolean;
}
