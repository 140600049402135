import {
  Button,
  Flex,
  Progress,
  Stack,
  Tag,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { CheckCircle2, XCircle } from "lucide-react";
import { IdataLessonReport } from "../../@types/interfaces/api/buildReport.interface";

interface ItemLessonTableReportProps {
  data: IdataLessonReport;
}

const ItemLessonTableReport: React.FC<ItemLessonTableReportProps> = ({
  data,
}) => {
  const verifyIcon = () => {
    if (data.is_correct === null) {
      return <></>;
    } else {
      return (
        <Flex w="24px">
          {data.is_correct ? (
            <CheckCircle2 color="#48BB78" size={24} />
          ) : (
            <XCircle color="#df3d3d" size={24} />
          )}
        </Flex>
      );
    }
  };

  return (
    <Tr>
      <Td
        p="16px 16px"
        textTransform="unset"
        whiteSpace="pre-wrap"
        maxW="300px"
      >
        <Text fontWeight="medium">{data.question}</Text>
      </Td>
      <Td
        p="16px 16px"
        textTransform="unset"
        whiteSpace="pre-wrap"
        maxW="300px"
      >
        <Flex align="center" gap="8px">
          {verifyIcon()}

          <Text fontWeight="medium">{data.student_answer}</Text>
        </Flex>
      </Td>

      <Td
        p="16px 16px"
        textTransform="unset"
        whiteSpace="pre-wrap"
        maxW="300px"
      >
        <Text fontWeight="medium">{data.correct_answer}</Text>
      </Td>
    </Tr>
  );
};

export default ItemLessonTableReport;
