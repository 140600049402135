import {
  Table,
  TableBodyProps,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interfaces";

const TableProgressStories: React.FC<ComponentProps> = ({ children }) => {
  return (
    <TableContainer border="1px solid #E2E8F0" borderRadius="16px" mt="16px">
      <Table variant="striped" size="lg" colorScheme="tableScheme">
        <Thead bg="white">
          <Tr>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Story Name
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              fontSize="16px"
              textTransform="unset"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Total Lessons
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Lessons Completed
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Pre-test
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Post-test
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Growth
            </Th>
            <Th
              py="16px"
              px="16px"
              color="blue.600"
              fontWeight="600"
              textTransform="unset"
              fontSize="16px"
              maxW="200px"
              whiteSpace="pre-wrap"
            >
              Total time on task
            </Th>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableProgressStories;
