import { ApexOptions } from "apexcharts";
import { isLabeledStatement } from "typescript";

interface donutsChart {
  chartId: string;
  labels: string[] | undefined;
}

export const donutsChartConfig = ({
  chartId,
  labels,
}: donutsChart): ApexOptions => ({
  chart: {
    type: "donut",
    id: chartId,
    animations: { enabled: false, dynamicAnimation: { enabled: false } },
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
  labels,

  colors: ["#A946E5", "#5E2780"],
});
