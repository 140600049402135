import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateTutorials from "../pages/tutorials/CreateTutorials";
import Tutorials from "../pages/tutorials/Tutorials";

const TutorialsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Tutorials />} />
      <Route path="/create-tutorials" element={<CreateTutorials />}>
        <Route path=":id" element={<CreateTutorials />} />
      </Route>
    </Routes>
  );
};

export default TutorialsRoutes;
