import React from "react";
import { Route, Routes } from "react-router-dom";
import Classroom from "../pages/classroom/Classrooms";
import CreateClassroom from "../pages/classroom/CreateClassroom";
import ViewClassroom from "../pages/classroom/ViewClassroom";
import CreateTeachers from "../pages/teacher/CreateTeachers";
import Teachers from "../pages/teacher/Teachers";
import ViewTeachers from "../pages/teacher/ViewTeachers";

const TeachersRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Teachers />} />
      <Route path="/create-teachers" element={<CreateTeachers />}>
        <Route path=":id" element={<CreateTeachers />} />
        <Route path="" element={<CreateTeachers />} />
      </Route>
      <Route path="/view-teachers" element={<ViewTeachers />}>
        <Route path=":id" element={<ViewTeachers />} />
        <Route path="" element={<ViewTeachers />} />
      </Route>
    </Routes>
  );
};

export default TeachersRoutes;
