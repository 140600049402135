import {
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Classroom } from "../../@types/interfaces/api/classroom.interface";
import { ClassroomCreate } from "../../@types/interfaces/app/forms/create-classroom.interface";
import { useAppSelector } from "../../hooks/redux";
import useTypeDelay from "../../hooks/useTypeDelay.hook";
import {
  clearUsers,
  loadUserByIdRequest,
  loadUsersRequest,
} from "../../stores/ducks/auth/actions";
import {
  registerClassroomRequest,
  updateClassroomRequest,
} from "../../stores/ducks/classroom/actions";
import { loadSchoolsRequest } from "../../stores/ducks/school/actions";
import { Form } from "../../styles/global";
import { classroomFormSchema } from "../../utils/yup-schemas";
import InputLabel from "../form/InputLabel";
import RegularInput from "../form/RegularInput";
import RegularButton from "../global/RegularButton";
import SmallButton from "../global/SmallButton";

interface createClassroomProps {
  classroom: Classroom | undefined;
}

const CreateClassroomForm: React.FC<createClassroomProps> = ({ classroom }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { usersList, user, userById } = useAppSelector((i) => i.auth);
  const { schoolsList } = useAppSelector((i) => i.school);
  const { isLoadingClassroom } = useAppSelector((i) => i.classroom);
  const userMe = useAppSelector((i) => i.auth.user);
  const [teacher, setTeacher] = useState<{
    value: number | null;
    label: string | null;
  }>({
    value: Number(userById?.id),
    label: userById?.first_name ?? "",
  });

  const form = useForm<ClassroomCreate>({
    defaultValues: {
      name: classroom ? classroom.name : "",
      teacher: classroom ? classroom.teacher : 0,
      school: classroom ? classroom.school : 0,
    },
    resolver: yupResolver(classroomFormSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  const teacherPrincipal = ["teacher", "principal"].includes(
    userMe?.user_type as string
  );
  const adminPrincipal = ["admin", "principal"].includes(
    userMe?.user_type as string
  );

  if (userMe && teacherPrincipal) {
    setValue("school", userMe?.school);
  }
  if (userMe && userMe.user_type === "teacher") {
    setValue("teacher", userMe?.id);
  }

  useEffect(() => {
    if (id && classroom?.teacher && userMe?.user_type === "admin") {
      dispatch(loadUserByIdRequest(classroom.teacher));
      dispatch(
        loadUsersRequest({
          user_type__in: "teacher,principal",
          profile__school__id__exact: classroom?.school,
        })
      );
    }

    if (userMe?.user_type === "admin") {
      dispatch(loadSchoolsRequest({ is_active__exact: true }));
    } else {
      if (classroom) {
        dispatch(loadUserByIdRequest(classroom.teacher));
      }

      dispatch(
        loadUsersRequest({
          user_type__in: "teacher,principal",
          profile__school__id__exact: classroom?.school,
        })
      );
    }

    return () => {
      setTeacher({
        value: null,
        label: null,
      });
      dispatch(clearUsers());
    };
  }, [id, classroom]);

  useEffect(() => {
    setTeacher({
      value: Number(userById?.id),
      label: userById?.first_name ?? "",
    });
  }, [userById]);

  const { setData, data } = useTypeDelay(
    (s) => {
      dispatch(loadSchoolsRequest({ search: s }));
    },
    () => {
      dispatch(loadSchoolsRequest({}));
    }
  );
  const { setData: setDataTeacher, data: dataTeacher } = useTypeDelay(
    (s) => {
      dispatch(
        loadUsersRequest({
          user_type__in: "teacher,principal",
          profile__school__id__exact: getValues("school"),
          search: s,
        })
      );
    },
    () => {
      dispatch(
        loadUsersRequest({
          user_type__in: "teacher,principal",
          profile__school__id__exact: getValues("school"),
        })
      );
    }
  );

  const submitForm = (value: ClassroomCreate) => {
    if (id) {
      dispatch(updateClassroomRequest(Number(id), value, navigate));
    } else {
      if (String(value.teacher) !== "0") {
        dispatch(registerClassroomRequest(value, navigate));
      } else {
        toast({
          title: "Erro",
          description: "The field teacher is required",
          status: "error",
        });
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <Flex
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Heading fontWeight="600" fontSize="18px">
          {id ? "Edit Classroom" : "Create New Classroom"}
        </Heading>

        <Flex mt="40px" gap="24px">
          <FormControl isInvalid={!!errors.name}>
            <InputLabel>
              Classroom Name*
              <RegularInput mt="4px" {...register("name")} />
              {<FormErrorMessage>{errors.name?.message}</FormErrorMessage>}
            </InputLabel>
          </FormControl>
          {userMe?.user_type === "admin" && !id && (
            <FormControl isInvalid={!!errors.school}>
              <InputLabel>
                School*
                <Select
                  onChange={(value) => {
                    setValue("school", value!.value);
                    dispatch(
                      loadUsersRequest({
                        user_type__in: "teacher,principal",
                        profile__school__id__exact: value!.value,
                      })
                    );
                  }}
                  placeholder={"Search Scholl:"}
                  onInputChange={(e) => {
                    setData(e);
                  }}
                  options={schoolsList?.results.map((data) => ({
                    label: data.name,
                    value: data.id,
                  }))}
                  isSearchable
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral80: "#718096",
                    },
                  })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      marginTop: "4px",
                      borderRadius: "100px",
                      height: "48px",
                      border: "1px solid #e6e8f0",
                      paddingInline: "12px",
                      fontSize: "16px",
                      color: "#718096",
                      ":hover": {
                        outline: "none",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                    }),
                  }}
                />
                {<FormErrorMessage>{errors.school?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          )}
          {adminPrincipal && (
            <FormControl isInvalid={!!errors.teacher}>
              <InputLabel>
                Teacher/Principal*
                <Flex gap="8px">
                  <Select
                    onChange={(value) => {
                      setValue("teacher", Number(value!.value));
                      setTeacher({
                        value: Number(value?.value),
                        label: value?.label ?? "",
                      });
                    }}
                    value={teacher}
                    placeholder={"Search Teacher:"}
                    onInputChange={(e) => {
                      setDataTeacher(e);
                    }}
                    options={usersList?.results.map((data) => ({
                      label: data.first_name + " " + data.last_name,
                      value: data.id,
                    }))}
                    isSearchable
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral80: "#718096",
                      },
                    })}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        marginTop: "4px",
                        minWidth: "250px",
                        borderRadius: "100px",
                        height: "48px",
                        border: "1px solid #e6e8f0",
                        paddingInline: "12px",
                        fontSize: "16px",
                        color: "#718096",
                        ":hover": {
                          outline: "none",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                      }),
                    }}
                  />

                  <Link to="/teachers/create-teachers">
                    <SmallButton>Create Teacher</SmallButton>
                  </Link>
                </Flex>
                {<FormErrorMessage>{errors.teacher?.message}</FormErrorMessage>}
              </InputLabel>
            </FormControl>
          )}
        </Flex>
      </Flex>

      {/* <SettingsClassroom form={form} /> */}

      <Flex mt="24px" mb="100px" gap="12px" w="full" justifyContent="flex-end">
        <RegularButton type="submit" isLoading={isLoadingClassroom}>
          Save
        </RegularButton>
      </Flex>
    </Form>
  );
};

export default CreateClassroomForm;
