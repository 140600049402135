import { TutorialData } from "../../../@types/interfaces/api/tutorial-data.interface";

export enum TutorialActionTypes {
  CREATE_TUTORIAL_REQUEST = "@Tutorial/CREATE_TUTORIAL_REQUEST",
  CREATE_TUTORIAL_SUCCESS = "@Tutorial/CREATE_TUTORIAL_SUCCESS",
  CREATE_TUTORIAL_ERROR = "@Tutorial/CREATE_TUTORIAL_ERROR",

  LOAD_TUTORIAL_REQUEST = "@Tutorial/LOAD_TUTORIAL_REQUEST",
  LOAD_TUTORIAL_SUCCESS = "@Tutorial/LOAD_TUTORIAL_SUCCESS",
  LOAD_TUTORIAL_ERROR = "@Tutorial/LOAD_TUTORIAL_ERROR",

  DELETE_TUTORIAL_REQUEST = "@Tutorial/DELETE_TUTORIAL_REQUEST",
  DELETE_TUTORIAL_SUCCESS = "@Tutorial/DELETE_TUTORIAL_SUCCESS",
  DELETE_TUTORIAL_ERROR = "@Tutorial/DELETE_TUTORIAL_ERROR",
}

export interface TutorialState extends TutorialStateRequestsStatus {
  tutorial: TutorialData[];
}
export interface TutorialStateRequestsStatus {
  isLoadingTutorial: boolean;
}
