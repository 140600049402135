import { Reducer } from "redux";
import { LibraryActionTypes, LibraryState } from "./types";

const INITIAL_STATE: LibraryState = {
  storyList: [],
  programList: [],
  levelList: [],
  libraryList: [],
  fullFileList: [],
  introList: [],
  isLoadingLibrary: false,
  isLoadingLevel: false,
};

const reducer: Reducer<LibraryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LibraryActionTypes.LOAD_STORY_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.LOAD_STORY_SUCCESS:
      return {
        ...state,
        storyList: action.payload,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.LOAD_STORY_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.LOAD_PROGRAM_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.LOAD_PROGRAM_SUCCESS:
      return {
        ...state,
        programList: action.payload,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.LOAD_PROGRAM_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.LOAD_LEVEL_REQUEST:
      return {
        ...state,
        isLoadingLevel: true,
      };
    case LibraryActionTypes.LOAD_LEVEL_SUCCESS:
      return {
        ...state,
        levelList: action.payload,
        isLoadingLevel: false,
      };
    case LibraryActionTypes.LOAD_LEVEL_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLevel: false,
      };

    case LibraryActionTypes.LOAD_LIBRARY_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.LOAD_LIBRARY_SUCCESS:
      return {
        ...state,
        libraryList: action.payload,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.LOAD_LIBRARY_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.LOAD_FF_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.LOAD_FF_SUCCESS:
      return {
        ...state,
        fullFileList: action.payload,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.LOAD_INTRO_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.LOAD_INTRO_SUCCESS:
      return {
        ...state,
        introList: action.payload,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.LOAD_EXTRA_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.CREATE_FF_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.CREATE_FF_SUCCESS:
      return {
        ...state,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.CREATE_FF_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.CREATE_INTRO_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.CREATE_INTRO_SUCCESS:
      return {
        ...state,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.CREATE_INTRO_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.CREATE_LIBRARY_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.CREATE_LIBRARY_SUCCESS:
      return {
        ...state,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.CREATE_LIBRARY_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.DELETE_LIBRARY_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.DELETE_LIBRARY_SUCCESS:
      return {
        ...state,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.DELETE_LIBRARY_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    case LibraryActionTypes.DELETE_EXTRA_REQUEST:
      return {
        ...state,
        isLoadingLibrary: true,
      };
    case LibraryActionTypes.DELETE_EXTRA_SUCCESS:
      return {
        ...state,
        isLoadingLibrary: false,
      };
    case LibraryActionTypes.DELETE_EXTRA_ERROR:
      return {
        ...INITIAL_STATE,
        isLoadingLibrary: false,
      };

    default:
      return state;
  }
};

export default reducer;
