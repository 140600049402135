import { Checkbox, Flex, Switch, Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../@types/interfaces/api/user.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateUserRequest } from "../../stores/ducks/auth/actions";
import BadgeStatus from "../global/BadgeStatus";
import EditButton from "../global/circle_button/EditButton";
import TrashButton from "../global/circle_button/TrashButton";
import ViewButton from "../global/circle_button/ViewButton";
import MailTo from "../global/MailTo";

interface ItemTeachersTableProps {
  teacher: User;
  isSelected: boolean;
  onClickCheckbox: () => void;
}

const ItemTeachersTable: React.FC<ItemTeachersTableProps> = ({
  teacher,
  isSelected,
  onClickCheckbox,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((i) => i.auth);

  return (
    <Tr>
      <Td w="10px" p="18px 16px">
        <Checkbox
          colorScheme="tagScheme"
          isChecked={isSelected}
          onChange={onClickCheckbox}
        />
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{teacher.first_name}</Text>
      </Td>

      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <MailTo email={teacher?.email}>
          <Text color="primary">{teacher?.email}</Text>
        </MailTo>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{teacher.school}</Text>
      </Td>
      <Td
        py="16px"
        px="10px"
        fontWeight="400"
        color="gray.700"
        maxW="200px"
        textTransform="unset"
        whiteSpace="pre-wrap"
      >
        <Text>{teacher.username}</Text>
      </Td>
      <Td
        py="16px"
        pl="0"
        pr="50px"
        fontWeight="400"
        color="gray.700"
        w="120px"
      >
        <Flex align="center" justify="space-between" gap="16px">
          <BadgeStatus status={teacher.is_active ? "active" : "inactive"} />
          <Switch
            size="sm"
            colorScheme="tagScheme"
            ml="6px"
            isChecked={teacher.is_active}
            onChange={(e: any) =>
              dispatch(
                updateUserRequest(
                  teacher.id,
                  {
                    ...teacher,
                    user_type: "teacher",
                    is_active: e.target.checked,
                  },
                  "teacher"
                )
              )
            }
          />
        </Flex>
      </Td>

      <Td py="16px" pr="16px" fontWeight="400" color="gray.700">
        <ViewButton
          onClick={() => navigate(`/teachers/view-teachers/${teacher.id}`)}
        />
        <EditButton
          ml="4px"
          onClick={() => navigate(`/teachers/create-teachers/${teacher.id}`)}
        />
      </Td>
    </Tr>
  );
};

export default ItemTeachersTable;
