import {
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RegularMenu from "../../components/form/RegularMenu";
import Layout from "../../components/global/Layout";
import MailTo from "../../components/global/MailTo";
import Pagination from "../../components/global/pagination/Pagination";
import SkeletonTable from "../../components/global/skeleton/SkeletonTable";
import ItemStudentTable from "../../components/students_components/ItemStudentTable";
import CustomTable from "../../components/tables/CustomTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadUserByIdRequest,
  loadUserByIdSuccess,
  simulateUserRequest,
} from "../../stores/ducks/auth/actions";
import { loadClassroomRequest } from "../../stores/ducks/classroom/actions";
import {
  loadSchoolsByIdRequest,
  loadSchoolsByIdSuccess,
} from "../../stores/ducks/school/actions";
import { loadStudentRequest } from "../../stores/ducks/student/action";
import { studantTables, teachersTables } from "../../utils/Tables";
import OutlineButton from "../../components/global/OutlineButton";

const ViewTeachers: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { userById, isLoadingUserById, user, isSignInLoading } = useAppSelector(
    (i) => i.auth
  );
  const { schoolsById, isLoadingSchool } = useAppSelector((i) => i.school);
  const { classroomList } = useAppSelector((i) => i.classroom);
  const { studentList, isStudentLoading, isActive } = useAppSelector(
    (i) => i.student
  );
  const students = studentList?.results;

  useEffect(() => {
    if (id) {
      dispatch(loadUserByIdRequest(Number(id)));
      dispatch(loadClassroomRequest({ teacher__id__exact: Number(id) }));
      dispatch(loadStudentRequest({ classroom__id__exact: 0 }));
    }
    return () => {
      dispatch(loadUserByIdSuccess(undefined));
    };
  }, [id]);

  useEffect(() => {
    if (userById) {
      dispatch(loadSchoolsByIdRequest(userById.school));
    }
    return () => {
      dispatch(loadSchoolsByIdSuccess(undefined));
    };
  }, [userById]);

  return (
    <Layout text={"Teachers"}>
      <Flex justify="flex-end" gap="10px">
        {user?.user_type === "admin" && userById && (
          <OutlineButton
            bg="white"
            isLoading={isSignInLoading}
            onClick={() => dispatch(simulateUserRequest(userById?.id))}
          >
            Simulate User
          </OutlineButton>
        )}
      </Flex>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            Teacher Details
          </Heading>
          {user?.user_type !== "teacher" && (
            <IconButton
              w="48px"
              h="48px"
              aria-label="eye"
              borderRadius="full"
              bg="white"
              border="1px solid #E2E8F0"
              icon={<Pencil size="24px" />}
              onClick={() => navigate(`/teachers/create-teachers/${id}`)}
            />
          )}
        </Flex>
        <Text fontWeight="400" fontSize="16px" mt="8px" color="gray.500">
          This is the teacher information
        </Text>
        <Grid mt="34px" gap="16px" templateColumns="repeat(5, 1fr)">
          <GridItem>
            <Text fontWeight="600">First Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Last Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Email Address</Text>
          </GridItem>
          <GridItem>
            {user?.user_type === "admin" && (
              <Text fontWeight="600">School</Text>
            )}
          </GridItem>
          <GridItem></GridItem>

          <GridItem>
            <Text>{userById?.first_name} </Text>
          </GridItem>
          <GridItem>
            <Text>{userById?.last_name}</Text>
          </GridItem>
          <GridItem>
            <MailTo email={userById?.email}>
              <Text color="primary">{userById?.email}</Text>
            </MailTo>
          </GridItem>
          <GridItem>
            {!isLoadingUserById &&
              !isLoadingSchool &&
              user?.user_type === "admin" && <Text>{schoolsById?.name}</Text>}
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </Flex>
      <Flex
        mb="100px"
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex w="full" flexDir="column">
          <Heading fontWeight="600" fontSize="18px">
            {userById?.first_name} Class
          </Heading>
          <Text fontWeight="400" fontSize="16px" mt="16px" color="gray.500">
            This is the class view of teacher {userById?.first_name}
          </Text>
          <Flex mt="24px">
            <RegularMenu
              mt="4px"
              placeholder="Selected Classroom"
              defaultValue={0}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  dispatch(
                    loadStudentRequest({ classroom__id__exact: e.target.value })
                  );
                }
              }}
            >
              {classroomList?.results.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </RegularMenu>
          </Flex>
        </Flex>
        {studentList ? (
          <CustomTable
            headers={studantTables}
            onClickHeader={(v) => console.log(v)}
            onClickCheck={() => console.log()}
          >
            {students?.map((item) => (
              <ItemStudentTable key={item.user} student={item} />
            ))}
          </CustomTable>
        ) : (
          <SkeletonTable />
        )}

        {students && students.length <= 0 && (
          <Center mt="32px">
            <Text fontSize="18px" fontWeight="500">
              No items found
            </Text>
          </Center>
        )}
      </Flex>
      {/* <Pagination onChangePage={onChangePage} pageCount={pageCount} /> */}
    </Layout>
  );
};

export default ViewTeachers;
