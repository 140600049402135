export enum FilterActionTypes {
  SET_FILTER = "@filter/SET_FILTER",
  CLEAR_FILTER = "@filter/CLEAR_FILTER",
}

export interface FilterState {
  search?: string;
  isActiveFilter?: boolean | null;
  redFlag?: boolean | null;
  page?: string;
  school?: number | undefined;
  filterLabel?: string;
  schoolName?: string;
}
