import {
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Pencil } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/Layout";
import MailTo from "../../components/global/MailTo";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { loadUserByIdRequest } from "../../stores/ducks/auth/actions";

const ViewAdmin: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const user = useAppSelector((s) => s.auth.userById);

  useEffect(() => {
    if (!id) return;
    dispatch(loadUserByIdRequest(Number(id)));
  }, []);

  return (
    <Layout text={"Administrator"}>
      <Flex
        mt="24px"
        w="full"
        p="24px"
        bg="white"
        flexDir="column"
        borderRadius="16px"
        boxShadow=" 2px 2px 48px 1px rgba(17, 20, 61, 0.06)"
      >
        <Flex justify="space-between" w="full" align="center">
          <Heading fontWeight="600" fontSize="18px">
            Administrator Details
          </Heading>

          <IconButton
            w="48px"
            h="48px"
            aria-label="eye"
            borderRadius="full"
            bg="white"
            border="1px solid #E2E8F0"
            icon={<Pencil size="24px" />}
            onClick={() => navigate("/admin/create-admin")}
          />
        </Flex>
        <Text fontWeight="400" fontSize="16px" color="gray.500">
          This is the administrator information
        </Text>
        <Grid mt="34px" gap="16px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Text fontWeight="600">First Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Last Name</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Email Address</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="600">Username</Text>
          </GridItem>

          <GridItem>
            <Text>{user?.first_name}</Text>
          </GridItem>
          <GridItem>
            <Text>{user?.last_name}</Text>
          </GridItem>
          <GridItem>
            <MailTo email={user?.email}>
              <Text color="primary">{user?.email}</Text>
            </MailTo>
          </GridItem>
          <GridItem>
            <Text>{user?.username}</Text>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </Flex>
    </Layout>
  );
};

export default ViewAdmin;
